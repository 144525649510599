import _ from 'lodash';
import { toast } from 'react-toastify';
import { OhsApiResponseType } from '../../../common/models/OhsApiModels';
import ohsApiClient from '../../../common/OhsApi';
import {
  OhsResendCodeEnroll,
  OhsUserMFAOTPTypes,
  UserServicesMFAProps,
} from './OhsUserSettingsModels';

export const mfaChallenge = (
  mfaMethod: string,
  methodData: UserServicesMFAProps
): Promise<OhsApiResponseType<OhsUserMFAOTPTypes>> => {
  let endpoint = `/users/mfa/challenge`;

  const errMsg = `Error`;
  return ohsApiClient
    .post(endpoint, { method: mfaMethod })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        let toasterTxt = '';
        const resMethod = response.data.result.method;
        if (resMethod === 'email' || resMethod === 'sms') {
          toasterTxt = `Authentication code has been sent ${
            response.data.result.method === 'email'
              ? 'to your email: ' + methodData.email
              : 'via SMS: ' + methodData.sms
          }`;
        }

        const appIfFirstTime = response.data.result.firstTimeEnroll ? `` : '';
        if (resMethod === 'app') {
          toasterTxt = `Please get the authentication code generated from your authenticator app. ${appIfFirstTime}`;
        }
        toast.success(toasterTxt);

        const genResponse = {
          ...response.data,
          data: response.data.result,
        };
        return genResponse;
      } else {
        const genResponse = {
          ...response.data,
        };
        toast.error(response.data.error.message[1]);
        return genResponse;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};

export const mfaOTPEnrollment = (
  mfaEnrollInfo: OhsUserMFAOTPTypes | OhsResendCodeEnroll,
  actionType: 'enroll' | 'confirm'
): Promise<OhsApiResponseType<OhsUserMFAOTPTypes>> => {
  let endpoint = '';
  if (actionType === 'confirm') {
    endpoint = `/users/mfa/enroll/confirm`;
  } else {
    endpoint = `/users/mfa/enroll`;
  }

  const errMsg = `Error`;
  return ohsApiClient
    .post(endpoint, mfaEnrollInfo)
    .then((response) => {
      let genResponse: any;
      if (_.has(response, 'data.success') && response.data.success === true) {
        genResponse = {
          success: response.data.success,
          data: response.data.result,
        };
        let successMsg = '';
        if (actionType === 'confirm') {
          successMsg = `Authentication method has been activated`;
        } else {
          let methodSuccess = '';
          switch (response.data.result.method) {
            case 'email':
              methodSuccess =
                'to your email: ' +
                mfaEnrollInfo.destination +
                ' to enable the new method';
              break;
            case 'sms':
              methodSuccess =
                'via SMS: ' +
                mfaEnrollInfo.destination +
                ' to enable the new method';
              break;
            case 'app':
              methodSuccess = 'authenticator app';
              break;
            default:
              methodSuccess = '';
          }
          const confirmToasterTxt = `Another authentication code has been sent ${methodSuccess}`;
          successMsg = confirmToasterTxt;
        }
        toast.success(successMsg);
      } else {
        genResponse = {
          success: false,
          error: response.data.error,
        };
        toast.error(response.data.error.message[1]);
      }
      return genResponse;
    })
    .catch(function (error) {
      const errorResponse = {
        success: false,
        error: error.error,
      };
      toast.error(errMsg);
      return errorResponse;
    });
};

export const mfaResetEnrollment = (): Promise<boolean> => {
  let endpoint = `/users/mfa/reset`;

  const errMsg = `Error`;
  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        toast.success('Multi-Factor Authentication has been disabled');
        return true;
      } else {
        console.log(response);
        toast.error(errMsg);
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return false;
    });
};
