import React, { useEffect } from 'react';
import * as _ from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import OhsModalHeader from '../../admin2/copy/OhsModalHeader';
import { Button } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    background: '#F6F8FA',
    borderTop: '1px solid #eaeaea',
    padding: '1rem',
    '& > *': {
      '&:last-child': {
        marginRight: '0',
      },
    },
  },
  contentContainer: {
    padding: '1rem',
  },
}));
interface Props {
  open: boolean;
  title?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  headerColor?: string;
  setModalOpenFunc?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  ContentNode: React.ReactNode;
  actionNode?: React.ReactNode;
}

function OhsModal(props: Props) {
  const [title, setTitle] = React.useState('');
  const { classes } = useStyles();

  const initialSetting = () => {
    if (props.title) {
      setTitle(props.title);
    }
  };

  const handleClose = () => {
    if (props.setModalOpenFunc) props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.open) {
      initialSetting();
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={props.maxWidth != null ? props.maxWidth : 'md'}
      fullWidth={true}
    >
      <OhsModalHeader
        title={props.title ? props.title : ' '}
        onCloseFn={handleClose}
        headerColor={props.headerColor}
      />
      <DialogContent className={classes.contentContainer}>
        {props.ContentNode}
      </DialogContent>
      {props.actionNode && (
        <DialogActions className={classes.footerContainer}>
          {props.actionNode}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default OhsModal;
