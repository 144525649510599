export interface OhsApiResponseType<RESPONSE_DATA> {
  success: boolean;
  error?: {
    code: string[];
    message: string[];
  };
  data?: RESPONSE_DATA;
}

export enum OhsApiWarningCodes {
  duplicates_not_allowed = 'duplicates_not_allowed',
}

export interface OhsApiErrorSet {
  code: OhsApiWarningCodes;
  message: string;
}
