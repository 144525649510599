import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from '../leftSideDrawer/OhsLeftSideDrawerModuleList';
import { OhsBreadcrumbsNameAndRef } from './OhsBreadcrumbsServices';

export const OhsBreadCrumbContractor = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  user: OhsUser
) => {
  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.contractor?.TITLE ?? '',
    };
  } else if (subPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).contractor,
      name: user.configs.contractor?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'New' };
  } else if (viewPath === 'view') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).contractor,
      name: user.configs.contractor?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (viewPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).contractor,
      name: user.configs.contractor?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/view?tab=detail&type=myContractor`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  }
  return ohsBreadcrumbsNameAndRef;
};
