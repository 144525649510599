import { getConfigValue } from './OhsConfigServices';
import { ModuleType } from '../../models/OhsObject';
import { setPermissions } from './OhsConfigurations';
import { OhsModuleConfig } from './OhsModuleConfig';
import { OhsModulePermissions } from './OhsModulePermissions';
export class OhsSafetyplanConfig implements OhsModuleConfig {
  TITLE: string;
  PERMISSIONS: OhsModulePermissions;
  MORPH_PERMISSIONS: OhsModulePermissions;
  TYPE = ModuleType.SafetyPlan;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(
      this.PERMISSIONS,
      getConfigValue(config, 'MORPH_PERMISSIONS')
    );
  }
}
