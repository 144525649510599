import React from 'react';
import OhsRegister from '../../global/register/OhsRegister';
import { TierType } from '../../models/tier/OhsTier';
import {
  OhsAllocatableRecord,
  OhsAllocationTierRecord,
  OhsSafetyPlanRecord,
} from '../OhsSafetyPlanModels';
import {
  getSafetyPlanAllocationList,
  allocateSafetyPlan,
  deallocateSafetyPlan,
} from '../OhsSafetyPlanServices';

import OhsAllocationModal from '../../global/modal/OhsAllocationModal';
import { OhsRegisterRecord } from '../../global/register/table/OhsRegisterModels';
import { ModuleType } from '../../models/OhsObject';
import OhsDeleteModal, {
  handleDeleteEndpoint,
} from '../../global/modal/OhsDeleteModal';
import { getOhsUser } from '../../user/OhsUser';
type Props = {
  safetyPlanList: Array<OhsSafetyPlanRecord>;
  reloadSafetyPlanList: any;
};
export default function OhsSafetyPlanList(props: Props) {
  const viewFunc = (item: OhsRegisterRecord) => {
    switch (item.type) {
      case ModuleType.SafetyPlan:
        {
          let url = '/safety-plan/' + item._id;
          if (item.tier && item.tier.type === TierType.T3) {
            url += '/orgDetails';
          } else {
            url += '/details';
          }
          window.open(url, '_self');
        }
        break;

      default:
        break;
    }
  };
  const [modalOpen, setModalOpen] = React.useState(false);
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [allocationList, setAllocationList] = React.useState(
    new Array<OhsAllocationTierRecord>()
  );
  const [safetyPlanIdOperated, setSafetyPlanIdOperated] = React.useState('');
  const [safetyPlanNameOperated, setSafetyPlanNameOperated] =
    React.useState('');

  const [safetyPlanList, setSafetyPlanList] = React.useState(
    props.safetyPlanList
  );

  React.useEffect(() => {
    setSafetyPlanList(props.safetyPlanList);
  }, [props.safetyPlanList]);

  const openAllocationModalFunc = async (item: OhsAllocatableRecord) => {
    setSafetyPlanIdOperated(item._id);
    await loadAlocationList(item._id);
    setModalTitle('Allocate Task: ' + item.name);
    setModalOpen(true);
  };

  const loadAlocationList = async (id: string) => {
    return getSafetyPlanAllocationList(id).then(
      (allocationRecordList: OhsAllocationTierRecord[]) => {
        setAllocationList(allocationRecordList);
        return allocationRecordList;
      }
    );
  };

  const openDeleteModalFunc = (item: OhsAllocatableRecord) => {
    setSafetyPlanIdOperated(item._id);
    setSafetyPlanNameOperated(item.name);
    setDelModalOpen(true);
  };
  const handleDeleteFunc = async () => {
    await handleDeleteEndpoint(ModuleType.SafetyPlan, safetyPlanIdOperated);
    window.location.reload();
  };
  const user = getOhsUser();

  const archiveFunc = (item: OhsAllocatableRecord, isArchive: boolean) => {};

  const onModalClose = async () => {
    props.reloadSafetyPlanList();
  };
  const columns = React.useMemo(
    () => [
      {
        id: 'nameId',
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Frequency',
        accessor: 'frequencyDisplay',
      },
      {
        Header: 'Details',
        accessor: 'description',
      },
      {
        Header: 'Workplaces',
        accessor: 'tier.name',
      },
    ],
    []
  );

  return (
    <>
      <OhsRegister
        columns={columns}
        data={safetyPlanList}
        sortBy={[{ id: 'nameId', desc: false }]}
        hasFilters="statusfilter"
        hasExpanding={true}
        rowOperations={{
          viewFunc: viewFunc,
          openAllocationModalFunc: openAllocationModalFunc,
          openDeleteModalButton: {
            func: openDeleteModalFunc,
            permission: user?.configs.safetyplan?.PERMISSIONS.edit === true,
          },
          archiveButton: {
            func: archiveFunc,
            permission: user?.configs.safetyplan?.PERMISSIONS.archive === true,
          },
        }}
      ></OhsRegister>
      <OhsAllocationModal
        title={modalTitle}
        id={safetyPlanIdOperated}
        open={modalOpen}
        allocationList={allocationList}
        loadAlocationList={loadAlocationList}
        setAllocationList={setAllocationList}
        allocationFunc={allocateSafetyPlan}
        deAllocationFunc={deallocateSafetyPlan}
        setModalOpen={setModalOpen}
        onClose={onModalClose}
      />
      <OhsDeleteModal
        open={delModalOpen}
        setModalOpenFunc={setDelModalOpen}
        moduleTitle={user?.configs.safetyplan?.TITLE!}
        recordName={safetyPlanNameOperated}
        handleDeleteFunc={handleDeleteFunc}
      />
    </>
  );
}
