import { IconButton, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { Icon, SemanticCOLORS } from 'semantic-ui-react';
interface Props {
  message: string;
  color?: SemanticCOLORS;
}
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
export default function OhsTooltip(props: Props) {
  return (
    <HtmlTooltip
      style={{ verticalAlign: 'text-bottom', height: '14px' }}
      title={props.message}
      placement="top"
      arrow
    >
      <IconButton size="small">
        <Icon
          size="tiny"
          circular
          inverted
          color={props.color}
          name="help"
        ></Icon>
      </IconButton>
    </HtmlTooltip>
  );
}
