import ohsApiClient from '../common/OhsApi';
import { OhsConstants } from '../global/constants/OhsConstants';
import * as _ from 'lodash';
import {
  arrayToObj,
  angularSetLocalStorageConfig,
  getAvailableModule,
  getAngularLocalStorageConfig,
  setAngularLocalStorage,
  getCookie,
  delCookie,
  resetUserData,
  SsoFlagName,
} from '../common/OhsDataParse';
import Cookies from 'js-cookie';
import getMixpanelTraker from '../common/mixpanel/OhsMixpanel';
import { OhsLogout } from '../login/OhsLoginServices';
import { toast } from 'react-toastify';
import { OhsConfigurations } from './configurations/OhsConfigurations';
import { OhsTier } from '../models/tier/OhsTier';
import { OhsMFAsettings } from './OhsUser';

export const handleLoginResponse = (res: any) => {
  localStorage.removeItem('multi-users');
  ohsApiClient.defaults.headers.common['Authorization'] = res.result.token;
};

export const loginUser = (email: string, password: string, token?: string) => {
  let endpoint = '/users/authenticate';
  if (token) {
    endpoint = '/users/azure-ad-oidc';
    const head = 'Bearer ' + token;
    ohsApiClient.defaults.headers.common['Authorization'] = head;
  }

  return ohsApiClient
    .post(endpoint, {
      email: email,
      password: password,
    })
    .then(function (response) {
      if (response.data.result.mfa) {
        let toasterTxt = '';
        const { method, destination } = response.data.result.mfa;
        if (method === 'email' || method === 'sms') {
          toasterTxt = `Authentication code has been sent ${
            method === 'email'
              ? 'to your email: ' + destination
              : 'via SMS: ' + destination
          }`;
          toast.success(toasterTxt);
        }

        return response.data;
      } else if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.token
      ) {
        if (token) {
          setAngularLocalStorage(SsoFlagName, true);
        } else {
          setAngularLocalStorage(SsoFlagName, false);
        }
        handleLoginResponse(response.data);
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const updateUseOrgDetails = (authResponse: any) => {
  return getUseOrgDetails().then((useOrgDetailsResponse: any) => {
    if (_.has(useOrgDetailsResponse, 'data.result')) {
      const userInfo = combineUserInfoAndUserOrgInfo(
        authResponse,
        useOrgDetailsResponse.data.result
      );
      initUserObject(userInfo);
      const mixpanel = getMixpanelTraker();
      try {
        mixpanel?.track('Login');
      } catch (e) {
        console.error(e);
      }
      var isTranslationOn = getAngularLocalStorageConfig(
        'l10nConfig',
        'LANGUAGE_SELECTION'
      );
      var lanInCookies = getCookie('googtrans');
      if (isTranslationOn !== true && lanInCookies != null) {
        delCookie('googtrans');
      }
      return userInfo;
    } else {
      return null;
    }
  });
};

export const loginMultiUser = (userId: string) => {
  let endpoint = '/users/multiuser/authorize';

  return ohsApiClient
    .post(endpoint, {
      user: { _id: userId },
    })
    .then(function (response) {
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.token
      ) {
        ohsApiClient.defaults.headers.common['Authorization'] =
          response.data.result.token;
        setAngularLocalStorage('isMorph', false);

        return response.data;
      } else {
        OhsLogout();
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};
export const getUseOrgDetails = () => {
  return ohsApiClient
    .get('/users/whoami')
    .then(function (response) {
      if (response.data.result.success === true) {
        return response.data.result;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

// getModuleConfigValue([],'core.module.notification','NOTIFY_SUBSCRIBE_EMAIL')
export const getModuleConfigValue = (
  configLists: any[],
  moduleType: string,
  name: string
) => {
  const getModule = configLists.find(
    (module: any) => module.type === moduleType
  );
  if (!getModule) {
    return null;
  }
  const getConfigValue = getModule.values.find((val: any) => val.name === name);
  return getConfigValue.value;
};

export const initUpdateUserConfigObject = function (data: any) {
  // Update User Module Config Localstorage values ONLY
  const configList = data.result.user.configuration;
  let modulePermission: any = {},
    lowTierPermission: any = {};
  _.forEach(configList, function (confItem) {
    _.forEach(confItem.values, function (confValue) {
      if (confValue.name === 'PERMISSIONS') {
        if (confValue.value === '*') {
          let tierType = 'core.tier.T' + data.result.user.tierNum;
          _.forEach(
            OhsConstants.adminPermissionList[tierType],
            function (item, key) {
              if (key === confItem.type) {
                confValue.value = item;
              }
            }
          );
        }
        // 12 remove 'core.module'
        modulePermission[confItem.type.substring(12)] = arrayToObj(
          confValue.value
        );
      } else if (
        confValue.name === 'MORPH_PERMISSIONS' &&
        data.result.user.tier.type === 'core.tier.T3'
      ) {
        if (confValue.value === '*') {
          _.forEach(OhsConstants.morphPermission, function (item, key) {
            if (key === confItem.type) {
              confValue.value = item;
            }
          });
        }
        // 12 remove 'core.module'
        lowTierPermission[confItem.type.substring(12)] = arrayToObj(
          confValue.value
        );
      }
    });
    angularSetLocalStorageConfig(confItem.type, confItem.values);
  });
};

export const initUserObject = function (data: any) {
  //reset data
  resetUserData();

  //setUserPayloadConfig
  setAngularLocalStorage('userPayloadConfig', data.result);
  // user level
  if (data.result.user != null) {
    if (
      data.result.user.orgAdmin != null &&
      data.result.user.orgAdmin._id === OhsConstants.FreemiumOrgId
    ) {
      data.result.user.level = 'free';
    } else {
      data.result.user.level = 'paid';
    }
  }

  // make the current tier easier for controllers to digest and compare
  data.result.user.tierNum = parseInt(data.result.user.tier.type.substr(-1));

  /* trim down the user object before it is stored in a cookie
         as part of this we are storage all of the config items into session variables
         through a config service
      */
  setAngularLocalStorage(
    'configObj',
    JSON.stringify(data.result.user.configuration)
  );
  /*
          Because limited cookies and desynchrony of $localStorage, all modules' permission
          configurations would be trimed here, and trimed obj would be under user's object.
      */
  let modulePermission: any = {},
    lowTierPermission: any = {};
  _.forEach(data.result.user.configuration, function (confItem) {
    _.forEach(confItem.values, function (confValue) {
      if (confValue.name === 'PERMISSIONS') {
        if (confValue.value === '*') {
          let tierType = 'core.tier.T' + data.result.user.tierNum;
          _.forEach(
            OhsConstants.adminPermissionList[tierType],
            function (item, key) {
              if (key === confItem.type) {
                confValue.value = item;
              }
            }
          );
        }
        // 12 remove 'core.module'
        modulePermission[confItem.type.substring(12)] = arrayToObj(
          confValue.value
        );
      } else if (
        confValue.name === 'MORPH_PERMISSIONS' &&
        data.result.user.tier.type === 'core.tier.T3'
      ) {
        if (confValue.value === '*') {
          _.forEach(OhsConstants.morphPermission, function (item, key) {
            if (key === confItem.type) {
              confValue.value = item;
            }
          });
        }
        // 12 remove 'core.module'
        lowTierPermission[confItem.type.substring(12)] = arrayToObj(
          confValue.value
        );
      }
    });
    angularSetLocalStorageConfig(confItem.type, confItem.values);
  });

  let availableModules = getAvailableModule(false);
  let moduleAccessible: any[] = [];

  _.forEach(availableModules, function (moduleName) {
    let fullModuleName = 'core.module.' + moduleName;
    let singleModule: any = {};
    let configString = moduleName + 'Config';
    singleModule.type = fullModuleName;
    let modTitle = getAngularLocalStorageConfig(configString, 'TITLE');

    if (modTitle !== undefined && modTitle !== null) {
      singleModule.homeUrl = OhsConstants.ohsModuleHomeUrl[fullModuleName];
      singleModule.title = modTitle;
      singleModule.permission = modulePermission[moduleName];
      moduleAccessible.push(singleModule);
    }
  });
  //put ORG_RECORDS_ACCESS
  let moduleOrgRecordsAccess: any = null;
  _.forEach(data.result.user.configuration, function (confItem) {
    _.forEach(confItem.values, function (confValue) {
      if (confValue.name === 'ORG_RECORDS_ACCESS') {
        if (moduleOrgRecordsAccess == null) {
          moduleOrgRecordsAccess = {};
        }
        moduleOrgRecordsAccess[confItem.type] = confValue.value;
      }
    });
  });

  delete data.result.user.configuration;
  data.result.user.moduleAccessible = moduleAccessible;
  if (moduleOrgRecordsAccess != null) {
    data.result.user.moduleOrgRecordsAccess = moduleOrgRecordsAccess;
  }
  data.result.user.modulePermission = modulePermission;
  if (data.result.user.tier.type === 'core.tier.T3') {
    data.result.user.lowTierPermission = lowTierPermission;
  }
  data.result.user.subWorkplaceAccess = getAngularLocalStorageConfig(
    'admin2Config',
    'LOWER_TIER_ACCESS'
  );

  setAngularLocalStorage('filterList', {});
  setAngularLocalStorage('user', data.result.user);
  if (data.result.user.tierNum === 5 && data.result.hierarchy) {
    setAngularLocalStorage('userHierarchy', JSON.parse(data.result.hierarchy));
  }
  /*
            TODO - clean up the user object so that references to tier no longer use the User object
            delete data.result.user.tier;
          */

  if (data.result.token) {
    // set token and expired in one day
    Cookies.set('token', data.result.token, {
      expires: OhsConstants.SessionDay,
    });
  }
  //init Mixpanel
  //   ohsMixpanelService.initMixpanel(data.result.user);
};

export const combineUserInfoAndUserOrgInfo = (
  userInfo: any,
  userOrgInfo: any
) => {
  if (
    userInfo &&
    userInfo.result &&
    userInfo.result.item &&
    userInfo.result.user == null
  ) {
    userInfo.result.user = userInfo.result.item;
    delete userInfo.result.item;
  }
  if (userOrgInfo != null) {
    userInfo.result.user.orgAdmin = userOrgInfo.orgAdmin;
    userInfo.result.user.org = userOrgInfo.org;
    userInfo.result.user.workplace = userOrgInfo.workplace;
    userInfo.result.user.workerGroup = userOrgInfo.workerGroup;
  }
  return userInfo;
};

export interface OhsUserResponseTypes {
  configuration: OhsConfigurations[];
  email: string;
  name: string;
  phone: string;
  phoneCountryCode: string;
  tier: OhsTier;
  type: string;
  _id: string;
}
