import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OhsDashboardBannerContentButton } from './banner/OhsDashboardBannerModels';

export type bannerContentType = {
  message: string;
  messageColor: string;
  bannerColors: Array<string>;
  buttons?: Array<OhsDashboardBannerContentButton>;
  isPreview?: boolean;
  removeBannerOnClick?: () => void;
};
export interface OhsDashboardState {
  leftSideDrawerToggleOpen: boolean;
  rightSideDrawerToggleOpen: boolean;
  topFilterDrawerToggleOpen: boolean;
  topBannerToggleOpen: boolean;
  topBannerTogglePreviewOpen: boolean;
  topBannerContentPreview: bannerContentType;
  isShowingBanner: boolean;
}
const initialState = {
  leftSideDrawerToggleOpen: false,
  rightSideDrawerToggleOpen: false,
  topFilterDrawerToggleOpen: false,
  topBannerToggleOpen: false,
  topBannerTogglePreviewOpen: false,
  topBannerContentPreview: {},
  isShowingBanner: false,
} as OhsDashboardState;

const dashbaordSlice = createSlice({
  name: 'dashbaord',
  initialState,
  reducers: {
    toggleLeftSideDrawer: (state, action: PayloadAction<boolean>) => {
      state.leftSideDrawerToggleOpen = action.payload;
    },
    toggleRightSideDrawer: (state, action: PayloadAction<boolean>) => {
      state.rightSideDrawerToggleOpen = action.payload;
    },
    toggleTopFilterDrawer: (state, action: PayloadAction<boolean>) => {
      state.topFilterDrawerToggleOpen = action.payload;
    },
    toggleTopBanner: (state, action: PayloadAction<boolean>) => {
      state.topBannerToggleOpen = action.payload;
    },
    toggleTopBannerPreview: (state, action: PayloadAction<boolean>) => {
      state.topBannerTogglePreviewOpen = action.payload;
    },
    setBannerPreviewContent: (
      state,
      action: PayloadAction<bannerContentType>
    ) => {
      state.topBannerContentPreview = action.payload;
    },
    setIsShowingBanner: (state, action: PayloadAction<boolean>) => {
      state.isShowingBanner = action.payload;
    },
  },
});

export const {
  toggleLeftSideDrawer,
  toggleRightSideDrawer,
  toggleTopFilterDrawer,
  toggleTopBanner,
  toggleTopBannerPreview,
  setBannerPreviewContent,
  setIsShowingBanner,
} = dashbaordSlice.actions;
export const dashboardReducer = dashbaordSlice.reducer;
