import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import { OhsTier } from '../models/tier/OhsTier';
import { ModuleType } from '../models/OhsObject';
import { getOhsUser } from '../user/OhsUser';
export interface OhsInspectionTaskRecord {
  completed: boolean;
}

export class OhsInspectionStart {
  dateCommenced?: string;
  tz: string;
  attachments: OhsAttachment[];
  constructor() {
    this.tz = '';
    this.attachments = new Array<OhsAttachment>();
  }
}

export class OhsQuickInspection {
  dateDue: string;
  notes: string;
  attachments: OhsAttachment[];
  constructor() {
    this.dateDue = '';
    this.notes = '';
    this.attachments = new Array<OhsAttachment>();
  }
}

export class OhsInspectionRecord {
  _id: string;
  allocatedToSelf: boolean;
  category: string;
  categoryOther: string | null;
  dateCreated: string;
  description: string;
  frequencyKey: string;
  frequencyValue: number;
  subcategory: string;
  subcategoryOther: string | null;
  tier: OhsTier;
  title: string;
  type: ModuleType.Inspection;
  tzDateCreated: string;
  execute: {
    task: {
      dateDue: string;
      inExecution: boolean;
      reference: string;
      _id: string;
    };
  };
  constructor() {
    const user = getOhsUser();
    this._id = '';
    this.allocatedToSelf = false;
    this.category = '';
    this.categoryOther = null;
    this.dateCreated = '';
    this.description = '';
    this.frequencyKey = '';
    this.frequencyValue = 0;
    this.subcategory = '';
    this.subcategoryOther = null;
    this.tier = user!.tier;
    this.title = '';
    this.type = ModuleType.Inspection;
    this.tzDateCreated = '';
    this.execute = {
      task: {
        dateDue: '',
        inExecution: false,
        reference: '',
        _id: '',
      },
    };
  }
}
