import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from './OhsAuthenticate';

interface IProps {
  children: ReactNode;
}

const OhsRequireAuth = ({ children }: IProps): any => {
  const [isLoggedIn, setIsLogin] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLogin(isAuthenticated());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return isAuthenticated() && isLoggedIn ? (
    children
  ) : (
    <Navigate to={'/login'} state={{ from: location }} replace={true} />
  );
};

export default OhsRequireAuth;
