import * as _ from 'lodash';
import shortid from 'shortid';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import { OhsCountry } from '../global/utils/OhsCountry';
import { setTierName, TierType } from '../models/tier/OhsTier';
import { OhsStatusFilterOption } from '../global/register/table/filters/OhsFilterTypes';
import { OhsAllocatableRecord } from '../safety-plan/OhsSafetyPlanModels';
import { getOhsUser } from '../user/OhsUser';
import { OhsRegisterTabItemTypes } from '../global/tab/OhsTabModels';
import {
  differenceInHours,
  formatDuration,
  intervalToDuration,
  isAfter,
  isToday,
  isYesterday,
} from 'date-fns';

export const arrayToObj = (array: any, mode?: string): any => {
  let obj: any = {};
  let key: string;
  if (mode == null) {
    _.forEach(array, function (value) {
      obj[value] = true;
    });
  } else {
    _.forEach(array, function (value) {
      key = value[mode];
      obj[key] = value;
    });
  }
  return obj;
};
export const AngularLocalStoragePrefix = 'ngStorage-';
export const angularSetLocalStorageConfig = (
  itemName: string,
  itemValues: any
) => {
  // remove core.module. with is 12
  const configName =
    AngularLocalStoragePrefix + itemName.substring(12) + 'Config';
  localStorage.setItem(configName, JSON.stringify(itemValues));
};

export const setAngularLocalStorage = (itemName: string, itemValues: any) => {
  // remove core.module. with is 12
  const configName = AngularLocalStoragePrefix + itemName;
  if (typeof itemValues === 'string') {
    localStorage.setItem(configName, itemValues);
  } else {
    localStorage.setItem(configName, JSON.stringify(itemValues));
  }
};
export const getAngularLocalStorage = (itemName: string): any => {
  // remove core.module. with is 12
  const configName = AngularLocalStoragePrefix + itemName;
  if (localStorage.getItem(configName)) {
    return JSON.parse(localStorage.getItem(configName)!);
  } else {
    return null;
  }
};
export const setLocalStorage = (itemName: string, itemValues: any) => {
  // remove core.module. with is 12
  const configName = itemName;
  if (typeof itemValues === 'string') {
    localStorage.setItem(configName, itemValues);
  } else {
    localStorage.setItem(configName, JSON.stringify(itemValues));
  }
};
export const getLocalStorage = (itemName: string): any => {
  // remove core.module. with is 12
  const configName = itemName;
  if (localStorage.getItem(configName)) {
    return JSON.parse(localStorage.getItem(configName)!);
  } else {
    return null;
  }
};
export const removeLocalStorage = (itemName: string): any => {
  return localStorage.removeItem(itemName);
};
export const getAvailableModule = (bool: boolean): any => {
  let modules1: string[] = [];
  let modules2: any[] = [];
  let modules3: any = {};
  let modules4: any[] = [];
  let configObj = getAngularLocalStorage('configObj');
  _.forEach(configObj, function (obj) {
    // 12 remove 'core.module'
    modules1.push(obj.type.substring(12));
  });
  _.forEach(configObj, function (obj) {
    modules2.push(obj.type);
  });
  _.forEach(configObj, function (obj) {
    // 12 remove 'core.module'
    modules3[obj.type.substring(12)] = true;
  });
  _.forEach(configObj, function (obj) {
    _.forEach(obj.values, function (singleValue) {
      if (singleValue.name === 'TITLE') {
        modules4.push(singleValue.value);
      }
    });
  });
  if (bool === false) {
    return modules1;
  } else if (bool === true) {
    return modules2;
  } else if (bool === 'title') {
    return modules4;
  } else {
    return modules3;
  }
};
export const getAngularLocalStorageConfig = function (
  configName: string,
  valueName: string
) {
  const valueString = localStorage.getItem(
    AngularLocalStoragePrefix + configName
  );
  try {
    const configReturn = _.filter(JSON.parse(valueString ?? ''), {
      name: valueName,
    });
    if (configReturn == null || configReturn.length === 0) {
      return null;
    } else {
      return configReturn[0].value;
    }
  } catch (error) {
    return null;
  }
};
export const getCookie = (cname: string): string => {
  const name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
export const setCookie = (cname: string, cvalue: string, exdays?: any) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};
export const delCookie = (cname: string) => {
  document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie =
    cname + '=; Path=/sc; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

  // delete domain specific cookies ( dev and live )
  document.cookie =
    cname +
    '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=dev.safetychampion.tech';
  document.cookie =
    cname +
    '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.safetychampion.tech';
  document.cookie =
    cname +
    '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.safetychampion.online';
  document.cookie =
    cname +
    '=; Path=/sc; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=dev.safetychampion.tech';
  document.cookie =
    cname +
    '=; Path=/sc; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.safetychampion.tech';
  document.cookie =
    cname +
    '=; Path=/sc; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.safetychampion.online';
};

export const SsoFlagName = 'isSso';
export const SsoFlagLocalStorageName = AngularLocalStoragePrefix + SsoFlagName;
export const resetUserData = () => {
  delCookie('user');
  delCookie('token');
  delCookie('tierObject');

  _.forEach(Object.entries(localStorage), function (value: any, key: string) {
    const itemName = value[0];

    if (
      (itemName && itemName === AngularLocalStoragePrefix + 'user') ||
      itemName.match(/^ngStorage-.*Config$/g) ||
      itemName === AngularLocalStoragePrefix + 'configObj' ||
      itemName === AngularLocalStoragePrefix + 'userHierarchy' ||
      itemName === AngularLocalStoragePrefix + 'isMorph' ||
      itemName === AngularLocalStoragePrefix + 'filterList'
    ) {
      if (itemName !== AngularLocalStoragePrefix + 'themeConfig')
        localStorage.removeItem(itemName);
    }
  });
};

export const youtube_parser = (url: string): string => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  try {
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export interface attachmentPayloadType {
  _id?: string;
  group?: string;
  file?: File;
  delete?: boolean;
}

export const parsePayload = (payload: any): any => {
  if (payload) {
    let getFileAttachments: any[] = [];
    let getFileSignatures: any[] = [];
    if (payload.attachments && payload.attachments.length > 0) {
      getFileAttachments = payload.attachments;
    }

    if (payload.signatures && payload.signatures.length > 0) {
      getFileSignatures = payload.signatures;
    }

    if (getFileAttachments) {
      const attachments = getFileAttachments;
      const signatures = getFileSignatures;
      payload = JSON.parse(JSON.stringify(payload));

      if (payload.attachments && payload.attachments.length > 0) {
        payload.attachments = attachments;
      }
      if (payload.signatures && payload.signatures.length > 0) {
        payload.signatures = signatures;
      }
    } else {
      payload = JSON.parse(JSON.stringify(payload));
    }

    if (payload.riskOwnerLinks && payload.riskOwnerLinks.length) {
      payload.riskOwnerLinks.forEach((item: any) => {
        delete item.moduleName;
        delete item.workplaceId;
        delete item.position;
      });
    }
    if (payload.actionLinks && payload.actionLinks.length) {
      const newActionList = new Array<any>();
      payload.actionLinks.forEach((action: any) => {
        newActionList.push({ _id: action._id, type: action.type });
      });
      payload.actionLinks = newActionList;
    }
    return payload;
  }

  return payload;
};

export const getListFromAttachments = (list: any[], attachmentList: any[]) => {
  let newList: any[] = [];
  list.map((item) => {
    const target = attachmentList.find(
      (attachment) => attachment.group === item._id
    );
    if (target) {
      newList.push(target);
    }
  });
  return newList;
};

export const removeListFromAttachments = (
  attachmentList: any[],
  itemsToRemove: any[]
) => {
  let newList: any[] = [];
  attachmentList.map((attachment) => {
    const target = itemsToRemove.find(
      (toRemove) => toRemove._id === attachment.group
    );
    if (_.isNil(target)) {
      newList.push(attachment);
    }
  });
  return newList;
};

export const convertObj2FormData = (dataRequest: any): FormData => {
  dataRequest = parsePayload(dataRequest);
  const fileAttachments: any[] = [];

  const genAttachments = (getAttachments: any[]) => {
    let generatedAttachments: any[] = getAttachments;
    const newAtts: any[] = [];
    generatedAttachments.forEach((att: OhsAttachment) => {
      if (att.download && att.delete === true) {
        newAtts.push({ _id: att._id, delete: true });
      } else if (att.file && att.delete !== true) {
        newAtts.push({
          group: att.group ?? shortid.generate(),
          file: att.file,
          delete: false,
        });
      }
    });
    return newAtts;
  };

  const processSignatures = (
    signatureListData: any[],
    attachmentListData: any[]
  ) => {
    const signatureLists: any[] = [];
    const attchListData: any[] = [...attachmentListData];

    signatureListData.forEach(function (
      item: attachmentPayloadType,
      index: number
    ) {
      if (item.file && item.file.name) {
        const signatureGrpID = 'signature-' + shortid.generate();
        attchListData.push({
          fileName: item.file.name,
          group: signatureGrpID,
        });
        signatureLists.push({
          name: item.file.name,
          fileName: item.file.name,
          _id: signatureGrpID,
        });
        fileAttachments.push(item.file);
      } else if (item.delete === true) {
        // as the file already exists, we only need to send the details through if we are going to delete
        var tempFile = {} as any;
        tempFile._id = item._id;
        tempFile.group = item.group;
        tempFile.delete = true;
        attchListData.push(tempFile);
        fileAttachments.push(tempFile);
      } else {
        // if we don't need the file, we don't add it to attachments
      }
    });
    return { signatures: signatureLists, attachments: attchListData };
  };

  const processAttachments = (attachmentsData: any[]) => {
    const attachmentDetails: any[] = [];
    attachmentsData.forEach(function (
      item: attachmentPayloadType,
      index: number
    ) {
      if (item.file && item.file.name) {
        if (item.file.name === 'signature.png') {
          attachmentDetails.push({
            fileName: item.file.name,
            group: 'signature',
          });
          fileAttachments.push(item.file);
        } else if (item.group) {
          attachmentDetails.push({
            fileName: item.file.name,
            group: item.group,
          });
          fileAttachments.push(item.file);
        } else {
          attachmentDetails.push({ fileName: item.file.name });
          fileAttachments.push(item.file);
        }
      } else if (item.delete === true) {
        // as the file already exists, we only need to send the details through if we are going to delete
        var tempFile = {} as any;
        tempFile._id = item._id;
        tempFile.group = item.group;
        tempFile.delete = true;
        attachmentDetails.push(tempFile);
        fileAttachments.push(tempFile);
      } else {
        // if we don't need the file, we don't add it to attachments
      }
    });
    return attachmentDetails;
  };

  if (
    dataRequest &&
    dataRequest.attachments &&
    dataRequest.attachments.length > 0
  ) {
    const generatedAttachments = genAttachments(dataRequest.attachments);
    const processAttachmentsReq = processAttachments(generatedAttachments);
    dataRequest.attachments = processAttachmentsReq;
  }

  if (
    dataRequest &&
    dataRequest.signatures &&
    dataRequest.signatures.length > 0
  ) {
    const generatedSignatures = genAttachments(dataRequest.signatures);
    const { signatures, attachments } = processSignatures(
      generatedSignatures,
      dataRequest.attachments
    );
    dataRequest.attachments = attachments;
    dataRequest.signatures = signatures;
  }

  if (!Array.isArray(dataRequest.attachments)) {
    dataRequest.attachments = [];
  }

  if (dataRequest.categoryOther === null) {
    delete dataRequest.categoryOther;
  }

  const formData = new FormData();
  formData.append('json', JSON.stringify(dataRequest));
  if (fileAttachments.length > 0) {
    fileAttachments.forEach(function (file, index) {
      // if the attachment has already been uploaded to the server, skip adding it to the formdata
      if (file._id === undefined) {
        formData.append(index.toString(), file);
      }
    });
  }

  return formData;
};

export const getCurrentTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export const isValidDateString = (dateString: string): boolean => {
  if (dateString) {
    const regexp = new RegExp('^\\d{4}-\\d{2}-\\d{2}$');
    return regexp.test(dateString);
  }
  return false;
};

export const getEnumKeyByEnumValue = (
  myEnum: any,
  enumValue: string
): string => {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  const keyText = keys.length > 0 ? keys[0] : '';

  if (keyText) {
    const result = keyText.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  return keyText;
};

/**
 * Compare two array and ignore the order
 * @param aList
 * @param bList
 * @returns
 */
export const isSameArray = (aList: any[], bList: any[]): boolean => {
  if (aList && bList && aList.length === bList.length) {
    for (let index = 0; index < aList.length; index++) {
      const aItem = aList[index];
      const aInbList = _.find(bList, aItem);
      if (aInbList == null) {
        return false;
      }
    }
  } else return false;
  return true;
};

export const convertToJSONFormData = (dataRequest: any): FormData => {
  const formData = new FormData();
  formData.append('json', JSON.stringify(dataRequest));
  return formData;
};

export const parseOtherDisplayValue = (
  value: string | null,
  otherValue: string | null
) => {
  if (value && value.toLocaleLowerCase() === 'other') {
    return `Other (${otherValue})`;
  } else return value ?? '';
};

export const replaceCountryCodeByName = (content: string) => {
  try {
    const contentList = content.split(' ');
    const countryCodeStr = contentList[contentList.length - 1];
    const countryCode = Number(countryCodeStr);
    if (countryCode > 0 && countryCode <= OhsCountry.length) {
      const countryItem = OhsCountry[countryCode - 1];
      if (countryItem && countryItem.name) {
        return (
          content.substring(0, content.length - countryCodeStr.length) +
          countryItem.name
        );
      }
    }
  } catch (error) {
    console.error(error);
    return content;
  }
  return content;
};

const mapAllocation = function (
  t3list: OhsAllocatableRecord[] = [],
  t4list: OhsAllocatableRecord[] = []
) {
  t4list.forEach(function (t4plan: any) {
    let foundFlag = false;
    t3list.forEach(function (t3plan) {
      // If T3 record tier.type is T4 and is allocated of, set allocationStatus to Allocated
      if (t3plan.tier.type === TierType.T4 && t3plan.allocationOf) {
        t3plan.allocationStatus = OhsStatusFilterOption.Allocated;
      }
      // If T3 record tier.type is T4 and is NOT allocated of, set allocationStatus to workplaceOwned
      if (t3plan.tier.type === TierType.T4 && !t3plan.allocationOf) {
        t3plan.allocationStatus = OhsStatusFilterOption.WorkplaceOwned;
      }
      // If T4 record tier.type is T4 and is allocated of, set allocationStatus to Allocated
      if (t4plan.tier.type === TierType.T4 && t3plan.allocationOf) {
        t4plan.allocationStatus = OhsStatusFilterOption.Allocated;
      }
      if (t4plan.allocationOf && t3plan._id === t4plan.allocationOf._id) {
        foundFlag = true;
        t3plan.subRows && t3plan.subRows.push(t4plan);
        t3plan.allocationStatus = OhsStatusFilterOption.Allocated;
      }
    });
    if (foundFlag === false) {
      t3list.push(t4plan);
    }
  });
};

export const parseRegisterAllocatableData = (
  list: OhsAllocatableRecord[],
  listType: OhsRegisterTabItemTypes
) => {
  const user = getOhsUser();
  let t3Registerlist: any[] = [];
  let t4Registerlist: any[] = [];
  list.forEach(function (d: any) {
    d.allocationStatus = OhsStatusFilterOption.NotAllocated;
    d.subRows = [];
    setTierName(d.tier);
    if (listType === 'mainlist') {
      switch (user?.tierNum) {
        case 3:
          if (d.allocationOf && d.tier.type !== TierType.T3) {
            t4Registerlist.push(d);
          } else if (d.tier.type === TierType.T3) {
            t3Registerlist.push(d);
          }
          break;
        case 4:
          if (d.allocationOf && d.tier.type !== TierType.T3) {
            t4Registerlist.push(d);
          } else {
            t3Registerlist.push(d);
          }
          break;
        default:
        // code block
      }
    }

    if (listType === 'organisation') {
      if (d.tier.type === TierType.T3) {
        t3Registerlist.push(d);
      }
    }
    if (listType === 'workplace') {
      if (d.tier.type === TierType.T4) {
        t4Registerlist.push(d);
      }
    }
  });
  mapAllocation(t3Registerlist, t4Registerlist);
  if (listType === 'workplace') return t4Registerlist;
  return t3Registerlist;
};

export const formatDateInterval = (startDate: Date, endDate: Date) => {
  let duration = intervalToDuration({
    start: new Date(startDate),
    end: new Date(endDate),
  });
  const checkYear = duration.years ?? 0;
  const intervalDate = formatDuration(duration, {
    format: checkYear > 0 ? ['years', 'months'] : ['months', 'days'],
    delimiter: ', ',
  });

  let isPastDue = false;
  if (isAfter(startDate, endDate)) {
    isPastDue = true;
  }
  if (isToday(endDate)) {
    return 'Today';
  }
  if (isYesterday(endDate)) {
    return 'Yesterday';
  }
  if (differenceInHours(endDate, startDate) < 23 && !isPastDue) {
    return `${duration.hours ? duration.hours : ''} hours`;
  }

  return `${isPastDue ? ' - ' : ''}${intervalDate}`;
};
(window as any).formatDateInterval = formatDateInterval;
