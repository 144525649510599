import React from 'react';
import { TableBody } from '@mui/material';
import _ from 'lodash';
import { OhsRegisterTableDragRow } from '../OhsRegisterTableDragRow';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableElementProps,
} from 'react-sortable-hoc';
import { makeStyles } from 'tss-react/mui';

interface Props {
  list: any[];
  moveRowFunc: (oldIndex: number, newIndex: number) => void;
  prepareRow: any;
}

const useStyles = makeStyles()(() => ({
  sortableHelper: {
    zIndex: 1400,
  },
}));

const OhsRegisterTableRowListDraggable = (props: Props) => {
  const { classes } = useStyles();

  const SortableItem: React.ComponentClass<
    SortableElementProps & { value: any; index: number },
    any
  > = SortableElement(({ value, index }: { value: any; index: number }) => {
    props.prepareRow(value);
    return <OhsRegisterTableDragRow row={value} index={index} key={index} />;
  });

  const SortableList: React.ComponentClass<
    SortableContainerProps & { items: any[] },
    any
  > = SortableContainer(({ items }: { items: any[] }) => {
    return (
      <TableBody role="table-body" data-testid="register-table-body">
        {items.map((value: any, index: number) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </TableBody>
    );
  });

  const onSortEnd: SortEndHandler = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    props.moveRowFunc(oldIndex, newIndex);
  };

  return (
    <SortableList
      axis="y"
      lockAxis="y"
      items={props.list}
      onSortEnd={onSortEnd}
      transitionDuration={300}
      lockToContainerEdges={true}
      useDragHandle
      helperClass={classes.sortableHelper}
    />
  );
};

export default OhsRegisterTableRowListDraggable;
