import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import OhsUserMFAInput from '../OhsUserMFAInput';
import { mfaChallenge, mfaOTPEnrollment } from '../OhsUserSettingsMFAservices';
import { MfaSettingsStatus, OhsResendCodeEnroll, OhsUserMFAOTPTypes } from '../OhsUserSettingsModels';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()({
  text: {
    textAlign: 'center',
    margin: '0 0 10px 0',
  },
  link: {
    textDecoration: 'none',
    margin: '15px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  otpError: {
    color: 'red',
    margin: '5px 0 -10px 0',
  },
  note: {
    marginBottom: '15px',
    textAlign: 'center',
  },
  header: {
    fontWeight: 'bold',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '20px',
    color: '#4c4c4c',
  },
  errorIcon: {
    verticalAlign: '-4px',
    fontSize: '16px',
    marginRight: '2px',
  },
  validating: {
    color: '#606060',
    margin: '8px 0 -5px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  validatingIcon: {
    verticalAlign: '-4px',
    fontSize: '14px',
    marginRight: '8px',
    width: '16px !important',
    height: '16px !important',
    color: '#606060',
  },
});
interface Props {
  mfaInfo: OhsUserMFAOTPTypes;
  setMFAInfo: React.Dispatch<React.SetStateAction<OhsUserMFAOTPTypes>>
  setOTP: React.Dispatch<React.SetStateAction<string>>;
  oTPvalidStatus: string;
  currentOTP: string;
}
const OhsMfaEmailSmsOTPform = (props: Props) => {
  const { classes } = useStyles();
  const [mfaInfo, setMFAInfo] = React.useState<OhsUserMFAOTPTypes>(
    new OhsUserMFAOTPTypes()
  );
  const [oTPvalidStatus, setOTPvalidStatus] = React.useState<MfaSettingsStatus>('idle');
  
  React.useEffect(() => {
    props.setOTP('');
    setMFAInfo(props.mfaInfo);
  }, [props.mfaInfo]);

  return (
    <>
      {!props.mfaInfo?.firstTimeEnroll && (
        <Typography
          variant="body1"
          gutterBottom
          className={classes.headerTitle}
        >
          {`Change Authentication Method`}
        </Typography>
      )}
      <Typography variant="body1" gutterBottom className={classes.note}>
        Please enter the authentication code that we sent to your{' '}
        {mfaInfo.method}:{' '}
        <span className={classes.header}>{mfaInfo.destination}</span>.
      </Typography>
      <OhsUserMFAInput setOTP={props.setOTP} currentOTP={props.currentOTP} />
      {props.oTPvalidStatus === 'invalid' && oTPvalidStatus !== 'resendingcode' && (
        <Typography
          variant="body1"
          gutterBottom
          className={`${classes.link} ${classes.otpError}`}
        >
          <ErrorIcon className={classes.errorIcon} />
          {`Incorrect verification code.`}
        </Typography>
      )}
      {props.oTPvalidStatus === 'otpValidating' && (
        <Typography
          variant="body1"
          gutterBottom
          className={`${classes.link} ${classes.validating}`}
        >
          <CircularProgress className={classes.validatingIcon} />
          {`Verifiying authentication code ...`}
        </Typography>
      )}
      {oTPvalidStatus === 'resendingcode' && (
        <Typography
          variant="body1"
          gutterBottom
          className={`${classes.link} ${classes.validating}`}
        >
          <CircularProgress className={classes.validatingIcon} />
          {`Resending authentication code ...`}
        </Typography>
      )}
      {mfaInfo.firstTimeEnroll && 
        <Link
          className={classes.link}
          onClick={async () => {
            setOTPvalidStatus('resendingcode');
            const resendCodePayload = {
              method: mfaInfo.method,
              mfaToken: mfaInfo.mfaToken,
              resendOtp: true
            } as OhsResendCodeEnroll

            const oTPEnrollConfirmReq = await mfaOTPEnrollment(
              resendCodePayload,
              'enroll'
            );
            if(oTPEnrollConfirmReq.data) {
              const { mfaToken, oob } = oTPEnrollConfirmReq.data
              props.setMFAInfo({ ...mfaInfo, mfaToken, oob, 
                firstTimeEnroll: true 
              })
              setOTPvalidStatus('idle');
            }
            props.setOTP('');
          }}
        >
          Resend Code
        </Link>
      }
      {!mfaInfo.firstTimeEnroll && 
        <Link
          className={classes.link}
          onClick={async () => {
            setOTPvalidStatus('resendingcode');
            const resendCodeReq = await mfaChallenge(mfaInfo.method, {
              email: props.mfaInfo.destination,
              sms: props.mfaInfo.destination,
            });
            if(resendCodeReq.data) {
              props.setMFAInfo(resendCodeReq?.data)
              setOTPvalidStatus('idle');
            }

            props.setOTP('');
          }}
        >
          Resend Code
        </Link>
      }
    </>
  );
};

export default OhsMfaEmailSmsOTPform;
