import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface OhsTier1CopyDialogState {
  isOpen: boolean;
  orgId: string;
  orgName: string;
}
const initialState = {
  isOpen: false,
  orgId: '',
  orgName: '',
} as OhsTier1CopyDialogState;

const OhsTier1CopyDialogSlice = createSlice({
  name: 'OhsTier1CopyDialog',
  initialState,
  reducers: {
    toggleOhsTier1CopyDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    sendOrgId: (
      state,
      action: PayloadAction<{ _id: string; name: string }>
    ) => {
      state.orgId = action.payload._id;
      state.orgName = action.payload.name;
    },
  },
});

export const { toggleOhsTier1CopyDialog, sendOrgId } =
  OhsTier1CopyDialogSlice.actions;
export const OhsTier1CopyDialogReducer = OhsTier1CopyDialogSlice.reducer;
