import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface OhsBackdropState {
  backdropOpen: boolean;
}
const initialState = {
  backdropOpen: false,
} as OhsBackdropState;

const backdropSlice = createSlice({
  name: 'backdrop',
  initialState,
  reducers: {
    toggleBackdrop: (state, action: PayloadAction<boolean>) => {
      state.backdropOpen = action.payload;
    },
  },
});

export const { toggleBackdrop } = backdropSlice.actions;
export const backdropReducer = backdropSlice.reducer;
