import {
  OhsCheckboxCusval,
  OhsCusval,
} from 'global/form/cusvals/OhsCusvalModel';
import {
  VisitorForm,
  VisitorMessage,
  VisitorRole,
  VisitorSite,
} from '../visitor/common/VisitorModels';
import * as _ from 'lodash';

export const parsePhoneNumberJSON = (value: string | null | undefined) => {
  if (value && JSON.parse(value).number) {
    let phonenumber = JSON.parse(value).code + ' - ' + JSON.parse(value).number;
    return phonenumber;
  } else {
    return null;
  }
};
export const parseCusvalList = (
  list: Array<OhsCusval> | undefined
): Array<any> => {
  const cusList = new Array<any>();
  if (list) {
    list.map((cusval) => {
      let cusValue: any = cusval.value ? cusval.value : null;
      if (cusval.type === 'time' && cusval.value) {
        let timeParts = cusval.value.split(':');
        cusValue = {
          hours: parseInt(timeParts[0], 10),
          minutes: parseInt(timeParts[1], 0),
        };
      }
      if (cusval.type === 'tel' && cusval.value) {
        cusValue = parsePhoneNumberJSON(cusval.value);
      }
      if (cusval.type === 'radio' && cusval.value) {
        cusValue = [cusval.value];
      }
      if (cusval.type === 'checkbox' && cusval.value) {
        if (JSON.parse(cusval.value)) {
          cusValue = JSON.parse(cusval.value);
        } else {
          cusValue = null;
        }
      }
      if (cusval.type === 'select_single' && cusval.value) {
        if (cusval.value) {
          cusValue = [cusval.value];
        } else {
          cusValue = null;
        }
      }
      if (cusval.type === 'currency' && cusval.value) {
        if (JSON.parse(cusval.value).amount) {
          cusValue = JSON.parse(cusval.value);
        } else {
          cusValue = null;
        }
      }
      cusList.push({
        _id: cusval._id,
        title: cusval.title,
        type: cusval.type,
        value: cusValue,
      });
    });
  }

  return cusList;
};
export const assignValuesToCusvalList = (
  cusvalList: Array<OhsCusval>,
  getValues: any
) => {
  cusvalList.forEach((item) => {
    if (item.type === 'tel' && item.value) {
      let phoneObj = JSON.parse(item.value);
      phoneObj.number = getValues(item._id);
      item.value = JSON.stringify(phoneObj);
    } else if (item.type === 'currency' && item.value) {
      let currencyObj = JSON.parse(item.value);
      currencyObj.amount = getValues(item._id);
      item.value = JSON.stringify(currencyObj);
    } else if (item.type === 'checkbox') {
      item = item as OhsCheckboxCusval;
      item.value = getValues(item._id);
    } else {
      item.value = getValues(item._id);
    }
  });
  return cusvalList;
};

export const getArriveIntroMessage = (
  role: VisitorRole,
  site: VisitorSite,
  formList: Array<VisitorForm>
): VisitorMessage | null | undefined => {
  const siteForm = _.find(site.forms, {
    role: { _id: role._id },
  });
  let message;
  message = siteForm?.arrive?.messages.pre;
  if (message == null) {
    const form = _.find(formList, {
      _id: siteForm?.arrive?.form._id,
    });
    message = form?.messages.pre;
  }

  return message;
};

const parseCurrencyJSON = (value: string | null | undefined) => {
  if (value && JSON.parse(value).amount) {
    let currency = JSON.parse(value).amount + ' ' + JSON.parse(value).code;
    return currency;
  } else {
    return null;
  }
};
const parseCheckboxJSON = (value: string | null | undefined) => {
  if (value && JSON.parse(value)) {
    return JSON.parse(value).join(', ');
  } else {
    return null;
  }
};

export const getDisplayValue = (product: OhsCusval) => {
  if (product.type === 'tel') {
    return parsePhoneNumberJSON(product.value);
  } else if (product.type === 'currency') {
    return parseCurrencyJSON(product.value);
  } else if (product.type === 'checkbox') {
    return parseCheckboxJSON(product.value);
  } else {
    return product.value?.toString();
  }
};
