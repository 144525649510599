import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import OhsHiddenField from './OhsHiddenField';

const useStyles = makeStyles()({
  radio: {
    paddingLeft: '14px',
  },
});

export type RadioOptions = Array<{
  name: string;
  value: string | number | boolean;
}>;

interface Props extends OhsFormFieldBaseProps {
  options: RadioOptions;
  row?: boolean;
}
function InputElem(props: Props) {
  const { classes } = useStyles();
  const disabled = props.disabled || false;
  const conditionalClass =
    props.ohsFormSettings.layout === 0 ? classes.radio : '';
  const radioValue = props.ohsFormSettings.useFormMethods.watch(props.id);

  const handleClick = (event: React.ChangeEvent<{}>) => {
    const { value } = event.target as HTMLInputElement;
    const handleBooleanLimitation = (val: boolean | string | undefined) => {
      if (val && typeof val === 'string') {
        if (val.toLowerCase() === 'true') return true;
        if (val.toLowerCase() === 'false') return false;
      }
      return val;
    };
    let newValue: string | boolean | undefined = handleBooleanLimitation(value);

    if (newValue === handleBooleanLimitation(radioValue)) {
      props.ohsFormSettings.useFormMethods.setValue(props.id, null);
    } else {
      props.ohsFormSettings.useFormMethods.setValue(props.id, newValue);
    }
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: () => {
          const val = props.ohsFormSettings.useFormMethods.getValues(props.id);
          if (props.required === true) {
            if (val == null || val === '') {
              return false;
            } else {
              return true;
            }
          }

          return true;
        },
      },
    });
  }, [
    radioValue,
    props.id,
    props.ohsFormSettings.useFormMethods,
    props.ohsFormSettings.useFormMethods.register,
    props.required,
  ]);

  return (
    <>
      <RadioGroup
        value={radioValue ?? ''}
        row={props.row ? props.row : false}
        className={conditionalClass}
      >
        {props.options.map((option) => {
          return (
            <FormControlLabel
              key={option.name}
              value={option.value}
              disabled={disabled}
              control={
                <Radio
                  color="primary"
                  onClick={disabled ? () => {} : handleClick}
                />
              }
              label={option.name}
            />
          );
        })}
      </RadioGroup>
      <OhsHiddenField
        register={props.ohsFormSettings.useFormMethods.register(props.id)}
      />
    </>
  );
}
export default function OhsRadioField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputElem {...props} />} />
    </>
  );
}
