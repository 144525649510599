export const isMobileDevice = (): boolean => {
  let isMobile = false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    isMobile = true;
  }
  return isMobile;
};

export const forwardToDynamicLink = (content: string): void => {
  let forwarDomainUrl;
  if (window.location.href.indexOf('dev.safetychampion.tech') > 0) {
    forwarDomainUrl = 'https://devsafetychampionsoftware.page.link';
  } else {
    forwarDomainUrl = 'https://safetychampionsoftware.page.link';
  }
  window.open(
    `${forwarDomainUrl}/?link=${encodeURIComponent(
      content
    )}&apn=au.com.safetychampion.scmobile&isi=1476478938&ibi=com.safetychampion.SafetyChampionSoftware&ivm=3.13.0&amv=22`,
    '_self'
  );
};
