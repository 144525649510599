import React from 'react';
import OhsIframeModulePublicHome from '../../global/angular-iframe/OhsIframeModulePublicHome';
import OhsLoginForm from '../../login/OhsLoginForm';
export const OhsFreemiumUserSetup = () => {
  return (
    <div>
      <div style={{ display: 'none' }}>
        <OhsLoginForm />
      </div>

      <OhsIframeModulePublicHome />
    </div>
  );
};
