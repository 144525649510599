import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';

import { getOhsUser } from '../../../user/OhsUser';
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
} from '../../../common/OhsDataParse';

import {
  localStorageKey,
  removedBannerCookieRecordType,
} from '../OhsDashboardBannerConstants';
import { getBannerList } from '../OhsDashboardBannerServices';
import { OhsDashboardBannerRecord } from '../OhsDashboardBannerModels';
import { BannerCarousel } from '../OhsDashboardBannerStyledComponents';

import DashboardBannerItem from './OhsDashboardBannerItem';
import getMixpanelTraker from '../../../common/mixpanel/OhsMixpanel';
import { dateDiffOnly } from '../../../global/utils/OhsDateServices';
import { connect } from 'react-redux';
import { setIsShowingBanner } from '../../OhsDashboardSlice';

const MaxEndDate = 7;

const getUpdatedRemovedBanner = ({
  userEmail,
  bannerList,
}: {
  userEmail: string;
  bannerList: OhsDashboardBannerRecord[];
}) => {
  const removedBannerCookieList = (getLocalStorage(localStorageKey) ||
    []) as removedBannerCookieRecordType[];
  //update all local endDate if we found it in the remote list.
  for (let index = 0; index < removedBannerCookieList.length; index++) {
    const localRecord = removedBannerCookieList[index];
    const remoteRecord = _.find(bannerList, { _id: localRecord._id });
    if (remoteRecord) {
      localRecord.endDate =
        dateDiffOnly(remoteRecord.endDate) <= MaxEndDate
          ? remoteRecord.endDate
          : moment(new Date()).add(MaxEndDate, 'days').format('YYYY-MM-DD');
    }
  }
  //remove expired record

  const newRemovedBannerCookieList = removedBannerCookieList.filter(
    (item) => dateDiffOnly(item.endDate) > -1
  );

  if (newRemovedBannerCookieList.length > 0) {
    setLocalStorage(localStorageKey, newRemovedBannerCookieList);
  } else {
    removeLocalStorage(localStorageKey);
  }

  return _.filter(
    newRemovedBannerCookieList,
    (item) => item.userEmail === userEmail
  );
};
interface Props {
  setIsShowingBanner: (isShwong: boolean) => void;
}
function OhsDashboardBanner(props: any) {
  const [dashboardBannerList, setDashboardBannerList] = useState<
    Array<OhsDashboardBannerRecord>
  >([]);
  const user = getOhsUser();

  const userId = _.get(user, '_id', '');
  const userEmail = _.get(user, 'email', '');
  const userOrgId = _.get(user, ['org', '_id'], '');

  // On load of the page,
  // we get the list from API THEN filtered out
  // depending on list from `removedBanner` saved in cookies/localStorage
  useEffect(() => {
    getBannerList().then((dataResponse) => {
      const removedBannerCookieList = userEmail
        ? getUpdatedRemovedBanner({ userEmail, bannerList: dataResponse })
        : [];
      const dashboardBannerFilteredList = _.filter(dataResponse, (item) => {
        return _.findIndex(removedBannerCookieList, { _id: item._id }) === -1;
      });
      if (
        dashboardBannerFilteredList &&
        dashboardBannerFilteredList.length > 0
      ) {
        props.setIsShowingBanner(true);
      } else {
        props.setIsShowingBanner(false);
      }
      setDashboardBannerList(dashboardBannerFilteredList);
    });
  }, [userEmail]);

  // Filter or remove selected banner right away
  // after saving its ID on localStorage > `removedBanner`
  const removeBannerOnClick = useCallback(
    ({ _id, endDate }: any) => {
      const mixpanel = getMixpanelTraker();
      try {
        const banner = _.find(dashboardBannerList, { _id: _id });
        mixpanel?.track('Close Banner', {
          BannerMessage: banner?.content.message,
          BannerId: banner?._id,
          BannerStartDate: banner?.startDate,
          BannerEndDate: banner?.endDate,
        });
      } catch (e) {
        console.error(e);
      }
      const removedBannerListCookie = getLocalStorage(localStorageKey) || [];

      // Maximum endDate value on localStorage is MaxEndDate days.
      const validateEndDate =
        dateDiffOnly(endDate) > MaxEndDate
          ? moment(new Date()).add(MaxEndDate, 'days').format('YYYY-MM-DD')
          : endDate;
      const newRemovedBannerValue = [
        ...removedBannerListCookie,
        {
          _id,
          endDate: validateEndDate,
          userId,
          userEmail,
          userOrgId,
        },
      ];

      const dashboardBannerFilteredList = _.filter(
        dashboardBannerList,
        (item) => _id !== item._id
      );

      if (
        dashboardBannerFilteredList &&
        dashboardBannerFilteredList.length > 0
      ) {
        props.setIsShowingBanner(true);
      } else {
        props.setIsShowingBanner(false);
      }
      setLocalStorage(localStorageKey, newRemovedBannerValue);
      setDashboardBannerList(dashboardBannerFilteredList);
    },
    [dashboardBannerList, userEmail, userOrgId, userId]
  );

  // Render a slider of banner list(s)
  return dashboardBannerList.length > 0 ? (
    <BannerCarousel itemCount={dashboardBannerList.length}>
      {_.map(dashboardBannerList, (bannerItem) => (
        <DashboardBannerItem
          key={bannerItem._id}
          {...bannerItem.content}
          removeBannerOnClick={() =>
            removeBannerOnClick({ ..._.pick(bannerItem, ['_id', 'endDate']) })
          }
        />
      ))}
    </BannerCarousel>
  ) : (
    <></>
  );
}

export default connect(null, { setIsShowingBanner })(OhsDashboardBanner);
