import { toast } from 'react-toastify';
import _ from 'lodash';
import ohsApiClient from '../../common/OhsApi';

export const verifyMfa = async (formData: any): Promise<null> => {
  const payload = await JSON.stringify(formData);
  let endpoint = '/users/mfa/verify';

  const errMsg = 'Invalid OTP';
  return ohsApiClient
    .post(endpoint, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};

export const verifyMfaRecoveryCode = async (formData: any): Promise<any> => {
  const payload = formData;
  let endpoint = '/users/mfa/recover';

  const errMsg = 'Invalid Recovery Code';
  return ohsApiClient
    .post(endpoint, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};
