import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
export class OhsVisitorPermissions {
  form_create: boolean;
  form_edit: boolean;
  form_archive: boolean;
  site_view: boolean;
  site_create: boolean;
  site_clone: boolean;
  site_allocate: boolean;
  site_edit: boolean;
  site_archive: boolean;
  visit_view: boolean;
  visit_archive: boolean;
  visit_arrive: boolean;
  visit_leave: boolean;
  constructor(flag: boolean) {
    this.form_create = flag;
    this.form_edit = flag;
    this.form_archive = flag;
    this.site_view = flag;
    this.site_create = flag;
    this.site_clone = flag;
    this.site_allocate = flag;
    this.site_edit = flag;
    this.site_archive = flag;
    this.visit_view = flag;
    this.visit_archive = flag;
    this.visit_arrive = flag;
    this.visit_leave = flag;
  }
}
export class OhsVisitorConfig {
  TITLE: string;
  VISIT_TERMS: { arrive: string; leave: string };
  PERMISSIONS: OhsVisitorPermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.VISIT_TERMS = getConfigValue(config, 'VISIT_TERMS');
    this.PERMISSIONS = new OhsVisitorPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}
