import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Header, SemanticTEXTALIGNMENTS } from 'semantic-ui-react';
import { SmallScreenWidthBreakpoint } from '../../global/constants/OhsLayoutConstants';
import { getOhsUser } from '../../user/OhsUser';
export const registerButtonStylesFunc = (theme: Theme) => ({
  button: {
    marginRight: '1px!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      padding: '.78571429em .78571429em .78571429em!important',
    },
  },
  buttonGroup: {
    marginTop: '-10px!important',
    marginBottom: '-10px!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      marginTop: '10px!important',
      marginBottom: '10px!important',
    },
  },
  buttonIcons: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      opacity: '.9',
      margin: '0!important',
      verticalAlign: 'top',
    },
  },
  hideForSmall: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'inline',
    },
  },
  displayForSmall: {
    display: 'inline',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'none',
    },
  },
});
export const registerButtonStyles = makeStyles()(registerButtonStylesFunc);
interface Props {
  children?: React.ReactNode;
  title?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  textAlign?: SemanticTEXTALIGNMENTS;
  color?: 'black';
}
export default function OhsModuleHeader(props: Props) {
  const user = getOhsUser();

  return (
    <Header
      as={props.as ?? 'h2'}
      style={{
        color: props.color
          ? props.color
          : user?.configs.theme.HEADER_BAR_BG_COLOR,
      }}
      textAlign={props.textAlign}
    >
      {props.children && props.children}
      <span>{props.title ?? ''}</span>
      &nbsp;
    </Header>
  );
}
