import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from '../leftSideDrawer/OhsLeftSideDrawerModuleList';
import { OhsBreadcrumbsNameAndRef } from './OhsBreadcrumbsServices';

export const OhsBreadCrumbVisitor = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  varPath: string,
  user: OhsUser
) => {
  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.visitor?.TITLE ?? '',
    };
  } else if (viewPath === 'add' && subPath === 'form') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).visitor,
      name: user.configs.visitor?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'New Form' };
  } else if (viewPath === 'add' && subPath === 'site') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).visitor,
      name: user.configs.visitor?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'New Site' };
  } else if (varPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).visitor,
      name: user.configs.visitor?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/${viewPath}/view`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  } else if (varPath === 'view') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).visitor,
      name: user.configs.visitor?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  }
  return ohsBreadcrumbsNameAndRef;
};
