import { TableCell } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

const OhsRegisterTableBodyCell = withStyles(() =>
  createStyles({
    root: {
      fontSize: '1rem',
    },
  })
)(TableCell);

export default OhsRegisterTableBodyCell;
