import React from 'react';

import { MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import _ from 'lodash';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

export interface SelectFieldOptionType {
  name: string;
  value: string | number;
}
export interface Props extends OhsFormFieldBaseProps {
  options: Array<SelectFieldOptionType>;
}
function InputEle(props: Props) {
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );

  const result = props.ohsFormSettings.useFormMethods.watch(props.id) ?? '';
  const handleChange = (event: SelectChangeEvent) => {
    const value = (event.target.value as string) ?? '';

    props.ohsFormSettings.useFormMethods.setValue(props.id, value);
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  return (
    <Select
      disabled={props.disabled}
      error={error != null}
      fullWidth
      margin={'dense'}
      size={'small'}
      required={props.required}
      displayEmpty
      value={result}
      variant={returnVariant(props.ohsFormSettings.layout)}
      style={returnFieldStyle(props.ohsFormSettings.layout, {
        marginTop: '8px',
      })}
      {...props.ohsFormSettings.useFormMethods.register(props.id, {
        onChange: handleChange,
        required: props.required === true,
      })}
    >
      <MenuItem value="" disabled={props.required}>
        <span style={{ color: 'gray' }}>-- Please Select --</span>
      </MenuItem>
      {props.options.map((item) => {
        return (
          <MenuItem key={item.name} value={item.value}>
            {item.name}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default function OhsSelectField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
