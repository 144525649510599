import * as _ from 'lodash';
import ohsApiClient from '../../common/OhsApi';
import { getCurrentTimezone } from '../../common/OhsDataParse';
import {
  OhsTier1CopySafetPlanRecord,
  OhsTier1CopyInspectionRecord,
} from './OhsTier1CopyModels';

export const getSafetyPlanCopyList = (
  type: string,
  orgId: string
): Promise<OhsTier1CopySafetPlanRecord | OhsTier1CopyInspectionRecord> => {
  const endpoint = '/safetyplans/list';

  return ohsApiClient
    .post(endpoint, { orgAdminTier: { _id: orgId, type: 'core.tier.T2' } })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const getInspectionCopyList = (
  type: string,
  orgId: string
): Promise<OhsTier1CopySafetPlanRecord | OhsTier1CopyInspectionRecord> => {
  const endpoint = '/inspections/list';

  return ohsApiClient
    .post(endpoint, { orgAdminTier: { _id: orgId, type: 'core.tier.T2' } })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const getOrgList = (): Promise<
  OhsTier1CopySafetPlanRecord | OhsTier1CopyInspectionRecord
> => {
  let endpoint = '/admin2/list';

  return ohsApiClient
    .post(endpoint, { type: 'core.tier.T2' })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items.filter((x: any) => !x.disabled);
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const copyData = (
  type: string,
  source: string,
  target: string,
  toCopy: string[]
): Promise<OhsTier1CopySafetPlanRecord | OhsTier1CopyInspectionRecord> => {
  let endpoint =
    type === 'safety-plan' ? '/safetyplans/copy' : '/inspections/copy';

  return ohsApiClient
    .post(endpoint, {
      source_orgAdminTier: {
        type: 'core.tier.T2',
        _id: source,
      },
      target_orgAdminTier: {
        type: 'core.tier.T2',
        _id: target,
      },
      toCopy: toCopy,
      tzDateCreated: getCurrentTimezone(),
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
