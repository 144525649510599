import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Theme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import getMixpanelTraker from '../../common/mixpanel/OhsMixpanel';
import {
  getEmbeddedReportsExportile,
  getEmbeddedReportsExportStatus,
  startEmbeddedReportsExport,
} from '../../data-analytics/PowerBIServices';
import { getAngularIframeWindow } from '../../global/angular-iframe/OhsAngularIframeServices';
import { OhsMaterialIconButton } from '../../global/buttons/OhsMaterialButton';
import { SmallScreenWidthBreakpoint } from '../../global/constants/OhsLayoutConstants';

const useStyles = makeStyles()((theme: Theme) => ({
  quickAccessButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'block',
    },
  },
}));
const delay = (n: number) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, n * 1000);
  });
};
export const OhsPrintButton = () => {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDailogOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const handleCloseDialog = () => {
    setDailogOpen(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const printPageOrIframePage = () => {
    setAnchorEl(null);
    setTimeout(function () {
      const iframeWindow = getAngularIframeWindow();
      if (iframeWindow) {
        iframeWindow.print();
      } else {
        window.print();
      }
    });
  };

  const ExportPowerBiPageToPdf = async () => {
    setAnchorEl(null);
    setDailogOpen(true);
    setProgress(0);

    const urlParts = window.location.pathname.split('/');
    const reportType = urlParts[2];
    const tokenType = urlParts[3];
    let reportStatus = await startEmbeddedReportsExport(
      reportType,
      tokenType,
      'PDF'
    );
    do {
      await delay(4);
      reportStatus = await getEmbeddedReportsExportStatus(
        reportStatus.groupId,
        reportStatus.reportId,
        reportStatus.id
      );
      setProgress(reportStatus.percentComplete);
    } while (reportStatus && reportStatus.percentComplete < 100);

    const pdfFile = await getEmbeddedReportsExportile(
      reportStatus.groupId,
      reportStatus.reportId,
      reportStatus.id
    );
    const linkSource = `data:application/pdf;base64,${pdfFile.fileBase64Data}`;
    const downloadLink = document.createElement('a');
    const fileName =
      reportStatus.reportName +
      '_' +
      reportStatus.createdDateTime.substring(0, 10) +
      '.pdf';
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setTimeout(function () {
      setDailogOpen(false);
    }, 2000);
  };

  const printPage = (event: any) => {
    const mixpanel = getMixpanelTraker();
    try {
      mixpanel?.track('Click Topbar Print Button');
    } catch (e) {
      console.error(e);
    }

    switch (window.location.pathname) {
      case '/reporting/dashboardReports':
      case '/reporting/orgReports':
      case '/reporting/orgAdminReports':
        handleClick(event);

        break;

      default:
        printPageOrIframePage();
        break;
    }
  };
  return (
    <>
      <OhsMaterialIconButton
        color="inherit"
        size="small"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={printPage}
        className={classes.quickAccessButton}
      >
        <Icon name="print" />
      </OhsMaterialIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={printPageOrIframePage}>Print</MenuItem>
        <MenuItem onClick={ExportPowerBiPageToPdf}>Export to PDF</MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <Grid container>
            <Grid item xs={1}>
              <DialogTitle id="alert-dialog-title">
                {progress < 100 ? (
                  <CircularProgress />
                ) : (
                  <Icon name="check"></Icon>
                )}
              </DialogTitle>
            </Grid>
            <Grid item xs={11}>
              <DialogTitle id="alert-dialog-title">
                {progress < 100
                  ? 'Export to PDF in progress'
                  : 'PDF file is ready for download'}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {progress < 100
                    ? 'Your report is being exported to a PDF file. This might take a few minutes.'
                    : 'The report was exported to a PDF file and is available for you to download.'}
                </DialogContentText>
              </DialogContent>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};
