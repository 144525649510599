import React from 'react';
import { Box, styled, TableCell, TableRow } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OhsUser } from '../../../../user/OhsUser';

const user = new OhsUser();
interface Props {
  index: number;
  list: any;
  showList: (item: string) => void;
  expandedRecords: any[];
}
const OhsRegisterCategoryTableRow = (props: Props) => {
  return (
    <OhsStyledCatTableRow
      key={props.index}
      onClick={() => props.showList(props.list.category.toLowerCase())}
    >
      <TableCell colSpan={100} size="small" style={{ color: '#ffffff' }}>
        <OhsExpandedIconWrap>
          <ChevronRightIcon
            fontSize="small"
            style={{
              transform: props.expandedRecords.includes(
                props.list.category.toLowerCase()
              )
                ? 'rotate(90deg)'
                : 'none',
              fontSize: '20px',
              marginRight: '10px',
            }}
          />
          {props.list.category}
        </OhsExpandedIconWrap>
      </TableCell>
    </OhsStyledCatTableRow>
  );
};

const OhsStyledCatTableRow = styled(TableRow)(() => {
  return {
    backgroundColor: user.configs.theme.HEADER_BAR_BG_COLOR,
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
  };
});

const OhsExpandedIconWrap = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700,
    padding: '2px 0 2px 0',
  };
});

export default OhsRegisterCategoryTableRow;
