import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import { OhsXsiRecord } from './OhsXsiModels';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { setupXsiIntegration } from './OhsXsiServices';
import { toast } from 'react-toastify';
import OhsModal from '../../global/modal/OhsModal';

export default function OhsXsiSetupFormDialog(props: {
  isOpen: boolean;
  xsiRecord: OhsXsiRecord | null;
  setOpen: any;
}) {
  const handleClose = () => {
    props.setOpen(false);
  };
  const setup = (data: Inputs) => {
    setupXsiIntegration(
      data.setUpEmail,
      props.xsiRecord!._id,
      props.xsiRecord!.tier._id
    ).then((result: any) => {
      if (result) {
        toast.success('Successfully sent out the set up email.');
        props.setOpen(false);
      }
    });
  };
  type Inputs = {
    setUpEmail: string;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      setUpEmail: '',
    },
  });

  return (
    <OhsModal
      open={props.isOpen}
      title={'Setup'}
      onClose={handleClose}
      maxWidth={'md'}
      ContentNode={
        <>
          <DialogContentText>
            An email will be sent to the address, and help the client to setup
            the integration.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            error={errors.setUpEmail != null}
            required={true}
            autoComplete="email"
            {...register('setUpEmail', {
              required: true,
              validate: {
                isEmail: (value) => {
                  if (validator.isEmpty(value)) {
                    return true;
                  } else return validator.isEmail(value);
                },
              },
            })}
            helperText={
              errors.setUpEmail?.type
                ? errors.setUpEmail?.type === 'isEmail'
                  ? 'Please provide a valid email address.'
                  : 'Email is required.'
                : ''
            }
          />
        </>
      }
      actionNode={
        <>
          <Button onClick={handleSubmit(setup)} color="primary">
            Confirm
          </Button>
        </>
      }
    />
  );
}
