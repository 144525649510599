import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';
import { toDate } from '../global/utils/OhsDateServices';

export const getAccident = (accidentId: string) => {
  const endpoint = `/incidents/${accidentId}/fetch`;
  const errMsg = 'Unable to fetch the incident/accident';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};
        resData = {
          ...resData,
          dateOccurred: toDate(resData.dateOccurred),
          dateCompleted: toDate(resData.dateCompleted),
        };
        return resData;
      } else {
        toast.error(errMsg);
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};
