import { Box, Link, styled, Typography } from '@mui/material';
import React from 'react';

export const OhsLoadingWrap = styled(Box)((props) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    width: '100%',
  };
});

const OhsStyledMessage = styled(Box)((props) => {
  return {
    flexDirection: 'column',
    minHeight: '250px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  };
});

const OhsStyledText = styled(Typography)((props) => {
  return {
    fontSize: '14px',
    fontWeight: '700',
  };
});

const OhsStyledHeaderText = styled(Typography)((props) => {
  return {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '26px',
  };
});

const OhsStyledLink = styled(Link)((props) => {
  return {
    margin: '20px 0 0 0',
    fontSize: '12px',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer',
  };
});

export const WhatIsMfa = () => {
  return (
    <OhsStyledMessage>
      <OhsStyledHeaderText variant="body1" color="textSecondary">
        We care about your security.
      </OhsStyledHeaderText>
      <OhsStyledText variant="body1" color="textSecondary" marginBottom="10px">
        Multi-factor Authentication (MFA) adds extra layer of security to
        traditional username and password authentication.
      </OhsStyledText>
      <OhsStyledText variant="body1" color="textSecondary" marginBottom="8px">
        It requires you to provide an additional form of verification to your
        account to access the Safety Champion platform. You will need to confirm
        your identity through MFA each time you login.
      </OhsStyledText>
    </OhsStyledMessage>
  );
};

interface Props {
  setCurrentContent: React.Dispatch<React.SetStateAction<number>>;
}
export const MfaRequiredMessage = (props: Props) => (
  <OhsStyledMessage>
    <OhsStyledText variant="body1" color="textSecondary" marginBottom="10px">
      Multi-factor Authentication (MFA) is mandatory for this oganisation.
    </OhsStyledText>
    <OhsStyledText variant="body1" color="textSecondary" marginBottom="10px">
      MFA will need to be activated to access the system
    </OhsStyledText>

    <OhsStyledLink onClick={async () => props.setCurrentContent(2)}>
      More Info
    </OhsStyledLink>
  </OhsStyledMessage>
);

export const MfaRequiredLimitsErrorMsg = () => (
  <OhsStyledMessage>
    <OhsStyledHeaderText variant="body1" color="textSecondary">
      We care about your security.
    </OhsStyledHeaderText>
    <OhsStyledText variant="body1" color="textSecondary" marginBottom="25px">
      Multi-factor Authentication (MFA) is mandatory for this organisation, but
      MFA is disabled and you cannot log in. Please contact your administrator.
    </OhsStyledText>
  </OhsStyledMessage>
);
