import * as _ from 'lodash';
import React from 'react';
import { getOhsUser } from '../../user/OhsUser';
import { copySafetyPlan } from '../OhsSafetyPlanServices';
import OhsRegister from '../../global/register/OhsRegister';
import OhsModal from '../../global/modal/OhsModal';
import { OhsModalContentHeader } from '../../global/modal/OhsModalContentHeader';

interface Props {
  open: boolean;
  title: string;
  onClose?: () => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadSafetyPlanList: () => Promise<any>;
  copyList: any[];
}

function OhsSafetyPlanCopyModal(props: Props) {
  const user = getOhsUser();

  const copyTableColumns = React.useMemo(
    () => [
      {
        id: 'nameId',
        Header: 'Name',
        accessor: 'name',
      },
      {
        id: 'frequencyId',
        Header: 'Frequency',
        accessor: (originalRow: any) => {
          return (
            (originalRow.frequencyValue + ' ' || '') +
            (originalRow.frequencyKey || '')
          );
        },
      },
      {
        id: 'descriptionId',
        Header: 'Details',
        accessor: 'description',
      },
    ],
    []
  );

  const copyPlanFunc = async (copiedItem: any) => {
    return copySafetyPlan(copiedItem._id).then(async function (item) {
      await props.loadSafetyPlanList();
      props.setModalOpen(false);
      if (props.onClose) {
        props.onClose();
      }
      return item;
    });
  };

  return (
    <OhsModal
      open={props.open}
      title={props.title}
      setModalOpenFunc={props.setModalOpen}
      onClose={props.onClose}
      maxWidth={'md'}
      ContentNode={
        <>
          <OhsModalContentHeader
            title={user?.configs.safetyplan?.TITLE + ' Copy Table'}
          />
          <OhsRegister
            columns={copyTableColumns}
            data={props.copyList}
            sortBy={[{ id: 'nameId', desc: false }]}
            rowOperations={{
              copyFunc: copyPlanFunc,
            }}
          />
        </>
      }
    ></OhsModal>
  );
}

export default OhsSafetyPlanCopyModal;
