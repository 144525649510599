import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';

export const fetchReviewPlanTask = (reviewPlanId: string, taskId: string) => {
  const endpoint = `/reviewPlans/${reviewPlanId}/tasks/${taskId}`;
  const errMsg = 'Unable to get task details';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};
        return resData;
      } else {
        toast.error(errMsg);
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};

export const fetchReviewPlan = (reviewPlanId: string) => {
  const endpoint = `/reviewPlans/${reviewPlanId}/fetch`;
  const errMsg = 'Unable to get details';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};
        return resData;
      } else {
        toast.error(errMsg);
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};
