import _ from 'lodash';
import { getOhsUser } from '../../user/OhsUser';
import { OhsObject } from '../OhsObject';
export enum TierType {
  T1 = 'core.tier.T1',
  T2 = 'core.tier.T2',
  T3 = 'core.tier.T3',
  T4 = 'core.tier.T4',
  T5 = 'core.tier.T5',
}

export class OhsTier implements OhsObject {
  type: TierType;
  _id: string;
  name: string;
  constructor(tierObj: any) {
    this._id = tierObj._id;
    this.name = tierObj.name;
    switch (tierObj.type) {
      case 'core.tier.T1': {
        this.type = TierType.T1;
        break;
      }
      case 'core.tier.T2': {
        this.type = TierType.T2;
        break;
      }
      case 'core.tier.T3': {
        this.type = TierType.T3;
        break;
      }
      case 'core.tier.T4': {
        this.type = TierType.T4;
        break;
      }
      default: {
        this.type = TierType.T5;
        break;
      }
    }
  }
}

export const setTierName = (tier: OhsTier): OhsTier => {
  const user = getOhsUser();
  if (tier.type === TierType.T3) {
    tier.name = user?.org?.name!;
  } else {
    tier.name = _.find(user?.configs.admin2?.LOWER_TIER_ACCESS, {
      _id: tier._id,
    })?.name!;
  }
  return tier;
};
