import React from 'react';
import { TableCellProps } from '@mui/material';
import OhsRegisterTableRow from './OhsRegisterTableRow';
import OhsRegisterTableBodyCell from './OhsRegisterTableBodyCell';
import { getOhsUser } from '../../../user/OhsUser';

const OhsRegisterTableSubRow = ({
  row,
  cellNum,
}: {
  row: any;
  cellNum: number;
}) => {
  const user = getOhsUser();
  return (
    <OhsRegisterTableRow {...row.getRowProps()}>
      {row.cells.map(
        (
          cell: {
            column: any;
            getCellProps: () => JSX.IntrinsicAttributes & TableCellProps;
            render: (arg0: string) => React.ReactNode;
          },
          index: number
        ) => {
          switch (index) {
            case 0:
            case cellNum - 1:
              return (
                <OhsRegisterTableBodyCell
                  {...cell.getCellProps()}
                  style={{
                    width: cell.column.customWidth
                      ? cell.column.customWidth
                      : 'auto',
                  }}
                >
                  {cell.render('Cell')}
                </OhsRegisterTableBodyCell>
              );
            case 2:
              return (
                <OhsRegisterTableBodyCell
                  colSpan={cellNum - 2}
                  {...cell.getCellProps()}
                  style={{
                    width: cell.column.customWidth
                      ? cell.column.customWidth
                      : 'auto',
                  }}
                >
                  <b
                    style={{ color: 'green' }}
                  >{`Allocated to ${row.original.tier.name}`}</b>
                </OhsRegisterTableBodyCell>
              );
            default:
              return null;
          }
        }
      )}
    </OhsRegisterTableRow>
  );
};

export default OhsRegisterTableSubRow;
