import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';

export const getSingleVdocTask = (vdocId: string, taskId: string) => {
  const endpoint = `/vdocs/${vdocId}/tasks/${taskId}`;
  const errorMessage = 'Unable to get the document record.';
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        return response.data.result.item;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const fetchSingleVdoc = (vdocId: string) => {
  const endpoint = `/vdocs/${vdocId}/fetch`;
  const errorMessage = 'Unable to get the document record.';
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        return response.data.result.item;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};
