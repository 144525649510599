import React from 'react';

import { TextField } from '@mui/material';

import { OhsFormFieldBaseProps } from '../OhsFormModels';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

import './OhsFormField.css';
import _ from 'lodash';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
export type OhsCusvalTextType =
  | 'text'
  | 'textarea'
  | 'number'
  | 'time'
  | 'password'
  | 'color';

export interface Props extends OhsFormFieldBaseProps {
  type: OhsCusvalTextType;
  rows?: number;
  maxLength?: number;
  minLength?: number;
  comparePasswords?: string;
  passwordValidation?: boolean;
  maxAmount?: number;
  minAmount?: number;
}

function InputEle(props: Props) {
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );
  return (
    <TextField
      type={props.type}
      error={error != null}
      fullWidth
      multiline={props.type === 'textarea'}
      inputProps={{
        style: props.type === 'textarea' ? { resize: 'vertical' } : {},
      }}
      rows={props.rows}
      size={'small'}
      margin={'dense'}
      InputLabelProps={{
        shrink: true,
      }}
      variant={returnVariant(props.ohsFormSettings.layout)}
      required={props.required}
      disabled={props.disabled}
      {...props.ohsFormSettings.useFormMethods.register(props.id, {
        validate: {
          maxLength: (value: any) => {
            if (props.maxLength) {
              if (value.length > props.maxLength) {
                return `The length must not be more than ${props.maxLength}.`;
              }
            }

            return true;
          },
          minLength: (value: any) => {
            if (props.minLength && props.required !== false) {
              if (value.length < props.minLength) {
                return `The length must not be less than ${props.minLength}.`;
              }
            }

            return true;
          },
          comparePasswords: (value: any) => {
            if (props.comparePasswords) {
              if (value !== props.comparePasswords) {
                return `The passwords do not match`;
              }
            }

            return true;
          },
          notEmpty: (value: any) => {
            if (props.required === true) {
              if (value == null || value.trim() === '') {
                return false;
              }
            }

            return true;
          },
          passwordValidation: (value: any) => {
            if (props.passwordValidation && value) {
              const strongRegex = new RegExp(
                /((?=.*d)(?=.*D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))^.{8,200}$/
              );
              if (!strongRegex.test(value)) {
                return 'The password must be at least 8 characters and has any combination of letters, numbers, and symbols.';
              }
            }

            return true;
          },
          minAmount: (value: any) => {
            if (
              !_.isEmpty(value) &&
              !_.isNil(value) &&
              props.type === 'number' &&
              !_.isUndefined(props.minAmount)
            ) {
              let targetValue = value;
              targetValue = parseFloat(targetValue.replace(/[^0-9]/g, ''));
              if (targetValue < props.minAmount) {
                return `Value should be more than ${props.minAmount}`;
              }
            }
            return true;
          },
          maxAmount: (value: any) => {
            if (
              !_.isEmpty(value) &&
              !_.isNil(value) &&
              props.type === 'number' &&
              !_.isUndefined(props.maxAmount)
            ) {
              let targetValue = value;
              targetValue = parseFloat(targetValue.replace(/[^0-9]/g, ''));
              if (targetValue > props.maxAmount) {
                return `Value should be less than ${props.maxAmount}`;
              }
            }
            return true;
          },
        },
      })}
      style={returnFieldStyle(props.ohsFormSettings.layout)}
    />
  );
}

export default function OhsTextField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
