import React from 'react';
import { Paper, TextField } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import _ from 'lodash';
import validator from 'validator';
import { Label } from 'semantic-ui-react';

interface Props {
  emailList: string[];
  updateEmailList: (emailList: string[]) => void;
}

export function OhsEmailList(props: Props) {
  const { classes } = useStyles();
  const { emailList, updateEmailList } = props;
  const [emailText, setEmailText] = React.useState('');
  const [err, setErr] = React.useState('');

  const handleDelete = (index: number) => {
    let tmp = [...emailList];
    tmp.splice(index, 1);
    updateEmailList(tmp);
  };
  const handleAdd = () => {
    if (emailText && emailList.includes(emailText) === false) {
      updateEmailList([...emailList, emailText.toLocaleLowerCase()]);
    }
    setEmailText('');
  };

  const handleTextChange = (text: string) => {
    if (validator.isEmpty(text) || validator.isEmail(text)) {
      setErr('');
    } else {
      setErr('Please provide a valid email address.');
    }
    setEmailText(text);
  };

  return (
    <React.Fragment>
      <div className={classes.emailWrap}>
        <TextField
          label={'Email'}
          value={emailText}
          onChange={(text) => handleTextChange(text.target.value)}
          size={'small'}
          fullWidth
          variant={'outlined'}
          placeholder={'New Email'}
          type={'email'}
          className={classes.emailText}
          error={!!err}
        />
        <Button
          onClick={handleAdd}
          focusRipple={false}
          color="primary"
          size={'small'}
          className={classes.addBtn}
          disabled={emailText.length < 1 || !!err}
        >
          <AddIcon className={classes.addIcon} fontSize={'large'} />
        </Button>
      </div>
      {err && <Label color={'red'}>{err}</Label>}
      <Paper
        component="ul"
        variant={'elevation'}
        elevation={0}
        className={classes.root}
      >
        {emailList &&
          emailList.map((email, index) => {
            return (
              <li key={index}>
                <Chip
                  icon={<EmailIcon className={classes.icon} />}
                  deleteIcon={<CloseRoundedIcon className={classes.icon} />}
                  label={email}
                  color={'primary'}
                  onDelete={() => handleDelete(index)}
                  className={classes.chip}
                />
              </li>
            );
          })}
      </Paper>
    </React.Fragment>
  );
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  emailWrap: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    marginTop: 10,
  },
  addIcon: {
    color: '#FFF',
  },
  addBtn: {
    backgroundColor: '#21ba45',
    minWidth: 40,
    width: 40,
    height: 38,
    '&:hover': {
      backgroundColor: '#1d9f3c',
      borderColor: '#1d9f3c',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#60c278',
      borderColor: '#60c278',
    },
    borderStartStartRadius: 0,
    borderEndStartRadius: 0,
  },
  emailText: {
    fontSize: 12,
    height: 40,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0.29rem',
        borderStartEndRadius: 0,
        borderEndEndRadius: 0,
      },
    },
  },
  input: {
    borderEndEndRadius: 0,
    borderColor: '#00F',
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#2185d0',
    borderRadius: '0.29rem',
    fontSize: 12,
    fontWeight: 700,
  },
  icon: {
    width: 15,
    height: '100%',
  },
}));
