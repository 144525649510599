export class OhsModulePermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  signoff: boolean;
  archive: boolean;
  delete: boolean;
  allocate: boolean;
  assign_task: boolean;
  form_create: boolean;
  form_edit: boolean;
  form_archive: boolean;
  form_export: boolean;
  pricing: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.delete = flag;
    this.allocate = flag;
    this.assign_task = flag;
    this.form_archive = flag;
    this.form_create = flag;
    this.form_edit = flag;
    this.form_export = flag;
    this.pricing = flag;
  }
}
