import React from 'react';
import Button from '@mui/material/Button';
import { OhsXsiRecord } from './OhsXsiModels';
import { createXsiIntegration, editXsiIntegration } from './OhsXsiServices';
import { toast } from 'react-toastify';
import _ from 'lodash';
import OhsModal from '../../global/modal/OhsModal';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import OhsModalFormItem from '../../global/form/OhsModalFormItem';
import OhsSelectField from 'global/form/fields/OhsSelectField';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsEmailField from 'global/form/fields/OhsEmailField';

const XeroVersionOptions = ['1.0', '2.0'].map((item) => {
  return {
    name: item,
    value: item,
  };
});

export default function OhsXsiAddEditFormDialog(props: {
  isOpen: boolean;
  xsiRecord: OhsXsiRecord;
  setOpen: any;
}) {
  const ohsFormSettings = new OhsFormSettings(
    {
      errorNotifyTo: props.xsiRecord.errorNotifyTo
        ? props.xsiRecord.errorNotifyTo.email
        : '',
      title: props.xsiRecord.title,
      description: props.xsiRecord.description,
      platform: props.xsiRecord.platform.name,
      version: props.xsiRecord.platformSettings?.version
        ? props.xsiRecord.platformSettings?.version
        : '2.0',
      emailMapping: _.get(
        props.xsiRecord,
        'platformSettings.mapping.email',
        'email'
      ),
      phoneMapping: _.get(
        props.xsiRecord,
        'platformSettings.mapping.phone',
        'phone'
      ),
    },
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  const handleClose = () => {
    props.setOpen(false);
  };
  const submit = (data: any) => {
    if (props.xsiRecord._id == null) {
      createXsiIntegration(
        data.title,
        data.description,
        data.errorNotifyTo,
        props.xsiRecord.tier._id,
        data.platform,
        data.version ?? null,
        data.emailMapping,
        data.phoneMapping
      ).then((result: any) => {
        if (result) {
          props.setOpen(false);
          toast.success('Successfully create a new integration.');
          window.location.reload();
        }
      });
    } else {
      editXsiIntegration(
        props.xsiRecord._id,
        data.title,
        data.description,
        data.errorNotifyTo,
        props.xsiRecord.tier._id,
        data.version ?? null,
        data.emailMapping,
        data.phoneMapping
      ).then((result: any) => {
        if (result) {
          props.setOpen(false);
          toast.success('Successfully edit the integration record.');
          window.location.reload();
        }
      });
    }
  };

  const watchPlatformName = ohsFormSettings.useFormMethods.watch(
    'platform',
    props.xsiRecord.platform.name
  );

  return (
    <>
      <OhsModal
        open={props.isOpen}
        title={
          props.xsiRecord._id == null ? 'Add Integration' : 'Edit Integration'
        }
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalFormItem>
              <OhsSelectField
                title="Type"
                id="platform"
                required
                options={[
                  { value: 'xero', name: 'Xero' },
                  { value: 'myob', name: 'MYOB' },
                  { value: 'emphero', name: 'Employment Hero' },
                ]}
                ohsFormSettings={ohsFormSettings}
                defaultValue={props.xsiRecord.platform.name}
              />
            </OhsModalFormItem>

            <OhsModalFormItem>
              <OhsTextField
                type="text"
                title="Title"
                id="title"
                required
                ohsFormSettings={ohsFormSettings}
              />
            </OhsModalFormItem>

            <OhsModalFormItem>
              <OhsTextField
                type="textarea"
                title="Description"
                id="description"
                required={false}
                ohsFormSettings={ohsFormSettings}
                rows={4}
              />
            </OhsModalFormItem>

            {(watchPlatformName === 'xero' ||
              watchPlatformName === 'myob' ||
              watchPlatformName === 'emphero') && (
              <>
                <OhsModalFormItem>
                  <OhsSelectField
                    title="Email Mapping"
                    id="emailMapping"
                    required
                    options={[
                      { value: 'email', name: 'Business Email' },
                      { value: 'personalEmail', name: 'Personal Email' },
                    ]}
                    ohsFormSettings={ohsFormSettings}
                    defaultValue={_.get(
                      props.xsiRecord,
                      'platformSettings.mapping.email',
                      'email'
                    )}
                  />
                </OhsModalFormItem>
                <OhsModalFormItem>
                  <OhsSelectField
                    title="Phone Mapping"
                    id="phoneMapping"
                    required
                    options={[
                      { value: 'phone', name: 'Business Phone' },
                      { value: 'personalPhone', name: 'Personal Phone' },
                    ]}
                    ohsFormSettings={ohsFormSettings}
                    defaultValue={_.get(
                      props.xsiRecord,
                      'platformSettings.mapping.phone',
                      'phone'
                    )}
                  />
                </OhsModalFormItem>
              </>
            )}

            {watchPlatformName === 'xero' && (
              <OhsModalFormItem>
                <OhsSelectField
                  title="Version"
                  id="version"
                  required
                  options={XeroVersionOptions}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
            )}
            <OhsModalFormItem>
              <OhsEmailField
                title="Error Notify to Email"
                id="errorNotifyTo"
                required
                ohsFormSettings={ohsFormSettings}
              />
            </OhsModalFormItem>
          </>
        }
        actionNode={
          <>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(submit)}
              color="primary"
            >
              Submit
            </Button>
          </>
        }
      />
    </>
  );
}
