import React from 'react';
import { Row } from 'react-table';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import OhsModal from '../../global/modal/OhsModal';
import { OhsModalContentHeader } from '../../global/modal/OhsModalContentHeader';
import OhsRegister from '../../global/register/OhsRegister';
import { getOhsUser } from '../../user/OhsUser';
import { OhsVdocRecord } from '../../vdoc/OhsVdocModels';

interface Props {
  open: boolean;
  vdocList: OhsVdocRecord[];

  setModalOpenFunc: (addModalOpen: boolean) => void;

  onClose?: () => void;
  handleAddVdocListFunc: (vdocList: OhsVdocRecord[]) => void;
}

export const OhsNoticeBoardVdocBlockAddModal = (props: Props) => {
  const [selectedRows, setSelectedRows] = React.useState<Array<Row<any>>>([]);
  React.useEffect(() => {});
  const columns = React.useMemo(
    () => [
      {
        id: 'categoryId',
        Header: 'Document Type',
        accessor: 'version.category',
      },
      {
        Header: 'Document Category',
        accessor: 'version.subcategory',
      },
      {
        Header: 'Document Name',
        accessor: 'version.name',
      },
      {
        Header: 'Issue Date',
        accessor: 'version.dateIssued',
      },
    ],
    []
  );
  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleButtonAdd = () => {
    if (selectedRows == null || selectedRows.length < 1) {
      toast.error('Please at least select one record.');
      return;
    }
    const vdocList = new Array<OhsVdocRecord>();
    selectedRows.forEach((item) => {
      vdocList.push(item.original);
    });
    props.handleAddVdocListFunc(vdocList);
    handleClose();
  };
  const user = getOhsUser();
  return (
    <>
      <OhsModal
        open={props.open}
        title={'Document Links'}
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalContentHeader
              title={user?.configs.vdoc?.TITLE + ' Register'}
            />
            <OhsRegister
              columns={columns}
              data={props.vdocList}
              sortBy={[{ id: 'categoryId', desc: false }]}
              rowOperations={{}}
              setSelectedRows={setSelectedRows}
              noPagination={true}
            />
          </>
        }
        actionNode={
          <>
            <Button onClick={handleButtonAdd} color={'green'}>
              Submit
            </Button>
          </>
        }
      />
    </>
  );
};
