import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import 'react-toastify/dist/ReactToastify.css';
import * as _ from 'lodash';
import { makeStyles } from 'tss-react/mui';

import scLogo from '../modules/build/img/sc-logo.png';
import { resetUserData, SsoFlagLocalStorageName } from '../common/OhsDataParse';
import OhsLoginForm from './OhsLoginForm';
import OhsLoginFooter from './OhsLoginFooter';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '50%',
  },
}));

const OhsLogin = () => {
  const { classes } = useStyles();

  React.useEffect(() => {
    localStorage.removeItem(SsoFlagLocalStorageName);
    resetUserData();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={scLogo} alt="Safety Champion" />
        <OhsLoginForm />
        {/* <OhsMaintainInfo /> */}
        <OhsLoginFooter />
      </div>
    </Container>
  );
};

export default OhsLogin;
