import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from '../leftSideDrawer/OhsLeftSideDrawerModuleList';
import { OhsBreadcrumbsNameAndRef } from './OhsBreadcrumbsServices';

export const OhsBreadCrumbNoticeboard = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  user: OhsUser
) => {
  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.noticeboard?.TITLE ?? '',
    };
  } else if (subPath === 'admin') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Manage' };
  } else if (viewPath === 'view') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/admin`,
      name: 'Manage',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (subPath === 'form') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/admin?tab=form`,
      name: 'Manage',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  } else if (subPath === 'form' && viewPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/admin?tab=form`,
      name: 'Manage',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Add' };
  }
  return ohsBreadcrumbsNameAndRef;
};
