import * as _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';
import {
  convertObj2FormData,
  getLocalStorage,
  setLocalStorage,
} from '../common/OhsDataParse';
import {
  OhsExcelConfig,
  OhsSafetyPlanExcelConfigList,
} from '../global/excel/OhsExcelExportModels';
import { OhsRegisterFilter } from '../models/OhsRegisterFilter';
import { TierType } from '../models/tier/OhsTier';
import { getOhsUser } from '../user/OhsUser';
import {
  OhsAllocationTierRecord,
  OhsSafetyPlanRecord,
} from './OhsSafetyPlanModels';

export const getSafetyPlanList = (): Promise<Array<OhsSafetyPlanRecord>> => {
  const endpoint = '/safetyplans/list';

  const filter = new OhsRegisterFilter();

  return ohsApiClient
    .post(endpoint, { filter: filter })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const getSafetyPlanCopyList = (): Promise<Array<any>> => {
  const endpoint = '/safetyplans/copylist';

  return ohsApiClient.get(endpoint).then((response) => {
    if (_.has(response, 'data.success') && response.data.success === true) {
      return response.data.result.item;
    } else {
      console.error(response);
    }
    return response;
  });
};

export const getSafetyPlanExportList = (): Promise<Array<any>> => {
  const endpoint = '/safetyplans/export';

  const filter = new OhsRegisterFilter();

  return ohsApiClient
    .post(endpoint, { filter: filter })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const getSafetyPlanAllocationList = (
  id: string
): Promise<Array<OhsAllocationTierRecord>> => {
  const endpoint = `/safetyplans/${id}/allocate`;

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const user = getOhsUser();
        response.data.result.items.forEach(
          (element: OhsAllocationTierRecord) => {
            if (element.type === TierType.T3) {
              element.name = user?.org?.name!;
            } else {
              element.name = _.find(user?.configs.admin2?.LOWER_TIER_ACCESS, {
                _id: element._id,
              })?.name!;
            }
          }
        );
        return response.data.result.items;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const allocateSafetyPlan = (
  id: string,
  item: OhsAllocationTierRecord
): Promise<OhsSafetyPlanRecord> => {
  const endpoint = `/safetyplans/${id}/allocate`;

  return ohsApiClient
    .post(endpoint, { dateDue: item.dateDue, to: item })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const deallocateSafetyPlan = (
  id: string,
  item: OhsAllocationTierRecord
): Promise<OhsSafetyPlanRecord> => {
  const endpoint = `/safetyplans/${id}/deallocate`;

  return ohsApiClient
    .post(endpoint, { to: item })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const addEditSafetyPlan = (
  safetyPlah: any
): Promise<OhsSafetyPlanRecord> => {
  const endpoint = '/safetyplans/new';

  return ohsApiClient
    .post(endpoint, convertObj2FormData(safetyPlah))
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const copySafetyPlan = (id: string): Promise<any> => {
  const endpoint = '/safetyplans/' + id + '/copy';

  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

const SafetyPlanExcelExportConfigName = 'OhsSafetyPlanExcelConfigList';
export const getSafetyPlanExcelExportConfig = (): OhsExcelConfig[] => {
  const config = getLocalStorage(SafetyPlanExcelExportConfigName);
  if (config) {
    return config;
  } else {
    return OhsSafetyPlanExcelConfigList;
  }
};

export const saveSafetyPlanExcelExportConfig = (target: OhsExcelConfig[]) => {
  setLocalStorage(SafetyPlanExcelExportConfigName, target);
};

export const getSafetyPlanTask = (safetyPlanId: string, taskId: string) => {
  const endpoint = `/safetyplans/${safetyPlanId}/tasks/${taskId}`;
  const errMsg = 'Unable to fetch the safety plan.';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};
        return resData;
      } else {
        toast.error(errMsg);
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};
export const fetchSafetyPlan = (safetyPlanId: string) => {
  const endpoint = `/safetyplans/${safetyPlanId}/fetch`;
  const errMsg = 'Unable to fetch the safety plan.';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};
        return resData;
      } else {
        toast.error(errMsg);
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};
