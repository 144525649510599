import React from 'react';

import { TextField } from '@mui/material';
import Joi from 'joi';
import _ from 'lodash';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

interface Props extends OhsFormFieldBaseProps {}
function InputEle(props: Props) {
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );
  return (
    <TextField
      type="url"
      error={error != null}
      fullWidth
      margin={'dense'}
      InputLabelProps={{
        shrink: true,
      }}
      size="small"
      variant={returnVariant(props.ohsFormSettings.layout)}
      required={props.required}
      disabled={props.disabled}
      {...props.ohsFormSettings.useFormMethods.register(props.id, {
        required: props.required,
        validate: {
          isURL: (valueURL: string) => {
            if (props.required !== true && _.isEmpty(valueURL)) {
              return true;
            } else {
              const schema = Joi.object({
                valueURL: Joi.string().uri(),
              });
              const { error } = schema.validate({ valueURL: valueURL });
              if (_.isUndefined(error) || _.isNil(error)) {
                return true;
              } else {
                return 'Please provide a valid URL which starts with "https://" or "http://".';
              }
            }
          },
        },
      })}
      style={returnFieldStyle(props.ohsFormSettings.layout)}
    />
  );
}

export default function OhsUrlField(props: Props) {
  return <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />;
}
