import _ from 'lodash';
import { toast } from 'react-toastify';
import { OhsActionsRecord } from '../action/OhsActionModels';
import {
  OhsApiResponseType,
  OhsApiWarningCodes,
} from '../common/models/OhsApiModels';
import ohsApiClient from '../common/OhsApi';
import { handleApiResponseError } from '../common/OhsApiUtils';
import {
  convertObj2FormData,
  parseRegisterAllocatableData,
} from '../common/OhsDataParse';
import { OhsContractorLookup } from '../contractor/OhsContractorModels';
import { parseContraLookupList } from '../contractor/OhsContractorServices';
import { OhsStatusFilterOption } from '../global/register/table/filters/OhsFilterTypes';
import OhsLinkHolderColumn from '../global/register/table/utils/OhsLinkHolderColumn';
import { OhsRegisterTabItemTypes } from '../global/tab/OhsTabModels';
import { OhsHrLookup } from '../human-resource/OhsHrModels';
import { parseHrLookupList } from '../human-resource/OhsHrServices';
import { ModuleType } from '../models/OhsObject';
import { OhsRegisterFilter } from '../models/OhsRegisterFilter';
import { TierType, setTierName } from '../models/tier/OhsTier';
import { OhsAllocationTierRecord } from '../safety-plan/OhsSafetyPlanModels';
import { getOhsUser } from '../user/OhsUser';
import {
  OhsCorporateRiskTaskRecord,
  OhsCorporateRiskArchive,
  OhsCorporateRiskRecord,
} from './OhsCorporateRiskModels';

const user = getOhsUser();
const moduleName = user?.getModuleName(ModuleType.CorporateRisk);

export const addEditCorporateRisk = (
  corporateRisk: any
): Promise<OhsCorporateRiskRecord | null> => {
  corporateRisk = { ...new OhsCorporateRiskRecord(), ...corporateRisk };
  const formPayload = convertObj2FormData(corporateRisk);

  let endpoint;
  let addedit = '';
  if (corporateRisk._id) {
    endpoint = `/crisks/${corporateRisk._id}/edit`;
    addedit = 'edit';
  } else {
    endpoint = '/crisks/new';
    addedit = 'add';
  }

  const defaultErrMsg = `Unable to ${addedit} the ${moduleName}.`;
  return ohsApiClient
    .post(endpoint, formPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        handleApiResponseError(response, defaultErrMsg, [
          {
            code: OhsApiWarningCodes.duplicates_not_allowed,
            message: 'Reference should be unique. Please change and resubmit.',
          },
        ]);
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(defaultErrMsg);
      return null;
    });
};

export const getCorporateRiskList = (): Promise<
  Array<OhsCorporateRiskRecord>
> => {
  const endpoint = '/crisks/list';
  const errorMessage = `Unable to get the ${moduleName} list.`;

  const filter = new OhsRegisterFilter();

  return ohsApiClient
    .post(endpoint, { filter: filter })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const loadCorporateRiskList = async (): Promise<
  Array<OhsCorporateRiskRecord>
> => {
  return getCorporateRiskList().then((list: Array<OhsCorporateRiskRecord>) =>
    parseRegisterAllocatableData(list, OhsRegisterTabItemTypes.MainRegister)
  );
};

export const getCorporateRiskRecord = (
  id: string
): Promise<OhsCorporateRiskRecord> => {
  const endpoint = `/crisks/${id}/fetch`;
  const errorMessage = `Unable to get the ${moduleName} record.`;

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const crisk = response.data.result.item;
        if (crisk && crisk.riskOwnerLinks) {
          crisk.riskOwnerLinks.forEach((link: any) => {
            if (link.type === ModuleType.HR) {
              link.moduleName = user?.configs.hr?.TITLE ?? '';
            } else if (link.type === ModuleType.Contractor) {
              link.moduleName = user?.configs.contractor?.TITLE ?? '';
            }
          });
        }
        return response.data.result.item;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getCorporateRiskTaskRecord = (
  corporateId: string,
  taskId: string
): Promise<OhsCorporateRiskRecord> => {
  const endpoint = `/crisks/${corporateId}/tasks/${taskId}`;
  const errorMessage = `Unable to get the ${moduleName} record.`;
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};
export const signOffCRiskTaskRecord = (
  taskRecord: OhsCorporateRiskTaskRecord,
  corporateId: string,
  taskId: string
): Promise<OhsApiResponseType<OhsCorporateRiskTaskRecord>> => {
  const endpoint = `/crisks/${corporateId}/tasks/${taskId}/signoff`;
  const errorMessage = `Unable to signoff ${moduleName} record.`;
  const formPayload = convertObj2FormData(taskRecord);
  return ohsApiClient
    .post(endpoint, formPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        if (taskRecord.complete === false) {
          toast.success(`"${taskRecord.title}" has been saved`);
        }
        const genResponse = {
          ...response.data,
          data: response.data.result.item,
        };
        return genResponse;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return response.data;
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const signOffCancelSave = (
  corporateId: string,
  taskId: string
): Promise<OhsCorporateRiskRecord> => {
  const endpoint = `/crisks/${corporateId}/tasks/${taskId}/removeSave`;
  const errorMessage = `Unable to cancel the saved data of ${moduleName} record.`;
  const formPayload = convertObj2FormData({});
  return ohsApiClient
    .post(endpoint, formPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        if (response.data.success === true) {
          toast.success('Saved data has been successfully cancelled.');
        }
        return response.data.result.item;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getCorporateRiskAllocationList = (
  id: string
): Promise<Array<OhsAllocationTierRecord>> => {
  const endpoint = `/crisks/${id}/allocate`;
  const errMsg = 'Unable to get the allocation list.';
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const user = getOhsUser();
        response.data.result.items.forEach(
          (element: OhsAllocationTierRecord) => {
            if (element.type === TierType.T3) {
              element.name = user?.org?.name!;
            } else {
              element.name = _.find(user?.configs.admin2?.LOWER_TIER_ACCESS, {
                _id: element._id,
              })?.name!;
            }
          }
        );
        return response.data.result.items;
      } else {
        handleApiResponseError(response, errMsg, []);
        return [];
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};

export const allocateCorporateRisk = (
  id: string,
  item: OhsAllocationTierRecord
): Promise<OhsCorporateRiskRecord | null> => {
  const endpoint = `/crisks/${id}/allocate`;
  const errMsg = 'Unable to allocate the record.';
  return ohsApiClient
    .post(endpoint, { dateDue: item.dateDue, to: item })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        handleApiResponseError(response, errMsg, []);
        return null;
      }
    })
    .catch(function (error) {
      toast.error(errMsg);
      console.log(error);
      return null;
    });
};

export const deallocateCorporateRisk = (
  id: string,
  item: OhsAllocationTierRecord
): Promise<OhsCorporateRiskRecord | null> => {
  const endpoint = `/crisks/${id}/deallocate`;
  const errMsg = 'Unable to deallocated the record.';
  return ohsApiClient
    .post(endpoint, { to: item })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        handleApiResponseError(response, errMsg, []);
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};

export const archiveCorporateRisk = (
  item: OhsCorporateRiskArchive,
  isArchive: boolean
): Promise<boolean> => {
  const endpoint = `/crisks/${item._id}/${isArchive ? 'archive' : 'unarchive'}`;
  const errMsg = `Unable to ${
    isArchive ? 'archive' : 'activate'
  } the ${moduleName}`;
  return ohsApiClient
    .post(endpoint, {
      tz: item.tzDateCreated,
      notes: item.reason,
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        toast.success(
          `"${item.title}" has been ${isArchive ? 'archived' : 'activated'}`
        );
        return true;
      } else {
        handleApiResponseError(response, errMsg, []);
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return false;
    });
};

export const deleteCorporateRisk = (id: string): Promise<boolean | null> => {
  const endpoint = `/crisks/${id}`;
  const errMsg = `Unable to delete ${moduleName}.`;
  return ohsApiClient
    .delete(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        toast.success('Record has been deleted !');
        return true;
      } else {
        handleApiResponseError(response, errMsg, []);
        return null;
      }
    })
    .catch(function (error) {
      toast.error(errMsg);
      console.log(error);
      return null;
    });
};

export const getSignOffTaskLists = (
  criskId?: string
): Promise<Array<OhsCorporateRiskTaskRecord>> => {
  const endpoint = `/crisks/${criskId}/tasks`;
  const errorMessage = `Unable to get the ${moduleName} tasks.`;
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getSignOffActionsTaskLists = (
  criskId?: string
): Promise<Array<OhsActionsRecord>> => {
  const endpoint = `/crisks/${criskId}/links/actions`;
  const errorMessage = `Unable to get the ${moduleName} task actions`;
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getHrLookupList = (): Promise<Array<OhsHrLookup>> => {
  const endpoint = `crisks/hrlookup`;
  const errMsg = 'Unable to get the HR list.';
  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return parseHrLookupList(response.data.result.items);
      } else {
        handleApiResponseError(response, errMsg, []);
        return [];
      }
    })
    .catch(function (error) {
      toast.error(errMsg);
      console.log(error);
      return [];
    });
};

export const getContractorLookupList = (): Promise<
  Array<OhsContractorLookup>
> => {
  const endpoint = `crisks/contractorlookup`;
  const errMsg = 'Unable to get the Contractor list.';
  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return parseContraLookupList(response.data.result.records);
      } else {
        handleApiResponseError(response, errMsg, []);
        return [];
      }
    })
    .catch(function (error) {
      toast.error(errMsg);
      console.log(error);
      return [];
    });
};

export const setConfidential = (
  id: string,
  isConfidential: boolean
): Promise<boolean> => {
  const endpoint = `crisks/${id}/confidential/${
    isConfidential === true ? 'set' : 'unset'
  }`;
  const errMsg = 'Unable to set confidential.';
  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return true;
      } else {
        handleApiResponseError(response, errMsg, []);
        return false;
      }
    })
    .catch(function (error) {
      toast.error(errMsg);
      console.log(error);
      return false;
    });
};

export const getEvidencePdfDownloadUrl = (
  riskId: string,
  taskId: string
): Promise<string> => {
  const endpoint = `/crisks/${riskId}/tasks/${taskId}/pdf-download`;
  const errorMessage = 'Unable to export the evidence PDF.';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        // window.open(data.result.url, '_blank');
        return response.data.result.url;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getDetailPdfDownloadUrl = (riskId: string): Promise<string> => {
  const endpoint = `/crisks/${riskId}/fetch/pdf-download`;
  const errorMessage = 'Unable to export the detail PDF.';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.url;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};
