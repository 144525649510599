import React from 'react';
import OhsOTPInput from '../../../global/form/OhsOTPInput';
import { OTP_LENGTH } from '../../../login/mfa/OhsMfaModal';

interface Props {
  setOTP: React.Dispatch<React.SetStateAction<string>>;
  currentOTP: string;
}
const OhsUserMFAInput = (props: Props) => {
  return (
    <OhsOTPInput
      value={props.currentOTP}
      setValue={(value: string) => props.setOTP(value)}
      shouldAutoFocus={true}
      length={OTP_LENGTH}
    />
  );
};

export default OhsUserMFAInput;
