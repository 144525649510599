import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface OhsLoginState {
  isLogin: boolean;
}
const initialState = {
  isLogin: false,
} as OhsLoginState;

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    isLoginFn: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
  },
});

export const { isLoginFn } = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
