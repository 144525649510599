import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
export class OhsHrPermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  signoff: boolean;
  archive: boolean;
  delete: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.delete = flag;
  }
}
export class OhsHrConfig {
  TITLE: string;
  PERMISSIONS: OhsHrPermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsHrPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}
