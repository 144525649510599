import React from 'react';
import OhsModal from '../../global/modal/OhsModal';
import { ModuleType } from '../../models/OhsObject';
import { TaskAssignUserStatus } from '../OhsTaskModels';
import { OhsTaskAssignTable } from './OhsTaskAssignTable';

interface Props {
  recordId: string;
  recordType: ModuleType;
  taskId: string;
  statusList: TaskAssignUserStatus[];
  open: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onModalClose: () => void;
}
export const OhsTaskAssignModal = (props: Props) => {
  return (
    <>
      <OhsModal
        title={'Assign'}
        open={props.open}
        setModalOpenFunc={props.setModalOpen}
        onClose={props.onModalClose}
        ContentNode={
          <OhsTaskAssignTable
            statusList={props.statusList}
            recordId={props.recordId}
            recordType={props.recordType}
            taskId={props.taskId}
          />
        }
      ></OhsModal>
    </>
  );
};
