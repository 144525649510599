import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
export class OhsCorrespondencePermissions {
  view_prose: boolean;
  create_prose: boolean;
  edit_prose: boolean;
  archive_prose: boolean;
  view_roll: boolean;
  create_roll: boolean;
  edit_roll: boolean;
  archive_roll: boolean;
  delete_roll: boolean;
  setup_exchange: boolean;
  send_exchange: boolean;
  archive_exchange: boolean;
  delete_exchange: boolean;

  constructor(flag: boolean) {
    this.view_prose = flag;
    this.create_prose = flag;
    this.edit_prose = flag;
    this.archive_prose = flag;
    this.view_roll = flag;
    this.create_roll = flag;
    this.edit_roll = flag;
    this.archive_roll = flag;
    this.delete_roll = flag;
    this.setup_exchange = flag;
    this.send_exchange = flag;
    this.archive_exchange = flag;
    this.delete_exchange = flag;
  }
}
export class OhsCorrespondenceConfig {
  TITLE: string;
  PERMISSIONS: OhsCorrespondencePermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsCorrespondencePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}
