import React from 'react';

import OhsFormFieldLayout from '../OhsFormFieldLayout';
import {
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import 'global/form/fields/OhsAttachmentsField.css';
import { Icon } from 'semantic-ui-react';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import { OhsFormFieldBaseProps } from '../OhsFormModels';

export interface Props extends OhsFormFieldBaseProps {
  acceptFiles?: string;
  maxFiles?: number;
}

function InputEle(props: Props) {
  const [attachments, setAttachments] = React.useState(
    new Array<OhsAttachment>()
  );

  React.useEffect(() => {
    const fList =
      props.ohsFormSettings.useFormMethods.getValues(props.id) || [];
    const validFList = fList.filter((file: OhsAttachment) => !_.isEmpty(file));

    if (
      attachments.length === 0 &&
      validFList &&
      validFList.length > 0 &&
      validFList.filter((item: any) => item.fileName).length > 0
    ) {
      setAttachments(props.ohsFormSettings.useFormMethods.getValues(props.id));
    }
    props.ohsFormSettings.useFormMethods.register('files', {});
  }, [attachments.length, props]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles:
      props.maxFiles != null && props.maxFiles > 0 ? props.maxFiles : undefined,
    accept: props.acceptFiles ?? undefined,
    onDrop: (files) => {
      props.ohsFormSettings.useFormMethods.setValue('files', files);
    },
  });

  const updateAttachments = () => {
    const attachmentList = attachments ? [...attachments] : [];
    const validAttachments = attachmentList.filter(
      (file: OhsAttachment) => !_.isEmpty(file)
    );
    if (acceptedFiles && acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        let foundFlag = false;
        for (let index = 0; index < validAttachments.length; index++) {
          const item = validAttachments[index];
          if (
            item.file &&
            item.size === file.size &&
            item.fileName === file.name
          ) {
            foundFlag = true;
          }
        }

        if (foundFlag === false) {
          validAttachments.push({
            group: 'other',
            type: '',
            dateUploaded: '',
            _id: '',
            file: file,
            fileName: file.name,
            size: file.size,
            download: '',
            delete: false,
          });
        }
      });
    }
    if (attachments == null || attachments.length !== validAttachments.length) {
      setAttachments(validAttachments);
      props.ohsFormSettings.useFormMethods.setValue(props.id, validAttachments);
      props.ohsFormSettings.useFormMethods.trigger(props.id);
    }
  };

  updateAttachments();

  const handleDelete = (index: number) => {
    const fileList = [...attachments];
    if (fileList[index].delete === true) {
      const fileCount = fileList.filter((item) => item.delete !== true).length;
      if (props.maxFiles == null || props.maxFiles > fileCount) {
        fileList[index].delete = false;
      }
    } else {
      fileList[index].delete = true;
    }

    setAttachments(fileList);
    props.ohsFormSettings.useFormMethods.setValue(props.id, fileList);
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: () => {
          const value =
            props.ohsFormSettings.useFormMethods.getValues(props.id) || [];
          const fileCount = value.filter(
            (item: OhsAttachment) => !_.isEmpty(item) && item?.delete !== true
          ).length;
          if (props.required && fileCount < 1) {
            return false;
          } else {
            return true;
          }
        },
      },
    });
  }, [
    attachments,
    props.id,
    props.maxFiles,
    props.ohsFormSettings.useFormMethods,
    props.ohsFormSettings.useFormMethods.register,
    props.required,
  ]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <div>
          {(attachments || []).length > 0 && (
            <List>
              {attachments.map((attachment, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Icon name="folder" />
                    </ListItemIcon>
                    <ListItemText primary={attachment.fileName} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          handleDelete(index);
                        }}
                      >
                        {attachment.delete === true ? (
                          <Icon name="recycle" />
                        ) : (
                          <Icon name="trash" />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
      </Grid>
      <FormGroup>
        {(attachments == null ||
          props.maxFiles == null ||
          props.maxFiles >
            attachments.filter((item) => item.delete !== true).length) && (
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} type="file" name="file" />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </section>
        )}
      </FormGroup>
    </>
  );
}

export default function OhsAttachmentDropzone(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
