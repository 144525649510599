import { OhsActionLink } from '../action/OhsActionModels';
import { getCurrentTimezone } from '../common/OhsDataParse';
import { OhsHRContractorLinkItem } from '../global/form/fields/OhsLinkHrContractorField/OhsHrContractorLinkModels';
import { ModuleType, OhsModuleCreateBy } from '../models/OhsObject';
import { OhsTier } from '../models/tier/OhsTier';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import { OhsAllocatableRecord } from '../safety-plan/OhsSafetyPlanModels';
import { getOhsUser } from '../user/OhsUser';
import { OhsCusval } from 'global/form/cusvals/OhsCusvalModel';
import { AdminSwitchOptionMap } from '../user/configurations/OhsAdmin2Config';
import { OhsStatusFilterOption } from '../global/register/table/filters/OhsFilterTypes';

export interface OhsTaskType {
  complete: boolean;
  dateDueReference: string;
  _id: string;
}
export interface OhsArchivalDetailsType {
  notes: string;
  date: string;
  tz: string;
}

export interface ArchiveDisplayType extends OhsArchivalDetailsType {
  status: 'Activate' | 'Archive';
  by: OhsModuleCreateBy;
}

export interface OhsTaskForType {
  type: ModuleType.Task;
  _id: string;
  attachments: OhsAttachment[];
  title: string;
  dateExpiry: string;
  dateIssued: string;
  notes: string;
  referenceId: string;
}
export class OhsCorporateRiskRecord implements OhsAllocatableRecord {
  allocated: boolean;
  tier: OhsTier;
  name: string;
  _id: string;
  type: ModuleType.CorporateRisk;
  title: string;
  referenceId: string;
  description: string;
  category: string;
  categoryOther: string | null;
  subcategory: string;
  subcategoryOther: string | null;
  subRows: Array<OhsCorporateRiskRecord>;
  task: OhsTaskType | null;
  inherentRiskRating: string | null;
  inherentRiskRatingOther: string | null;
  futureRiskRating: string | null;
  futureRiskRatingOther: string | null;
  currentMitigation: string;
  futureMitigation: string | null;
  residualRisk: string;
  residualRiskOther: string | null;
  futureControl: boolean;
  riskOwner: string;
  riskOwnerOther: string | null;
  riskOwnerLinks: OhsHRContractorLinkItem[];
  dateIssued: string;
  dateExpiry: string | null;
  cusvals: OhsCusval[];
  subcategoryCusvals: OhsCusval[];
  notes: string;
  attachments: OhsAttachment[];
  previousAttachments?: OhsAttachment[];
  control?: { signatures?: string };
  actionLinks: OhsActionLink[];
  tzDateCreated: string;
  allocationActive: boolean;
  allocationOf: { _id: string; type: ModuleType.CorporateRisk } | undefined;
  createdBy: OhsModuleCreateBy;
  signatures?: OhsAttachment[];
  complete: boolean;
  for?: OhsCorporateRiskRecord;
  save: boolean;
  dateDue: string;
  reviewNotes: string;
  tzDateSignedoff: string;
  dateCompleted: string;
  dateSignedoff: string;
  signedoffBy: OhsModuleCreateBy;
  dateCreated: string;
  confidential?: boolean;
  recurrent: boolean;
  reviewDateRequired: string[];
  archivalDetails?: OhsArchivalDetailsType;
  partialChronology?: OhsCorporateRiskRecord[];
  allocationStatus?: OhsStatusFilterOption;
  constructor() {
    const user = getOhsUser();
    this.allocated = false;
    this.tier = user!.tier;
    this.name = '';
    this._id = '';
    this.type = ModuleType.CorporateRisk;
    this.title = '';
    this.referenceId = '';
    this.description = '';
    this.category = '';
    this.categoryOther = null;
    this.subcategory = '';
    this.subcategoryOther = null;
    this.subRows = new Array<OhsCorporateRiskRecord>();
    this.createdBy = new OhsModuleCreateBy();
    this.tzDateCreated = getCurrentTimezone();
    this.dateCreated = '';
    this.inherentRiskRating = null;
    this.inherentRiskRatingOther = null;
    this.futureRiskRating = null;
    this.futureRiskRatingOther = null;
    this.currentMitigation = '';
    this.futureMitigation = null;
    this.residualRisk = '';
    this.residualRiskOther = null;
    this.futureControl = false;
    this.riskOwner = '';
    this.riskOwnerOther = null;
    this.riskOwnerLinks = new Array<OhsHRContractorLinkItem>();
    this.dateIssued = '';
    this.dateExpiry = null;
    this.task = null;
    this.cusvals = new Array<OhsCusval>();
    this.subcategoryCusvals = new Array<OhsCusval>();
    this.notes = '';
    this.attachments = new Array<OhsAttachment>();
    this.control = { signatures: AdminSwitchOptionMap.disabled };
    this.actionLinks = new Array<OhsActionLink>();
    this.complete = true;
    this.for = undefined;
    this.dateCompleted = '';
    this.dateDue = '';
    this.dateSignedoff = '';
    this.description = '';
    this.reviewNotes = '';
    this.signatures = new Array<OhsAttachment>();
    this.signedoffBy = new OhsModuleCreateBy();
    this.tier = user!.tier;
    this.title = '';
    this.tzDateSignedoff = '';
    this.save = true;
    this.recurrent = true;
    this.reviewDateRequired = [];
    this.allocationActive = false;
  }
}
export class OhsCorporateRiskArchive {
  _id: string;
  title: string;
  referenceID: string;
  archiveDate: any;
  isArchive: boolean;
  reason: string;
  tzDateCreated: string;
  constructor() {
    this._id = '';
    this.title = '';
    this.referenceID = '';
    this.archiveDate = '';
    this.isArchive = false;
    this.reason = '';
    this.tzDateCreated = '';
  }
}
export class OhsCorporateRiskTaskRecord {
  _id: string;
  referenceId: string | null;
  attachments: OhsAttachment[];
  previousAttachments?: OhsAttachment[];
  complete: boolean;
  dateCompleted: string;
  dateDue: string;
  dateSignedoff: string;
  description: string;
  reviewNotes: string;
  signatures?: OhsAttachment[];
  signedoffBy: OhsModuleCreateBy;
  tier: OhsTier;
  title: string;
  type: string;
  tzDateSignedoff: string;
  save?: boolean;
  constructor() {
    const user = getOhsUser();
    this.referenceId = null;
    this.attachments = new Array<OhsAttachment>();
    this.complete = true;
    this.dateCompleted = '';
    this.dateDue = '';
    this.dateSignedoff = '';
    this.description = '';
    this.type = '';
    this._id = '';
    this.reviewNotes = '';
    this.signatures = new Array<OhsAttachment>();
    this.signedoffBy = new OhsModuleCreateBy();
    this.type = '';
    this.tier = user!.tier;
    this.title = '';
    this.tzDateSignedoff = '';
  }
}
export class OhsCorporateRiskTaskReview {
  reviewDate: string;
  reviewNotes: string;
  reviewDateOption: number;
  constructor() {
    this.reviewDateOption = 0;
    this.reviewNotes = '';
    this.reviewDate = '';
  }
}
