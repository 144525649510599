import { createTheme } from '@mui/material/styles';
import { blue, orange } from '@mui/material/colors';
import { OhsUser } from '../user/OhsUser';

export const ohsTheme = (() => {
  const user = new OhsUser();
  return createTheme({
    components: {
      MuiTextField: { defaultProps: { variant: 'standard' } },
      MuiSelect: { defaultProps: { variant: 'standard' } },
      MuiFormControl: { defaultProps: { variant: 'standard' } },
    },
    typography: {
      h1: {
        color: user.configs.theme.HEADER_BAR_BG_COLOR,
      },
      h2: {
        color: user.configs.theme.HEADER_BAR_BG_COLOR,
      },
      h3: {
        color: user.configs.theme.HEADER_BAR_BG_COLOR,
      },
      h4: {
        color: user.configs.theme.HEADER_BAR_BG_COLOR,
      },
      h5: {
        color: user.configs.theme.HEADER_BAR_BG_COLOR,
      },
      h6: {
        color: user.configs.theme.HEADER_BAR_BG_COLOR,
      },
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Lato',
        'Helvetica Neue',
        'Arial',
        'Helvetica',
        'sans-serif',
      ].join(','),
    },
    palette: {
      background: {
        default: user.configs.theme.MAIN_BG_COLOR,
      },
      primary: { main: blue[700] },
      warning: { main: orange[700] },
    },
  });
})();
