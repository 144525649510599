import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from '../leftSideDrawer/OhsLeftSideDrawerModuleList';
import { OhsBreadcrumbsNameAndRef } from './OhsBreadcrumbsServices';

export const OhsBreadCrumbReviewplan = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  operationPath: string,
  user: OhsUser
) => {
  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.reviewplan?.TITLE ?? '',
    };
  } else if (subPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'New' };
  } else if (viewPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/view`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  } else if (viewPath === 'view') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (operationPath.indexOf('signoff') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/view?tab=detail&type=orgReviewPlan`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Signoff' };
  }

  return ohsBreadcrumbsNameAndRef;
};
