import _ from 'lodash';
import ohsApiClient from '../common/OhsApi';
import { OhsRegisterFilter } from '../models/OhsRegisterFilter';
import { OhsVdocRecord } from './OhsVdocModels';

export const getVdocLinkableList = (): Promise<Array<OhsVdocRecord>> => {
  const endpoint = '/vdocs/linkablelist';

  const filter = new OhsRegisterFilter();

  return ohsApiClient
    .post(endpoint, { filter: filter })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const getVdocRecord = (vdocId: string): Promise<OhsVdocRecord> => {
  const endpoint = `/vdocs/${vdocId}/fetch`;

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
