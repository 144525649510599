import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OhsRequireAuth from '../OhsRequireAuth';
import OhsAngularIframe from './OhsAngularIframe';

export default function OhsIframeModuleHome() {
  return (
    <div>
      <Routes>
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </div>
  );
}
