import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import { getOhsUser } from '../../user/OhsUser';

interface Props {
  title: string;
  onCloseFn: () => void;
  headerColor?: string;
}

const OhsModalHeader = ({ title, onCloseFn, headerColor }: Props) => {
  const user = getOhsUser();
  const useStyles = makeStyles()(() => ({
    copyTierWrapper: {
      backgroundColor: headerColor
        ? headerColor
        : user?.configs.theme.HEADER_BAR_BG_COLOR,
    },
    formDialogTitle: {
      padding: '0.8rem 1rem !important',
      borderBottom: '1px solid rgba(34, 36, 38, 0.15)',
      width: '95%',
    },
    copyTierTitle: {
      color: user?.configs.theme.HEADER_BAR_TEXT_COLOR,
      fontSize: '1.2em !important',
      lineHeight: '1.28571429em !important',
      fontWeight: 'bold',
    },
    copyTierClose: {
      position: 'absolute',
      right: '1rem',
      top: '0.2em',
      color: 'rgba(255, 255, 255, 0.9)',
      opacity: '0.8 !important',
      width: '2.25rem !important',
      height: '2.25rem !important',
      padding: '0.625rem 0 0 0',
      fontSize: '1.25em !important',
      cursor: 'pointer',
      '&:hover': {
        opacity: '1 !important',
      },
    },
  }));
  const { classes } = useStyles();

  return (
    <div className={classes.copyTierWrapper}>
      <div className={classes.formDialogTitle}>
        <span className={classes.copyTierTitle}>{title ? title : ' '}</span>
      </div>

      <Icon
        onClick={onCloseFn}
        className={classes.copyTierClose}
        name="close"
      />
    </div>
  );
};

OhsModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onCloseFn: PropTypes.func,
};

export default OhsModalHeader;
