import { Container, Grid, Paper, Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React from 'react';
import { OhsWithRouterTypes } from '../../global/types/OhsGlobalTypes';
import OhsWithRouter from '../../global/utils/OhsWithRouter';
import { getOhsUser } from '../../user/OhsUser';
import { OhsXsiRecord } from './OhsXsiModels';
import { getXsiList } from './OhsXsiServices';
import OhsXsiTable from './OhsXsiTable';
type PathParamsType = {
  orgId: string;
};
type PropsType = { classes: any; router: OhsWithRouterTypes<PathParamsType> };
const user = getOhsUser();
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    title: {
      flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: 'calc(100vh - 80px)',
      overflow: 'auto',
      backgroundColor: user?.configs.theme.MAIN_BG_COLOR,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  });
class OhsXsiHome extends React.Component<PropsType> {
  state = {
    xsiList: new Array<OhsXsiRecord>(),
    orgId: '',
  };
  componentDidMount() {
    this.setState({ orgId: this.props.router.params.orgId });
    getXsiList(this.props.router.params.orgId).then((xsiList: any) => {
      this.setState({ xsiList: xsiList });
    });
  }
  render() {
    const { classes } = this.props as any;

    return (
      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}

            {/* Recent Orders */}

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <OhsXsiTable
                  xsiList={this.state.xsiList}
                  orgId={this.state.orgId}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    );
  }
}
export default OhsWithRouter(withStyles(styles)(OhsXsiHome));
