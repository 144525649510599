import { setAngularLocalStorage } from '../../common/OhsDataParse';
import { OhsLowerTier } from '../../user/configurations/OhsAdmin2Config';

export interface OhsFilterList {
  module: { [key: string]: OhsModuleFilter };
  workplace: { [key: string]: OhsWorkplaceFilter };
  chemicalLocation: { [key: string]: OhsChemicalLocationFilter };
  task: { [key: string]: OhsTaskFilterTypes };
}

export interface OhsModuleFilter {
  checkbox: boolean;
  obj: { type: string; homeUrl: string; title: string };
}
export interface OhsTaskFilterTypes {
  key: string;
  name: string;
  value: boolean | string;
  type: string;
}
export interface OhsWorkplaceFilter {
  checkbox: boolean;
  obj: OhsLowerTier;
}
export interface OhsChemicalLocationFilter {
  checkbox: boolean;
  obj: { name: string };
}

export const updateFilterListByModuleFilter = (
  filterList: OhsFilterList,
  ohsModuleFilterList: Array<OhsModuleFilter>
) => {
  ohsModuleFilterList.forEach((item) => {
    filterList.module[item.obj.type].checkbox = item.checkbox;
  });
  setAngularLocalStorage('filterList', filterList);
  return filterList;
};

export const updateTaskFilterCheckbox = (
  filterList: OhsFilterList,
  ohsTaskFilterList: Array<OhsTaskFilterTypes>
) => {
  ohsTaskFilterList.forEach((item: OhsTaskFilterTypes, index: number) => {
    if (filterList.task[index].type === 'checkbox') {
      filterList.task[index].value = item.value;
    }
  });
  setAngularLocalStorage('filterList', filterList);
};

export const updateFilterListByWorkplaceFilter = (
  filterList: OhsFilterList,
  ohsWorkplaceFilterList: Array<OhsWorkplaceFilter>
) => {
  ohsWorkplaceFilterList.forEach((item) => {
    filterList.workplace[item.obj.name].checkbox = item.checkbox;
  });
  setAngularLocalStorage('filterList', filterList);
  return filterList;
};
export const updateFilterListByChemicalLocationFilter = (
  filterList: OhsFilterList,
  ohsChemicalLocationFilterList: Array<OhsChemicalLocationFilter>
) => {
  ohsChemicalLocationFilterList.forEach((item) => {
    if (filterList.chemicalLocation[item.obj.name]) {
      filterList.chemicalLocation[item.obj.name].checkbox = item.checkbox;
    } else {
      Object.assign(filterList.chemicalLocation, {
        [item.obj.name]: { checkbox: item.checkbox },
      });
    }
  });
  setAngularLocalStorage('filterList', filterList);
};
export const OhsPotentialFilterModuleList: string[] = [
  'core.module.action',
  'core.module.chemical',
  'core.module.incident',
  'core.module.reviewplan',
  'core.module.safetyplan',
  'core.module.training',
  'core.module.vdoc',
  'core.module.inspection',
  'core.module.crisk',
  'core.module.asset',
];
