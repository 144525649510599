import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { OhsXsiRecord } from './OhsXsiModels';
import { disableEnableXsiIntegration } from './OhsXsiServices';
import { toast } from 'react-toastify';

export default function OhsXsiDisableAlertDialog(props: {
  isOpen: boolean;
  xsiRecord: OhsXsiRecord;
  setOpen: any;
}) {
  const handleClose = () => {
    props.setOpen(false);
  };
  const disableXsiRecord = () => {
    disableEnableXsiIntegration(
      props.xsiRecord._id,
      props.xsiRecord.tier._id,
      true
    ).then((result: any) => {
      if (result) {
        props.setOpen(false);
        toast.success('Successfully disable the integration record.');
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ backgroundColor: 'orange' }}
          id="alert-dialog-title"
        >
          {'Do you want to  stop the service?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If the service has been enabled, the HR records will stop updating.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ fontWeight: 'bold', textTransform: 'none' }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            style={{ fontWeight: 'bold', textTransform: 'none' }}
            onClick={disableXsiRecord}
            color="primary"
            autoFocus
          >
            Disable
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
