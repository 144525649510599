import * as _ from 'lodash';
import { toast } from 'react-toastify';

import {
  getFileTypebyFileName,
  NoticeboardForm,
  OhsFileType,
  OhsNoticeboardBlock,
  OhsNoticeboardBlockType,
  OhsNoticeBoardRecord,
  OhsNoticeBoardRecordConfig,
  setBlockDisplayType,
} from './OhsNoticeBoardModels';
import { OhsRegisterFilter } from '../models/OhsRegisterFilter';
import ohsApiClient from '../common/OhsApi';
import { OhsAllocationTierRecord } from '../safety-plan/OhsSafetyPlanModels';
import { TierType } from '../models/tier/OhsTier';
import { getOhsUser } from '../user/OhsUser';
import {
  convertObj2FormData,
  getCurrentTimezone,
  getEnumKeyByEnumValue,
} from '../common/OhsDataParse';
import { ModuleType } from '../models/OhsObject';
import { OhsVdocRecord } from '../vdoc/OhsVdocModels';
import { OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import { fetchAttachmentDownloadUrl } from '../global/attachment/OhsAttachmentServices';

const shortid = require('shortid');

export const getNoticeBoardList = (): Promise<Array<OhsNoticeBoardRecord>> => {
  const endpoint = '/noticeboard/boards/list';
  const errorMessage = 'Unable to get the noticeboard list.';

  const filter = new OhsRegisterFilter();

  return ohsApiClient
    .post(endpoint, { filter })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      }
      console.error(response);
      toast.error(errorMessage);
      return [];
    })
    .catch((error) => {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const fetchNoticeboardLinkedDocs = (
  id: string
): Promise<OhsVdocRecord[]> => {
  const endpoint = `/noticeboard/boards/${id}/blocklist/vdocs`;
  const errMsg = 'Unable to get the document list';
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      }
      console.error(response);
      toast.error(errMsg);
      return [];
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return [];
    });
};

export const getNoticeBoardRecord = (
  noticeBoardId: string
): Promise<OhsNoticeBoardRecord | null> => {
  const endpoint = `/noticeboard/boards/${noticeBoardId}/fetch`;
  const errMsg = 'Unable to get the noticeboard.';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      }
      console.error(response);
      toast.error(errMsg);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};
export const fetchNoticeboardForm = (
  id: string
): Promise<NoticeboardForm | null> => {
  const endpoint = `/noticeboard/forms/${id}/fetch`;
  const errMsg = 'Unable to get the form.';
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const form = response.data.result.item as NoticeboardForm;
        if (form.cusvals && form.cusvals.length > 0) {
          form.cusvals.forEach((item) => {
            item.typeDisplay = getEnumKeyByEnumValue(OhsCusvalType, item.type);
            item.requiredDisplay = item.required === true ? 'Yes' : 'No';
          });
        }

        return form;
      }
      console.error(response);
      toast.error(errMsg);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};
export const getNoticeBoardRecordConfig = (
  noticeBoardId: string
): Promise<OhsNoticeBoardRecordConfig | null> => {
  const endpoint = `/noticeboard/boards/${noticeBoardId}/blocklist/fetch`;
  const errMsg = 'Unable to get the noticeboard details';
  return ohsApiClient
    .get(endpoint)
    .then(async (response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const noticeboardConf = response.data.result.item;

        if (
          noticeboardConf &&
          noticeboardConf.attachments &&
          noticeboardConf.blocks
        ) {
          noticeboardConf.blocks.forEach((item: any) => {
            if (
              item.type === OhsNoticeboardBlockType.OhsAttachment ||
              item.type === OhsNoticeboardBlockType.OhsLink
            ) {
              const attachment = _.find(noticeboardConf.attachments, {
                group: item.data._id,
              });
              item.data.attachment = attachment;
            }
          });
        }
        if (noticeboardConf.blocks) {
          for (
            let index = 0;
            index < noticeboardConf.blocks.length;
            index += 1
          ) {
            const item = noticeboardConf.blocks[index];
            if (
              item.type === OhsNoticeboardBlockType.OhsAttachment &&
              item.data.attachment
            ) {
              item.typeDisplay = setBlockDisplayType(
                item.type,
                item.data.attachment.fileName
              );
            } else {
              item.typeDisplay = setBlockDisplayType(item.type);
            }
          }
        }
        const linkedDocList = await fetchNoticeboardLinkedDocs(noticeBoardId);
        if (linkedDocList && noticeboardConf.blocks)
          linkedDocList.forEach((vdoc) => {
            const block = noticeboardConf.blocks.find(
              (item: any) =>
                item.type === OhsNoticeboardBlockType.OhsDocument &&
                item.data._id === vdoc._id
            );
            if (block) {
              block.data.title = vdoc.version.name;
              [block.data.attachment] = vdoc.version.attachments;
              block.data.description = vdoc.version.description;
            }
          });

        // Mark missing vdocs
        if (noticeboardConf.blocks) {
          noticeboardConf.blocks.forEach((block: OhsNoticeboardBlock) => {
            if (
              block.type === OhsNoticeboardBlockType.OhsDocument &&
              !block.data.title
            ) {
              block.data.title = 'N/A';

              block.data.description = 'The linked document is not accessible.';
            }
          });
          noticeboardConf.blocks = noticeboardConf.blocks.filter(
            (item: any) =>
              item.type !== OhsNoticeboardBlockType.OhsDocument ||
              (item.type === OhsNoticeboardBlockType.OhsDocument &&
                item.data.title)
          );
        }

        if (noticeboardConf.blocks) {
          for (
            let index = 0;
            index < noticeboardConf.blocks.length;
            index += 1
          ) {
            const item = noticeboardConf.blocks[index];
            if (
              item.data &&
              item.data.attachment &&
              item.data.attachment.download &&
              item.data.attachment.fileName &&
              getFileTypebyFileName(item.data.attachment.fileName) ===
                OhsFileType.OhsAttachmentImg
            ) {
              item.data.attachment.downloadUrl =
                await fetchAttachmentDownloadUrl(item.data.attachment.download);
            }
          }
        }
        if (noticeboardConf.forms && noticeboardConf.forms.length > 0) {
          for (
            let index = 0;
            index < noticeboardConf.forms.length;
            index += 1
          ) {
            const element = noticeboardConf.forms[index];
            noticeboardConf.forms[index] = await fetchNoticeboardForm(
              element._id
            );
          }
        }
        return noticeboardConf;
      }
      console.error(response);
      toast.error(errMsg);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};
export const editNoticeBoardRecordConfig = (
  config: OhsNoticeBoardRecordConfig
): Promise<OhsNoticeBoardRecordConfig | null> => {
  const endpoint = `/noticeboard/boards/${config.board._id}/blocklist/edit`;
  const errMsg = 'Unable to edit the noticeboard.';
  const payload: any = {
    blocks: new Array<OhsNoticeboardBlock>(0),
    forms: [],
    contacts: [],
    attachments: [],
  };
  payload.tzDateUpdated = getCurrentTimezone();
  if (config.blocks)
    config.blocks.forEach((item) => {
      switch (item.type) {
        case OhsNoticeboardBlockType.OhsDocument:
          payload.blocks.push({
            type: OhsNoticeboardBlockType.OhsDocument,
            data: {
              type: ModuleType.VersionDocument,
              _id: item.data._id,
            },
          });

          break;
        case OhsNoticeboardBlockType.OhsYoutube:
          payload.blocks.push({
            type: OhsNoticeboardBlockType.OhsYoutube,
            data: {
              title: item.data.title,
              source: item.data.source,
              description: item.data.description,
              _id: '',
            },
          });

          break;
        case OhsNoticeboardBlockType.OhsAttachment:
          if (
            !item.data.title &&
            item.data.attachment &&
            item.data.attachment.file == null &&
            item.data.attachment.delete === true
          ) {
            payload.attachments.push({
              group: item.data._id,

              delete: true,
            });
          } else if (
            item.data.attachment &&
            item.data.attachment.file &&
            item.data.attachment.delete === true
          ) {
            const newId = shortid.generate();
            payload.attachments.push({
              group: item.data._id,

              delete: true,
            });
            payload.blocks.push({
              type: OhsNoticeboardBlockType.OhsAttachment,
              data: {
                title: item.data.title,

                description: item.data.description,
                _id: newId,
              },
            });
            if (item.data.attachment && item.data.attachment.file)
              payload.attachments.push({
                group: newId,
                file: item.data.attachment.file,
                delete: false,
              });
          } else {
            payload.blocks.push({
              type: OhsNoticeboardBlockType.OhsAttachment,
              data: {
                title: item.data.title,

                description: item.data.description,
                _id: item.data._id,
              },
            });
            if (item.data.attachment && item.data.attachment.file)
              payload.attachments.push({
                group: item.data._id,
                file: item.data.attachment.file,
                delete: false,
              });
          }

          break;
        case OhsNoticeboardBlockType.OhsLink:
          if (
            !item.data.title &&
            item.data.attachment &&
            item.data.attachment.file == null &&
            item.data.attachment.delete === true
          ) {
            payload.attachments.push({
              group: item.data._id,

              delete: true,
            });
          } else if (
            item.data.attachment &&
            item.data.attachment.file &&
            item.data.attachment.delete === true
          ) {
            const newId = shortid.generate();
            payload.attachments.push({
              group: item.data._id,

              delete: true,
            });
            payload.blocks.push({
              type: OhsNoticeboardBlockType.OhsLink,
              data: {
                title: item.data.title,

                description: item.data.description,
                _id: newId,
                links: item.data.links,
              },
            });
            if (item.data.attachment && item.data.attachment.file)
              payload.attachments.push({
                group: newId,
                file: item.data.attachment.file,
                delete: false,
              });
          } else {
            payload.blocks.push({
              type: OhsNoticeboardBlockType.OhsLink,
              data: {
                title: item.data.title,

                description: item.data.description,
                _id: item.data._id,
                links: item.data.links,
              },
            });
            if (item.data.attachment && item.data.attachment.file)
              payload.attachments.push({
                group: item.data._id,
                file: item.data.attachment.file,
                delete: false,
              });
          }

          break;
        default:
          break;
      }
    });
  if (config.contacts)
    config.contacts.forEach((item) => {
      payload.contacts.push({
        phones: item.phones,
        emails: item.emails,
        role: item.role ?? null,
        roleOther: item.roleOther ?? null,
        notes: item.notes ? item.notes : null,
        name: item.name,
      });
    });

  if (config.forms)
    config.forms.forEach((item) => {
      payload.forms.push({
        type: ModuleType.NoticeBoardForm,
        _id: item._id,
      });
    });

  return ohsApiClient

    .post(endpoint, convertObj2FormData(payload), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const noticeboardConf = response.data.result.item;

        return noticeboardConf;
      }
      console.error(response);
      toast.error(errMsg);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};

export const getNoticeBoardAllocationList = (
  id: string
): Promise<Array<OhsAllocationTierRecord>> => {
  const endpoint = `/noticeboard/boards/${id}/allocate`;
  const errMsg = 'Unable to get the allocation list.';
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const user = getOhsUser();
        response.data.result.items.forEach(
          (element: OhsAllocationTierRecord) => {
            if (element.type === TierType.T3) {
              element.name = user?.org?.name!;
            } else {
              element.name = _.find(user?.configs.admin2?.LOWER_TIER_ACCESS, {
                _id: element._id,
              })?.name!;
            }
          }
        );
        return response.data.result.items;
      }
      console.error(response);
      toast.error(errMsg);
      return [];
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return [];
    });
};

export const allocateNoticeBoard = (
  id: string,
  item: OhsAllocationTierRecord
): Promise<OhsNoticeBoardRecord | null> => {
  const endpoint = `/noticeboard/boards/${id}/allocate`;
  const errMsg = 'Unable to allocate the noticeboard.';
  return ohsApiClient
    .post(endpoint, { dateDue: item.dateDue, to: item })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      }
      toast.error(errMsg);
      console.error(response);
      return null;
    })
    .catch((error) => {
      toast.error(errMsg);
      console.error(error);
      return null;
    });
};

export const deallocateNoticeBoard = (
  id: string,
  item: OhsAllocationTierRecord
): Promise<OhsNoticeBoardRecord | null> => {
  const endpoint = `/noticeboard/boards/${id}/deallocate`;
  const errMsg = 'Unable to deallocated the noticeboard';
  return ohsApiClient
    .post(endpoint, { to: item })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      }
      toast.error(errMsg);
      console.error(response);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};

export const addEditNoticeBoard = (
  noticeBoard: any
): Promise<OhsNoticeBoardRecord | null> => {
  let endpoint;
  let addedit = '';
  if (noticeBoard._id) {
    endpoint = `/noticeboard/boards/${noticeBoard._id}/edit`;
    addedit = 'edit';
  } else {
    endpoint = '/noticeboard/boards/new';
    addedit = 'add';
  }
  const errMsg = `Unable to ${addedit} the noticeboard.`;
  return ohsApiClient
    .post(endpoint, noticeBoard)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      }
      console.error(response);
      toast.error(errMsg);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};

export const fetchNoticeboardDoc = (
  id: string
): Promise<OhsVdocRecord | null> => {
  const endpoint = `/vdocs/${id}/fetch`;
  const errMsg = 'Unable to get the document';
  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      }
      toast.error(errMsg);
      console.error(response);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};

export const archiveNoticeBoard = (
  id: string,
  isArchive: boolean
): Promise<boolean> => {
  const endpoint = `/noticeboard/boards/${id}/${
    isArchive ? 'archive' : 'unarchive'
  }`;
  const errMsg = `Unable to ${
    isArchive ? 'archive' : 'activate'
  } the noticeboard`;
  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return true;
      }
      console.error(response);
      toast.error(errMsg);
      return false;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return false;
    });
};

export const archiveNoticeBoardForm = (
  id: string,
  isArchive: boolean
): Promise<boolean> => {
  const endpoint = `/noticeboard/forms/${id}/${
    isArchive ? 'archive' : 'unarchive'
  }`;
  const errMsg = `Unable to ${isArchive ? 'archive' : 'activate'} the form`;
  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return true;
      }
      toast.error(errMsg);
      return false;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return true;
    });
};

export const getNoticeboardFormList = (): Promise<NoticeboardForm[]> => {
  const endpoint = '/noticeboard/forms/list';
  const errMsg = 'Unable to get the form list.';
  const filter = new OhsRegisterFilter();
  return ohsApiClient
    .post(endpoint, { filter })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      }
      console.error(response);
      toast.error(errMsg);
      return [];
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return [];
    });
};

export const formSubmission = (data: any): Promise<boolean> => {
  const endpoint = '/noticeboard/formsubmission';
  const errMsg = 'Unable to submit the form.';
  return ohsApiClient
    .post(endpoint, data || {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return true;
      }
      console.error(response);
      toast.error(errMsg);
      return false;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return false;
    });
};

export const addEditNoticeBoardForm = (
  form: any
): Promise<OhsNoticeBoardRecord | null> => {
  let endpoint;
  if (form._id) {
    endpoint = `/noticeboard/forms/${form._id}/edit`;
  } else {
    endpoint = '/noticeboard/forms/new';
  }

  const errMsg = `Unable to ${form._id ? 'edit' : 'create'} the form.`;
  return ohsApiClient
    .post(endpoint, form)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      }
      console.error(response);
      toast.error(errMsg);
      return null;
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};

export interface IFormSubmissionBody {
  board: {
    type: string;
    _id: string;
  };
  form: {
    type: string;
    _id: string;
  };
}
export const getAllNoticeBoardFormSubmission = (
  boardId: string,
  formId?: string
): Promise<Array<NoticeboardForm>> => {
  const endpoint = '/noticeboard/formsubmission/export';
  const errMsg = 'Unable to export submitted forms.';
  const body = {
    board: {
      type: 'core.module.noticeboard.board',
      _id: boardId,
    },
  } as any;
  if (formId) {
    body.form = {
      type: 'core.module.noticeboard.form',
      _id: formId,
    };
  }
  return ohsApiClient
    .post(endpoint, body)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      }
      console.error(response);
      toast.error(errMsg);
      return [] as NoticeboardForm[];
    })
    .catch((error) => {
      console.error(error);
      toast.error(errMsg);
      return [] as NoticeboardForm[];
    });
};
