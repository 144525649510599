import React from 'react';
import { styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';

const OhsToastContainer = () => {
  return (
    <OhsStyledToast
      position="bottom-right"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

const OhsStyledToast = styled(ToastContainer)((props: any) => {
  return {
    zIndex: props.theme.zIndex.modal + 2,
    // more styling here .....
  };
});

export default OhsToastContainer;
