import React from 'react';

import { Row } from 'react-table';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OhsRegisterTableHeader from './OhsRegisterTableHeader';
import OhsRegisterTableRow from './OhsRegisterTableRow';
import OhsRegisterTableSortLabel from './OhsRegisterTableSortLabel';
import OhsRegisterTableBodyCell from './OhsRegisterTableBodyCell';

import {
  OhsAllocatableRecord,
  OhsAllocationTierRecord,
} from '../../../safety-plan/OhsSafetyPlanModels';
import {
  expanderColumnId,
  searchColumnId,
  statuColumnId,
} from '../OhsRegister';
import OhsRegisterTableListWithCatGroupings from './tableViewLists/OhsRegisterTableListWithCatGroupings';
import OhsRegisterTableRowList from './tableViewLists/OhsRegisterTableRowList';
import OhsRegisterTableRowListDraggable from './tableViewLists/OhsRegisterTableRowListDraggable';
import OhsHeaderContent from './utils/OhsHeaderContent';

interface Props {
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;

  getTableProps: any;
  headerGroups: any;
  page: any;
  prepareRow: any;
  selectedFlatRows: any;
  rowFilterNode?: React.ReactNode;
  moveRowFunc?: any;
  enableDragDrop?: boolean;
  noPagination?: boolean;
  withCategoryGroupings?: boolean;
  isCompress?: boolean;
  hideHeader?: boolean;
}
export interface RowOperations {
  viewFunc?: (item: any) => void;
  openAllocationModalFunc?: (item: OhsAllocatableRecord) => void;
  openDeleteModalFunc?: (item: OhsAllocatableRecord) => void;
  archiveFunc?: (item: OhsAllocatableRecord, isArchive: boolean) => void;
  allocationFunc?: (allocationItem: OhsAllocationTierRecord) => Promise<any>;
  deAllocationFunc?: (
    deallocationItem: OhsAllocationTierRecord
  ) => Promise<any>;
}

const OhsRegisterTable = (props: Props) => {
  let isSortedAscDesc;
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader {...props.getTableProps()}>
        <TableHead>
          {props.headerGroups.map((headerGroup: any, index: number) => (
            <React.Fragment key={index}>
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {props.enableDragDrop === true && (
                  <OhsRegisterTableHeader></OhsRegisterTableHeader>
                )}
                {headerGroup.headers.map((column: any, columnIndex: number) => {
                  if (column.isSorted) {
                    isSortedAscDesc = column.isSortedDesc;
                  }
                  return (
                    <React.Fragment key={columnIndex}>
                      <OhsRegisterTableHeader
                        {...(column.id === 'selection'
                          ? column.getHeaderProps()
                          : column.getHeaderProps(
                              column.getSortByToggleProps()
                            ))}
                        column={column}
                      >
                        {column.render('Header')}

                        {column.id !== 'selection' &&
                        column.id !== statuColumnId &&
                        column.id !== searchColumnId &&
                        column.id !== expanderColumnId ? (
                          <OhsRegisterTableSortLabel
                            active={column.isSorted}
                            // react-table has a unsorted state which is not treated here
                            direction={column.isSortedDesc ? 'asc' : 'desc'}
                            IconComponent={ExpandLessIcon}
                          />
                        ) : null}
                      </OhsRegisterTableHeader>
                    </React.Fragment>
                  );
                })}
              </TableRow>

              {props.headerGroups.map((headerGroup: any, index: number) =>
                headerGroup.headers.map((column: any, keyindex: number) => (
                  <React.Fragment key={keyindex}>
                    {column.canFilter && column.filterFields
                      ? column.render('filterFields')
                      : null}
                  </React.Fragment>
                ))
              )}
            </React.Fragment>
          ))}
        </TableHead>
        {props.withCategoryGroupings === true ? (
          <OhsRegisterTableListWithCatGroupings
            prepareRow={props.prepareRow}
            dataLists={props.page}
            moveRowFunc={props.moveRowFunc}
            isCompress={props.isCompress}
            isSortedAscDesc={isSortedAscDesc}
          />
        ) : (
          <>
            {props.enableDragDrop === true ? (
              <OhsRegisterTableRowListDraggable
                prepareRow={props.prepareRow}
                list={props.page}
                moveRowFunc={props.moveRowFunc}
              />
            ) : (
              <OhsRegisterTableRowList
                prepareRow={props.prepareRow}
                dataLists={props.page}
              />
            )}
          </>
        )}
        {props.page.length === 0 && (
          <TableBody>
            <OhsRegisterTableRow>
              <OhsRegisterTableBodyCell colSpan={123}>
                No item(s) found!
              </OhsRegisterTableBodyCell>
            </OhsRegisterTableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default OhsRegisterTable;
