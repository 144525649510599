import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../../common/OhsApi';
import { ModuleType } from '../../models/OhsObject';
import { getOhsUser } from '../../user/OhsUser';
import { OhsExportRecord } from './OhsExportRecord';
const user = getOhsUser();
const moduleName = user?.getModuleName(ModuleType.Export);
export const getExportList = (): Promise<Array<OhsExportRecord>> => {
  const endpoint = '/exports/list';
  const errorMessage = `Unable to get the ${moduleName} list.`;

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.exports;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getExportDownloadUrl = (storeKey: string): Promise<string> => {
  const endpoint = '/exports/download';
  const errorMessage = `Unable to get the download URL.`;

  return ohsApiClient
    .post(endpoint, { download: storeKey })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.url;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return null;
    });
};

export const getExportTypeName = (exportType: string): string => {
  switch (exportType) {
    case 'MONGODB_BSON':
      return 'Database Backup';

    default:
      return exportType;
  }
};
