import React from 'react';
import { Row } from 'react-table';
import { Button, Icon, Label } from 'semantic-ui-react';
import { registerButtonStyles } from '../../OhsModuleHeader';

export interface CopyButtonsProps {
  row: Row<any>;
  copyFunc: (item: any) => Promise<any>;
}
function OhsCopyButtons({
  row,
  copyFunc,
}: CopyButtonsProps) {
  const { classes } = registerButtonStyles();
  const [copyLoading, setCopyLoading] = React.useState(false);
  const [copyDisabled, setCopyDisabled] = React.useState(false);

  return (
    <>
      {row.original && row.original.copied && (
        <Label>Copied</Label>
      )}
      {row.original && row.original.archived === true && (
        <Label>Archived</Label>
      )}

      {copyFunc && row.original && !row.original.copied && row.original.allocated !== true && (
        <Button
          onClick={async () => {
            setCopyDisabled(true);
            setCopyLoading(true);
            await copyFunc(row.original);
            setCopyDisabled(false);
            setCopyLoading(false);
          }}
          className={classes.button}
          size="small"
          color="green"
          disabled={copyDisabled}
          loading={copyLoading}
        >
          <Icon className={classes.buttonIcons} name="copy" />
          <span className={classes.hideForSmall}>Copy</span>
        </Button>
      )}
    </>
  );
}

export default OhsCopyButtons;
