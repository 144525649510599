import React from 'react';
import _ from 'lodash';
import {
  OhsFileType,
  OhsNoticeboardBlockTypeDisplay,
  setBlockDisplayType,
} from '../OhsNoticeBoardModels';
import { inherits } from 'util';
import { Dialog, DialogContent } from '@mui/material';
import OhsModalHeader from '../../admin2/copy/OhsModalHeader';
import OhsModalForm from '../../global/form/OhsModalForm';
import OhsModal from '../../global/modal/OhsModal';

class OhsNoticeboardModal extends React.Component<{
  item: any;
  closeModal: any;
  title: any;
  blockType: any;
}> {
  render() {
    const { item, closeModal, title, blockType } = this.props;
    const modalHeight =
      blockType === OhsNoticeboardBlockTypeDisplay.OhsYoutube ? 55 : 80;

    return (
      <>
        <OhsModal
          open={item !== null}
          title={title}
          onClose={closeModal}
          maxWidth={'lg'}
          ContentNode={
            <>
              {blockType === OhsNoticeboardBlockTypeDisplay.OhsYoutube && (
                <iframe
                  width="100%"
                  height="500px"
                  src={_.get(item, 'embed')}
                  title={_.get(item, 'data.title')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
              {blockType === 'Image' && item.data.attachment && (
                <img
                  src={item.data.attachment.downloadUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </>
          }
          actionNode={<></>}
        />

        {/* <div
          className="ui dimmer modals page transition visible active"
          ref={(el) => {
            if (el) {
              el.style.setProperty('display', 'flex', 'important');
            }
          }}
        >
          <div
            className="ui modal resourcePDFViewerModal active"
            style={{ height: `${modalHeight}%` }}
          >
            <i
              className="close icon"
              onClick={() => closeModal()}
              style={{ top: '0.4em', right: '1em' }}
            />
            <div
              className="header"
              style={{
                textAlign: 'center',
                backgroundColor: '#3b5998',
                color: '#ffffff',
                padding: '0.8rem 1rem',
              }}
            >
              {_.get(item, 'data.title')}
            </div>
            <div
              className="content"
              style={{ padding: '10px', height: '100%' }}
            >
              <div
                className="column sixteen wide"
                style={{ height: '100%' }}
              ></div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default OhsNoticeboardModal;
