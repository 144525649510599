import React, { ReactNode } from 'react';
import OhsTooltip from '../OhsTooltip';
import { Box } from '@mui/material';
import './OhsFormField.css';

interface Props {
  title?: string;
  description?: string;
  labelEle?: ReactNode;
  required: boolean;
  inputEle: ReactNode;
  style?: React.CSSProperties;
  renderAction?: ReactNode;
}

export function AngularPageFormLayoutFields(props: Props) {
  return (
    <div style={props.style} className="angularLayoutFields">
      <div className="angularLayoutLabel">
        <b>
          {props.title} {props.title && props.required === true ? '*' : ''}{' '}
          {props.description && (
            <OhsTooltip message={props.description} color={'black'} />
          )}
        </b>
        {props.labelEle}
      </div>
      <div className="angularLayoutInput">
        {props.renderAction ? (
          <Box display="flex" alignItems="center">
            {props.inputEle}
            {props.renderAction}
            <OhsTooltip
              message="This field has been removed in the admin configuration and can no longer be edited, you can either leave it for now or delete it."
              color={'blue'}
            />
          </Box>
        ) : (
          props.inputEle
        )}
      </div>
    </div>
  );
}
