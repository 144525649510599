import moment from 'moment-timezone';
import _ from 'lodash';
import XLSX from '../libraries/sheetjs/core';
import { OhsExcelConfig } from './OhsExcelExportModels';
import {
  explodeTreeHierarychy,
  getValueByStringProperties,
  removeInvalidExcelCharacters,
} from '../utils/OhsUtility';
import { toast } from 'react-toastify';

export const exportFormating = (exportList: any[]) => {
  exportList.forEach((item) => {
    if (item.tier && item.tier.type && item.tier.name) {
      if (item.tier.type === 'core.tier.T3') {
        item.exportTierInfo = 'Org: ' + item.tier.name;
      } else {
        item.exportTierInfo = 'Workplace: ' + item.tier.name;
      }
    }

    if (item.createdBy) {
      item.exportCreateInfo =
        item.createdBy.name + '<' + item.createdBy.email + '>';
      item.exportAllocateInfo = '';
    }

    if (item.allocationTo !== undefined) {
      item.allocationTo.forEach((allocate: any, index: number) => {
        if (index !== 0) {
          item.exportAllocateInfo =
            item.exportAllocateInfo + ',' + allocate.name;
        } else {
          item.exportAllocateInfo = item.exportAllocateInfo + allocate.name;
        }
      });
    }
  });
  return exportList;
};
function filterRowData(item: any, configList: OhsExcelConfig[]): boolean {
  let isMultiple = true;
  let isDateRange: boolean | null = null;
  let isWithinAmount = true;

  configList.forEach((config) => {
    let value = item[config.fn];
    if (value) {
      if (
        config.filter &&
        config.filter.type === 'multiple' &&
        config.filter.value &&
        config.filter.value.length > 0
      ) {
        if (!config.filter.value.includes(value)) isMultiple = false;
      }

      if (
        config.filter &&
        config.filter.type === 'dateRange' &&
        config.filter.value &&
        config.filter.value.length === 2
      ) {
        let isAfterDateRange = null;
        let isBeforeDateRange = null;

        const targetDate = moment(value);
        if (config.filter.value[0]) {
          const fromDate = moment(config.filter.value[0]);
          isAfterDateRange = targetDate.isSameOrAfter(fromDate);
        }
        if (config.filter.value[1]) {
          const toDate = moment(config.filter.value[1]).endOf('day');
          isBeforeDateRange = targetDate.isBefore(toDate);
        }
        if (config.filter.value[0] && config.filter.value[1]) {
          isDateRange = isAfterDateRange && isBeforeDateRange;
        } else if (config.filter.value[0]) {
          isDateRange = isAfterDateRange;
        } else if (config.filter.value[1]) {
          isDateRange = isBeforeDateRange;
        }
      }

      if (config.filter && config.filter.value) {
        if (config.filter.type === 'amount') {
          const filterCurrency = config.filter.value[0] || '';
          const filterMinValue = config.filter.value[1] || '';
          const filterMaxValue = config.filter.value[2] || '';

          const amountCurrSplit = value.split(' ');
          const targetAmount = +amountCurrSplit[0] || 0;
          const targetCurrency = amountCurrSplit[1] || '';

          if (!_.isEmpty(filterCurrency) && filterCurrency !== targetCurrency) {
            isWithinAmount = false;
          }

          if (!_.isEmpty(filterMaxValue) && +filterMaxValue < targetAmount) {
            isWithinAmount = false;
          }
          if (!_.isEmpty(filterMinValue) && +filterMinValue > targetAmount) {
            isWithinAmount = false;
          }
        }
      }
    }
  });

  if (_.isNil(isDateRange)) return isMultiple && isWithinAmount;
  return isMultiple && isDateRange && isWithinAmount;
}

function filterExportList(
  configList: OhsExcelConfig[],
  exportList: any[]
): any[] {
  const nList = new Array<any>();
  exportList.forEach((item) => {
    const flag = filterRowData(item, configList);

    if (flag === true) {
      nList.push(item);
    }
  });
  return nList;
}

export function genExelFile(
  exportAll: boolean,
  fileName: string,
  configList: OhsExcelConfig[],
  exportList: any[]
) {
  if (exportAll !== true) {
    exportList = filterExportList(configList, exportList);
    if (exportList.length === 0) {
      toast.error('No records found');
      return null;
    }
  }
  const wb = XLSX.utils.book_new();
  const tableElem = document.createElement('table');
  const tableHeaderElem = document.createElement('tr');

  const validFileName = removeInvalidExcelCharacters(fileName);
  wb.SheetNames.push(validFileName);
  const ws_data = new Array<Array<string>>();

  const headerData = new Array<string>();

  configList.forEach((item) => {
    if (item.selected === true) headerData.push(item.fd);
  });

  headerData.forEach(function (header) {
    var hrEle = document.createElement('th');
    hrEle.appendChild(document.createTextNode(header));

    tableHeaderElem.appendChild(hrEle);
  });
  tableElem.appendChild(tableHeaderElem);

  exportList.forEach((item) => {
    const bodyData = new Array<string>();
    configList.forEach((key) => {
      if (key.selected === true) {
        const content = item[key.fn];
        if (content) {
          bodyData.push(content.toString());
        } else {
          bodyData.push('');
        }
      }
    });
    ws_data.push(bodyData);
  });
  ws_data.forEach(function (row) {
    var tablebadyElem = document.createElement('tr');
    row.forEach(function (cell) {
      var tdEle = document.createElement('td');
      tdEle.appendChild(
        document.createTextNode(cell != null ? cell.toString() : '')
      );

      tablebadyElem.appendChild(tdEle);
    });
    tableElem.appendChild(tablebadyElem);
  });

  let exportedSheet = XLSX.utils.table_to_sheet(tableElem);

  exportedSheet = applyStyle(exportedSheet);

  wb.Sheets[validFileName] = exportedSheet;

  const nowString = moment().format('YYYYMMDD');

  return XLSX.writeFile(wb, validFileName + '_report_' + nowString + '.xlsx', {
    cellStyles: true,
  });
}

const applyStyle = (ws: XLSX.Sheet): XLSX.Sheet => {
  const cellList = Object.keys(ws);
  if (cellList) {
    cellList.forEach((cellName: string) => {
      const match = cellName.match(/\d+$/);
      if (match) {
        const rowNumberString = match[0];
        const rowPreFix = cellName.substring(
          0,
          cellName.length - rowNumberString.length
        );

        if (ws[cellName].v == null) {
          ws[cellName].v = '';
        } else if (ws[cellName].z === 'm/d/yy') {
          ws[cellName].z = 'dd/mm/yyyy';
          if (ws[rowPreFix + '1'] && ws[rowPreFix + '1'].z == null) {
            ws[rowPreFix + '1'].z = 'dd/mm/yyyy';
          }
        } else if (ws[cellName].v instanceof Date) {
          ws[cellName].z = 'h:mm AM/PM';
        }
        let cellStyles = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        } as any;
        if (rowNumberString === '1') {
          cellStyles['fgColor'] = { rgb: 'BFBFBF' };
          cellStyles['bold'] = true;
        }

        ws[cellName].s = cellStyles;
      }
    });
  }

  return ws;
};

export const setConfigFilterOption = (
  exportList: any[],
  configList: OhsExcelConfig[]
) => {
  configList.forEach((config) => {
    if (config.filter && config.filter.type === 'amount') {
      const optionList = new Array<string>();
      const amountArray: number[] = [];
      exportList.forEach((item) => {
        if (item[config.fn]) {
          const value = item[config.fn];
          const amountCurrSplit = value.split(' ');

          const amount = amountCurrSplit[0];
          if (amountArray)
            if (amountArray.includes(amount) === false) {
              amountArray.push(+amount);
            }
          const currencyCode = amountCurrSplit[1];
          if (optionList.includes(currencyCode) === false) {
            optionList.push(currencyCode);
          }
        }
      });
      const maxAmount = _.max(amountArray) || 0;
      const minAmount = _.min(amountArray) || 0;
      config.filter.maxAmount = Math.ceil(maxAmount);
      config.filter.minAmount = Math.floor(minAmount);
      config.filter.options = optionList;
    }
    if (config.filter && config.filter.type === 'multiple') {
      const optionList = new Array<string>();
      exportList.forEach((item) => {
        if (item[config.fn] && optionList.includes(item[config.fn]) === false) {
          optionList.push(item[config.fn]);
        }
      });
      config.filter.options = optionList;
      const formattedOptions = new Array<string>();

      optionList.forEach((option) => {
        if (typeof option === 'string') {
          if (option.toLowerCase() === 'true') {
            formattedOptions.push('true');
          } else if (option.toLowerCase() === 'false') {
            formattedOptions.push('false');
          } else formattedOptions.push(option);
        } else if (typeof option === 'boolean') {
          if (option) formattedOptions.push('true');
          if (!option) formattedOptions.push('false');
        }
      });

      config.filter.options = _.uniq(formattedOptions);
    }
  });
  return configList;
};

export const extractCustomKeyPairs = (data: any[], keys: string[]) => {
  // keys: categoryCusvals, cusvals, propOrEnvDamageCusvals
  // keys are just custom value keys

  let pairs: any[] = [];
  data.forEach((item: any) => {
    keys.forEach((key: string) => {
      let keyValue = null;
      try {
        keyValue = getValueByStringProperties(item, key);
        if (keyValue && Array.isArray(keyValue)) {
          keyValue.forEach((seed) => {
            let isFound = false;
            pairs.forEach((pair) => {
              if (pair.key === seed._id) {
                isFound = true;
                if (typeof pair.type === 'string') {
                  if (pair.type !== 'cusval-' + seed.type) {
                    pair.type = [pair.type, 'cusval-' + seed.type];
                  }
                } else {
                  if (!pair.type.includes('cusval-' + seed.type)) {
                    pair.type = [...pair.type, 'cusval-' + seed.type];
                  }
                }
              }
            });
            if (!isFound) {
              pairs.push({
                key: seed._id,
                description: seed.title,
                type: 'cusval-' + seed.type,
              });
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    });
  });
  return pairs;
};

const parseRawCusValueByFieldType = (fieldType: string, rawValue: any) => {
  if (fieldType && rawValue != null) {
    if (
      fieldType === 'time' &&
      Object.prototype.hasOwnProperty.call(rawValue, 'hours') &&
      Object.prototype.hasOwnProperty.call(rawValue, 'minutes')
    ) {
      const t_str =
        rawValue.hours.toString().padStart(2, '0') +
        ':' +
        rawValue.minutes.toString().padStart(2, '0');
      const today = moment().format('YYYY-MM-DD');

      rawValue = moment(today + 'T' + t_str).format('hh:mm A');
    } else if (fieldType === 'date') {
      rawValue = moment(rawValue).format('YYYY-MM-DD');
    } else if (fieldType === 'currency' && rawValue.code && rawValue.amount) {
      rawValue = rawValue.code + ' ' + rawValue.amount;
    } else if (
      fieldType === 'checkbox' &&
      Array.isArray(rawValue) === true &&
      rawValue.length > 0
    ) {
      rawValue = rawValue.join(', ');
    } else if (
      (fieldType === 'select_multiple' || fieldType === 'select_single') &&
      Array.isArray(rawValue) === true &&
      rawValue.length > 0
    ) {
      rawValue = rawValue
        .map((item: any[]) => explodeTreeHierarychy(item, '>'))
        .join(', ');
    }
  }
  return rawValue;
};

export const parseExportDataByKeys = (
  exportDataList: any[],
  keys: string[]
) => {
  const dataList = [...exportDataList];
  dataList.forEach((item) => {
    keys.forEach((key) => {
      let keyValue = null;
      try {
        keyValue = getValueByStringProperties(item, key);
        if (keyValue && Array.isArray(keyValue)) {
          keyValue.forEach((seed) => {
            if (seed.type) {
              item[seed._id] = parseRawCusValueByFieldType(
                seed.type,
                seed.value
              );
            } else {
              item[seed._id] = seed.value;
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    });
  });
  return dataList;
};
