import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { getOhsBreadcrumbsNameAndRef } from './OhsBreadcrumbsServices';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles()((theme: Theme) => ({
  link: {
    display: 'flex',
    fontSize: 'small',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export default function OhsBreadcrumbs() {
  const { classes } = useStyles();

  const ohsBreadcrumbsNameAndRef = getOhsBreadcrumbsNameAndRef();

  return (
    <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
      {ohsBreadcrumbsNameAndRef.homeRef && (
        <Link
          href={ohsBreadcrumbsNameAndRef.homeRef.href}
          className={classes.link}
        >
          Home
        </Link>
      )}
      {ohsBreadcrumbsNameAndRef.moduleRef && (
        <Link
          href={ohsBreadcrumbsNameAndRef.moduleRef.href}
          className={classes.link}
        >
          {ohsBreadcrumbsNameAndRef.moduleRef.name}
        </Link>
      )}
      {ohsBreadcrumbsNameAndRef.parentOperationRef && (
        <Link
          href={ohsBreadcrumbsNameAndRef.parentOperationRef.href}
          className={classes.link}
        >
          {ohsBreadcrumbsNameAndRef.parentOperationRef.name}
        </Link>
      )}
      {ohsBreadcrumbsNameAndRef.leafName && (
        <Typography className={classes.link}>
          {ohsBreadcrumbsNameAndRef.leafName.name}
        </Typography>
      )}
    </Breadcrumbs>
  );
}
