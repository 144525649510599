import { OhsFormLayoutType } from '../OhsFormModels';

export const returnVariant = (layout: OhsFormLayoutType) => {
  switch (layout) {
    case OhsFormLayoutType.AngularPageForm:
      return 'outlined';
    case OhsFormLayoutType.AngularModalForm:
      return 'outlined';
    case OhsFormLayoutType.AngularModalUnderlinedForm:
      return 'standard';
    default:
      return 'outlined';
  }
};

export const returnFieldStyle = (
  layout: OhsFormLayoutType,
  defaultStyle?: any
) => {
  const defStyle = defaultStyle ? defaultStyle : undefined;
  switch (layout) {
    case OhsFormLayoutType.AngularPageForm:
      return { marginTop: '0px', marginBottom: '0px' };
    case OhsFormLayoutType.AngularModalForm:
      return defStyle;
    case OhsFormLayoutType.AngularModalUnderlinedForm:
      return { marginTop: '0px', marginBottom: '0px' };
    default:
      return defStyle;
  }
};
