import { ModuleHomeUrl } from '../../dashboard/leftSideDrawer/OhsLeftSideDrawerModuleList';
import { goToHomepage, getHomeUrl } from '../../login/OhsLoginServices';
import { OhsUser } from '../OhsUser';

export const checkCurrentUrlAfterMorph = () => {
  const user = new OhsUser();
  const pathname = window.location.pathname;
  if (user && user._id && pathname) {
    if (user.tierNum < 3) {
      goToHomepage();
      return;
    }
    const modulePath = pathname.split('/')[1];
    const subPath = pathname.split('/')[2];
    const operationPath = pathname.substring(modulePath.length + 1);

    let newUrlAfterMorph = pathname;
    switch (modulePath) {
      case 'admin2': {
        if (subPath === 'user-settings') {
          window.location.reload();
        } else {
          newUrlAfterMorph = getHomeUrl();
        }
        break;
      }
      case 'action': {
        if (
          user.configs.action?.PERMISSIONS.view === true ||
          user.configs.action?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.action?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).action;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'chemical': {
        if (
          user.configs.chemical?.PERMISSIONS.view === true ||
          user.configs.chemical?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.chemical?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).chemical;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'correspondence': {
        if (
          user.configs.correspondence?.PERMISSIONS.view_prose === true ||
          user.configs.correspondence?.PERMISSIONS.create_prose === true
        ) {
          if (
            user.configs.correspondence?.PERMISSIONS.create_prose !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any)
              .correspondence;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'contractor': {
        if (
          user.configs.contractor?.PERMISSIONS.view === true ||
          user.configs.contractor?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.contractor?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any)
              .contractor;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'documents': {
        if (
          user.configs.vdoc?.PERMISSIONS.view === true ||
          user.configs.vdoc?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.vdoc?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).vdoc;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'people': {
        if (
          user.configs.hr?.PERMISSIONS.view === true ||
          user.configs.hr?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.hr?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).hr;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'ai': {
        if (
          user.configs.incident?.PERMISSIONS.view === true ||
          user.configs.incident?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.incident?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).incident;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'inspection': {
        if (
          user.configs.inspection?.PERMISSIONS.view === true ||
          user.configs.inspection?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.inspection?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any)
              .inspection;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'reviewplan': {
        if (
          user.configs.reviewplan?.PERMISSIONS.view === true ||
          user.configs.reviewplan?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.reviewplan?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any)
              .reviewplan;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'safety-plan': {
        if (
          user.configs.safetyplan?.PERMISSIONS.view === true ||
          user.configs.safetyplan?.PERMISSIONS.create === true
        ) {
          if (
            user.configs.safetyplan?.PERMISSIONS.create !== true ||
            subPath !== 'add'
          ) {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any)
              .safetyplan;
          }
        } else {
          newUrlAfterMorph = getHomeUrl();
        }

        break;
      }
      case 'visitor': {
        if (user.configs.visitor?.PERMISSIONS.site_view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).visitor;
        } else {
          newUrlAfterMorph = getHomeUrl();
        }
        break;
      }
      case 'noticeboard': {
        if (user.configs.noticeboard?.PERMISSIONS.view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).noticeboard;
        } else {
          newUrlAfterMorph = getHomeUrl();
        }
        break;
      }
      case 'crisk': {
        if (user.configs.corporateRisk?.PERMISSIONS.view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any)
            .corporateRisk;
        } else {
          newUrlAfterMorph = getHomeUrl();
        }
        break;
      }
      case 'assets': {
        if (user.configs.asset?.PERMISSIONS.view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).asset;
        } else {
          newUrlAfterMorph = getHomeUrl();
        }
        break;
      }
      default: {
        window.location.reload();
        break;
      }
    }
    window.location.href = newUrlAfterMorph;
  }
};
