import moment from 'moment';

export const dateDiffOnly = (date: string) => {
  var now = moment(new Date()).startOf('day');
  var due = moment(new Date(date)).startOf('day');
  return moment(due).diff(now, 'days');
};

export const dateDiffBetween = (date1: string, date2: string) => {
  var now = moment(new Date(date1)).startOf('day');
  var due = moment(new Date(date2)).startOf('day');
  return moment(due).diff(now, 'days');
};

export const getTodayDateString = () => {
  const d = new Date();
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const isValidDate = (dateString: string) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};

export const toDate = (input: string) => {
  if (input === undefined) return moment();
  var date = new Date(input);
  if (date.toString() === 'Invalid Date') {
    return input;
  }
  return moment(date).format('YYYY-MM-DD');
};

export const toLocalTime = (utcString: string) => {
  if (utcString) {
    const time = moment(new Date(utcString));
    return time.format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
};
