import { number, string } from 'joi';
import React from 'react';
import { Pagination, PaginationProps } from 'semantic-ui-react';

interface Props {
  gotoPage: any;

  pageIndex: number;
  totalPages: number;
}

export default function OhsRegisterTablePagination({
  gotoPage,

  pageIndex,
  totalPages,
}: Props) {
  const handlePaginationChange = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: PaginationProps
  ) => {
    if (data.activePage && typeof data.activePage === 'number') {
      gotoPage(data.activePage - 1);
    } else {
      gotoPage(0);
    }
  };
  return (
    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
      <Pagination
        onPageChange={handlePaginationChange}
        defaultActivePage={pageIndex + 1}
        totalPages={totalPages}
      />
    </div>
  );
}
