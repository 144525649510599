import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/';
import { makeStyles } from 'tss-react/mui';

import { TextField } from '@mui/material';
interface Props {
  date: string;
  maxDate?: string;
  minDate?: string;
  disabled?: boolean;
  onChange: (date: string) => void;
  renderInput?: (params: any) => React.ReactElement;
  isClearable?: boolean;
}

export const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
  },
  closeContainer: {
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  close: {
    marginRight: '12px',
    fontSize: '1.25em',
    opacity: 0.5,
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
}));

function OhsDatePicker(props: Props) {
  const { classes } = useStyles();
  const [dateValue, setDateValue] = React.useState<string | null>(null);
  const isClearable =
    props.isClearable !== undefined ? props.isClearable : true;

  const handleClear = () => {
    setDateValue(null);
  };

  React.useEffect(() => {
    if (props.date) {
      setDateValue(props.date);
    } else {
      setDateValue(null);
    }
  }, [props.date]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        inputFormat="yyyy-MM-DD"
        maxDate={props.maxDate}
        minDate={props.minDate}
        value={dateValue}
        InputAdornmentProps={{
          position: 'start',
        }}
        PopperProps={{ placement: 'bottom-start' }}
        disabled={props.disabled ?? false}
        onChange={(value: any, keyboardInputValue?: string) => {
          if (value) {
            props.onChange(value.format('yyyy-MM-DD'));
          }
        }}
        mask="____-__-__"
        renderInput={(params) => {
          if (props.renderInput) {
            return props.renderInput(params);
          } else {
            return (
              <div className={classes.container}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  size="small"
                  {...params}
                />
                {isClearable && dateValue && (
                  <div className={classes.closeContainer}>
                    <CloseIcon
                      className={classes.close}
                      onClick={handleClear}
                    />
                  </div>
                )}
              </div>
            );
          }
        }}
      />
    </LocalizationProvider>
  );
}

export default OhsDatePicker;
