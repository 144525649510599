import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { toggleLeftSideDrawer } from '../OhsDashboardSlice';
import { OhsUser } from '../../user/OhsUser';
import {
  ListItemLink,
  OhsLeftSideDrawerModuleList,
} from './OhsLeftSideDrawerModuleList';
import HomeIcon from '@mui/icons-material/Home';
import { getHomeUrl } from '../../login/OhsLoginServices';
import { openResourceCentre } from '../topbar/OhsTopBar';
import { OhsLogoImg } from '../OhsLogoImg';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    width: '64px',
  },
}));

const OhsLeftSideDrawer = (props: any) => {
  const { classes } = useStyles();

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    props.toggleLeftSideDrawer(open);
  };

  const user = new OhsUser();
  const homeUrl = getHomeUrl();
  return (
    <Drawer anchor={'left'} open={props.isOpen} onClose={toggleDrawer(false)}>
      <div
        className={clsx(classes.list)}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <Box m={1}>
            <OhsLogoImg className={classes.logo} />
          </Box>
          <Divider />
          <Box m={2}>
            <p>
              <b>{user.name}</b>
            </p>
            <p style={{ color: 'blue' }}>
              {user.tierNum === 2 &&
              user.isMorph === true &&
              user.configs.admin2?.LOWER_TIER_ACCESS &&
              user.configs.admin2?.LOWER_TIER_ACCESS[0]
                ? user.configs.admin2?.LOWER_TIER_ACCESS[0].name
                : user.tier.name}
            </p>
          </Box>
          <Divider />
          <ListItemLink button key={'homeLink'} href={homeUrl}>
            <ListItemText primary={'Home'} />{' '}
            <HomeIcon style={{ float: 'right' }} fontSize="small" />
          </ListItemLink>

          <Divider />

          <OhsLeftSideDrawerModuleList />
          {user.isMorph !== true &&
            user.email !== 'champ@safetychampion.com.au' &&
            user.email !== 'safety@safetychampion.com.au' && (
              <ListItemLink
                button
                key="user-settings"
                href="/admin2/user-settings"
              >
                <ListItemText primary="User Settings" />{' '}
              </ListItemLink>
            )}

          <Divider />
          {(user.tierNum > 2 || (user.tierNum <= 2 && user.isMorph !== true)) &&
            user.configs.admin2 != null && (
              <div>
                <Box m={2}>
                  <p>
                    <b>Administration</b>
                  </p>
                </Box>
                <ListItemLink
                  button
                  key="Administration"
                  href={getAdminHomeUrl()}
                  dense={true}
                >
                  <ListItemText primary={user.configs.admin2.TITLE} />
                </ListItemLink>

                {user.tierNum > 2 &&
                  user.configs.bi?.PERMISSIONS.view === true && (
                    <ListItemLink
                      button
                      href="/reporting/dashboardReports"
                      dense={true}
                    >
                      <ListItemText primary={user.configs.bi?.TITLE} />
                    </ListItemLink>
                  )}
                {user.tierNum <= 2 &&
                  user.configs.bi &&
                  user.configs.bi.TITLE &&
                  user.isMorph !== true && (
                    <ListItemLink
                      button
                      href="/reporting/orgAdminReports"
                      dense={true}
                    >
                      <ListItemText primary={user.configs.bi.TITLE} />
                    </ListItemLink>
                  )}
                {user.tierNum > 2 &&
                  user.configs.export?.PERMISSIONS.view === true && (
                    <ListItemLink button href="/admin2/export" dense={true}>
                      <ListItemText primary={user.configs.export?.TITLE} />
                    </ListItemLink>
                  )}

                {user.tierNum > 2 && (
                  <ListItemLink
                    button
                    key="Reporting"
                    href="/reporting/tier?tab=task"
                    dense={true}
                  >
                    <ListItemText primary="Reporting" />
                  </ListItemLink>
                )}
                <Divider />
              </div>
            )}

          {user.isMorph !== true && (
            <div>
              <Box m={2}>
                <Button.Group>
                  <Button color="red" onClick={openResourceCentre}>
                    Support
                  </Button>
                  <Button.Or />
                  <Button color="blue" href="/feedback">
                    Feedback
                  </Button>
                </Button.Group>
              </Box>
              <Divider />
            </div>
          )}

          <Box m={2}>
            <p>Version: {(window as any).SAFETY_CHAMPION_VERSION}</p>
          </Box>
        </List>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => {
  return { isOpen: state.dashboardReducer.leftSideDrawerToggleOpen };
};

export default connect(mapStateToProps, { toggleLeftSideDrawer })(
  OhsLeftSideDrawer
);

export const getAdminHomeUrl = () => {
  const user = new OhsUser();
  if (user.tierNum > 2) {
    return '/admin2/main';
  } else {
    return '/admin2/home';
  }
};
