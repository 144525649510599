import React from 'react';

import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from 'tss-react/mui';
import { getOhsUser } from '../../user/OhsUser';

interface Props {
  title: string;
  onCloseFn?: () => void;
  onBackFn?: () => void;
  headerColor?: string;
  showClose?: boolean;
  align?: 'left' | 'center' | 'right';
}

const OhsModalHeader2 = ({
  title,
  onCloseFn,
  onBackFn,
  headerColor,
  align,
}: Props) => {
  const user = getOhsUser();

  const headerBG = () => {
    if (headerColor) return headerColor;
    if (user?.configs.theme.HEADER_BAR_BG_COLOR)
      return user?.configs.theme.HEADER_BAR_BG_COLOR;
    return '#F6F8FA';
  };

  const useStyles = makeStyles()({
    wrapper: {
      backgroundColor: headerBG(),
      padding: '10px 0 10px 0',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontWeight: '600',
      textAlign: align || 'left',
      padding: '0 15px',
    },
    headerRightContainer: {
      height: '100%',
      width: '50px',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      padding: '0 12px',
    },
    headerLeftContainer: {
      height: '100%',
      width: '50px',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '9px 12px',
    },
    icon: {
      opacity: '0.8 !important',
      fontSize: '1.25em !important',
      cursor: 'pointer',
      '&:hover': {
        opacity: '1 !important',
      },
    },
  });
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      {onBackFn && (
        <div className={classes.headerLeftContainer}>
          <ArrowBackIcon
            onClick={onBackFn}
            className={classes.icon}
            name="close"
            sx={{ margin: '0' }}
          />
        </div>
      )}

      <div className={classes.title}>
        <span>{title ? title : ' '}</span>
      </div>

      {onCloseFn && (
        <div className={classes.headerRightContainer}>
          <CloseIcon
            onClick={onCloseFn}
            className={classes.icon}
            name="close"
            sx={{ margin: '0' }}
          />
        </div>
      )}
    </div>
  );
};

OhsModalHeader2.propTypes = {
  title: PropTypes.string.isRequired,
  onCloseFn: PropTypes.func,
};

export default OhsModalHeader2;
