import React from 'react';
import { Routes, Route } from 'react-router-dom';

const OhsAssetsMainPage = React.lazy(
  () => import('./register/OhsAssetsMainPage')
);

const OhsAssetsViewTabs = React.lazy(() => import('./view/OhsAssetsViewTabs'));

const OhsAssetAddEditForm = React.lazy(
  () => import('./add-edit/OhsAssetAddEditForm')
);

const OhsAssetSignOffHome = React.lazy(
  () => import('./sign-off/OhsAssetSignOffHome')
);

const OhsAssetsEvidenceHome = React.lazy(
  () => import('./view/evidence/OhsAssetsEvidenceHome')
);

const OhsAngularIframe = React.lazy(
  () => import('../global/angular-iframe/OhsAngularIframe')
);
const OhsRequireAuth = React.lazy(() => import('../global/OhsRequireAuth'));

const OhsAssetsRoutes = () => {
  return (
    <React.Suspense fallback={null}>
      <Routes>
        <Route
          path={`home`}
          element={
            <OhsRequireAuth>
              <OhsAssetsMainPage />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`add`}
          element={
            <OhsRequireAuth>
              <OhsAssetAddEditForm />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:assetId/view`}
          element={
            <OhsRequireAuth>
              <OhsAssetsViewTabs />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:assetId/edit`}
          element={
            <OhsRequireAuth>
              <OhsAssetAddEditForm />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:assetId/task/:taskId/signoff`}
          element={
            <OhsRequireAuth>
              <OhsAssetSignOffHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:assetId/task/:taskId/completed`}
          element={
            <OhsRequireAuth>
              <OhsAssetsEvidenceHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </React.Suspense>
  );
};

export default OhsAssetsRoutes;
