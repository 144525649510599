import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  item: {
    padding: theme.spacing(2, 0),
    textAlign: 'left',
  },
  label: {
    fontWeight: 'bold',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function OhsModalFormItem({
  md,
  sm,
  xs,
  style,
  children,
  className,
}: {
  md?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xs?: false | 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  style?: any;
  children?: React.ReactNode;
  className?: string;
}) {
  const { classes } = useStyles();

  const gridClassName = `${classes.item} ${className ? className : ''}`;

  return (
    <Grid
      className={gridClassName}
      style={{ ...style }}
      item
      xs={xs ?? 12}
      sm={sm}
    >
      {children}
    </Grid>
  );
}
