import _ from 'lodash';
import ohsApiClient from '../common/OhsApi';
import { getLocalStorage, setLocalStorage } from '../common/OhsDataParse';
import { getOhsUser } from '../user/OhsUser';
import { EmbedConfig, ReportExportStatus } from './models/EmbedConfig';
export const getEmbeddedReports = (
  reportType: string,
  tokenType: string
): Promise<EmbedConfig> => {
  let url = `/bi/token`;
  if (tokenType) {
    url = `/bix/token`;
  }

  return ohsApiClient
    .post(url, { reportType: reportType, tokenType: tokenType })
    .then(function (response) {
      if (response && response.data && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const startEmbeddedReportsExport = (
  reportType: string,
  tokenType: string,
  format: string
): Promise<ReportExportStatus> => {
  let url = `/bi/startExport`;
  if (tokenType) {
    url = `/bix/startExport`;
  }

  return ohsApiClient
    .post(url, { reportType: reportType, tokenType: tokenType, format: format })
    .then(function (response) {
      if (response && response.data && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const getEmbeddedReportsExportStatus = (
  groupId: string,
  reportId: string,
  exportId: string
): Promise<ReportExportStatus> => {
  let url = `/bi/exportStatus`;

  return ohsApiClient
    .post(url, { groupId: groupId, reportId: reportId, exportId: exportId })
    .then(function (response) {      
      if (response && response.data && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const getEmbeddedReportsExportile = (
  groupId: string,
  reportId: string,
  exportId: string
): Promise<{fileBase64Data:string}> => {
  let url = `/bi/getExportFile`;

  return ohsApiClient
    .post(url, { groupId: groupId, reportId: reportId, exportId: exportId })
    .then(function (response) {      
      if (response && response.data && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export interface PbAccessToken {
  tokenType: string;
  expiresIn: number;
  expiresOn: string;
  resource: string;
  accessToken: string;
  isMRRT: boolean;
  _clientId: string;
  _authority: string;
}
export interface PbDashboard {
  id: string;
  displayName: string;
  isReadOnly: string;
  webUrl: string;
  embedUrl: string;
  users: any[];
}
export const getPowerBiAccessToken = (): Promise<PbAccessToken> => {
  return ohsApiClient
    .get('/reporting/getAccessToken')
    .then(function (response) {
      if (response && response.data && response.data.success === true) {
        setLocalStorage(
          PowerBiAccessTokenLocalstorageName,
          response.data.result.item
        );
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};
const Group_ID = '4548c4e4-5594-49bc-90b0-faf98cdc4b86';
const PowerBiAccessTokenLocalstorageName = 'PowerBiAccessToken';
export const getLocalPowerBiAccessToken = (): PbAccessToken => {
  return getLocalStorage(PowerBiAccessTokenLocalstorageName);
};

export const getDashboardList = async (): Promise<PbDashboard[]> => {
  const pbAccessToken = getLocalPowerBiAccessToken();
  return ohsApiClient
    .get(`https://api.powerbi.com/v1.0/myorg/groups/${Group_ID}/dashboards`, {
      headers: {
        Authorization: 'Bearer ' + pbAccessToken.accessToken, //the token is a variable which holds the token
      },
    })
    .then(function (response) {
      if (response && response.data && response.data.value) {
        return response.data.value;
      } else {
        console.error(response);
      }
      return;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const createPersonalDashboard = async (
  name: string
): Promise<PbDashboard> => {
  const pbAccessToken = getLocalPowerBiAccessToken();
  return ohsApiClient
    .post(
      `https://api.powerbi.com/v1.0/myorg/groups/${Group_ID}/dashboards`,
      { name: name },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + pbAccessToken.accessToken, //the token is a variable which holds the token
        },
      }
    )
    .then(function (response) {
      if (response && response.data && response.data.value) {
        return response.data.value;
      } else {
        console.error(response);
      }
      return;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const getPersonalDashboard = async (): Promise<PbDashboard> => {
  const user = getOhsUser();
  await getPowerBiAccessToken();
  const dashboardList = await getDashboardList();
  let personalDashboard = _.find(dashboardList, { displayName: user?._id });
  if (personalDashboard == null) {
    personalDashboard = await createPersonalDashboard(user?._id!);
  }

  return new Promise((resolve) => {
    resolve(personalDashboard!);
  });
};
