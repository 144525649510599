import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Button, Icon, SemanticCOLORS } from 'semantic-ui-react';

import { registerButtonStyles } from '../../register/OhsModuleHeader';
interface Props {
  title?: string;
  color?: SemanticCOLORS;
  options: Array<{ name: string; key: string; description?: string }>;
  handleSelect: (key: string) => void;
}
export function OhsDropdownButton(props: Props) {
  const { classes } = registerButtonStyles();
  const [modalAnchor, setModalAnchor] = React.useState<null | HTMLElement>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setModalAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setModalAnchor(null);
  };

  return (
    <>
      <Button
        icon={!props.title}
        className={classes.button}
        color={props.color}
        onClick={handleClick}
      >
        <Icon className={classes.buttonIcons} name="ellipsis vertical"></Icon>
        {props.title}
      </Button>
      <Menu
        anchorEl={modalAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(modalAnchor)}
        onClose={handleClose}
      >
        {props.options.map((item, index) => (
          <MenuItem
            key={index}
            component="button"
            onClick={() => {
              props.handleSelect(item.key);
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
