import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Typography } from '@mui/material';
import _ from 'lodash';
import OhsModal2 from '../../global/modal/OhsModal2';

interface Props {
  open: boolean;
  setHelpModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  method: string;
  useRecoveryCode: () => void;
}

const useStyles = makeStyles()({
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  description: {
    fontSize: '0.875',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center !important',
    padding: '8px',
    width: '100%',
  },
  actionBtns: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});

function OhsMfaHelpModal(props: Props) {
  const { classes } = useStyles();

  const returnTitle = () => {
    if (props.method === 'sms') return `Didn't receive SMS`;
    else if (props.method === 'email') return `Didn't receive Email`;
    else return '';
  };

  const renderSMS = () => (
    <>
      <Typography variant="body1" color="textSecondary" marginBottom="26px">
        We sent SMS to your phone. If you have not received the code after
        several attempts, please try:
      </Typography>
      <Typography variant="body1" color="textSecondary" marginBottom="8px">
        1. Check if your phone is overdue.
      </Typography>
      <Typography variant="body1" color="textSecondary" marginBottom="8px">
        2. Check if the message is in the SMS bin
      </Typography>
      <Typography variant="body1" color="textSecondary" marginBottom="8px">
        3. Ensure the binding phone number is 456**9123
      </Typography>
      <Typography variant="body1" color="textSecondary">
        4. The message may be delayed for a few minutes, so you can retry it 10
        minutes later.
      </Typography>
    </>
  );

  const renderEmail = () => (
    <>
      <Typography variant="body1" color="textSecondary" marginBottom="26px">
        We sent code to your email. If you have not received the code after
        several attempts, please try:
      </Typography>
      <Typography variant="body1" color="textSecondary" marginBottom="8px">
        1. Check if the message is in the spam
      </Typography>
      <Typography variant="body1" color="textSecondary" marginBottom="8px">
        2. Ensure the binding email is al**@***.com.au
      </Typography>
      <Typography variant="body1" color="textSecondary">
        3. The message may be delayed for a few minutes, so you can retry it 10
        minutes later.
      </Typography>
    </>
  );

  const renderContent = () => {
    if (props.method === 'sms') return renderSMS();
    else if (props.method === 'email') return renderEmail();
    else return '';
  };

  return (
    <>
      <OhsModal2
        open={props.open}
        title={returnTitle()}
        alignHeader="center"
        maxWidth={'xs'}
        onClose={() => props.setHelpModalOpen(false)}
        ContentNode={<div>{renderContent()}</div>}
        actionNode={
          <Box className={classes.footer}>
            <Box className={classes.actions}>
              <Button
                onClick={() => {
                  props.useRecoveryCode();
                  props.setHelpModalOpen(false);
                }}
                size="small"
                variant="text"
                color="primary"
              >
                Try Another Way
              </Button>
              <Button
                onClick={() => props.setHelpModalOpen(false)}
                size="small"
                variant="contained"
                color="primary"
                className={classes.actionBtns}
              >
                I Understand
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
}

export default OhsMfaHelpModal;
