import ohsApiClient from '../../../../common/OhsApi';
import { ModuleType } from '../../../../models/OhsObject';
import * as _ from 'lodash';
import { getApiPrexByModuleType } from '../../../OhsModuleServices';
export const handleModuleRecordArchive = (
  moduleType: ModuleType,
  isArchive: boolean,
  id: string
) => {
  const endpoint = `/${getApiPrexByModuleType(moduleType)}/${id}/${
    isArchive === true ? 'archive' : 'unarchive'
  }`;

  return ohsApiClient
    .post(endpoint, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const handleModuleRecordDelete = (
  moduleType: ModuleType,
  id: string
) => {
  const endpoint = `/${getApiPrexByModuleType(moduleType)}/${id}`;

  return ohsApiClient
    .delete(endpoint)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
