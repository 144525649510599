import { AxiosResponse } from 'axios';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { OhsUser } from '../../user/OhsUser';
import { decode } from 'jsonwebtoken';
export const responseMixpanelInterceptor = (
  response: AxiosResponse<any>
): AxiosResponse<any> => {
  try {
    track(response.config, response.data, response.headers);
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const responseRejectedMixpanelInterceptor = (
  error: any
): Promise<any> => {
  try {
    track(
      error.config,
      { success: false, error: 'responseError' },
      error.headers
    );
  } catch (e) {
    console.error(e);
  }
  return Promise.reject(error);
};

const track = (config: any, response: any, headers: any) => {
  //   const mixpanel = getMixpanelTraker();

  //   var state = $injector.get('$state');
  //   var authr = $injector.get('authr');
  //   var path = config.url.replace(ohsEnv, '').substr(1);
  //   var dir_identity = state.current.name + ':' + path;
  var event;
  var properties;
  var result;
  var pobj;
  const requestUrl = config.url;
  mixpanel.init(
    (window as any).MIXPANEL_ID
      ? (window as any).MIXPANEL_ID
      : '03776c63a39a3c98908d60ac2e2859db'
  );
  switch (requestUrl) {
    case '/users/authenticate':
      result = response;
      mixpanel.register({
        'Application Type': 'Web',
        'Application Version': window.SAFETY_CHAMPION_VERSION,
        'Login Type': 'Username Password',
      });
      var loginForm = JSON.parse(config.data);
      if (loginForm != null && loginForm.email != null) {
        mixpanel.identify(loginForm.email.toLowerCase());
        mixpanel.people.set({ $email: loginForm.email.toLowerCase() });
      } else {
        mixpanel.identify('Login error user');
      }
      pobj = getEventProperties(null, {
        config: config,
        path: requestUrl,
        response: response,
        headers: headers,
      });
      event = pobj.event;
      properties = pobj.properties;
      break;
    case '/users/azure-ad-oidc':
      result = response;
      mixpanel.register({
        'Application Type': 'Web',
        'Application Version': window.SAFETY_CHAMPION_VERSION,
        'Login Type': 'Azure SSO',
      });
      try {
        var user_token = decode(config.headers.Authorization);
        // mixpanel.identify(user_token?.unique_name?.toLowerCase());
      } catch (err) {
        mixpanel.identify('Azure SSA Token error user');
        console.error(err);
      }
      pobj = getEventProperties(null, {
        config: config,
        path: requestUrl,
        response: response,
        headers: headers,
      });
      event = pobj.event;
      properties = pobj.properties;
      break;
    default:
      var user = new OhsUser();
      if (user == null) {
        return;
      }
      pobj = getEventProperties(user, {
        config: config,
        path: requestUrl,
        response: response,
        headers: headers,
      });
      event = pobj.event;
      properties = pobj.properties;
      break;
  }
  if (event) {
    try {
      mixpanel.track(event, properties);
    } catch (error) {
      console.error(error);
    }
  }
};
const getEventProperties = (user: OhsUser | null, wrapper: any) => {
  let payload = null;
  //   let properties: any = {};

  if (wrapper.config && wrapper.config.data) {
    if (_.isObject(wrapper.config.data)) {
      if (_.isFunction(wrapper.config.data.values)) {
        // At this point, `wrapper.config.data` is an
        // instance of FormData; since operator `instanceof`
        // is not available on ES5, workaround is to
        // check if `values` property is a function
        // which will return an iterator

        // Since we only register (1) key on formData
        // across all request calls, `value` will always
        // be the `json` property attribute

        payload = wrapper.config.data.values().next().value;
      } else {
        payload = wrapper.config.data;
      }
    } else {
      payload = wrapper.config.data;
    }
  }

  var event = _.chain(wrapper.path)
    .split('/')
    .filter(function (x) {
      return x.match(/^[0-9a-fA-F]{24}$/) === null;
    })
    .map(function (x) {
      return x.replace(/^\w/, function (c) {
        return c.toUpperCase();
      });
    })
    .join(' ')
    .value();

  var nowTimestamp = new Date().getTime();
  var properties: any = {
    Morph: user?.isMorph,
    'Endpoint URL': wrapper.config.url,
    'Response Time':
      wrapper.config.requestTimestamp != null
        ? nowTimestamp - wrapper.config.requestTimestamp
        : '',
    'Response Payload Size': parseInt(wrapper.headers['Content-Length'], 10),
    'Request Payload Size': byteLength(payload),
    'HTTP Method': wrapper.config.method,
    'Successful Response':
      wrapper.response != null && wrapper.response.success ? 'Yes' : 'No',
    'Error Info':
      wrapper.response != null ? JSON.stringify(wrapper.response.error) : 'NA',
  };

  if (user && user.name) {
    properties['Tier Name'] = user.tier.name;
    properties['Tier Id'] = user.tier._id;
    properties['Tier Level'] = user.tierNum;
  }

  return { event: event, properties: properties };
};
const byteLength = (str: string): number => {
  if (!str) return 0;

  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--; //trail surrogate
  }
  return s;
};
