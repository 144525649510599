import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';
import { convertObj2FormData } from '../common/OhsDataParse';
import { OhsActionsRecord } from './OhsActionModels';
import { toDate } from '../global/utils/OhsDateServices';
import { OhsTaskRecord } from '../task/OhsTaskModels';

export const addEditActions = (
  actionsData: any
): Promise<OhsActionsRecord | null> => {
  actionsData = { ...new OhsActionsRecord(), ...actionsData };
  const formPayload = convertObj2FormData(actionsData);

  let endpoint;
  let addedit = '';
  if (actionsData._id) {
    endpoint = `/actions/${actionsData._id}/edit`;
    addedit = 'edit';
  } else {
    endpoint = '/actions/new';
    addedit = 'add';
  }

  const errMsg = `Unable to ${addedit} the Actions.`;
  return ohsApiClient
    .post(endpoint, formPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.log(response);
        toast.error(errMsg);
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};

export const getActionTask = (actionId: string): Promise<OhsTaskRecord> => {
  const endpoint = `/actions/${actionId}/task`;
  const errMsg = 'Unable to fetch the action';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};

        if (
          resData.signOffDate !== null &&
          resData.signOffDate !== '' &&
          resData.signOffDate !== undefined
        ) {
          resData = {
            ...resData,
            signOffDate: toDate(resData.signOffDate),
            completedDate: toDate(resData.completedDate),
            hazardDescription: resData.hazardCategory,
          };
        }

        return resData;
      } else {
        toast.error(errMsg);
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};

export const fetchAction = (actionId: string) => {
  const endpoint = `/actions/${actionId}/fetch`;
  const errMsg = 'Unable to fetch the action';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};

        return resData;
      } else {
        toast.error(errMsg);
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};
