import React from 'react';
import { Theme } from '@mui/material/styles';
import { createStyles, withStyles } from '@mui/styles';

import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';

import SettingsIcon from '@mui/icons-material/Settings';

import scLogo from '../../../modules/build/img/sc-logo.png';
import xeroLogo from '../../../images/xero-logo.png';
import myobLogo from '../../../images/myob-logo.png';
import ehLogo from '../../../images/eh-logo.png';

import { toast } from 'react-toastify';

import { verifyXSISetup } from '../common/OhsXSIServices';
import _ from 'lodash';
import OhsCopyright from '../../../global/OhsCopyright';
import { OhsUser } from '../../../user/OhsUser';
import { removeNonNumeric } from '../../../global/utils/OhsUtility';
import { OhsWithRouterTypes } from '../../../global/types/OhsGlobalTypes';
import OhsWithRouter from '../../../global/utils/OhsWithRouter';

type PathParamsType = {
  token: string;
};
type PropsType = { classes: any; router: OhsWithRouterTypes<PathParamsType> };
const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(10),

      [theme.breakpoints.up(600 + removeNonNumeric(theme.spacing(2)) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    Card: {
      width: 300,
      margin: 'auto',
    },
    Media: {
      height: '100%',
      width: '100%',
    },
    logo: {
      objectFit: 'contain',
      height: 300,
      width: '100%',
      padding: theme.spacing(2),
    },
    grid: {
      marginBottom: theme.spacing(10),
    },
  });
class OhsHrIntegration extends React.Component<PropsType> {
  state = {
    xsiLogo: undefined,
    xsi: undefined,
    xsiError: false,
    displayText: undefined,
    token: undefined,
  };
  componentDidMount() {
    const token = this.props.router.params.token;

    const logoMap = {
      xero: xeroLogo,
      myob: myobLogo,
      emphero: ehLogo,
    };

    verifyXSISetup(token).then((data) => {
      if (data && data.success === true) {
        const logo = _.get(logoMap, data.result.platform.name, undefined);
        let text;

        if (_.get(data.result, 'platform.name') === 'xero') {
          text = `
            Import your HR data from Xero software to your Safety Champion organisation
            and automatically sync employee records during daily scheduled data pulling cycles.
            `;
        }

        if (logo) {
          this.setState({
            xsiLogo: logo,
            xsi: data.result,
            displayText: text,
            token: token,
          });
        } else {
          toast.error('Unsupported Platform.');
          this.setState({
            xsiError: true,
          });
        }
      } else {
        this.setState({
          xsiError: true,
        });
        toast.error('Unable to verify xsi data.');
      }
    });

    window.addEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e: Event) {}

  render() {
    const { classes } = this.props as any;
    // const tierId = this.props.match.params.tierId;

    const user = new OhsUser();

    const setup = () => (event: any) => {
      if (!this.state.xsi) return;

      const platform = _.get(this.state.xsi, 'platform.name', null);

      if (
        platform === 'xero' ||
        platform === 'myob' ||
        platform === 'emphero'
      ) {
        const url = _.get(this.state.xsi, 'oauth.redirectUrl', null);

        const link = url + '&state=' + this.state.token;

        window.location.href = link;
      } else {
        console.error(`Unsupported platform type '${platform}'.`);
      }
    };

    return (
      <React.Fragment>
        <CssBaseline />

        <main className={classes.layout}>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <img
                  className={classes.logo}
                  src={scLogo}
                  alt="Safety Champion"
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                {this.state.xsiError === true ? (
                  <WarningIcon
                    style={{
                      color: 'secondary',
                      padding: 40,
                      width: 300,
                      height: 300,
                    }}
                  />
                ) : this.state.xsi ? (
                  <img
                    className={classes.logo}
                    src={this.state.xsiLogo}
                    alt="Safety Champion"
                  />
                ) : (
                  <CircularProgress size={300} style={{ padding: 40 }} />
                )}
              </Paper>
            </Grid>
            {this.state.xsi ? (
              <Grid item xs={12}>
                <Typography align="center">
                  {'Safety Champion | '}
                  {_.get(
                    {
                      xero: 'Xero',
                      myob: 'MYOB',
                      emphero: 'Employment Hero',
                    },
                    _.get(this.state, 'xsi.platform.name') || ''
                  )}
                  {' Integration'}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ marginTop: 10 }}
                >
                  {this.state.displayText || null}
                </Typography>
                <Button
                  onClick={setup()}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  className={classes.button}
                  startIcon={<SettingsIcon />}
                >
                  Continue Setup
                </Button>
              </Grid>
            ) : null}
            {this.state.xsiError === true ? (
              <Grid item xs={12}>
                <Typography align="center">
                  {'Email activation unsuccessful!'}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ marginTop: 10 }}
                >
                  {
                    'You are currently logged in as an unauthorised user. Please log in using the Safety Champion account you were invited on.'
                  }
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <OhsCopyright />
        </main>
      </React.Fragment>
    );
  }
}

export default OhsWithRouter(withStyles(styles)(OhsHrIntegration));
