import React from 'react';
import { Button } from 'semantic-ui-react';
import { OhsAllocationTierRecord } from '../../safety-plan/OhsSafetyPlanModels';
import { getOhsUser } from '../../user/OhsUser';
import {
  OhsUrlQueryParamOperationType,
  OhsUrlQueryParamType,
} from '../constants/OhsUrlQueryParams';
import OhsDatePicker from '../picker/OhsDatePicker';
import OhsRegister from '../register/OhsRegister';
import { OhsAllocStatusFilter } from '../register/table/filters/OhsFilterTypes';
import OhsRegisterTableStatusFilter, {
  AllocationStatusFilter,
} from '../register/table/filters/OhsRegisterTableStatusFilter';
import OhsRegisterTableStatusIcons from '../register/table/OhsRegisterTableStatusIcons';
import {
  OhsActionColorOrange,
  OhsActionColorRed,
} from '../theme/OhsThemeColors';
import OhsModal from './OhsModal';
import { OhsModalContentHeader } from './OhsModalContentHeader';

const allocateStatusLists = [
  {
    label: 'Allocated',
    value: OhsAllocStatusFilter.Allocated,
    iconColor: OhsActionColorOrange,
    permission: [4, 3],
  },
  {
    label: 'Not Allocated',
    value: OhsAllocStatusFilter.NotAllocated,
    iconColor: OhsActionColorRed,
    permission: [3],
  },
];

interface Props {
  title: string;
  id: string;
  open: boolean;
  onClose?: () => void;
  allocationList: OhsAllocationTierRecord[];
  hasFilters?: 'statusfilter' | 'corporaterisk' | 'allocationfilter';
  loadAlocationList: (id: string) => Promise<any>;
  setAllocationList: React.Dispatch<
    React.SetStateAction<OhsAllocationTierRecord[]>
  >;
  allocationFunc: (
    id: string,
    allocationItem: OhsAllocationTierRecord
  ) => Promise<any>;
  deAllocationFunc: (
    id: string,
    allocationItem: OhsAllocationTierRecord
  ) => Promise<any>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hasDueDate?: boolean;
}

function OhsAllocationModal(props: Props) {
  let urlParams = new URLSearchParams(window.location.search);

  let operateKey = urlParams.get(OhsUrlQueryParamType.OperationType);
  const allocationColumns = React.useMemo(() => {
    const cols = [
      {
        id: 'nameId',
        Header: 'Name',
        accessor: 'name',
      },
    ] as any[];

    cols.push({
      Header: 'Allocation',
      accessor: 'allocated',
      Cell: ({ row }: any) => {
        return (
          <OhsRegisterTableStatusIcons
            row={row.original}
            statusLists={allocateStatusLists}
          />
        );
      },
      filterFields: (data: any) => {
        return (
          <OhsRegisterTableStatusFilter
            data={data.column}
            statusList={allocateStatusLists}
          />
        );
      }, // Checkbox Fields
      filter: AllocationStatusFilter, // Filter Logic / Conditionals
      headerAlign: 'center',
    });

    if (props.hasDueDate !== false) {
      cols.push({
        Header: 'Due Date',
        Cell: function (cellProps: any, instance: any) {
          return (
            cellProps.row.original &&
            cellProps.row.original.allocated !== true && (
              <OhsDatePicker
                onChange={(date) => {
                  cellProps.data[cellProps.row.index]['dateDue'] = date;

                  props.setAllocationList([...cellProps.data]);
                }}
                date={cellProps.row.original.dateDue}
              />
            )
          );
        },
      });
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const user = getOhsUser();

  const allocateItemFunc = async (allocationItem: OhsAllocationTierRecord) => {
    return props
      .allocationFunc(props.id, allocationItem)
      .then(async function (item) {
        await props.loadAlocationList(props.id);
        return item;
      });
  };

  const deAllocateItemFunc = async (
    allocationItem: OhsAllocationTierRecord
  ) => {
    return props
      .deAllocationFunc(props.id, allocationItem)
      .then(async function (item) {
        await props.loadAlocationList(props.id);
        return item;
      });
  };

  return (
    <>
      <OhsModal
        open={props.open}
        title={props.title}
        setModalOpenFunc={props.setModalOpen}
        onClose={props.onClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalContentHeader title={''} />
            <OhsRegister
              columns={allocationColumns}
              data={props.allocationList}
              sortBy={[{ id: 'nameId', desc: false }]}
              hasFilters={props.hasFilters}
              rowOperations={{
                allocationFunc: allocateItemFunc,
                deAllocationFunc: deAllocateItemFunc,
              }}
            />
          </>
        }
        actionNode={
          operateKey === OhsUrlQueryParamOperationType.AllocationAndAssign && (
            <>
              <Button
                onClick={() => {
                  if (
                    operateKey ===
                    OhsUrlQueryParamOperationType.AllocationAndAssign
                  ) {
                    urlParams.set(
                      OhsUrlQueryParamType.OperationType,
                      OhsUrlQueryParamOperationType.Assign
                    );
                    window.location.search = urlParams.toString();
                  } else if (
                    operateKey === OhsUrlQueryParamOperationType.Allocation
                  ) {
                    urlParams.delete(OhsUrlQueryParamType.OperationType);
                    window.location.search = urlParams.toString();
                  }
                }}
                color={'green'}
              >
                Assign
              </Button>
            </>
          )
        }
      ></OhsModal>
    </>
  );
}

export default OhsAllocationModal;
