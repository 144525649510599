import React from 'react';

import _ from 'lodash';
import { Box, Button, ButtonGroup, Grid, Typography } from '@mui/material';
import OhsRegister from '../../global/register/OhsRegister';
import { OhsXsiRecord } from './OhsXsiModels';
import OhsGreenButton from '../../global/buttons/OhsGreenButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import OhsXsiSetupFormDialog from './OhsXsiSetupFormDialog';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import OhsXsiAddEditFormDialog from './OhsXsiAddEditFormDialog';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import OhsXsiDisableAlertDialog from './OhsXsiDisableAlertDialog';
import { toast } from 'react-toastify';
import { disableEnableXsiIntegration } from './OhsXsiServices';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     appBar: {
//       position: 'relative',
//     },
//     layout: {
//       width: 'auto',
//       marginLeft: theme.spacing(2),
//       marginRight: theme.spacing(2),
//       [theme.breakpoints.up(600 + removeNonNumeric(theme.spacing(2)) * 2)]: {
//         width: 600,
//         marginLeft: 'auto',
//         marginRight: 'auto',
//       },
//     },
//     paper: {
//       marginTop: theme.spacing(3),
//       marginBottom: theme.spacing(3),
//       padding: theme.spacing(2),
//       [theme.breakpoints.up(600 + removeNonNumeric(theme.spacing(3)) * 2)]: {
//         marginTop: theme.spacing(6),
//         marginBottom: theme.spacing(6),
//         padding: theme.spacing(3),
//       },
//     },
//     appBarSpacer: theme.mixins.toolbar,
//     content: {
//       flexGrow: 1,
//       height: '100vh',
//       overflow: 'auto',
//     },
//     container: {
//       paddingTop: theme.spacing(4),
//       paddingBottom: theme.spacing(4),
//     },
//     buttons: {
//       display: 'flex',
//       justifyContent: 'flex-end',
//     },
//     button: {
//       marginTop: theme.spacing(3),
//       marginLeft: theme.spacing(1),
//     },
//   })
// );

const OhsXsiTable = (props: { xsiList: OhsXsiRecord[]; orgId: string }) => {
  // const classes = useStyles();
  const [isSetupDialogOpen, setIsSetupDialogOpen] = React.useState(false);
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = React.useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
  const [xsiRecord, setXsiRecord] = React.useState({} as OhsXsiRecord);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Platform',
        accessor: (originalRow: OhsXsiRecord) => {
          if (originalRow.platform.name === 'xero') {
            return 'Xero';
          } else if (originalRow.platform.name === 'myob') {
            return 'MYOB';
          } else if (originalRow.platform.name === 'emphero') {
            return 'Employment Hero';
          } else {
            return originalRow.platform.name;
          }
        },
      },
      {
        Header: 'Version',
        accessor: 'platformSettings.version',
      },
      {
        Header: 'Target Name',
        accessor: (originalRow: OhsXsiRecord) => {
          if (
            originalRow.platformData &&
            originalRow.platformData.tenant &&
            originalRow.platformData.tenant.tenantName
          ) {
            return originalRow.platformData.tenant.tenantName;
          } else if (
            originalRow.platformData &&
            originalRow.platformData.company &&
            originalRow.platformData.company.Name
          ) {
            return originalRow.platformData.company.Name;
          } else if (
            originalRow.platformData &&
            originalRow.platformData.org &&
            originalRow.platformData.org.name
          ) {
            return originalRow.platformData.org.name;
          } else {
            return '';
          }
        },
      },
      {
        Header: 'Error Notify To',
        accessor: 'errorNotifyTo.email',
      },
      {
        Header: 'Created By',
        accessor: (originalRow: OhsXsiRecord) => {
          return (
            originalRow.createdBy.name +
            ' (' +
            originalRow.createdBy.email +
            ')'
          );
        },
      },
      { Header: 'Date Created', accessor: 'dateCreated' },
      {
        Header: 'Setup Complete',
        accessor: (originalRow: OhsXsiRecord) => {
          if (originalRow.control.setupComplete === true) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
      },
      {
        Header: 'Enabled',
        accessor: (originalRow: OhsXsiRecord) => {
          if (originalRow.control.enabled === true) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
      },
      {
        id: 'buttons',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox.  Pagination is a problem since this will select all
        // rows even though not all rows are on the current page.  The solution should
        // be server side pagination.  For one, the clients should not download all
        // rows in most cases.  The client should only download data for the current page.
        // In that case, getToggleAllRowsSelectedProps works fine.
        Header: '',
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }: { row: any }) => {
          return (
            <div style={{ textAlign: 'right' }}>
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => editXsiRecord(row.original)}
                  style={{ fontWeight: 'bold', textTransform: 'none' }}
                >
                  Edit
                </Button>
                {row.original.control.setupComplete !== true && (
                  <OhsGreenButton
                    variant="contained"
                    color="secondary"
                    startIcon={<MailOutlineIcon />}
                    onClick={() => setupXsiRecord(row.original)}
                  >
                    Setup
                  </OhsGreenButton>
                )}

                {(row.original as OhsXsiRecord).control.setupComplete ===
                  true &&
                  (row.original as OhsXsiRecord).control.enabled !== false && (
                    <Button
                      style={{ fontWeight: 'bold', textTransform: 'none' }}
                      variant="contained"
                      startIcon={<LockIcon />}
                      onClick={() => disableXsiRecord(row.original)}
                    >
                      Disable
                    </Button>
                  )}
                {(row.original as OhsXsiRecord).control.setupComplete ===
                  true &&
                  (row.original as OhsXsiRecord).control.enabled === false && (
                    <OhsGreenButton
                      variant="contained"
                      color="secondary"
                      startIcon={<LockOpenIcon />}
                      onClick={() => enableXsiRecord(row.original)}
                    >
                      Enable
                    </OhsGreenButton>
                  )}
              </ButtonGroup>
            </div>
          );
        },
      },
    ],
    []
  );
  const createXsiRecord = () => {
    setIsAddEditDialogOpen(true);
    setXsiRecord({
      tier: { _id: props.orgId },
      errorNotifyTo: { email: '' },
      platform: { name: '' },
    } as OhsXsiRecord);
  };

  const editXsiRecord = (ohsXsiRecord: OhsXsiRecord) => {
    setIsAddEditDialogOpen(true);
    setXsiRecord(ohsXsiRecord);
  };

  const setupXsiRecord = (ohsXsiRecord: OhsXsiRecord) => {
    setIsSetupDialogOpen(true);
    setXsiRecord(ohsXsiRecord);
  };
  const disableXsiRecord = (ohsXsiRecord: OhsXsiRecord) => {
    setIsAlertDialogOpen(true);
    setXsiRecord(ohsXsiRecord);
  };
  const enableXsiRecord = (ohsXsiRecord: OhsXsiRecord) => {
    disableEnableXsiIntegration(
      ohsXsiRecord._id,
      ohsXsiRecord.tier._id,
      false
    ).then((result: any) => {
      if (result) {
        toast.success('Successfully enable the integration record.');
        window.location.reload();
      }
    });
  };
  return (
    <div>
      <Box style={{ marginBottom: '1rem' }}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <Typography variant="h4">XSI Register</Typography>
          </Grid>
          <Grid item>
            <OhsGreenButton
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={createXsiRecord}
            >
              Add Integration
            </OhsGreenButton>
          </Grid>
        </Grid>
      </Box>
      {props.xsiList.length > 0 ? (
        <Grid item xs={12}>
          <OhsRegister columns={columns} data={props.xsiList} />
        </Grid>
      ) : (
        <Typography variant={'h6'}>No item(s) found</Typography>
      )}
      <div>
        <OhsXsiSetupFormDialog
          isOpen={isSetupDialogOpen}
          xsiRecord={xsiRecord}
          setOpen={setIsSetupDialogOpen}
        />
        {isAddEditDialogOpen === true && (
          <OhsXsiAddEditFormDialog
            isOpen={isAddEditDialogOpen}
            xsiRecord={xsiRecord}
            setOpen={setIsAddEditDialogOpen}
          />
        )}
        <OhsXsiDisableAlertDialog
          isOpen={isAlertDialogOpen}
          xsiRecord={xsiRecord}
          setOpen={setIsAlertDialogOpen}
        />
      </div>
    </div>
  );
};
export default OhsXsiTable;
