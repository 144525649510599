import React from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';

const BtnWithTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#b34e4d',
    color: 'white',
    boxShadow: theme.shadows[0],
    fontSize: '13px',
    padding: '5px 10px 8px 10px',
  },
  arrow: {
    color: '#b34e4d',
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles()(() => ({
  disabled: {
    '& .button': {
      borderRadius: '0 !important',
    },
  },
}));

interface Props {
  tooltipMsg: string;
  disabled?: boolean;
  children: React.ReactElement<any, any>;
}
export default function ButtonWithTooltips(props: Props) {
  const { classes } = useStylesBootstrap();
  const Element = () => props.children

  return props.disabled
    ? (
      <span className={classes.disabled}>{props.children}</span>
  ) : (
    <BtnWithTooltip arrow title={props.disabled ? props.tooltipMsg : ''}>
      <Element />
    </BtnWithTooltip>
  );
}
