import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OhsRequireAuth from '../../global/OhsRequireAuth';
import OhsDashboardBannerList from './register/OhsDashboardBannerList';
import OhsDashboardBannerSave from './register/OhsDashboardBannerSave';

const OhsDashboardBannerRoutes = () => {
  return (
    <Routes>
      <Route
        path={`home`}
        element={
          <OhsRequireAuth>
            <OhsDashboardBannerList />
          </OhsRequireAuth>
        }
      />
      <Route
        path={`add`}
        element={
          <OhsRequireAuth>
            <OhsDashboardBannerSave />
          </OhsRequireAuth>
        }
      />
      <Route
        path={`:bannerId/edit`}
        element={
          <OhsRequireAuth>
            <OhsDashboardBannerSave />
          </OhsRequireAuth>
        }
      />
    </Routes>
  );
};

export default OhsDashboardBannerRoutes;
