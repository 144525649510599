import React, { ReactNode } from 'react';

import {
  Row,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
  useFilters,
} from 'react-table';
import OhsRegisterTableColOperationButtons from './table/rowButtons/OhsRegisterTableColOperationButtons';
import OhsRegisterTableHeaderSearchBar from './table/OhsRegisterTableHeaderSearchBar';
import {
  OhsAllocatableRecord,
  OhsAllocationTierRecord,
} from '../../safety-plan/OhsSafetyPlanModels';
import { Icon } from 'semantic-ui-react';
import OhsRegisterTablePagination from './table/OhsRegisterTablePagination';
import OhsRegisterTable from './table/OhsRegisterTable';
import OhsRegisterCardList from './cardList/OhsRegisterCardList';
import update from 'immutability-helper';
import { useSelector } from 'react-redux';
import { SmallScreenWidthBreakpoint } from '../constants/OhsLayoutConstants';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef() as any;
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
interface Props {
  columns: Array<any>;
  data: Array<any>;
  sortBy?: { id: string; desc: boolean }[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  setRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  hasFilters?:
    | 'statusfilter'
    | 'corporaterisk'
    | 'allocationfilter'
    | 'criskwpstatusfilter';
  hasExpanding?: boolean;
  rowOperations?: RowOperations;
  enableDragDrop?: boolean;
  noPagination?: boolean;
  withCategoryGroupings?: boolean;
  isCompress?: boolean;
  hideColumns?: string[];
  hideHeader?: boolean;
}

export const searchColumnId = 'searchCol';
export const statuColumnId = 'statusCol';
export const expanderColumnId = 'expander';
export interface RowOperations {
  viewFunc?: (item: any) => void;
  viewButton?: {
    func: (item: any) => void;
    label: string;
    permission: boolean;
  };
  openAllocationModalFunc?: (item: OhsAllocatableRecord) => void;
  openDeleteModalButton?: {
    func: (item: any, index?: number) => void;
    permission: boolean;
  };

  allocationFunc?: (allocationItem: OhsAllocationTierRecord) => Promise<any>;
  deAllocationFunc?: (
    deallocationItem: OhsAllocationTierRecord
  ) => Promise<any>;
  copyFunc?: (item: any) => Promise<any>;

  editButton?: {
    func: (item: any, index?: number) => void;
    showFunc?: (item: any) => void;
    permission: boolean;
  };
  archiveButton?: {
    func?: (item: any, isArchive: boolean) => void;

    permission: boolean;
  };
  assignButton?: {
    func?: (item: any, isAssign: boolean) => void;

    permission: boolean;
  };
  leaveButton?: {
    func?: (row: any) => void;
  };
  exportButton?: {
    func?: (row: Row) => void;
  };
  inspectionButton?: {
    func: (item: any) => void;
  };
}

const OhsRegister = (props: Props) => {
  const [records, setRecords] = React.useState(new Array<any>());
  const [filteredRecords, setFilteredRecords] = React.useState(
    new Array<any>()
  );

  const screenWidth = useSelector(
    (state: any) => state.screenSizeReducer.screenWidth
  ) as number;

  let tableOptions = {
    columns: props.columns,
    data: records,
    initialState: {
      pageSize: 100,
      hiddenColumns: props.hideColumns ? props.hideColumns : [],
    },
  } as any;

  if (props.noPagination === true) {
    tableOptions.initialState.pageSize = 100000;
  }
  if (props.sortBy && props.sortBy.length > 0) {
    tableOptions.initialState.sortBy = props.sortBy;
  }
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    selectedFlatRows,
    pageOptions,
    gotoPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = useTable(
    tableOptions,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,

    (hooks) => {
      if (props.setSelectedRows != null) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: any) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
      if (props.hasExpanding === true) {
        hooks.visibleColumns.push((columns) => [
          {
            // Build our expander column
            id: expanderColumnId, // Make sure it has an ID
            Header: ({
              getToggleAllRowsExpandedProps,
              isAllRowsExpanded,
            }: any) => {
              return (
                <span {...getToggleAllRowsExpandedProps()}>
                  {isAllRowsExpanded ? (
                    <Icon name={'chevron down'} />
                  ) : (
                    <Icon name={'chevron right'} />
                  )}
                </span>
              );
            },
            Cell: ({ row }: any) => {
              return row.canExpand ? (
                <span {...row.getToggleRowExpandedProps({})}>
                  {row.isExpanded ? (
                    <Icon name={'chevron down'} />
                  ) : (
                    <Icon name={'chevron right'} />
                  )}
                </span>
              ) : row.depth !== 0 ? (
                <Icon color={'green'} name={'arrow right'} />
              ) : null;
            },
          },
          ...columns,
        ]);
      }
      if (props.rowOperations)
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            id: searchColumnId,

            Header: (header: any) => (
              <OhsRegisterTableHeaderSearchBar
                header={header}
              ></OhsRegisterTableHeaderSearchBar>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: any) => (
              <OhsRegisterTableColOperationButtons
                row={row}
                rowOperations={props.rowOperations}
              />
            ),
          },
        ]);
    }
  ) as any;

  React.useEffect(() => {
    setRecords(props.data);
  }, [props.data]);

  React.useEffect(() => {
    if (props.setRows && rows) {
      props.setRows(rows);
    }
  }, [props, rows]);

  React.useEffect(() => {
    if (props.setSelectedRows && selectedFlatRows) {
      props.setSelectedRows(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };

  return (
    <>
      {screenWidth >= SmallScreenWidthBreakpoint && (
        <OhsRegisterTable
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
          selectedFlatRows={selectedFlatRows}
          setSelectedRows={props.setSelectedRows}
          withCategoryGroupings={props.withCategoryGroupings}
          moveRowFunc={moveRow}
          noPagination={props.noPagination}
          enableDragDrop={props.enableDragDrop}
          isCompress={props.isCompress}
          hideHeader={props.hideHeader}
        />
      )}
      {screenWidth < SmallScreenWidthBreakpoint && (
        <OhsRegisterCardList
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
          selectedFlatRows={selectedFlatRows}
          setSelectedRows={props.setSelectedRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
      )}

      {props.noPagination !== true && pageOptions && pageOptions.length > 1 && (
        <OhsRegisterTablePagination
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          totalPages={pageOptions.length}
        />
      )}
    </>
  );
};

export default OhsRegister;
