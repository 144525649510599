import React from 'react';
import { Route, Routes } from 'react-router-dom';

const OhsCorporateRiskPage = React.lazy(
  () => import('./register/OhsCorporateRiskPage')
);
const OhsCorporateRiskViewHome = React.lazy(
  () => import('./view/OhsCorporateRiskViewHome')
);
const OhsCorporateRiskAddEditHome = React.lazy(
  () => import('./add-edit/OhsCorporateRiskAddEditHome')
);
const OhsCorporateRiskSignOffHome = React.lazy(
  () => import('./sign-off/OhsCorporateRiskSignOffHome')
);
const OhsCorporateRiskEvidenceHome = React.lazy(
  () => import('./view/evidence/OhsCorporateRiskEvidenceHome')
);
const OhsAngularIframe = React.lazy(
  () => import('../global/angular-iframe/OhsAngularIframe')
);
const OhsRequireAuth = React.lazy(() => import('../global/OhsRequireAuth'));

const OhsCorporateRiskRoutes = () => {
  return (
    <React.Suspense fallback={null}>
      <Routes>
        <Route
          path={`home`}
          element={
            <OhsRequireAuth>
              <OhsCorporateRiskPage />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:corporateRiskId/view`}
          element={
            <OhsRequireAuth>
              <OhsCorporateRiskViewHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`add`}
          element={
            <OhsRequireAuth>
              <OhsCorporateRiskAddEditHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:corporateRiskId/edit`}
          element={
            <OhsRequireAuth>
              <OhsCorporateRiskAddEditHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:corporateRiskId/task/:taskId/signoff`}
          element={
            <OhsRequireAuth>
              <OhsCorporateRiskSignOffHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:corporateRiskId/task/:taskId/completed`}
          element={
            <OhsRequireAuth>
              <OhsCorporateRiskEvidenceHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </React.Suspense>
  );
};

export default OhsCorporateRiskRoutes;
