import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from '../leftSideDrawer/OhsLeftSideDrawerModuleList';
import { OhsBreadCrumbInspection } from './OhsBreadcrumbInspection';
import { OhsBreadCrumbAction } from './OhsBreadcrumbAction';
import { OhsBreadcrumbAsset } from './OhsBreadcrumbAsset';
import { OhsBreadCrumbChemical } from './OhsBreadcrumbChemical';
import { OhsBreadCrumbIncident } from './OhsBreadcrumbIncident';
import { OhsBreadCrumbCorrespondence } from './OhsBreadcrumbCorrespondence';
import { OhsBreadCrumbVisitor } from './OhsBreadcrumbVisitor';
import { OhsBreadCrumbReviewplan } from './OhsBreadcrumbReviewplan';
import { OhsBreadCrumbContractor } from './OhsBreadcrumbContractor';
import { OhsBreadCrumbCorporateRisk } from './OhsBreadcrumbCorporateRisk';
import { OhsBreadCrumbSafetyplan } from './OhsBreadcrumbSafetyplan';
import { OhsBreadCrumbDocuments } from './OhsBreadcrumbDocuments';
import { OhsBreadCrumbNoticeboard } from './OhsBreadcrumbNoticeboard';
import { ModuleType } from '../../models/OhsObject';

export class OhsBreadcrumbsNameAndRef {
  homeRef: { href: string } | null;
  moduleRef: { href: string; name: string } | null;
  parentOperationRef: { href: string; name: string } | null;
  leafName: { name: string } | null;
  constructor() {
    this.homeRef = null;
    this.moduleRef = null;
    this.parentOperationRef = null;
    this.leafName = null;
  }
}

export const getOhsBreadcrumbsNameAndRef = (): OhsBreadcrumbsNameAndRef => {
  const user = new OhsUser();
  const pathname = window.location.pathname;
  let ohsBreadcrumbsNameAndRef = new OhsBreadcrumbsNameAndRef();
  if (user && user._id && pathname) {
    if (user.tierNum > 2) {
      ohsBreadcrumbsNameAndRef.homeRef = { href: '/task/active' };
    } else {
      ohsBreadcrumbsNameAndRef.homeRef = { href: '/admin2/home' };
    }
    const modulePath = pathname.split('/')[1];
    const subPath = pathname.split('/')[2];
    const viewPath = pathname.split('/')[3];
    const operationPath = pathname.substring(modulePath.length + 1);
    switch (modulePath) {
      case 'admin2': {
        if (subPath === 'user-settings') {
          ohsBreadcrumbsNameAndRef.leafName = { name: 'User Settings' };
        } else if (subPath === 'export') {
          ohsBreadcrumbsNameAndRef.leafName = {
            name: user.getModuleName(ModuleType.Export),
          };
        } else {
          ohsBreadcrumbsNameAndRef.leafName = {
            name: user.configs.admin2?.TITLE ?? 'Administration',
          };
        }
        break;
      }
      case 'mobileappadmin': {
        ohsBreadcrumbsNameAndRef.leafName = { name: 'Mobile App Admin' };
        break;
      }
      case 'dashboardbanner': {
        ohsBreadcrumbsNameAndRef.leafName = { name: 'Dashboard Banner' };
        break;
      }
      case 'documents': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbDocuments(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          operationPath,
          user
        );
        break;
      }
      case 'safety-plan': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbSafetyplan(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case 'inspection': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbInspection(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case 'resourcecentre': {
        ohsBreadcrumbsNameAndRef.leafName = { name: 'Resource Centre' };
        break;
      }
      case 'action': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbAction(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          operationPath,
          user
        );
        break;
      }
      case 'assets': {
        ohsBreadcrumbsNameAndRef = OhsBreadcrumbAsset(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case 'chemical': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbChemical(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case 'correspondence': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbCorrespondence(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          user
        );
        break;
      }
      case 'contractor': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbContractor(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          user
        );
        break;
      }
      case 'crisk': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbCorporateRisk(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case 'people': {
        if (subPath === 'home') {
          ohsBreadcrumbsNameAndRef.leafName = {
            name: user.configs.hr?.TITLE ?? '',
          };
        } else if (subPath === 'training' || subPath === 'communication') {
          ohsBreadcrumbsNameAndRef.leafName = {
            name: user.configs.hr?.TITLE ?? '',
          };
        } else if (subPath === 'add') {
          ohsBreadcrumbsNameAndRef.moduleRef = {
            href: (ModuleHomeUrl[user.tier.type] as any).hr,
            name: user.configs.hr?.TITLE ?? '',
          };
          ohsBreadcrumbsNameAndRef.leafName = { name: 'New' };
        } else if (viewPath === 'view') {
          ohsBreadcrumbsNameAndRef.moduleRef = {
            href: (ModuleHomeUrl[user.tier.type] as any).hr,
            name: user.configs.hr?.TITLE ?? '',
          };
          ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
        }
        break;
      }
      case 'ai': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbIncident(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case 'visitor': {
        const varPath = pathname.split('/')[4];
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbVisitor(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          varPath,
          user
        );
        break;
      }
      case 'reviewplan': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbReviewplan(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case 'noticeboard': {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbNoticeboard(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          user
        );
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }
  return ohsBreadcrumbsNameAndRef;
};
