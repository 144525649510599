import React from 'react';
import { useNavigate } from 'react-router-dom';
import OhsModuleBackground from '../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../global/register/OhsModuleHeader';
import OhsModuleListBackground from '../../global/register/OhsModuleListBackground';
import { ModuleType } from '../../models/OhsObject';
import { getOhsUser } from '../../user/OhsUser';
import { OhsExportCentreRegisterList } from './OhsExportCentreRegisterList';
import { OhsExportRecord } from './OhsExportRecord';
import { getExportList } from './OhsExportServices';

export const OhsExportCentreRegisterHome = () => {
  const navigate = useNavigate();
  const [exportList, setExportList] = React.useState<OhsExportRecord[]>(
    new Array<OhsExportRecord>()
  );

  const user = getOhsUser();

  const getExportListFunc = async () => {
    const list = await getExportList();
    setExportList(list);
  };

  React.useEffect(() => {
    getExportListFunc();
  }, []);

  return (
    <>
      <OhsModuleBackground>
        <OhsModuleHeader
          title={user?.getModuleName(ModuleType.Export) + ' Register' ?? ''}
        ></OhsModuleHeader>
        <OhsModuleListBackground>
          <OhsExportCentreRegisterList exportList={exportList} />
        </OhsModuleListBackground>
      </OhsModuleBackground>
    </>
  );
};
