import React from 'react';
import { TableCellProps } from '@mui/material';
import DragIndicatorSharpIcon from '@mui/icons-material/DragIndicatorSharp';
import { DragHandle } from '../../dnd/OhsDragAndDrop';

import OhsRegisterTableBodyCell from './OhsRegisterTableBodyCell';
import OhsRegisterTableRow from './OhsRegisterTableRow';

interface Props {
  row: any;
  index: number | undefined;
}
export const OhsRegisterTableDragRow = (props: Props) => {
  return (
    <OhsRegisterTableRow key={props.index} {...props.row.getRowProps()}>
      <OhsRegisterTableBodyCell key={'moveHandler'}>
        <DragHandle icon={<DragIndicatorSharpIcon />} />
      </OhsRegisterTableBodyCell>
      {props.row.cells.map(
        (
          cell: {
            column: any;
            getCellProps: () => JSX.IntrinsicAttributes & TableCellProps;
            render: (arg0: string) => React.ReactNode;
          },
          index: number
        ) => {
          return (
            <OhsRegisterTableBodyCell
              {...cell.getCellProps()}
              key={(props.index || '').toString() + index.toString()}
              style={{
                width: cell.column.customWidth
                  ? cell.column.customWidth
                  : 'auto',
              }}
            >
              {cell.render('Cell')}
            </OhsRegisterTableBodyCell>
          );
        }
      )}
    </OhsRegisterTableRow>
  );
};
