import { combineReducers } from 'redux';
import { OhsTier1CopyDialogReducer } from '../admin2/copy/OhsTier1CopyDialogSlice';
import { dashboardReducer } from '../dashboard/OhsDashboardSlice';
import { backdropReducer } from '../global/backdrop/OhsBackdropSlice';
import { screenSizeReducer } from '../global/screenSize/OhsScreenSizeSlice';
import { loginReducer } from '../login/OhsLoginSlice';

// rootReducer.ts
export const rootReducer = {
  reducer: {
    dashboardReducer: dashboardReducer,
    screenSizeReducer: screenSizeReducer,
    loginReducer: loginReducer,
    backdropReducer: backdropReducer,
    OhsTier1CopyDialogReducer: OhsTier1CopyDialogReducer,
  },
};
