import ohsApiClient from '../../../common/OhsApi';
import * as _ from 'lodash';

export const verifyXSISetup = (token: string) => {
  let endpoint = '/xsi/linkaccess/setup/verify';
  let payload = { token };

  return ohsApiClient
    .post(endpoint, payload)
    .then(function (response) {
      if (response && response.data && response.data.success === true) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const completeXSISetup = (token: string, code: string) => {
  let endpoint = '/xsi/linkaccess/setup/complete';
  let payload = { token, code };

  return ohsApiClient
    .post(endpoint, payload)
    .then(function (response) {
      if (response && response.data) {
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
