import React from 'react';

import OhsModalForm from '../../../../global/form/OhsModalForm';
import OhsModalFormItem from '../../../../global/form/OhsModalFormItem';

import { FormContainer } from '../../OhsDashboardBannerStyledComponents';

import ButtonForm from './ButtonForm';
import OhsTextField from 'global/form/fields/OhsTextField';
import { OhsFormSettings } from 'global/form/OhsFormModels';

interface Props {
  ohsFormSettings: OhsFormSettings;
}

export default function BannerContentForm(props: Props) {
  return (
    <>
      <h4>Content</h4>
      <FormContainer>
        <OhsModalForm>
          <OhsModalFormItem sm={9}>
            <OhsTextField
              type="text"
              title="Message"
              id="content.message"
              required
              ohsFormSettings={props.ohsFormSettings}
            />
          </OhsModalFormItem>
          <OhsModalFormItem sm={3}>
            <OhsTextField
              type="color"
              title="Color (Message)"
              id="content.messageColor"
              required
              ohsFormSettings={props.ohsFormSettings}
            />
          </OhsModalFormItem>
          <OhsModalFormItem sm={6}>
            <OhsTextField
              type="color"
              title="Banner Color 1"
              id="content.bannerColors[0]"
              required
              ohsFormSettings={props.ohsFormSettings}
            />
          </OhsModalFormItem>
          <OhsModalFormItem sm={6}>
            <OhsTextField
              type="color"
              title="Banner Color 2"
              id="content.bannerColors[1]"
              required
              ohsFormSettings={props.ohsFormSettings}
            />
          </OhsModalFormItem>
        </OhsModalForm>

        <ButtonForm ohsFormSettings={props.ohsFormSettings} />
      </FormContainer>
    </>
  );
}
