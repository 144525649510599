import React from 'react';

import { Route, Routes } from 'react-router-dom';

import OhsAngularIframe from '../global/angular-iframe/OhsAngularIframe';
import OhsRequireAuth from '../global/OhsRequireAuth';
import OhsNoticeBoardPage from './board/OhsNoticeBoardPage';
import OhsNoticeBoardView from './details/OhsNoticeBoardView';
import { OhsNoticeBoardFormAddEditPage } from './form/add-edit/OhsNoticeBoardFormAddEditPage';
import OhsNoticeboardViewList from './view-list/OhsNoticeboardViewList';

function OhsNoticeBoardRoutes() {
  return (
    <Routes>
      <Route
        path="admin"
        element={
          <OhsRequireAuth>
            <OhsNoticeBoardPage />
          </OhsRequireAuth>
        }
      />
      <Route
        path="home"
        element={
          <OhsRequireAuth>
            <OhsNoticeboardViewList />
          </OhsRequireAuth>
        }
      />
      <Route
        path=":noticeBoardId/view"
        element={
          <OhsRequireAuth>
            <OhsNoticeBoardView />
          </OhsRequireAuth>
        }
      />
      <Route
        path="form/add"
        element={
          <OhsRequireAuth>
            <OhsNoticeBoardFormAddEditPage />
          </OhsRequireAuth>
        }
      />
      <Route
        path="form/:formId/edit"
        element={
          <OhsRequireAuth>
            <OhsNoticeBoardFormAddEditPage />
          </OhsRequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <OhsRequireAuth>
            <OhsAngularIframe />
          </OhsRequireAuth>
        }
      />
    </Routes>
  );
}

export default OhsNoticeBoardRoutes;
