import React from 'react';
import { OhsConstants } from '../constants/OhsConstants';

export default function OhsAngularIframe() {
  let bodyUrl = window.location.href.substring(
    window.location.href.indexOf(window.location.host) +
      window.location.host.length
  );
  let adjustedURL = '/' + OhsConstants.LegacyModuleFolder + '/#' + bodyUrl;

  return (
    <div>
      <iframe
        style={{
          height: 'calc(100vh )',
          width: 'calc(100vw)',
          boxSizing: 'border-box',
        }}
        src={adjustedURL}
        id="OhsAngularIframeId"
        title="OhsAngularIframe"
      ></iframe>
    </div>
  );
}
