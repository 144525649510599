import React from 'react';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import './OhsFormField.css';
import _ from 'lodash';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import ReactQuill from 'react-quill';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import 'react-quill/dist/quill.snow.css';

interface Props extends OhsFormFieldBaseProps {
  rows?: number;
}

function InputEle(props: Props) {
  const inputRef = props.ohsFormSettings.useFormMethods.register(props.id).ref;

  const { classes } = useStyles();
  const ContentSizeLimitKBytes = 20;
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];

  const onEditorStateChange = (editorState: any) => {
    props.ohsFormSettings.useFormMethods.setValue(props.id, editorState);
    if (error) {
      props.ohsFormSettings.useFormMethods.trigger(props.id);
    }
  };

  const editorContent = props.ohsFormSettings.useFormMethods.watch(
    props.id
  ) ?? { ops: [] };
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      required: props.required,
      validate: {
        overSize: (content: any) => {
          if (content) {
            const size = new TextEncoder().encode(
              JSON.stringify(content)
            ).length;
            if (size > ContentSizeLimitKBytes * 1024) {
              return `The content size is ${size} KB which exceeds the size limit ${ContentSizeLimitKBytes} KB. Please reduce the size.`;
            }
          }

          return true;
        },
        notEmpty: (value: any) => {
          if (props.required === true) {
            const contentOnly = value.replace(/(<([^>]+)>)/gi, '');
            if (contentOnly == null || contentOnly.trim() === '') {
              return false;
            }
          }

          return true;
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ohsFormSettings.useFormMethods.register]);

  return (
    <ReactQuill
      ref={inputRef}
      className={clsx(classes.root, classes.editor, {
        [classes.error]: error,
      })}
      theme="snow"
      value={editorContent}
      onChange={onEditorStateChange}
      modules={modules}
      formats={formats}
    />
  );
}

const useStyles = makeStyles()({
  root: {
    '& .quill': {
      borderRadius: '5px',
    },
    '& .ql-snow': {
      borderRadius: '5px',
    },
  },
  editor: {
    '& .ql-toolbar.ql-snow': {
      marginTop: '5px',
      borderRadius: '5px 5px 0 0',
    },
    '& .ql-editor': {
      maxHeight: '200px',
      borderRadius: '0 0 5px 5px',
    },
    '& .ql-container.ql-snow': {
      borderRadius: '0 0 5px 5px',
    },
  },
  error: {
    '& .ql-toolbar.ql-snow': {
      background: 'rgb(255, 246, 246)',
      border: '1px solid #f34335',
    },
    '& .ql-container.ql-snow': {
      borderRight: '1px solid #f34335',
      borderLeft: '1px solid #f34335',
      borderBottom: '1px solid #f34335',
    },
    '& .ql-editor': {
      borderRadius: '0 0 5px 5px',
    },
  },
});

export default function OhsRichTextField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
