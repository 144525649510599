import { Box, Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import OhsModal2 from '../../../../global/modal/OhsModal2';
import { getUserSettings } from '../../OhsUserSettingsServices';
import { mfaOTPEnrollment } from '../OhsUserSettingsMFAservices';
import { MfaSettingsStatus, OhsUserMFAOTPTypes } from '../OhsUserSettingsModels';
import OhsMfaAppEnrollment from './OhsMfaAppEnrollment';

import OhsMfaEmailSmsOTPform from './OhsMfaEmailSmsOTPform';
import OhsMfaSuccess from './OhsMfaSuccess';

const useStyles = makeStyles()({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '250px',
    width: '100%',
  },
  resend: {
    margin: '5px 0 10px 0',
    textDecoration: 'none',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  alignRight: {
    justifyContent: 'end',
  },
  actionBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    padding: '8px 0 8px 0 !important',
  },
});

interface Props {
  mfaInfo: OhsUserMFAOTPTypes;
  setMFAInfo: React.Dispatch<React.SetStateAction<OhsUserMFAOTPTypes>>;
  setMFAModalOpen: (status: boolean) => void;
  mfaModalOpen: boolean;
  switchToMethod: string;
}
const OhsMfaSettingsModal = (props: Props) => {
  const { classes } = useStyles();
  const [currentStep, setCurrentStep] = React.useState('');
  const [currentOTP, setCurrentOTP] = React.useState('');
  const [OTPvalidStatus, setOTPvalidStatus] = React.useState<MfaSettingsStatus>('idle');
  const [userProfile, setUserProfile] = React.useState<any>();
  const [methodDest, setMethodDest] = React.useState<any>();
  const [mfaInfo, setMFAInfo] = React.useState<OhsUserMFAOTPTypes>(
    new OhsUserMFAOTPTypes()
  );

  const getUserMfaSettings = async () => {
    const getMfaSettings = await getUserSettings();
    setUserProfile(getMfaSettings);
  };

  React.useEffect(() => {
    getUserMfaSettings();
  }, []);

  React.useEffect(() => {
    let methodSuccess = '';
    switch (props.switchToMethod) {
      case 'email':
        methodSuccess = userProfile?.email;
        break;
      case 'sms':
        methodSuccess =
          '+' + userProfile?.phoneCountryCode + ' - ' + userProfile?.phone;
        break;
      case 'app':
        methodSuccess = 'Google Authenticator.';
        break;
      default:
        methodSuccess = '';
    }
    setMethodDest(methodSuccess);
  }, [currentStep, props.switchToMethod, userProfile]);

  const handleMFAEnroll = async () => {
    setOTPvalidStatus('otpValidating');
    const OTPconfirm = {
      ...mfaInfo,
      otp: currentOTP,
      method: props.switchToMethod,
    };
    const actionStatus = mfaInfo.firstTimeEnroll ? 'confirm' : 'enroll';

    const oTPEnrollConfirmReq = await mfaOTPEnrollment(
      OTPconfirm,
      actionStatus
    );

    if (oTPEnrollConfirmReq.success) {
      if (actionStatus === 'confirm') {
        setOTPvalidStatus('valid');
        setCurrentStep('success');
      } else {
        const OTPenroll = {
          ...mfaInfo,
          ...oTPEnrollConfirmReq.data,
          firstTimeEnroll: true,
          method: props.switchToMethod,
          destination: methodDest,
        } as OhsUserMFAOTPTypes;
        setMFAInfo(OTPenroll);
        props.setMFAInfo(OTPenroll);
        setCurrentStep(mfaInfo.method);
        setCurrentOTP('');
      }
      setOTPvalidStatus('valid');
    } else {
      setOTPvalidStatus('invalid');
    }
  };

  React.useEffect(() => {
    if (props.mfaInfo) {
      setMFAInfo(props.mfaInfo);
      setCurrentStep(props.mfaInfo.method);
    }
  }, [props.mfaInfo, props.switchToMethod]);

  React.useEffect(() => {
    if (currentOTP === '') setOTPvalidStatus('idle');
    if (currentOTP.length === 6) handleMFAEnroll();
  }, [currentOTP]);

  const renderSteps = () => {
    switch (currentStep) {
      case 'sms':
        return (
          <OhsMfaEmailSmsOTPform
            mfaInfo={mfaInfo}
            setMFAInfo={setMFAInfo}
            setOTP={setCurrentOTP}
            currentOTP={currentOTP}
            oTPvalidStatus={OTPvalidStatus}
          />
        );
      case 'email':
        return (
          <OhsMfaEmailSmsOTPform
            mfaInfo={mfaInfo}
            setMFAInfo={setMFAInfo}
            setOTP={setCurrentOTP}
            currentOTP={currentOTP}
            oTPvalidStatus={OTPvalidStatus}
          />
        );
      case 'app':
        return (
          <OhsMfaAppEnrollment
            mfaInfo={mfaInfo}
            setOTP={setCurrentOTP}
            currentOTP={currentOTP}
            oTPvalidStatus={OTPvalidStatus}
          />
        );
      case 'appEnroll':
        return (
          <OhsMfaEmailSmsOTPform
            mfaInfo={mfaInfo}
            setMFAInfo={setMFAInfo}
            setOTP={setCurrentOTP}
            currentOTP={currentOTP}
            oTPvalidStatus={OTPvalidStatus}
          />
        );
      case 'success':
        return <OhsMfaSuccess mfaInfo={mfaInfo} />;
      default:
        return null;
    }
  };

  return (
    <OhsModal2
      headerColor="#F6F8FA"
      open={props.mfaModalOpen}
      title={'Multi-Factor Authentication'}
      setModalOpenFunc={() => props.setMFAModalOpen(true)}
      onClose={() => props.setMFAModalOpen(false)}
      maxWidth={'xs'}
      ContentNode={<Box className={classes.content}>{renderSteps()}</Box>}
      actionNode={
        <Box
          className={clsx(classes.actions, {
            [classes.alignRight]: currentStep === 'success',
          })}
        >
          {currentStep !== 'success' && (
            <Button
              variant="text"
              onClick={() => setCurrentOTP('')}
              className={classes.actionBtn}
            >
              Clear
            </Button>
          )}
          {currentStep === 'success' && (
            <Button
              variant="contained"
              onClick={() => props.setMFAModalOpen(false)}
              className={classes.actionBtn}
            >
              Done
            </Button>
          )}
          {currentStep !== 'success' && (
            <Button
              variant="text"
              className={classes.actionBtn}
              onClick={() => props.setMFAModalOpen(false)}
            >
              Cancel
            </Button>
          )}
        </Box>
      }
    ></OhsModal2>
  );
};

export default OhsMfaSettingsModal;
