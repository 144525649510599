import { getCurrentTimezone } from '../common/OhsDataParse';
import { OhsRegisterRecord } from '../global/register/table/OhsRegisterModels';
import { ModuleType, OhsModule, OhsObject } from '../models/OhsObject';
import { OhsTier } from '../models/tier/OhsTier';
import { OhsAllocatableRecord } from '../safety-plan/OhsSafetyPlanModels';
import { OhsCusval } from 'global/form/cusvals/OhsCusvalModel';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';

export interface OhsNoticeBoardRecord extends OhsAllocatableRecord {
  type: ModuleType.Noticeboard;
  title: string;
  description: string;
  control: object;
  category: string;
  categoryOther: string | null;
  subcategory: string;
  subcategoryOther: string | null;
  subRows: Array<OhsNoticeBoardRecord>;

  allocationOf: { _id: string; type: ModuleType.Noticeboard } | undefined;

  createdBy: {
    type: 'core.user';
    _id: string;
    name: string;
    email: string;
  };
  tzDateCreated: string;
  dateCreated: string;
}
export interface OhsAllocationTierRecord extends OhsRegisterRecord {
  allocated: boolean;
  latestCompletedTask: string | null;
  dateDue?: string;
  name: string;
}

export interface OhsNoticeBoardRecordConfig extends OhsModule {
  attachments: OhsAttachment[];
  board: OhsNoticeBoardRecord;
  blocks: OhsNoticeboardBlock[];
  contacts: OhsNoticeboardContact[];
  forms: NoticeboardForm[];
  tier: OhsTier;
}

export type OhsNoticeboardBlock =
  | OhsNoticeboardBlockRichText
  | OhsNoticeboardBlockFile
  | OhsNoticeboardBlockLink
  | OhsNoticeboardBlockYoutube
  | OhsNoticeboardBlockVdoc;

export enum OhsNoticeboardBlockType {
  OhsRichText = 'text/html',
  OhsDocument = 'application/vnd.safetychampion.core_module_vdoc',
  OhsAttachment = 'application/vnd.safetychampion.attachment',
  OhsYoutube = 'video/x-youtube',
  OhsLink = 'application/vnd.safetychampion.externalLinks',
}
export enum OhsNoticeboardBlockTypeDisplay {
  OhsRichText = 'Rich Text',
  OhsDocument = 'Document',
  OhsAttachmentImg = 'Image',
  OhsAttachmentPdf = 'PDF',
  OhsAttachmentWord = 'Word',
  OhsAttachmentPpt = 'Power Point',
  OhsAttachmentExcel = 'Excel',
  OhsAttachmentFile = 'File',
  OhsYoutube = 'Youtube',
  OhsLink = 'Link',
}

export enum OhsFileType {
  OhsAttachmentImg = 'Image',
  OhsAttachmentPdf = 'PDF',
  OhsAttachmentWord = 'Word',
  OhsAttachmentPpt = 'Power Point',
  OhsAttachmentExcel = 'Excel',
  OhsAttachmentFile = 'File',
}

export const ImageFileExtensionList = [
  'png',
  'gif',
  'jpg',
  'jpeg',
  'webp',
  'svg',
  'bmp',
];
const PdfFileExtensionList = ['pdf'];
const WordFileExtensionList = ['doc', 'docx', 'dotx'];
const PowerPointFileExtensionList = [
  'ppt',
  'pptx',
  'ppsx',
  'pps',
  'potx',
  'ppsm',
];
const ExcelFileExtensionList = ['xls', 'xlsx', 'xlsb', 'xlsm'];

export const getFileTypebyFileName = (fileName?: string): OhsFileType => {
  if (fileName && fileName.indexOf('.') > 0) {
    const fileExtension = fileName.split('.').pop() ?? '';

    if (ImageFileExtensionList.includes(fileExtension.toLocaleLowerCase())) {
      return OhsFileType.OhsAttachmentImg;
    } else if (
      PdfFileExtensionList.includes(fileExtension.toLocaleLowerCase())
    ) {
      return OhsFileType.OhsAttachmentPdf;
    } else if (
      WordFileExtensionList.includes(fileExtension.toLocaleLowerCase())
    ) {
      return OhsFileType.OhsAttachmentWord;
    } else if (
      PowerPointFileExtensionList.includes(fileExtension.toLocaleLowerCase())
    ) {
      return OhsFileType.OhsAttachmentPpt;
    } else if (
      ExcelFileExtensionList.includes(fileExtension.toLocaleLowerCase())
    ) {
      return OhsFileType.OhsAttachmentExcel;
    }
  }

  return OhsFileType.OhsAttachmentFile;
};

export interface OhsNoticeboardBlockRichText {
  type: OhsNoticeboardBlockType.OhsRichText;
  typeDisplay: string;
  data: { _id: string; title: string; content: any };
}

export interface OhsNoticeboardBlockVdoc {
  type: OhsNoticeboardBlockType.OhsDocument;
  typeDisplay: string;
  data: {
    _id: string;
    title: string;
    description: string | null;
    type: ModuleType.VersionDocument;
    attachment: OhsAttachment;
  };
}

export interface OhsNoticeboardBlockFile {
  type: OhsNoticeboardBlockType.OhsAttachment;
  typeDisplay: string;
  data: {
    _id: string;
    title: string;
    description: string | null;
    attachment: OhsAttachment;
  };
}

export interface OhsNoticeboardBlockLink {
  type: OhsNoticeboardBlockType.OhsLink;
  typeDisplay: string;
  data: {
    _id: string;
    title: string;
    description: string | null;
    links: {
      title: null;
      uri: string;
    }[];
    attachment: OhsAttachment;
  };
}

export interface OhsNoticeboardBlockYoutube {
  type: OhsNoticeboardBlockType.OhsYoutube;
  typeDisplay: string;
  data: {
    _id: string;
    title: string;
    description: string | null;
    source: string;
  };
}
export const setBlockDisplayType = (
  blockType: OhsNoticeboardBlockType,
  attachmentFileName?: string
) => {
  switch (blockType) {
    case OhsNoticeboardBlockType.OhsLink:
      return OhsNoticeboardBlockTypeDisplay.OhsLink;
    case OhsNoticeboardBlockType.OhsRichText:
      return OhsNoticeboardBlockTypeDisplay.OhsRichText;
    case OhsNoticeboardBlockType.OhsYoutube:
      return OhsNoticeboardBlockTypeDisplay.OhsYoutube;
    case OhsNoticeboardBlockType.OhsDocument:
      return OhsNoticeboardBlockTypeDisplay.OhsDocument;
    case OhsNoticeboardBlockType.OhsAttachment:
      return getFileTypebyFileName(attachmentFileName);

    default:
      return blockType;
  }
};

export interface OhsNoticeboardContact {
  phones: string[];
  emails: string[];
  role: string | null;
  roleOther: string | null;
  notes: string;
  name: string;
}

export interface OhsNoticeboardDownload extends OhsModule {
  url: string;
}

export class NoticeboardForm {
  _id: string;
  type: ModuleType.NoticeBoardForm;
  cusvals: Array<OhsCusval>;
  title: string;
  formTitle?: string;
  description: string | null;
  messages: NoticeboardFormMessages;
  tzDateCreated: string;
  archived?: boolean;

  tier?: OhsObject;
  constructor() {
    this._id = '';
    this.type = ModuleType.NoticeBoardForm;
    this.cusvals = new Array<OhsCusval>();
    this.title = '';
    this.description = null;
    this.messages = { pre: null, in: null, post: null };
    this.tzDateCreated = getCurrentTimezone();
  }
}
export interface NoticeboardFormYoububeMessage {
  type: NoticeboardFormMessageType.Youtube;
  value: string;
}

export interface NoticeboardFormRichTextMessage {
  type: NoticeboardFormMessageType.RichText;
  value: any;
}

export enum NoticeboardFormMessageType {
  Youtube = 'video/x-youtube',
  RichText = 'text/html',
}

export class NoticeboardFormMessages {
  pre: NoticeboardFormYoububeMessage | NoticeboardFormRichTextMessage | null;
  in: NoticeboardFormYoububeMessage | NoticeboardFormRichTextMessage | null;
  post: NoticeboardFormYoububeMessage | NoticeboardFormRichTextMessage | null;
  constructor() {
    this.pre = null;
    this.in = null;
    this.post = null;
  }
}
