import { OhsRecordFieldType } from '../../global/record-filed/OhsRecordFieldType';
export const OhsPurchaseAmountRecordField = new OhsRecordFieldType(
  'purchaseAmount',
  'Purchase Amount'
);

export const OhsDateExpiryRecordField = new OhsRecordFieldType(
  'dateExpiry',
  'Due Date'
);

export const OhsLocationRecordField = new OhsRecordFieldType(
  'location',
  'Location '
);

export const OhsLocationOtherRecordField = new OhsRecordFieldType(
  'locationOther',
  'Other Location '
);

export const OhsIssuedToRecordField = new OhsRecordFieldType(
  'issuedTo',
  'Holder'
);

export const OhsIssuedToOtherRecordField = new OhsRecordFieldType(
  'issuedToOther',
  'Holder Other'
);

export const OhsIssuedToLinksRecordField = new OhsRecordFieldType(
  'issuedToLinks',
  'Holder Links (Auto Suggest)'
);

export const OhsRiskRatingRecordField = new OhsRecordFieldType(
  'riskRating',
  'Risk Rating'
);

export const OhsRiskRatingOtherRecordField = new OhsRecordFieldType(
  'riskRatingOther',
  'Risk Rating Other'
);

export const OhsSerialNumberRecordField = new OhsRecordFieldType(
  'serialNumber',
  'Serial Number'
);

export const OhsDatePurchasedRecordField = new OhsRecordFieldType(
  'datePurchased',
  'Purchased Date'
);

export const OhsStatusRecordField = new OhsRecordFieldType('status', 'Status');

export const OhsStatusOtherRecordField = new OhsRecordFieldType(
  'statusOther',
  'Other Status'
);

export const OhsNotesRecordField = new OhsRecordFieldType('notes', 'Notes');

export const OhsRiskAssessmentsRequiredRecordField = new OhsRecordFieldType(
  'riskAssessmentsRequired',
  'Is a risk assessment Required?'
);

export const OhsRiskAssessmentsCompleteRecordField = new OhsRecordFieldType(
  'riskAssessmentsComplete',
  'Has a risk assessment been completed?'
);

export const OhsRiskAssessmentsRecordField = new OhsRecordFieldType(
  'riskAssessments',
  'Risk Assessments'
);

export const OhsPreviousRiskAssessmentsRecordField = new OhsRecordFieldType(
  'previousRiskAssessments',
  `Previous ${OhsRiskAssessmentsRecordField.name}`
);

export const OhsImagesRecordField = new OhsRecordFieldType(
  'images',
  'Image of Asset, Equipment or Plant'
);

export const OhsPreviousImagesRecordField = new OhsRecordFieldType(
  'previousImages',
  `Previous ${OhsImagesRecordField.name}`
);
