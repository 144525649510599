import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import { SmallScreenWidthBreakpoint } from '../constants/OhsLayoutConstants';
import { OhsRegisterFilter } from '../../models/OhsRegisterFilter';
import { getOhsUser } from '../../user/OhsUser';
import ButtonWithTooltip from '../buttons/OhsButtonWithTooltip';
import { OhsExportButton } from '../buttons/OhsExportButton';

export const registerButtonStyles = makeStyles()((theme: Theme) => ({
  button: {
    marginRight: '1px!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      padding: '.78571429em .78571429em .78571429em!important',
    },
  },
  buttonGroup: {
    marginTop: '-10px!important',
    marginBottom: '-10px!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      marginTop: '10px!important',
      marginBottom: '10px!important',
    },
  },
  buttonIcons: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      opacity: '.9',
      margin: '0!important',
      verticalAlign: 'top',
    },
  },
  hideForSmall: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'inline',
    },
  },
}));
interface Props {
  createButton?: { func: () => void; name?: string; permission: boolean };
  openAllocateButton?: { func: () => void; name?: string; permission: boolean };
  archiveButton?: { name?: string; permission: boolean };
  editButton?: { func: () => void; permission: boolean; disable?: boolean };
  openExcelModal?: () => void;
  openCopyModal?: () => void;
  expandButton?: { func: () => void; isCompress: boolean; showButton: boolean };
  signoffButton?: { func: () => void; canSignOff: boolean };
  assignButton?: { func: () => void; permission: boolean };
  reviewTask?: { func: () => void; permission: boolean };
}

export default function OhsModuleHeaderButtons(props: Props) {
  const { classes } = registerButtonStyles();
  const user = getOhsUser();
  const moduleConfig = user?.getCurrentURLModuleConfig();
  const registerFilter = new OhsRegisterFilter();
  function handleArchive() {
    let urlParams = new URLSearchParams(window.location.search);
    if (registerFilter.archived === true) {
      urlParams.set('archived', 'false');
    } else {
      urlParams.set('archived', 'true');
    }
    window.location.search = urlParams.toString();
  }

  return (
    <>
      {moduleConfig && (
        <ButtonGroup floated="right">
          {props.createButton &&
            props.createButton?.permission === true &&
            registerFilter.archived !== true && (
              <Button
                className={classes.button}
                color="green"
                onClick={props.createButton.func}
              >
                <Icon className={classes.buttonIcons} name="plus" />
                <span className={classes.hideForSmall}>
                  {props.createButton.name
                    ? props.createButton.name
                    : moduleConfig.TITLE}
                </span>
              </Button>
            )}
          {props.reviewTask && props.reviewTask?.permission === true && (
            <Button
              className={classes.button}
              color="blue"
              onClick={props.reviewTask.func}
            >
              <span className={classes.hideForSmall}>Set Review</span>
            </Button>
          )}

          {props.editButton && props.editButton?.permission === true && (
            <ButtonWithTooltip
              tooltipMsg={
                'Edit is currently disable until saved task has been signed-off.'
              }
              disabled={props.editButton?.disable}
            >
              <Button
                className={classes.button}
                color="blue"
                onClick={props.editButton.func}
                disabled={props.editButton?.disable}
              >
                <Icon className={classes.buttonIcons} name="edit" />
                <span className={classes.hideForSmall}>Edit</span>
              </Button>
            </ButtonWithTooltip>
          )}
          {props.openAllocateButton &&
            props.openAllocateButton.permission === true && (
              <Button
                onClick={props.openAllocateButton.func}
                className={classes.button}
                size="small"
                color="green"
              >
                <Icon className={classes.buttonIcons} name="retweet" />
                <span className={classes.hideForSmall}>Allocate</span>
              </Button>
            )}

          {props.openCopyModal &&
            moduleConfig.PERMISSIONS &&
            moduleConfig.PERMISSIONS.create === true &&
            registerFilter.archived !== true && (
              <Button
                className={classes.button}
                color="green"
                onClick={props.openCopyModal}
              >
                <Icon className={classes.buttonIcons} name="copy" />
                <span className={classes.hideForSmall}>
                  Copy {moduleConfig.TITLE}
                </span>
              </Button>
            )}

          {props.signoffButton && props.signoffButton.canSignOff && (
            <Button
              className={classes.button}
              color="green"
              onClick={props.signoffButton.func}
            >
              <Icon className={classes.buttonIcons} name="signup" />
              <span className={classes.hideForSmall}>Sign-off</span>
            </Button>
          )}
          {props.assignButton && props.assignButton.permission === true && (
            <Button
              className={classes.button}
              color="teal"
              onClick={props.assignButton.func}
            >
              <Icon className={classes.buttonIcons} name="user" />
              <span className={classes.hideForSmall}>Assign</span>
            </Button>
          )}

          {props.archiveButton &&
            moduleConfig.PERMISSIONS &&
            moduleConfig.PERMISSIONS.archive === true &&
            props.archiveButton?.permission === true && (
              <Button
                className={classes.button}
                color="teal"
                onClick={handleArchive}
              >
                <Icon className={classes.buttonIcons} name="exchange" />
                <span className={classes.hideForSmall}>
                  {registerFilter.archived !== true ? 'Archived' : 'Active'}
                </span>
              </Button>
            )}

          {props.openExcelModal && (
            <OhsExportButton onClick={props.openExcelModal} />
          )}

          {props.expandButton && props.expandButton?.showButton === true && (
            <Button
              className={classes.button}
              color="orange"
              onClick={props.expandButton.func}
            >
              <Icon
                className={classes.buttonIcons}
                name={props.expandButton.isCompress ? 'expand' : 'compress'}
              />
              <span className={classes.hideForSmall}>
                {props.expandButton.isCompress ? 'Expand' : 'Compress'}
              </span>
            </Button>
          )}
        </ButtonGroup>
      )}
    </>
  );
}
