import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SmallScreenWidthBreakpoint } from '../../global/constants/OhsLayoutConstants';
import { removeNonNumeric } from '../../global/utils/OhsUtility';

export const screenSizeDispalyStyles = makeStyles()((theme: Theme) => ({
  displayForSmall: {
    display: 'inline',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'none',
    },
  },

  hideForSmall: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'inline',
    },
  },
}));

export const editFormLayout = makeStyles()((theme: Theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1000 + removeNonNumeric(theme.spacing(2)) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  rightbutton: {
    textAlign: 'right',
    paddingBottom: '13px',
  },
}));
