import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import OhsAngularIframe from '../global/angular-iframe/OhsAngularIframe';
import { toggleTopBanner } from '../dashboard/OhsDashboardSlice';

import { getOhsUser } from '../user/OhsUser';
import OhsTier1CopyDialog from './copy/OhsTier1CopyDialog';
import OhsXsiHome from './xsi/OhsXsiHome';
import OhsRequireAuth from '../global/OhsRequireAuth';
import OhsUserProfileSettingsForm from '../user/user-settings/OhsUserSettingsForm';
import { OhsExportCentreRegisterHome } from './export/OhsExportCentreRegisterHome';

const OhsAdmin2Routes = (props: {
  toggleTopBanner: (show: boolean) => void;
}) => {
  const { pathname } = useLocation();
  const user = getOhsUser();

  useEffect(() => {
    // Tier 1 shouldn't have banner message on its dashboard page.
    if (user?.tierNum !== 1) {
      props.toggleTopBanner(true);
      return () => props.toggleTopBanner(false);
    }
  }, [user?.tierNum]);

  return (
    <>
      <Routes>
        <Route
          path={`xsi/:orgId`}
          element={
            <OhsRequireAuth>
              <OhsXsiHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`user-settings`}
          element={
            <OhsRequireAuth>
              <OhsUserProfileSettingsForm />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`export`}
          element={
            <OhsRequireAuth>
              <OhsExportCentreRegisterHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
      {user?.tierNum === 1 && <OhsTier1CopyDialog></OhsTier1CopyDialog>}
    </>
  );
};

export default connect(null, { toggleTopBanner })(OhsAdmin2Routes);
