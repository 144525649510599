import { Link, Toolbar } from '@mui/material';
import React from 'react';
import { openAngularIframeModal } from '../../global/angular-iframe/OhsAngularIframeServices';
import { getOhsUser } from '../../user/OhsUser';

export const OhsFreemiumTopbar = () => {
  const openUpgradeModal = () => {
    openAngularIframeModal('upgradeAccountModal', null);
  };
  const user = getOhsUser();
  return (
    <Toolbar
      style={{
        minHeight: '40px',
        backgroundImage: 'linear-gradient(to right, #f47431, #fcd808)',
      }}
    >
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          color: 'white',
          margin: 'auto',
          fontWeight: 'bold',
          fontSize: '1rem',
        }}
      >
        You’re using Safety Champion Light.
        {user?.tierNum === 3 && (
          <span>
            {' '}
            <Link
              href="#"
              onClick={openUpgradeModal}
              style={{
                color: 'white',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              {'Upgrade'}
            </Link>{' '}
            today to experience more features and modules.
          </span>
        )}
      </div>
    </Toolbar>
  );
};
