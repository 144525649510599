import React, { useEffect } from 'react';
import * as _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { toggleOhsTier1CopyDialog, sendOrgId } from './OhsTier1CopyDialogSlice';
import {
  getSafetyPlanCopyList,
  getInspectionCopyList,
  getOrgList,
  copyData,
} from './OhsTier1CopyServices';
import OhsRegister from '../../global/register/OhsRegister';
import OhsModalHeader from './OhsModalHeader';
import {
  OhsTier1CopySafetPlanRecord,
  OhsTier1CopyInspectionRecord,
} from './OhsTier1CopyModels';
import { Row } from 'react-table';

function OhsTier1CopyDialog(props: any) {
  (window as any).toggleOhsTier1CopyDialog = props.toggleOhsTier1CopyDialog;
  (window as any).sendOrgId = props.sendOrgId;
  const safetyPlan = 'safety-plan';
  const inspection = 'inspection';

  const [selectedRows, setSelectedRows] = React.useState<Array<Row<any>>>([]);
  const safetyListcolumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Frequency',
        accessor: (originalRow: OhsTier1CopySafetPlanRecord) => {
          return (
            (originalRow.frequencyValue + ' ' || '') +
            (originalRow.frequencyKey || '')
          );
        },
      },
      {
        Header: 'Details',
        accessor: 'description',
      },
    ],
    []
  );

  const inspectionListcolumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'title',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Subcategory',
        accessor: 'subcategory',
      },
      {
        Header: 'Frequency',
        accessor: (originalRow: OhsTier1CopyInspectionRecord) => {
          return (
            (originalRow.frequencyValue + ' ' || '') +
            (originalRow.frequencyKey || '')
          );
        },
      },
      {
        Header: 'Details',
        accessor: 'description',
      },
    ],
    []
  );

  let [safetyPlanList, safetPlanListFn] = React.useState([]);
  let [inspectionList, inspectionListFn] = React.useState([]);
  let [orgList, orgListFn] = React.useState([]);

  let [selectedRadio, selectedRadioFn] = React.useState('' as string);
  const [activeStep, setActiveStep] = React.useState(0);
  const [copySelectionType, copySelectionTypeFn] = React.useState(safetyPlan);

  const handleOrgChange = (id: string) => {
    selectedRadio = id;
    selectedRadioFn(selectedRadio);
  };

  const handleChange = (event: any) => {
    copySelectionTypeFn(event.target.value);
  };

  const handleSubmit = () => {
    if (
      copySelectionType &&
      props.orgId &&
      selectedRadio &&
      selectedRows.length
    ) {
      copyData(
        copySelectionType,
        props.orgId,
        selectedRadio,
        selectedRows.map((x: any) => x.original._id)
      ).then((response: any) => {
        if (response && response.success === true) {
          toast.success('Record(s) have been successfully copied.');
          props.toggleOhsTier1CopyDialog(false);
        } else {
          toast.error(response.error);
        }

        // window.location.reload();
      });
    }
  };

  const handleClose = () => {
    props.toggleOhsTier1CopyDialog(false);
    setActiveStep(0);
  };

  const handleCall = (activeStep: number) => {
    if (activeStep === 0) {
      if (copySelectionType === safetyPlan) {
        getSafetyPlanCopyList(copySelectionType, props.orgId).then(
          (list: any) => {
            safetyPlanList = list;
            safetPlanListFn(safetyPlanList);
          }
        );
      } else {
        getInspectionCopyList(copySelectionType, props.orgId).then(
          (list: any) => {
            inspectionList = list;
            inspectionListFn(inspectionList);
          }
        );
      }
    }
    if (activeStep === 1) {
      getOrgList().then((list: any) => {
        orgList = list.filter((x: any) => x._id !== props.orgId);
        orgList.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
        orgListFn(orgList);
      });
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    handleCall(activeStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleCall(activeStep);
  };
  const initialSetting = () => {
    setActiveStep(0);
    setSelectedRows([]);
    safetPlanListFn([]);
    inspectionListFn([]);
    orgListFn([]);
    selectedRadioFn('');
    copySelectionTypeFn(safetyPlan);
  };

  useEffect(() => {
    if (props.open) {
      initialSetting();
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={true}
    >
      <OhsModalHeader
        title={'Copy From: ' + props.orgName}
        onCloseFn={handleClose}
      />
      <DialogContent>
        <div
          style={{ display: activeStep === 0 ? 'block' : 'none' }}
          className={activeStep === 0 ? 'showClass' : 'hideClass'}
        >
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="copy-selection-type-label">Select type</InputLabel>
            <Select
              labelId="copy-selection-type-label"
              id="copy-selection-type"
              defaultValue="safety-plan"
              onChange={handleChange}
            >
              <MenuItem value={safetyPlan}>Safety plan</MenuItem>
              <MenuItem value={inspection}>Inspection</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            display:
              activeStep === 1 && copySelectionType === safetyPlan
                ? 'block'
                : 'none',
          }}
          className={activeStep === 1 ? 'showClass' : 'hideClass'}
        >
          <h4>Safety Plan list</h4>
          {
            <OhsRegister
              columns={safetyListcolumns}
              data={safetyPlanList}
              setSelectedRows={setSelectedRows}
            />
          }
        </div>

        <div
          style={{
            display:
              activeStep === 1 && copySelectionType === inspection
                ? 'block'
                : 'none',
          }}
          className={activeStep === 1 ? 'showClass' : 'hideClass'}
        >
          <h4>Inspection list</h4>
          {
            <OhsRegister
              columns={inspectionListcolumns}
              data={inspectionList}
              setSelectedRows={setSelectedRows}
            />
          }
        </div>

        <div
          style={{ display: activeStep === 2 ? 'block' : 'none' }}
          className={activeStep === 2 ? 'showClass' : 'hideClass'}
        >
          <h4>Org Admin list</h4>
          {
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="org-label">Select Target Org Admin</InputLabel>
              <Select
                labelId="org-label"
                id="org-id"
                onChange={(e: any) => handleOrgChange(e.target.value)}
              >
                {orgList.map((item: any) => {
                  return <MenuItem value={item._id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ display: activeStep === 0 ? 'none' : 'block' }}
          className={activeStep === 0 ? 'hideClass' : 'showClass'}
          color="primary"
          variant="outlined"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleNext}
          disabled={activeStep === 1 && !selectedRows.length ? true : false}
          style={{ display: activeStep === 2 ? 'none' : 'block' }}
          className={activeStep === 2 ? 'hideClass' : 'showClass'}
        >
          Next
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          style={{ display: activeStep === 2 ? 'block' : 'none' }}
          disabled={activeStep === 2 && !selectedRadio ? true : false}
          className={activeStep === 2 ? 'showClass' : 'hideClass'}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state: any) => {
  return {
    isOpen: state.OhsTier1CopyDialogReducer.isOpen,
    orgId: state.OhsTier1CopyDialogReducer.orgId,
    orgName: state.OhsTier1CopyDialogReducer.orgName,
  };
};

export default connect(mapStateToProps, {
  toggleOhsTier1CopyDialog,
  sendOrgId,
})(OhsTier1CopyDialog);
