import { Box, Divider, Link, Typography } from '@mui/material';
import QRCode from 'qrcode';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import OhsUserMFAInput from '../OhsUserMFAInput';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import { MfaSettingsStatus, OhsUserMFAOTPTypes } from '../OhsUserSettingsModels';

const useStyles = makeStyles()({
  caption: {
    textAlign: 'center',
    fontSize: '12px',
  },
  link: {
    textDecoration: 'none',
    margin: '5px',
    fontSize: '12px',
    cursor: 'pointer',
  },
  destinationTxt: {
    fontWeight: 'bold',
  },
  header: {
    marginBottom: '15px',
    textAlign: 'center',
  },
  otpError: {
    color: 'red',
    margin: '5px 0 -10px 0',
  },
  errorIcon: {
    verticalAlign: '-4px',
    fontSize: '16px',
    marginRight: '2px',
  },
  validating: {
    color: 'green',
    margin: '5px 0 -10px 0',
    display: 'flex',
  },
  validatingIcon: {
    verticalAlign: '-4px',
    fontSize: '16px',
    marginRight: '8px',
    width: '18px !important',
    height: '18px !important',
    color: 'green',
  },
});

interface Props {
  mfaInfo: OhsUserMFAOTPTypes;
  setOTP: React.Dispatch<React.SetStateAction<string>>;
  oTPvalidStatus: MfaSettingsStatus;
  currentOTP: string;
}
const OhsMfaAppEnrollment = (props: Props) => {
  const { classes } = useStyles();
  const [QRcode, setQRcode] = React.useState('');

  const getQrCode = async () => {
    const qrsrc = await QRCode.toDataURL(props.mfaInfo.barcodeUri ?? '');
    setQRcode(qrsrc);
  };

  React.useEffect(() => {
    if (props.mfaInfo.barcodeUri) getQrCode();
    props.setOTP('');
  }, [props.mfaInfo.barcodeUri]);

  return (
    <>
      {QRcode && (
        <>
          <Typography variant="body1" gutterBottom className={classes.caption}>
            To complete this process, scan the QR code using your authenticator
            app.
          </Typography>
          <img src={QRcode} alt="" />
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: 'center', margin: '0 0 10px 0' }}
          >
            {`Enter the 6-digit authentication code generated by your app.`}
          </Typography>
          <Divider sx={{ width: '100%', marginBottom: '15px' }} />
        </>
      )}
      {!QRcode && (
        <>
          <Typography variant="body1" gutterBottom className={classes.header}>
            Please enter the authentication code generated by your authenticator
            app.
          </Typography>
        </>
      )}
      <OhsUserMFAInput setOTP={props.setOTP} currentOTP={props.currentOTP} />
      {props.oTPvalidStatus !== 'idle' && (
        <Typography
          variant="body1"
          gutterBottom
          className={`${classes.link} ${classes.otpError}`}
        >
        {props.oTPvalidStatus === 'invalid' && <>
          <ErrorIcon className={classes.errorIcon} />
          {`Incorrect verification code.`}
        </>}
        {props.oTPvalidStatus === 'otpValidating' && <>
          <CircularProgress className={classes.validatingIcon} />
          {`verifiying authentication code ...`}
        </>}
        {props.oTPvalidStatus === 'resendingcode' && <>
          <CircularProgress className={classes.validatingIcon} />
          {`resending authentication code ...`}
        </>}
        </Typography>
      )}
    </>
  );
};

export default OhsMfaAppEnrollment;
