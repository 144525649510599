import { TextField } from '@mui/material';
import React from 'react';

interface Props {
  setUserList?: () => void;
  cellProps: any;
}
export const OhsTaskAssignInput = (props: Props) => {
  const [taskMessage, setTaskMessage] = React.useState('');

  return (
    <TextField
      key={props.cellProps.data[props.cellProps.row.index]['_id']}
      onChange={(data) => {
        props.cellProps.data[props.cellProps.row.index]['message'] =
          data.target.value;
        setTaskMessage(data.target.value);
      }}
      variant="outlined"
      margin={'dense'}
      multiline={true}
      value={taskMessage}
    />
  );
};
