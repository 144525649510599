import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';
import {
  OhsInspectionTaskRecord,
  OhsInspectionStart,
} from './OhsInspectionModels';
import { convertToJSONFormData } from '../common/OhsDataParse';
import { getOhsUser } from '../user/OhsUser';
import { ModuleType } from '../models/OhsObject';
import { OhsRegisterFilter } from '../models/OhsRegisterFilter';
import { handleApiResponseError } from '../common/OhsApiUtils';
import { OhsInspectionRecord } from './OhsInspectionModels';

const user = getOhsUser();
const moduleName = user?.getModuleName(ModuleType.Inspection);

export const getInspectionTaskRecord = (
  inspId: string,
  taskId: string
): Promise<OhsInspectionTaskRecord> => {
  const endpoint = `/inspections/${inspId}/tasks/${taskId}`;
  const errorMessage = 'Unable to get the inspection risk record.';
  return ohsApiClient
    .get(endpoint)
    .then((response: any) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return [];
      }
    })
    .catch(function (error: any) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const fetchSingleInspection = (
  inspId: string
): Promise<OhsInspectionTaskRecord> => {
  const endpoint = `/inspections/${inspId}/fetch`;
  const errorMessage = 'Unable to fetch inspection.';
  return ohsApiClient
    .get(endpoint)
    .then((response: any) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return [];
      }
    })
    .catch(function (error: any) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const startInspection = (
  inspectionId: string,
  taskId: string,
  payload: OhsInspectionStart,
  callback?: Function
): Promise<OhsInspectionStart | null> => {
  const endpoint = `/inspections/${inspectionId}/tasks/${taskId}/start`;
  const errorMessage = 'Unable to start inspection.';
  const formData = convertToJSONFormData(payload);

  return ohsApiClient
    .post(endpoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        if (callback) callback();
        return response.data.result.item;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getAvailableInspectionList = (): Promise<
  Array<OhsInspectionRecord>
> => {
  const endpoint = '/inspections/list/available';
  const errorMessage = `Unable to get the ${moduleName} list.`;
  const filter = new OhsRegisterFilter();

  return ohsApiClient
    .post(endpoint, { filter: filter })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const createNewOneOffChild = (
  id: string,
  payload: any,
  callback?: Function
) => {
  const endpoint = `/inspections/${id}/newchild`;
  const errorMessage = `Unable to create one off ${moduleName}.`;
  const formData = convertToJSONFormData(payload);

  return ohsApiClient
    .post(endpoint, formData)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        if (callback) callback(response.data.result.item);
        toast.success('One off inspection has been started successfully');
        return response.data.result.item;
      } else {
        handleApiResponseError(response, errorMessage, []);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};
