import React from 'react';
import OtpInput from 'react-otp-input';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    'div:not(:first-child):not(:last-child)': {
      input: {
        borderRightWidth: '1px',
        borderLeftWidth: '1px',
      },
    },
    'div:first-child': {
      input: {
        borderTopLeftRadius: '6px!important',
        borderBottomLeftRadius: '6px!important',
        borderRightWidth: '1px',
      },
    },
    'div:last-child': {
      input: {
        borderTopRightRadius: '6px!important',
        borderBottomRightRadius: '6px!important',
        borderLeftWidth: '1px',
      },
    },
  },
  input: {
    height: '64px',
    width: '48px!important',
    borderStyle: 'solid',
    borderColor: '#CECECE',
    borderWidth: '2px',
    fontSize: '20px',
    fontWeight: '500',
  },
  focus: {
    border: 'transparent',
    outline: '2px solid #3874CB',
    zIndex: 1,
    outlineOffset: '-2px',
  },
});

interface Props {
  value: string;
  setValue: (value: string) => void;
  shouldAutoFocus?: boolean;
  length: number;
  disabled?: boolean;
}

const OhsOTPInput = (props: Props) => {
  const { classes } = useStyles();

  const handleChange = (otp: string) => {
    props.setValue(otp);
  };

  return (
    <OtpInput
      value={props.value}
      onChange={handleChange}
      numInputs={props.length}
      containerStyle={classes.container}
      inputStyle={classes.input}
      focusStyle={classes.focus}
      shouldAutoFocus={props.shouldAutoFocus}
      isDisabled={props.disabled}
    />
  );
};
export default OhsOTPInput;
