import React from 'react';
import { Button } from 'semantic-ui-react';
import OhsModalForm from '../../global/form/OhsModalForm';
import OhsModalFormItem from '../../global/form/OhsModalFormItem';
import OhsModal from '../../global/modal/OhsModal';
import { OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import {
  OhsNoticeboardBlock,
  OhsNoticeboardBlockFile,
  OhsNoticeboardBlockType,
  OhsNoticeboardBlockTypeDisplay,
} from '../OhsNoticeBoardModels';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsAttachmentDropzone from 'global/form/fields/OhsAttachmentDropzone';

const shortid = require('shortid');
interface Props {
  open: boolean;

  setModalOpenFunc: (addModalOpen: boolean) => void;

  onClose?: () => void;
  handleFileFunc: (fileBlock: OhsNoticeboardBlockFile) => void;
  operateFileBlock: OhsNoticeboardBlock | null;
}

export const OhsNoticeBoardFileBlockModal = (props: Props) => {
  const [fileBlock, setFileBlock] =
    React.useState<OhsNoticeboardBlockFile | null>(null);

  const ohsFormSettings = new OhsFormSettings(
    {
      title: '',
      description: '',
      attachments: new Array<OhsAttachment>(),
    },
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  React.useEffect(() => {
    if (
      props.operateFileBlock &&
      props.operateFileBlock.type === OhsNoticeboardBlockType.OhsAttachment
    ) {
      setFileBlock(props.operateFileBlock);
      ohsFormSettings.useFormMethods.reset({
        title: props.operateFileBlock.data.title,
        description: props.operateFileBlock.data.description ?? '',
        attachments: [props.operateFileBlock.data.attachment],
      });
    }
  }, [
    props.operateFileBlock,
    ohsFormSettings.useFormMethods.reset,
    ohsFormSettings.useFormMethods,
  ]);

  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleButtonAdd = (data: any) => {
    const attachments = data.attachments;
    const newAttachment = attachments.find(
      (item: any) => item.delete === false
    );
    let attachment;

    const files = data.files as File[];
    if (newAttachment && !newAttachment?._id) {
      newAttachment.file = files.find(
        (item) => item.name === newAttachment?.fileName
      );
      attachment = newAttachment;
    }

    props.handleFileFunc({
      type: OhsNoticeboardBlockType.OhsAttachment,
      typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsAttachmentFile,
      data: {
        _id:
          fileBlock && fileBlock.data._id
            ? fileBlock?.data._id
            : shortid.generate(),
        title: data.title,

        description: data.description,
        attachment: attachment as OhsAttachment,
      },
    });
    handleClose();
  };

  return (
    <>
      <OhsModal
        open={props.open}
        title={'File'}
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalForm>
              <OhsModalFormItem>
                <OhsTextField
                  type="text"
                  title="Title"
                  id="title"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem>
                <OhsAttachmentDropzone
                  title="File"
                  description="Please select one file."
                  id="attachments"
                  required
                  ohsFormSettings={ohsFormSettings}
                  maxFiles={1}
                />
              </OhsModalFormItem>

              <OhsModalFormItem>
                <OhsTextField
                  type={OhsCusvalType.Textarea}
                  title="Description"
                  id="description"
                  required={false}
                  ohsFormSettings={ohsFormSettings}
                  rows={4}
                />
              </OhsModalFormItem>
            </OhsModalForm>
          </>
        }
        actionNode={
          <>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleButtonAdd
              )}
              color={'green'}
            >
              Submit
            </Button>
          </>
        }
      />
    </>
  );
};
