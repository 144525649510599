import _ from 'lodash';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { CurrencyCodes } from '../../../global/constants/OhsConstants';
import OhsModalForm from '../../../global/form/OhsModalForm';
import OhsModalFormItem from '../../../global/form/OhsModalFormItem';
import OhsModal from '../../../global/modal/OhsModal';
import { OhsCusval, OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsSelectField from 'global/form/fields/OhsSelectField';
import OhsMultipleSelectField from 'global/form/fields/OhsMultipleSelectField';
import OhsTextArrayFields from 'global/form/fields/OhsTextArrayFields';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';

const requiredOptions = [
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' },
];

const currencyOptions = CurrencyCodes.map((item) => {
  return { name: item.code + ' - ' + item.name, value: item.code };
});

interface Props {
  open: boolean;

  setModalOpenFunc: React.Dispatch<React.SetStateAction<boolean>>;

  onClose?: () => void;
  setCusvals: React.Dispatch<React.SetStateAction<OhsCusval[]>>;
  cusvals: OhsCusval[];
  operateCusval: OhsCusval | null;
}

export const OhsNoticeBoardFormCusvalModal = (props: Props) => {
  const [cusval, setCusval] = React.useState<OhsCusval | null>(null);

  const ohsFormSettings = new OhsFormSettings(
    {
      title: cusval?.title,
      description: cusval?.description,
      required: cusval?.required,
      currencyOptions: [],
    },
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  React.useEffect(() => {
    if (props.operateCusval) {
      setCusval(props.operateCusval);
      const cusObj = { ...props.operateCusval } as any;
      if (props.operateCusval.type === OhsCusvalType.Currency) {
        cusObj.currencyOptions = props.operateCusval.options ?? [];
      } else if (
        props.operateCusval.type === OhsCusvalType.Radio ||
        props.operateCusval.type === OhsCusvalType.Checkbox ||
        props.operateCusval.type === OhsCusvalType.Select ||
        props.operateCusval.type === OhsCusvalType.MutipleSelect
      ) {
        cusObj.selectOptions = props.operateCusval.options
          ? props.operateCusval.options.map((item) => item.name)
          : [];
      }
      ohsFormSettings.useFormMethods.reset(cusObj);
    }
  }, [
    props.operateCusval,
    ohsFormSettings.useFormMethods.reset,
    ohsFormSettings.useFormMethods,
  ]);

  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleButtonAdd = (data: any) => {
    if (cusval) {
      const newCusval = { ...cusval };
      newCusval.title = data.title;
      newCusval.description = data.description;
      newCusval.required = data.requiredDisplay === 'Yes';
      newCusval.requiredDisplay = data.requiredDisplay;
      switch (cusval?.type) {
        case OhsCusvalType.Checkbox:
        case OhsCusvalType.MutipleSelect:
        case OhsCusvalType.Radio:
        case OhsCusvalType.Select:
          newCusval.options = data.selectOptions.map((item: string) => {
            return {
              name: item,
            };
          });
          break;
        case OhsCusvalType.Currency:
          newCusval.options = data.currencyOptions;
          break;

        default:
          break;
      }
      const newList = [...props.cusvals];
      const index = _.findIndex(newList, { _id: cusval._id });
      if (index > -1) {
        newList[index] = newCusval;
      } else {
        newList.push(newCusval);
      }

      props.setCusvals(newList);
    }

    handleClose();
  };

  return (
    <>
      <OhsModal
        open={props.open}
        onClose={handleClose}
        setModalOpenFunc={props.setModalOpenFunc}
        title={'Custom Field Type: ' + cusval?.typeDisplay}
        ContentNode={
          <OhsModalForm>
            <OhsModalFormItem sm={6}>
              <OhsTextField
                type="text"
                title="Title"
                id="title"
                required
                ohsFormSettings={ohsFormSettings}
              />
            </OhsModalFormItem>
            <OhsModalFormItem sm={6}>
              <OhsSelectField
                title="Required"
                id="requiredDisplay"
                required
                options={requiredOptions}
                ohsFormSettings={ohsFormSettings}
              />
            </OhsModalFormItem>
            <OhsModalFormItem>
              {cusval?.type === OhsCusvalType.Currency && (
                <OhsMultipleSelectField
                  title="Currency Options"
                  id="currencyOptions"
                  required
                  options={currencyOptions}
                  ohsFormSettings={ohsFormSettings}
                />
              )}
              {(cusval?.type === OhsCusvalType.Select ||
                cusval?.type === OhsCusvalType.MutipleSelect ||
                cusval?.type === OhsCusvalType.Checkbox ||
                cusval?.type === OhsCusvalType.Radio) && (
                <OhsTextArrayFields
                  title="Select Options"
                  id="selectOptions"
                  required={true}
                  ohsFormSettings={ohsFormSettings}
                />
              )}
            </OhsModalFormItem>

            <OhsModalFormItem>
              <OhsTextField
                type={OhsCusvalType.Textarea}
                title="Description"
                id="description"
                required={false}
                ohsFormSettings={ohsFormSettings}
                rows={4}
              />
            </OhsModalFormItem>
          </OhsModalForm>
        }
        actionNode={
          <Button
            onClick={ohsFormSettings.useFormMethods.handleSubmit(
              handleButtonAdd
            )}
            color={'green'}
          >
            Submit
          </Button>
        }
      />
    </>
  );
};
