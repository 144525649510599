import { Box, Divider, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Card, Header } from 'semantic-ui-react';
import _ from 'lodash';
import React from 'react';
import OhsModuleBackground from '../../global/register/OhsModuleBackground';
import OhsModuleHeader, {
  registerButtonStylesFunc,
} from '../../global/register/OhsModuleHeader';
import OhsModuleListBackground from '../../global/register/OhsModuleListBackground';
import {
  OhsModuleCategory,
  OhsModuleSubCategory,
} from '../../user/configurations/OhsModuleCategory';
import { loadNoticeBoardList } from '../board/register/OhsNoticeBoardRegister';
import OhsNoticeboardRightMenu from './OhsNoticeboardRightMenu';
import OhsNoticeboardItem from './OhsNoticeboardItem';
import OhsNoticeboardModal from './OhsNoticeboardModal';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import { getOhsUser } from '../../user/OhsUser';
import {
  NoticeboardForm,
  OhsFileType,
  OhsNoticeboardBlock,
  OhsNoticeboardBlockTypeDisplay,
  OhsNoticeboardContact,
  OhsNoticeBoardRecord,
  OhsNoticeBoardRecordConfig,
} from '../OhsNoticeBoardModels';
import { connect } from 'react-redux';
import {
  fetchNoticeboardDoc,
  getNoticeBoardRecordConfig,
} from '../OhsNoticeBoardServices';
import { OhsVdocRecord } from '../../vdoc/OhsVdocModels';
import OhsNoticeboardContactsModal from './OhsNoticeboardContactsModal';
import RenderOhsCusvalField from './cusvalModel/RenderOhsCusvalField';
import { OhsDropdownButton } from '../../global/buttons/dropdownWithButton/OhsDropdownButton';
import { OhsCusval } from 'global/form/cusvals/OhsCusvalModel';
import { fetchAttachmentDownloadUrl } from '../../global/attachment/OhsAttachmentServices';
interface Props {
  classes: any;
  screenWidth?: any;
}

function groupBy(seq: any, keys: any): any {
  if (!keys.length) return seq;
  var first = keys[0];
  var rest = keys.slice(1);
  return _.mapValues(_.groupBy(seq, first), function (value) {
    return groupBy(value, rest);
  });
}

class OhsNoticeboardViewList extends React.Component<Props> {
  state = {
    categoryList: new Array<OhsModuleCategory>(),
    selectedCategory: -1,
    selectedSubcategory: -1,
    selectedItem: null,
    selectedBlockTypeDisplay: OhsNoticeboardBlockTypeDisplay,
    blocks: new Array<OhsNoticeboardBlock>(),
    selectedRecord: undefined,
    uncategoryList: new Array<OhsNoticeBoardRecord>(),
    title: '',
    description: '',
    contacts: new Array<OhsNoticeboardContact>(),
    contactModalOpen: false,
    formModalOpen: false,
    contactModalElement: React.createRef(),
    formsData: [],
    selectedForm: undefined,
  };

  async componentDidMount() {
    const noticeBoardList = await loadNoticeBoardList();
    const typeList = new Array<OhsModuleCategory>();
    let uncategoryList = new Array<OhsNoticeBoardRecord>();
    // noticeBoardList.forEach((item) => {
    //   const category = _.find(typeList, { category: item.category });
    //   if (category) {
    //     if (
    //       category.subcategory &&
    //       category.subcategory.includes(item.subcategory) === false
    //     ) {
    //       category.subcategory.push(item.subcategory);
    //     }
    //   } else {
    //     typeList.push({
    //       category: item.category,
    //       subcategory: [item.subcategory],
    //       collapsed: true
    //     });
    //   }
    // });

    const noticeBoardGrouping = groupBy(noticeBoardList, [
      'category',
      'subcategory',
    ]);

    Object.keys(noticeBoardGrouping).map((i) => {
      if ('null' === i) {
        uncategoryList = uncategoryList.concat(noticeBoardGrouping[i]['null']);
      } else {
        const sortedSubCategories = Object.keys(noticeBoardGrouping[i]).sort();
        const parentCategory: OhsModuleCategory = {
          category: i,
          subcategory: sortedSubCategories,
          children: new Array<OhsModuleSubCategory>(),
          collapsed: true,
        };

        sortedSubCategories.map((j) => {
          const child: OhsModuleSubCategory = {
            category: j,
            records: noticeBoardGrouping[i][j],
            collapsed: true,
          };
          parentCategory.children.push(child);
        });
        if (typeList.length == 0) {
          parentCategory.collapsed = false;
          if (parentCategory.children.length > 0) {
            parentCategory.children[0].collapsed = false;
          }
        }
        typeList.push(parentCategory);
      }
    });
    uncategoryList = _.sortBy(uncategoryList, 'title');
    if (typeList.length === 0 && uncategoryList.length > 0) {
      this.setState({
        categoryList: typeList,
        selectedRecord: uncategoryList[0],
        uncategoryList: uncategoryList,
      });
      this.fetchBlockList(uncategoryList[0]);
    } else if (typeList.length > 0 && typeList[0].subcategory.length > 0) {
      this.setState({
        categoryList: typeList,
        selectedCategory: 0,
        selectedSubcategory: 0,
        selectedRecord: typeList[0].children[0].records[0],
        uncategoryList: uncategoryList,
      });
      this.fetchBlockList(typeList[0].children[0].records[0]);
    }
  }

  fetchBlockList = (record: OhsNoticeBoardRecord) => {
    this.setState({
      title: record.title,
      description: record.description,
      selectedRecord: record,
    });
    getNoticeBoardRecordConfig(record._id).then(
      (recordConfig: OhsNoticeBoardRecordConfig | null) => {
        if (recordConfig) {
          const blocks = (
            recordConfig.blocks ? recordConfig.blocks : []
          ).filter((record) => {
            return record.type !== 'text/html';
          });
          this.setState({
            blocks: blocks,
            contacts: recordConfig.contacts,
            formsData: recordConfig.forms,
          });
        }
      }
    );
  };

  render() {
    const { screenWidth } = this.props;

    const setCategory = (category: string, subcategory: string) => {
      this.setState({ category: category, subcategory: subcategory });
    };

    const setSelectedItem = (item: any, blockTypeDisplay: any) => {
      this.setState({
        selectedItem: item,
        selectedBlockTypeDisplay: blockTypeDisplay,
      });
    };

    const onOpen = (item: any, fileType: OhsFileType) => {
      let openUrl = '';
      switch (fileType) {
        case OhsFileType.OhsAttachmentPdf:
          fetchAttachmentDownloadUrl(item.data.attachment.download).then(
            (url: string | null) => {
              if (url)
                window.open(
                  'https://docs.google.com/gview?url=' +
                    encodeURIComponent(url),
                  '_blank'
                );
            }
          );
          break;
        case OhsFileType.OhsAttachmentWord:
        case OhsFileType.OhsAttachmentPpt:
        case OhsFileType.OhsAttachmentExcel:
          fetchAttachmentDownloadUrl(item.data.attachment.download).then(
            (url: string | null) => {
              if (url)
                window.open(
                  'https://view.officeapps.live.com/op/view.aspx?src=' +
                    encodeURIComponent(url),
                  '_blank'
                );
            }
          );
          break;
        case OhsFileType.OhsAttachmentFile:
          if (item.data.attachment) {
            fetchAttachmentDownloadUrl(item.data.attachment.download).then(
              (url: string | null) => {
                if (url) window.open(url, '_blank');
              }
            );
          } else {
            fetchNoticeboardDoc(item.data._id).then(
              (vdoc: OhsVdocRecord | null) => {
                if (vdoc) {
                  fetchAttachmentDownloadUrl(
                    vdoc.version.attachments[0].download
                  ).then((url: string | null) => {
                    if (url) window.open(url, '_blank');
                  });
                }
              }
            );
          }

          break;
        case OhsFileType.OhsAttachmentImg:
          fetchAttachmentDownloadUrl(item.data.attachment.download).then(
            (url: string | null) => {
              this.setState({
                imageUrl: url ? url : '',
              });
            }
          );
          break;
        default:
      }
    };

    const {
      selectedItem,
      selectedBlockTypeDisplay,
      contacts,
      contactModalOpen,
    } = this.state;
    const user = getOhsUser();

    const handleOpen = () => {
      this.setState({ contactModalOpen: true });
    };
    const handleClose = () => {
      this.setState({ contactModalOpen: false });
    };

    const handleSelect = (key: string) => {
      const targetForm = _.find(this.state.formsData, { _id: key }) as any;

      if (targetForm && targetForm.cusvals) {
        targetForm.cusvals.forEach((item: OhsCusval) => {
          item.value = null;
        });
        this.setState({ selectedForm: targetForm, formModalOpen: true });
      }
    };
    const handleCloseForm = async () => {
      await this.setState({ formModalOpen: false });
      return this.state.formModalOpen;
    };
    return (
      <>
        <OhsModuleBackground>
          <OhsModuleHeader
            title={user?.getCurrentURLModuleConfig()?.TITLE ?? ''}
          >
            <ButtonGroup floated="right">
              {(user?.configs.noticeboard?.PERMISSIONS.allocate ||
                user?.configs.noticeboard?.PERMISSIONS.edit ||
                user?.configs.noticeboard?.PERMISSIONS.archive ||
                user?.configs.noticeboard?.PERMISSIONS.create) && (
                <Button
                  className={this.props.classes.button}
                  color="blue"
                  onClick={() => {
                    window.location.href = '/noticeboard/admin';
                  }}
                >
                  <Icon
                    className={this.props.classes.buttonIcons}
                    name="setting"
                  />
                  <span className={this.props.classes.hideForSmall}>
                    Manage
                  </span>
                </Button>
              )}
            </ButtonGroup>
          </OhsModuleHeader>

          <Divider style={{ marginBottom: '10px' }} />
          <OhsModuleListBackground>
            <Box>
              {selectedItem && (
                <OhsNoticeboardModal
                  closeModal={() => setSelectedItem(null, null)}
                  title={_.get(selectedItem, 'data.title')}
                  item={selectedItem}
                  blockType={selectedBlockTypeDisplay}
                />
              )}
              <Grid container spacing={2}>
                <Grid item xs={screenWidth >= 1200 ? 3 : 12}>
                  <OhsNoticeboardRightMenu
                    categoryList={this.state.categoryList}
                    selectedCategory={this.state.selectedCategory}
                    selectedSubcategory={this.state.selectedSubcategory}
                    selectedRecord={this.state.selectedRecord}
                    uncategoryList={this.state.uncategoryList}
                    setCategory={setCategory}
                    selectBoard={this.fetchBlockList}
                  />
                </Grid>
                <Grid item xs={screenWidth >= 1200 ? 9 : 12}>
                  <Header as="h2">
                    {this.state.title}
                    <ButtonGroup floated="right">
                      {this.state.formsData &&
                        this.state.formsData.length > 0 &&
                        this.state.formsData.filter(
                          (item: NoticeboardForm) => item.archived !== true
                        ).length > 0 && (
                          <OhsDropdownButton
                            title="Forms"
                            handleSelect={handleSelect}
                            options={this.state.formsData
                              .filter(
                                (item: NoticeboardForm) =>
                                  item.archived !== true
                              )
                              .map((form: NoticeboardForm) => {
                                return {
                                  name: form.title,
                                  key: form._id,
                                };
                              })}
                          />
                        )}

                      {Array.isArray(this.state.contacts) &&
                      this.state.contacts.length ? (
                        <Button
                          className={this.props.classes.button}
                          onClick={handleOpen}
                        >
                          <Icon name="address card outline"></Icon>
                          Contacts
                        </Button>
                      ) : null}
                    </ButtonGroup>
                    <p style={{ fontSize: '15px', fontWeight: 'normal' }}>
                      {this.state.description}
                    </p>
                  </Header>

                  <Grid
                    container
                    spacing={2}
                    style={{
                      overflow: 'auto',
                      maxHeight: 'calc(100vh - 230px)',
                    }}
                  >
                    <Grid item xs={12}>
                      <Card.Group
                        centered={screenWidth < 1200}
                        style={{ marginBottom: '0px' }}
                      >
                        {this.state.blocks &&
                          this.state.blocks.map(function (item, index) {
                            return (
                              <OhsNoticeboardItem
                                onClick={setSelectedItem}
                                onOpen={onOpen}
                                key={index}
                                item={item}
                              />
                            );
                          })}
                      </Card.Group>
                    </Grid>
                  </Grid>
                  {contactModalOpen === true && (
                    <OhsNoticeboardContactsModal
                      open={contactModalOpen}
                      contacts={contacts}
                      closeModal={() => handleClose()}
                      title={'Contacts'}
                    />
                  )}
                  {this.state.formModalOpen && (
                    <RenderOhsCusvalField
                      selectedCategory={this.state.selectedCategory}
                      selectedSubcategory={this.state.selectedSubcategory}
                      selectedRecord={this.state.selectedRecord}
                      selectedForm={this.state.selectedForm}
                      contacts={contacts}
                      modalOpen={this.state.formModalOpen}
                      setModalOpen={(open: boolean) => {
                        this.setState({ formModalOpen: open });
                      }}
                      onClose={handleCloseForm}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </OhsModuleListBackground>
        </OhsModuleBackground>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { screenWidth: state.screenSizeReducer.screenWidth };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(registerButtonStylesFunc)(OhsNoticeboardViewList));
