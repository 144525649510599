import { getHomeUrl } from '../login/OhsLoginServices';

export default function ohsLegacyUrlParse() {
  let headUrl = window.location.href.substring(
    0,
    window.location.href.indexOf(window.location.host) +
      window.location.host.length
  );
  let bodyUrl = window.location.href.substring(
    window.location.href.indexOf(window.location.host) +
      window.location.host.length
  );
  let adjustedURL = '';
  if (bodyUrl.indexOf('/#/') === 0) {
    adjustedURL = headUrl + bodyUrl.substring(2);
    window.location.href = adjustedURL;
  } else {
    window.location.href = getHomeUrl();
  }
}
