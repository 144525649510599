import React from 'react';
import { Button } from 'semantic-ui-react';
import OhsModalForm from '../../global/form/OhsModalForm';
import OhsModalFormItem from '../../global/form/OhsModalFormItem';
import OhsModal from '../../global/modal/OhsModal';
import { OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import {
  OhsNoticeboardBlock,
  OhsNoticeboardBlockLink,
  OhsNoticeboardBlockType,
  OhsNoticeboardBlockTypeDisplay,
} from '../OhsNoticeBoardModels';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsUrlField from 'global/form/fields/OhsUrlField';
import OhsAttachmentDropzone from 'global/form/fields/OhsAttachmentDropzone';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';

const shortid = require('shortid');
interface Props {
  open: boolean;

  setModalOpenFunc: (addModalOpen: boolean) => void;

  onClose?: () => void;
  handleLinkFunc: (linkBlock: OhsNoticeboardBlockLink) => void;
  operateLinkBlock: OhsNoticeboardBlock | null;
}

export const OhsNoticeBoardLinkBlockModal = (props: Props) => {
  const [linkBlock, setLinkBlock] =
    React.useState<OhsNoticeboardBlockLink | null>(null);

  const ohsFormSettings = new OhsFormSettings(
    {
      title: '',
      description: '',
      uri: '',
      attachments: new Array<OhsAttachment>(),
    },
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  React.useEffect(() => {
    if (
      props.operateLinkBlock &&
      props.operateLinkBlock.type === OhsNoticeboardBlockType.OhsLink
    ) {
      setLinkBlock(props.operateLinkBlock);
      ohsFormSettings.useFormMethods.reset({
        title: props.operateLinkBlock.data.title,
        description: props.operateLinkBlock.data.description ?? '',
        uri: props.operateLinkBlock.data.links[0].uri,
        attachments: [props.operateLinkBlock.data.attachment],
      });
    }
  }, [
    props.operateLinkBlock,
    ohsFormSettings.useFormMethods.reset,
    ohsFormSettings.useFormMethods,
  ]);

  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleButtonAdd = (data: any) => {
    const attachments = data.attachments;
    const newAttachment = attachments.find(
      (item: any) => item.delete === false
    );

    let attachment;

    const files = data.files as File[];
    if (newAttachment && !newAttachment?._id) {
      newAttachment.file = files.find(
        (item) => item.name === newAttachment?.fileName
      );
      attachment = newAttachment;
    }

    props.handleLinkFunc({
      type: OhsNoticeboardBlockType.OhsLink,
      typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsLink,
      data: {
        _id:
          linkBlock && linkBlock.data._id
            ? linkBlock?.data._id
            : shortid.generate(),
        title: data.title,

        description: data.description,
        links: [{ title: null, uri: data.uri }],
        attachment: attachment as OhsAttachment,
      },
    });
    handleClose();
  };

  return (
    <>
      <OhsModal
        open={props.open}
        title={'Link'}
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalForm>
              <OhsModalFormItem sm={6}>
                <OhsTextField
                  type="text"
                  title="Title"
                  id="title"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsUrlField
                  title="URL"
                  id="uri"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem>
                <OhsAttachmentDropzone
                  title="Image"
                  description="Only accept image files."
                  id="attachments"
                  required
                  ohsFormSettings={ohsFormSettings}
                  acceptFiles={'image/jpeg,image/png'}
                  maxFiles={1}
                />
              </OhsModalFormItem>

              <OhsModalFormItem>
                <OhsTextField
                  type={OhsCusvalType.Textarea}
                  title="Description"
                  id="description"
                  required={false}
                  ohsFormSettings={ohsFormSettings}
                  rows={4}
                />
              </OhsModalFormItem>
            </OhsModalForm>
          </>
        }
        actionNode={
          <>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleButtonAdd
              )}
              color={'green'}
            >
              Submit
            </Button>
          </>
        }
      />
    </>
  );
};
