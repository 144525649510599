import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import OhsAngularIframe from '../global/angular-iframe/OhsAngularIframe';
import OhsRequireAuth from '../global/OhsRequireAuth';
import OhsDashboardReports from './dashboardReports';

export default function OhsReportingHome() {
  const { pathname } = useLocation();
  return (
    <div>
      <Routes>
        <Route
          path={`dashboardReports/:tokenType`}
          element={
            <OhsRequireAuth>
              <OhsDashboardReports />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`dashboardReports`}
          element={
            <OhsRequireAuth>
              <OhsDashboardReports />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`orgReports`}
          element={
            <OhsRequireAuth>
              <OhsDashboardReports />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`orgAdminReports`}
          element={
            <OhsRequireAuth>
              <OhsDashboardReports />
            </OhsRequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </div>
  );
}
