export enum OhsUrlQueryParamType {
  OperationType = 'op',
  TabType = 'tab',
  RecordType = 'type',
}

export enum OhsUrlQueryParamOperationType {
  Allocation = 'al',
  Assign = 'as',
  AllocationAndAssign = 'al-as',
}
