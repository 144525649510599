import React from 'react';
import OhsRegister from '../../../global/register/OhsRegister';
import { OhsNoticeBoardRecord } from '../../OhsNoticeBoardModels';
import {
  OhsAllocatableRecord,
  OhsAllocationTierRecord,
} from '../../../safety-plan/OhsSafetyPlanModels';
import OhsAllocationModal from '../../../global/modal/OhsAllocationModal';
import {
  getNoticeBoardAllocationList,
  allocateNoticeBoard,
  deallocateNoticeBoard,
  archiveNoticeBoard,
} from '../../OhsNoticeBoardServices';
import { OhsRegisterRecord } from '../../../global/register/table/OhsRegisterModels';
import { getOhsUser } from '../../../user/OhsUser';

type Props = {
  noticeBoardList: Array<OhsNoticeBoardRecord>;
  reloadNoticeBoardList: any;
};
export default function OhsNoticeBoardList(props: Props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [allocationList, setAllocationList] = React.useState(
    new Array<OhsAllocationTierRecord>()
  );
  const [noticeBoardIdOperated, setNoticeBoardIdOperated] = React.useState('');
  const [noticeBoardNameOperated, setNoticeBoardNameOperated] =
    React.useState('');

  const [noticeBoardList, setNoticeBoardList] = React.useState(
    props.noticeBoardList
  );

  React.useEffect(() => {
    setNoticeBoardList(props.noticeBoardList);
  }, [props.noticeBoardList]);

  const openAllocationModalFunc = async (item: OhsAllocatableRecord) => {
    setNoticeBoardIdOperated(item._id);
    await loadAlocationList(item._id);
    setModalTitle('Allocate Noticeboard: ' + item.title);
    setModalOpen(true);
  };

  const user = getOhsUser();

  const loadAlocationList = async (id: string) => {
    return getNoticeBoardAllocationList(id).then(
      (allocationRecordList: OhsAllocationTierRecord[]) => {
        setAllocationList(allocationRecordList);
        return allocationRecordList;
      }
    );
  };

  const onModalClose = async () => {
    // props.reloadNoticeBoardList();
    window.location.reload();
  };

  const viewFunc = (item: OhsRegisterRecord) => {
    let url = `/noticeboard/${item._id}/view?tab=resources`;
    window.open(url, '_self');
  };

  const archiveFunc = async (
    item: OhsAllocatableRecord,
    isArchive: boolean
  ) => {
    const archiveNoticeBoardRequest = await archiveNoticeBoard(
      item._id,
      isArchive
    );
    if (archiveNoticeBoardRequest) window.location.reload();
  };
  const columnconfig = [
    {
      id: 'titleId',
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Subcategory',
      accessor: 'subcategory',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
  ];
  if (user?.tierNum === 3) {
    columnconfig.push({
      Header: 'Workplaces',
      accessor: 'tier.name',
    });
  }

  const columns = React.useMemo(() => columnconfig, [columnconfig]);

  return (
    <>
      <OhsRegister
        columns={columns}
        data={noticeBoardList}
        sortBy={[{ id: 'titleId', desc: false }]}
        hasFilters="statusfilter"
        hasExpanding={user?.tierNum === 3}
        withCategoryGroupings={false}
        rowOperations={{
          viewFunc: viewFunc,
          openAllocationModalFunc: openAllocationModalFunc,
          archiveButton: {
            func:
              user?.tierNum === 3 || user?.tierNum === 4
                ? archiveFunc
                : undefined,
            permission: user?.configs.noticeboard?.PERMISSIONS.archive === true,
          },
        }}
      ></OhsRegister>
      <OhsAllocationModal
        title={modalTitle}
        id={noticeBoardIdOperated}
        open={modalOpen}
        allocationList={allocationList}
        loadAlocationList={loadAlocationList}
        setAllocationList={setAllocationList}
        allocationFunc={allocateNoticeBoard}
        deAllocationFunc={deallocateNoticeBoard}
        setModalOpen={setModalOpen}
        onClose={onModalClose}
        hasDueDate={false}
      />
    </>
  );
}
