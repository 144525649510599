import React from 'react';
import { Button } from 'semantic-ui-react';
import OhsModalForm from '../../global/form/OhsModalForm';
import OhsModalFormItem from '../../global/form/OhsModalFormItem';
import OhsModal from '../../global/modal/OhsModal';
import { getOhsUser } from '../../user/OhsUser';
import { OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import { OhsNoticeboardContact } from '../OhsNoticeBoardModels';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsEmailField from 'global/form/fields/OhsEmailField';
import OhsSelectField from 'global/form/fields/OhsSelectField';

interface Props {
  open: boolean;

  setModalOpenFunc: (addModalOpen: boolean) => void;

  onClose?: () => void;
  handleContactFunc: (contact: OhsNoticeboardContact) => void;
  operateContact: OhsNoticeboardContact | null;
}

export const OhsNoticeBoardContactModal = (props: Props) => {
  const [contact, setContact] = React.useState<OhsNoticeboardContact | null>(
    null
  );

  const ohsFormSettings = new OhsFormSettings(
    {
      name: '',
      role: '',
      roleOther: '',
      email: '',
      phone: '',
      notes: '',
    },
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  React.useEffect(() => {
    if (props.operateContact) {
      setContact(props.operateContact);
      ohsFormSettings.useFormMethods.reset({
        name: props.operateContact.name,
        role: props.operateContact.role ?? '',
        roleOther: props.operateContact.roleOther ?? '',
        email: props.operateContact.emails[0],
        phone: props.operateContact.phones[0],
        notes: props.operateContact.notes,
      });
    }
  }, [props.operateContact, ohsFormSettings.useFormMethods]);

  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleButtonAdd = (data: any) => {
    props.handleContactFunc({
      name: data.name,
      role: data.role,
      roleOther: data.roleOther,
      emails: [data.email ?? ''],
      phones: [data.phone ?? ''],
      notes: data.notes,
    });
    handleClose();
  };
  const user = getOhsUser();
  const titleList = user?.configs.noticeboard?.CONTACT_ROLE_LIST;
  const [role]: [string] = ohsFormSettings.useFormMethods.watch(['role']);

  return (
    <>
      <OhsModal
        open={props.open}
        title={'Contact'}
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalForm>
              <OhsModalFormItem>
                <OhsTextField
                  type="text"
                  title="Contact Name"
                  id="name"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsTextField
                  type="text"
                  title="Contact Telephone"
                  id="phone"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsEmailField
                  title="Contact Email"
                  id="email"
                  required={true}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsSelectField
                  id="role"
                  title="Role"
                  required={false}
                  options={
                    titleList?.map((item) => {
                      return { name: item.title, value: item.title };
                    }) ?? []
                  }
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                {role && role.toLocaleLowerCase() === 'other' && (
                  <OhsTextField
                    type="text"
                    title="Other"
                    id="roleOther"
                    required={false}
                    ohsFormSettings={ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>

              <OhsModalFormItem>
                <OhsTextField
                  type={OhsCusvalType.Textarea}
                  title="Description"
                  id="notes"
                  required={false}
                  ohsFormSettings={ohsFormSettings}
                  rows={4}
                />
              </OhsModalFormItem>
            </OhsModalForm>
          </>
        }
        actionNode={
          <>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleButtonAdd
              )}
              color={'green'}
            >
              Submit
            </Button>
          </>
        }
      />
    </>
  );
};
