import React from 'react';

import { getOhsUser } from '../../../../user/OhsUser';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import { TierType } from '../../../../models/tier/OhsTier';

import OhsAllocationButtons from './OhsAllocationButtons';
import OhsCopyButtons from './OhsCopyButtons';
import { RowOperations } from '../../OhsRegister';
import { handleModuleRecordArchive } from './OhsRowOperationServices';
import { registerButtonStyles } from '../../OhsModuleHeader';
import { Box } from '@mui/material';
import { OhsLeaveButton } from '../../../buttons/OhsLeaveButton';
import { OhsExportButton } from '../../../buttons/OhsExportButton';

interface Props {
  row: any;
  rowOperations?: RowOperations;
}
const OhsRegisterTableColOperationButtons = ({ row, rowOperations }: Props) => {
  const [rowOperationsWidth, setRowOperationsWidth] = React.useState('');
  const { classes } = registerButtonStyles();
  const user = getOhsUser();
  const moduleConfig = user?.getCurrentURLModuleConfig();
  const ref = React.useRef<HTMLDivElement>(null);

  async function handleArchive(isArchive: boolean, id: string) {
    if (moduleConfig?.TYPE)
      await handleModuleRecordArchive(moduleConfig?.TYPE, isArchive, id);
    window.location.reload();
  }

  React.useEffect(() => {
    // get the current width of 'combined rowOperations' buttons
    const rowColWidth = ref.current ? ref.current.offsetWidth + 10 : 0;
    // do not set width if only 1 or 2 action buttons
    if (Object.keys(rowOperations ?? {}).length > 2) {
      setRowOperationsWidth(rowColWidth.toString());
    }
  }, [ref.current]);

  return (
    <Box ref={ref} sx={{ minWidth: rowOperationsWidth + 'px' }}>
      {rowOperations && (
        <ButtonGroup
          size="tiny"
          floated="right"
          compact={false}
          className={classes.buttonGroup}
        >
          {rowOperations.viewFunc && (
            <Button
              onClick={() => {
                if (rowOperations.viewFunc)
                  rowOperations.viewFunc(row.original);
              }}
              className={classes.button}
              color="blue"
            >
              View
            </Button>
          )}
          {rowOperations.viewButton &&
            rowOperations.viewButton.permission &&
            rowOperations.viewButton.permission === true && (
              <Button
                onClick={() => {
                  if (rowOperations.viewButton)
                    rowOperations.viewButton.func(row.original);
                }}
                className={classes.button}
                color="blue"
              >
                {rowOperations.viewButton.label !== ''
                  ? rowOperations.viewButton.label
                  : 'View'}
              </Button>
            )}
          {rowOperations.editButton &&
            rowOperations.editButton?.func &&
            rowOperations.editButton.permission === true &&
            (rowOperations.editButton.showFunc == null ||
              (rowOperations.editButton.showFunc &&
                rowOperations.editButton.showFunc(row.original))) &&
            row.original.allocationOf == null && (
              <Button
                onClick={() => {
                  rowOperations.editButton?.func(row.original, row.index);
                }}
                className={classes.button}
                color="blue"
              >
                <Icon className={classes.buttonIcons} name="pencil" />
                <span className={classes.hideForSmall}>Edit</span>
              </Button>
            )}
          {rowOperations.openAllocationModalFunc &&
            row.original.tier &&
            row.original.tier.type === TierType.T3 &&
            row.original.archived !== true &&
            moduleConfig?.PERMISSIONS.allocate === true &&
            row.original.allocationOf == null && (
              <Button
                onClick={() => {
                  if (rowOperations.openAllocationModalFunc)
                    rowOperations.openAllocationModalFunc(row.original);
                }}
                className={classes.button}
                size="small"
                color="green"
              >
                <Icon className={classes.buttonIcons} name="retweet" />
                <span className={classes.hideForSmall}>Allocate</span>
              </Button>
            )}
          {rowOperations.archiveButton &&
            rowOperations.archiveButton.func &&
            rowOperations.archiveButton.permission === true &&
            user?.tier.type === row.original.tier.type &&
            row.depth === 0 &&
            row.original.allocationOf == null && (
              <Button
                onClick={() => {
                  if (rowOperations.archiveButton?.func)
                    rowOperations.archiveButton?.func(
                      row.original,
                      row.original.archived !== true
                    );
                }}
                className={classes.button}
                color={row.original.archived !== true ? undefined : 'green'}
              >
                <Icon className={classes.buttonIcons} name="archive" />
                <span className={classes.hideForSmall}>
                  {row.original.archived !== true ? 'Archive' : 'Activate'}
                </span>
              </Button>
            )}
          {rowOperations.assignButton &&
            rowOperations.assignButton.func &&
            rowOperations.assignButton.permission === true &&
            row.depth === 0 && (
              <Button
                onClick={() => {
                  if (rowOperations.assignButton?.func)
                    rowOperations.assignButton?.func(
                      row.original,
                      row.original.assigned !== true
                    );
                }}
                className={classes.button}
                color={row.original.assigned === true ? undefined : 'teal'}
                loading={row.original.loading === true}
              >
                <Icon className={classes.buttonIcons} name="user" />
                <span className={classes.hideForSmall}>
                  {row.original.assigned === true ? 'Unassign' : 'Assign'}
                </span>
              </Button>
            )}
          {rowOperations.allocationFunc && rowOperations.deAllocationFunc && (
            <OhsAllocationButtons
              row={row}
              allocationFunc={rowOperations.allocationFunc}
              deAllocationFunc={rowOperations.deAllocationFunc}
            />
          )}
          {rowOperations.copyFunc && (
            <OhsCopyButtons row={row} copyFunc={rowOperations.copyFunc} />
          )}
          {rowOperations.leaveButton?.func && row.depth === 0 && (
            <OhsLeaveButton
              onClick={() => {
                if (
                  rowOperations &&
                  rowOperations.leaveButton &&
                  rowOperations.leaveButton?.func
                )
                  rowOperations.leaveButton?.func(row);
              }}
            />
          )}
          {rowOperations.exportButton?.func && row.depth === 0 && (
            <OhsExportButton
              onClick={() => {
                if (
                  rowOperations &&
                  rowOperations.exportButton &&
                  rowOperations.exportButton?.func
                )
                  rowOperations.exportButton?.func(row);
              }}
            />
          )}
          {rowOperations.openDeleteModalButton &&
            ((row.original.tier &&
              user?.tier.type === row.original.tier.type &&
              rowOperations.openDeleteModalButton.permission === true) ||
              row.original.tier == null) &&
            row.depth === 0 &&
            row.original.allocationOf == null && (
              <Button
                icon={true}
                onClick={() => {
                  if (rowOperations.openDeleteModalButton?.func)
                    rowOperations.openDeleteModalButton?.func(
                      row.original,
                      row.index
                    );
                }}
                className={classes.button}
                color={'red'}
              >
                <Icon className={classes.buttonIcons} name="trash" />
              </Button>
            )}
          {rowOperations.inspectionButton && (
            <Button
              onClick={() => {
                if (rowOperations.inspectionButton)
                  rowOperations.inspectionButton.func(row.original);
              }}
              color="green"
            >
              <Icon className={classes.buttonIcons} name="pencil alternate" />
              <span className={classes.hideForSmall}>
                Go to {user?.configs.inspection?.TITLE}
              </span>
            </Button>
          )}
        </ButtonGroup>
      )}
    </Box>
  );
};

export default OhsRegisterTableColOperationButtons;
