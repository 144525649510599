import { OhsUser } from '../../../user/OhsUser';
import { styled, TableCell } from '@mui/material';

const user = new OhsUser();
interface Props {
  column?: any;
}
const OhsRegisterTableHeader = styled(TableCell)((props: Props) => {
  const { hideheader, headerAlign } = props.column ?? {};
  return {
    backgroundColor: user.configs.theme.HEADER_BAR_BG_COLOR,
    color: user.configs.theme.HEADER_BAR_TEXT_COLOR,
    fontSize: '1em',
    fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
    fontWeight: 700,
    paddingBottom: '0.928571em',
    paddingTop: '0.928571em',
    whiteSpace: 'nowrap',
    ...(hideheader === true && { display: 'none' }),
    textAlign: headerAlign ? headerAlign : 'left',
  };
});

export default OhsRegisterTableHeader;
