import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getOhsUser } from '../../../../user/OhsUser';
import OhsRegisterTableRow from '../OhsRegisterTableRow';
import OhsRegisterTableBodyCell from '../OhsRegisterTableBodyCell';
import { Checkbox } from 'semantic-ui-react';
import { TierType } from '../../../../models/tier/OhsTier';
import { Box } from '@mui/material';
import { OhsAllocStatusFilter, OhsStatusFilterOption } from './OhsFilterTypes';

const user = getOhsUser();

export const AllocationStatusFilter = (
  rows: any,
  filler: any,
  filterValue: any
): any => {
  let newList = new Array<any>();
  rows.forEach((val: any) => {
    const getAllocData = val.original.allocated;
    if (filterValue.includes(OhsAllocStatusFilter.Allocated) === true) {
      if (getAllocData) newList.push(val);
    }

    if (filterValue.includes(OhsAllocStatusFilter.NotAllocated) === true) {
      if (!getAllocData) newList.push(val);
    }
  });

  return newList;
};

export const StatusFilter = (rows: any, filler: any, filterValue: any): any => {
  const arr: any[] = [];
  rows.forEach((val: any) => {
    if (
      filterValue.includes(OhsStatusFilterOption.Allocated) === true &&
      val.original.allocationStatus === OhsStatusFilterOption.Allocated
    ) {
      arr.push(val);
    }

    if (
      filterValue.includes(OhsStatusFilterOption.NotAllocated) === true &&
      val.original.allocationStatus === OhsStatusFilterOption.NotAllocated
    ) {
      arr.push(val);
    }

    if (
      filterValue.includes(OhsStatusFilterOption.WorkplaceOwned) === true &&
      val.original.allocationStatus === OhsStatusFilterOption.WorkplaceOwned
    ) {
      arr.push(val);
    }
  });

  return arr;
};

const setFilteredParams = (filterArr: any, val: any) => {
  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n: any) => {
      return n !== val;
    });
  } else filterArr.push(val);

  if (filterArr.length === 0) filterArr = undefined;
  return filterArr;
};

interface ColumnTypes {
  Header: string;
  filterValue: any[];
  setFilter: any;
  preFilteredRows: any;
  id: any;
}
interface Props {
  data: ColumnTypes;
  statusList: any[];
}

const OhsRegisterTableStatusFilter = ({
  data: { Header, filterValue = [], setFilter, preFilteredRows, id },
  statusList,
}: Props) => {
  return (
    <>
      <OhsRegisterTableRow>
        <OhsRegisterTableBodyCell align={'center'} colSpan={100}>
          {statusList.map((option: any, index: number) => {
            return option.permission.includes(user?.tierNum) ? (
              <span style={{ marginRight: '1em' }} key={index}>
                <Checkbox
                  id={option.value}
                  label={option.label}
                  value={option.value}
                  checked={filterValue.includes(option.value)}
                  onChange={(e: any) => {
                    setFilter(setFilteredParams(filterValue, option.value));
                  }}
                />
                <span style={{ marginLeft: '0.5em' }}>
                  <FiberManualRecordIcon
                    fontSize={'small'}
                    style={{
                      fill: option.iconColor,
                      verticalAlign: 'text-top',
                    }}
                  ></FiberManualRecordIcon>
                </span>
              </span>
            ) : (
              ''
            );
          })}
        </OhsRegisterTableBodyCell>
      </OhsRegisterTableRow>
    </>
  );
};

export default OhsRegisterTableStatusFilter;
