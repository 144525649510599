import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleTopBanner } from '../dashboard/OhsDashboardSlice';
import OhsAngularIframe from '../global/angular-iframe/OhsAngularIframe';
import { OhsTaskLinkRedirect } from './link/OhsTaskLinkRedirect';
import { OhsTaskRegisterHome } from './register/OhsTaskRegisterHome';
import { isMobileDevice } from '../global/utils/OhsMobileServices';
import { OhsTaskLinkMobileForward } from './link/OhsTaskLinkMobileForward';
import OhsRequireAuth from '../global/OhsRequireAuth';

const OhsTaskRoutes = (props: { toggleTopBanner: (show: boolean) => void }) => {
  useEffect(() => {
    props.toggleTopBanner(true);
    return () => props.toggleTopBanner(false);
  }, [props]);

  const isMobileBoolean = isMobileDevice();

  return (
    <React.Suspense fallback={null}>
      <Routes>
        {isMobileBoolean === true ? (
          <Route
            path={`link/redirect`}
            element={<OhsTaskLinkMobileForward />}
          />
        ) : (
          <Route
            path={`link/redirect`}
            element={
              <OhsRequireAuth>
                <OhsTaskLinkRedirect />
              </OhsRequireAuth>
            }
          />
        )}

        <Route
          path={`active`}
          element={
            <OhsRequireAuth>
              <OhsTaskRegisterHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </React.Suspense>
  );
};

export default connect(null, { toggleTopBanner })(OhsTaskRoutes);
