import React from 'react';
import { Row } from 'react-table';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import OhsDeleteModal from '../../global/modal/OhsDeleteModal';
import OhsModuleHeader, {
  registerButtonStyles,
} from '../../global/register/OhsModuleHeader';
import OhsRegister from '../../global/register/OhsRegister';
import { getOhsUser } from '../../user/OhsUser';
import {
  OhsNoticeboardContact,
  OhsNoticeBoardRecordConfig,
} from '../OhsNoticeBoardModels';
import { editNoticeBoardRecordConfig } from '../OhsNoticeBoardServices';
import { OhsNoticeBoardContactModal } from './OhsNoticeBoardContactModal';

interface Props {
  noticeboardContacts: OhsNoticeboardContact[];
  noticeBoardConfig: OhsNoticeBoardRecordConfig | null;
}

export const OhsNoticeBoardContactList = (props: Props) => {
  const [noticeboardContacts, setNoticeboardContacts] = React.useState(
    new Array<OhsNoticeboardContact>()
  );
  const [operateContact, setOperateContact] =
    React.useState<OhsNoticeboardContact | null>(null);
  const [contactModalOpen, setContactModalOpen] = React.useState(false);
  const [operateIndex, setOperateIndex] = React.useState(-1);
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [rows, setRows] = React.useState(new Array<Row<any>>());

  React.useEffect(() => {
    setNoticeboardContacts(props.noticeboardContacts);
  }, [props.noticeboardContacts]);
  const columns = React.useMemo(
    () => [
      {
        id: 'nameId',
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Phone',
        accessor: 'phones[0]',
      },
      {
        Header: 'Email',
        accessor: 'emails[0]',
      },
      {
        Header: 'Description',
        accessor: 'notes',
      },
    ],
    []
  );
  const editFunc = (item: OhsNoticeboardContact, index?: number) => {
    if (index !== undefined && index >= 0) {
      setOperateContact(item);
      setOperateIndex(index);
      setContactModalOpen(true);
    }
  };
  const openDeleteModalFunc = (item: any, index?: number) => {
    if (index != null) {
      setOperateIndex(index);
      setOperateContact(item);
      setDelModalOpen(true);
    }
  };
  const { classes } = registerButtonStyles();

  const saveContactList = async () => {
    const contact = new Array<OhsNoticeboardContact>();
    rows.forEach((item: any) => {
      contact.push(item.original);
    });

    if (props.noticeBoardConfig) {
      const config = { ...props.noticeBoardConfig };
      config.contacts = contact;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) window.location.reload();
    }
  };
  const handleContact = async (contact: OhsNoticeboardContact) => {
    if (!contact.role) {
      contact.role = null;
    }
    if (!contact.roleOther) {
      contact.roleOther = null;
    }
    const list = [...noticeboardContacts];
    if (operateIndex > -1) {
      list[operateIndex] = contact;
    } else {
      list.push(contact);
    }
    // setNoticeboardContacts(list);
    setOperateIndex(-1);
    if (props.noticeBoardConfig) {
      const config = { ...props.noticeBoardConfig };
      config.contacts = list;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) window.location.reload();
    }
  };
  const handleDeleteFunc = async () => {
    const list = [...noticeboardContacts];
    list.splice(operateIndex, 1);
    setNoticeboardContacts(list);

    if (props.noticeBoardConfig) {
      const config = { ...props.noticeBoardConfig };
      config.contacts = list;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) {
        // close delete modal and refresh if successfull delete
        setDelModalOpen(false);
        window.location.reload();
      } else {
        setDelModalOpen(true);
      }
    }
  };
  const user = getOhsUser();
  return (
    <>
      <OhsModuleHeader title="Contact Register">
        <ButtonGroup floated="right">
          <Button
            color="green"
            className={classes.button}
            onClick={() => {
              setOperateContact({
                name: '',
                role: '',
                roleOther: '',
                emails: [''],
                phones: [''],
                notes: '',
              });
              setContactModalOpen(true);
            }}
          >
            <Icon name="plus" className={classes.buttonIcons} />
            <span className={classes.hideForSmall}>Contact</span>
          </Button>
          <Button
            className={classes.button}
            style={{ height: '36px' }}
            color="blue"
            onClick={() => {
              saveContactList();
            }}
          >
            <Icon className={classes.buttonIcons} name="save" />
            <span className={classes.hideForSmall}>Save</span>
          </Button>
        </ButtonGroup>
      </OhsModuleHeader>
      <OhsRegister
        columns={columns}
        data={noticeboardContacts}
        setRows={setRows}
        rowOperations={{
          editButton: {
            func: editFunc,
            permission: user?.configs.noticeboard?.PERMISSIONS.edit === true,
          },
          openDeleteModalButton: {
            func: openDeleteModalFunc,
            permission: user?.configs.noticeboard?.PERMISSIONS.edit === true,
          },
        }}
        noPagination={true}
        enableDragDrop={true}
      ></OhsRegister>
      <OhsNoticeBoardContactModal
        setModalOpenFunc={setContactModalOpen}
        open={contactModalOpen}
        handleContactFunc={handleContact}
        operateContact={operateContact}
      />
      <OhsDeleteModal
        open={delModalOpen}
        setModalOpenFunc={setDelModalOpen}
        moduleTitle={'Resource'}
        handleDeleteFunc={handleDeleteFunc}
        recordName={operateContact?.name ?? ''}
      />
    </>
  );
};
