import { ListItem, ListItemProps, ListItemText } from '@mui/material';
import React from 'react';
import getMixpanelTraker from '../../common/mixpanel/OhsMixpanel';
import { openAngularIframeModal } from '../../global/angular-iframe/OhsAngularIframeServices';
import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from './OhsLeftSideDrawerModuleList';
export function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export const OhsLeftSideDrawerFreeUserModuleList = () => {
  const user = new OhsUser();
  const moduleHome = ModuleHomeUrl[user.tier.type] as any;
  const openPaidPlanAngularModal = (moduleName: string) => {
    try {
      getMixpanelTraker()?.track('Unpaid Module Select', {
        'Module Name': moduleName,
      });
    } catch (e) {
      console.error(e);
    }

    openAngularIframeModal('unavailableModulesModal', null);
  };

  return (
    <div>
      {user.tierNum === 2 && (
        <ListItemLink button href="/onboardings/home" dense={true}>
          <ListItemText primary="Onboardings" />
        </ListItemLink>
      )}
      <ListItemLink button href={moduleHome['action']} dense={true}>
        <ListItemText primary="Action" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Chemical');
        }}
        dense={true}
      >
        <ListItemText primary="Chemical - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Communication');
        }}
        dense={true}
      >
        <ListItemText primary="Communication - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Contractor');
        }}
        dense={true}
      >
        <ListItemText primary="Contractor - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Data Analytics');
        }}
        dense={true}
      >
        <ListItemText primary="Data Analytics - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Document');
        }}
        dense={true}
      >
        <ListItemText primary="Document - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Equipment Register');
        }}
        dense={true}
      >
        <ListItemText primary="Equipment Register - PAID PLAN SOON" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Human Resources');
        }}
        dense={true}
      >
        <ListItemText primary="Human Resources - PAID PLAN" />
      </ListItemLink>
      <ListItemLink button href={moduleHome['incident']} dense={true}>
        <ListItemText primary="Incident" />
      </ListItemLink>
      <ListItemLink button href={moduleHome['inspection']} dense={true}>
        <ListItemText primary="Inspection" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Insurance');
        }}
        dense={true}
      >
        <ListItemText primary="Insurance - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Injury Management');
        }}
        dense={true}
      >
        <ListItemText primary="Injury Management - PAID PLAN SOON" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Noticeboard');
        }}
        dense={true}
      >
        <ListItemText primary="Noticeboard - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Risk Management');
        }}
        dense={true}
      >
        <ListItemText primary="Risk Management - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Training');
        }}
        dense={true}
      >
        <ListItemText primary="Training - PAID PLAN" />
      </ListItemLink>
      <ListItemLink button href={moduleHome['safetyplan']} dense={true}>
        <ListItemText primary="Safety Plan" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Visitor');
        }}
        dense={true}
      >
        <ListItemText primary="Visitor - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanAngularModal('Worker Records');
        }}
        dense={true}
      >
        <ListItemText primary="Worker Records - PAID PLAN" />
      </ListItemLink>
    </div>
  );
};
