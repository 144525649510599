import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { OhsApiErrorSet, OhsApiResponseType } from './models/OhsApiModels';

export const handleApiResponseError = (
  response: AxiosResponse<any>,
  defaultErrorMessage: string,
  extraErrors?: OhsApiErrorSet[]
) => {
  let hasTargetError: boolean = false;
  if (extraErrors)
    extraErrors.forEach((item) => {
      if (response && JSON.stringify(response).indexOf(item.code) > 0) {
        hasTargetError = true;
        toast.error(item.message);
      }
    });

  if (hasTargetError === false) toast.error(defaultErrorMessage);
  console.error(response);
};

export const OhsApiResponse = (response: any): OhsApiResponseType<any> => {
  return {
    success: response.success,
    ...(response.result && { data: response.result }),
    ...(response.error && { error: response.error }),
  };
};
