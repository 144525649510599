import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';

export class OhsFileBoxPermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  delete: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.delete = flag;
  }
}
export class OhsFileBoxConfig {
  TITLE: string;
  PERMISSIONS: OhsFileBoxPermissions;
  MORPH_PERMISSIONS: OhsFileBoxPermissions;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsFileBoxPermissions(false);
    this.MORPH_PERMISSIONS = new OhsFileBoxPermissions(false);

    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(
      this.MORPH_PERMISSIONS,
      getConfigValue(config, 'PERMISSIONS')
    );
  }
}
