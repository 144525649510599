import * as _ from 'lodash';
import React from 'react';

import { Button } from 'semantic-ui-react';
import { getOhsUser } from '../../user/OhsUser';
import { useForm } from 'react-hook-form';
import OhsModalForm from '../../global/form/OhsModalForm';
import OhsModalFormItem from '../../global/form/OhsModalFormItem';
import OhsTextField from 'global/form/fields/OhsTextField';
import {
  FrequencyOptions,
  OhsCusvalType,
} from 'global/form/cusvals/OhsCusvalModel';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import {
  addEditSafetyPlan,
  getSafetyPlanAllocationList,
  allocateSafetyPlan,
  deallocateSafetyPlan,
} from '../OhsSafetyPlanServices';
import { getCurrentTimezone } from '../../common/OhsDataParse';
import OhsAllocationModal from '../../global/modal/OhsAllocationModal';
import { OhsAllocationTierRecord } from '../OhsSafetyPlanModels';
import OhsSelectField from 'global/form/fields/OhsSelectField';
import OhsModal from '../../global/modal/OhsModal';

interface Props {
  open: boolean;
  isEdit: boolean;

  setModalOpenFunc: (addModalOpen: boolean) => void;

  onClose?: () => void;
}

function OhsSafetyPlanAddEditModal(props: Props) {
  const user = getOhsUser();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [allocationList, setAllocationList] = React.useState(
    new Array<OhsAllocationTierRecord>()
  );
  const [safetyPlanIdOperated, setSafetyPlanIdOperated] = React.useState('');

  const ohsFormSettings = new OhsFormSettings(
    {},
    OhsFormLayoutType.AngularModalForm
  );

  const loadAlocationList = async (id: string) => {
    return getSafetyPlanAllocationList(id).then(
      (allocationRecordList: OhsAllocationTierRecord[]) => {
        setAllocationList(allocationRecordList);
        return allocationRecordList;
      }
    );
  };

  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleButtonSubmit = async (data: any) => {
    data['tzDateCreated'] = getCurrentTimezone();
    props.setModalOpenFunc(false);
    await addEditSafetyPlan(data);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleButtonSubmitAllocate = async (data: any) => {
    data['tzDateCreated'] = getCurrentTimezone();
    props.setModalOpenFunc(false);
    const sp = await addEditSafetyPlan(data);
    if (props.onClose) {
      props.onClose();
    }
    setSafetyPlanIdOperated(sp._id);
    await loadAlocationList(sp._id);
    setModalTitle('Allocate Task: ' + sp.name);
    setModalOpen(true);
  };
  const onModalClose = async () => {};

  return (
    <>
      <OhsModal
        open={props.open}
        title={
          props.isEdit === true
            ? 'Edit '
            : 'Create ' + user?.configs.safetyplan?.TITLE
        }
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalForm>
              <OhsModalFormItem>
                <OhsTextField
                  type="text"
                  title="Name"
                  id="name"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsTextField
                  type="number"
                  title="Frequency"
                  id="frequencyValue"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsSelectField
                  id="frequencyKey"
                  required
                  options={FrequencyOptions}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem>
                <OhsTextField
                  type={OhsCusvalType.Textarea}
                  title="Details"
                  id="description"
                  required
                  ohsFormSettings={ohsFormSettings}
                  rows={4}
                />
              </OhsModalFormItem>
            </OhsModalForm>
          </>
        }
        actionNode={
          <>
            {' '}
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleButtonSubmit
              )}
              color={'green'}
            >
              Submit
            </Button>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleButtonSubmitAllocate
              )}
              color={'green'}
            >
              Submit & Allocate
            </Button>
          </>
        }
      />

      <OhsAllocationModal
        title={modalTitle}
        id={safetyPlanIdOperated}
        open={modalOpen}
        allocationList={allocationList}
        loadAlocationList={loadAlocationList}
        setAllocationList={setAllocationList}
        allocationFunc={allocateSafetyPlan}
        deAllocationFunc={deallocateSafetyPlan}
        setModalOpen={setModalOpen}
        onClose={onModalClose}
      />
    </>
  );
}

export default OhsSafetyPlanAddEditModal;
