import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { isLoginFn } from './OhsLoginSlice';
import {
  loginMultiUser,
  loginUser,
  handleLoginResponse,
  getModuleConfigValue,
  updateUseOrgDetails,
  OhsUserResponseTypes,
} from '../user/UserServices';
import { setLocalStorage } from '../common/OhsDataParse';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getHomeUrl } from './OhsLoginServices';
import { useLocation, useNavigate } from 'react-router-dom';

import OhsMultiLoginPlaceDialog from './OhsMultiLoginPlaceDialog';
import { OhsMultiUserLoginOption } from './OhsMultiUserLoginOption';
import { connect } from 'react-redux';
import OhsMfaModal from './mfa/OhsMfaModal';
import { mfaDetails } from './mfa/OhsMfaModels';
import OhsMfaMandatoryMessageModal from '../user/user-settings/mfa/modal/OhsMfaMandatoryMessageModal';
import OhsMfaSettingsOnLoginModal, {
  MfaUserDetailsTypes,
} from './OhsMfaSettingsOnLoginModal';
import { ModuleType } from '../models/OhsObject';
import {
  AdminSwitchOptionMap,
  getMfaStatusStringVal,
} from '../user/configurations/OhsAdmin2Config';
import OhsMfaRecoveryCodeModal from './mfa/OhsMfaRecoveryCodeModal';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '50%',
  },
}));

type Inputs = {
  email: string;
  password: string;
};

let customLoginRedirect = '';

const OhsLoginForm = (props: any) => {
  const [open, setOpen] = useState(false);
  const [openMfa, setMfaOpen] = useState(false);
  const [mfaDetails, setMfaDetails] = useState<mfaDetails | null>();
  const [selectedLocationUserId, setSelectedLocationUserId] = useState('');
  const [userOptions, setUserOptions] = useState(
    new Array<OhsMultiUserLoginOption>()
  );
  const [loginCredentials, setLoginCredentials] = useState<Inputs>();
  const [openMandatoryMfaModal, setMandatoryMfaModal] =
    React.useState<boolean>(false);
  const [openMfaSettingsOnLoginModal, setMfaSettingsOnLoginModal] =
    React.useState<boolean>(false);
  const [mfaUserDetails, setMfaUserDetails] =
    React.useState<MfaUserDetailsTypes>();
  const [userAuthResponse, setUserAuthResponse] = React.useState<any>();
  const [isSSO, setIsSSO] = React.useState<boolean>(false);
  const [isRecoveryCodeModalOpen, setRecoveryCodeModalOpen] =
    React.useState<boolean>(false);
  const { classes } = useStyles();
  let location = useLocation();
  const navigate = useNavigate();
  props.isLoginFn(false);

  const handleUpdateUseOrgDetails = async (response: any) => {
    const updatedUserInfo = await updateUseOrgDetails(response);
    responseLoginPayload(updatedUserInfo);
  };

  const handleMfaMultiUserMandatory = (
    response: any,
    userInfo: OhsUserResponseTypes
  ) => {
    // Get MFA settings from response configurationd
    const getMfaUserConfigValue: string = getModuleConfigValue(
      response.result.item.configuration,
      ModuleType.Admin2,
      'MFA'
    );
    if (
      _.isNil(response.result.item.mfa) &&
      getMfaUserConfigValue === AdminSwitchOptionMap.required &&
      isSSO !== true
    ) {
      // MFA Register component
      const { email, phone, phoneCountryCode, tier } = userInfo;
      setMfaUserDetails({
        email,
        phoneCountryCode,
        phone,
        mfaConfigStatus: getMfaStatusStringVal(getMfaUserConfigValue) ?? '',
        tier: tier,
      });
      setMandatoryMfaModal(true);
      setUserAuthResponse(response);
    } else {
      handleUpdateUseOrgDetails(response);
    }
  };

  const handleDialogClose = (id: string) => {
    setOpen(false);
    if (id) {
      setSelectedLocationUserId(id);
      loginMultiUser(id).then((response: any) => {
        handleMfaMultiUserMandatory(response, response.result.item);
      });
    }
  };

  const queryParams = new URLSearchParams(useLocation().search);
  const username = queryParams.get('username');
  const password = queryParams.get('password');
  const id_token = queryParams.get('id_token');

  const submitLogin = (data: Inputs) => {
    setLoginCredentials(data);
    loginUser(data.email, data.password).then(function (response: any) {
      handleOhsLoginResponse(response);
    });
  };
  const submitReLogin = () => {
    if (loginCredentials) submitLogin(loginCredentials);
  };
  (window as any).submitLogin = submitLogin;

  const handleSingleMuliLogin = (authResponse: any) => {
    //Multi user
    if (authResponse.result.item != null) {
      setLocalStorage('multi-users', authResponse.result);
      setUserOptions(authResponse.result.item.options);
      //If only one option, log in directly
      if (authResponse.result.item.options.length === 1) {
        loginMultiUser(authResponse.result.item.options[0].user._id).then(
          (response: any) => {
            // force enroll mfa if multiuser's mfa config is required and no enabled mfa method
            handleMfaMultiUserMandatory(response, response.result.item);
          }
        );
      } else {
        setOpen(true);
        setMfaOpen(false);
      }
    } else {
      //single user
      handleUpdateUseOrgDetails(authResponse);
    }
  };

  const handleOhsLoginResponse = (response: any) => {
    if (response && response.success === true) {
      if (isSSO === true) {
        handleSingleMuliLogin(response);
      } else {
        // Get MFA settings from response configuration
        const getMfaUserConfigValue: string = response.result?.user
          ? getModuleConfigValue(
              response.result?.user?.configuration ?? [],
              ModuleType.Admin2,
              'MFA'
            )
          : '';
        // check if OTP is required to login / a MFA method is enabled
        if (_.isNil(response.result.mfa) === false) {
          setMfaOpen(true);
          const resData = response.result.mfa;
          const mfaResData = {
            destination: resData.destination,
            method: resData.method,
            mfaToken: resData.mfaToken,
            oob: resData.oob,
          };
          setMfaDetails(mfaResData);
        } else {
          // check if MFA mandatory
          if (
            response.result.user != null &&
            getMfaUserConfigValue === AdminSwitchOptionMap.required &&
            isSSO === false
          ) {
            // MFA Register component
            const { email, phone, phoneCountryCode, tier } =
              response.result.user;
            setMfaUserDetails({
              email,
              phoneCountryCode,
              phone,
              mfaConfigStatus:
                getMfaStatusStringVal(getMfaUserConfigValue) ?? '',
              tier: tier,
            });
            setMandatoryMfaModal(true);
            setUserAuthResponse(response);
          } else {
            handleSingleMuliLogin(response);
          }
        }
      }
    } else {
      let warning = 'Login failed, please check your email and password.';
      if (isSSO === true) {
        warning = 'Login failed.';
      }
      toast.error(warning);
    }

    return;
  };

  const handleRecoveryCode = () => {
    //setMfaOpen(false);
    setRecoveryCodeModalOpen(true);
  };

  const handleMfaLoginResponse = (response: any) => {
    handleLoginResponse(response);
    handleSingleMuliLogin(response);
  };

  const handleMfaRecoveryCodeRes = (response: any) => {
    handleLoginResponse(response);
    handleSingleMuliLogin(response);
  };

  const handleMfaResetOnRecoveryCode = (response: any) => {
    customLoginRedirect = '/admin2/user-settings';
    handleLoginResponse(response);
    handleSingleMuliLogin(response);
  };

  const responseLoginPayload = (response: any) => {
    if (_.has(response, 'success') === true && response.success === true) {
      let { from }: any = location.state || {
        from: { pathname: getHomeUrl() },
      };

      if (customLoginRedirect !== '') {
        from = { pathname: customLoginRedirect };
      }

      navigate(from);
      props.isLoginFn(true);
    }
  };

  const handleActivateMfa = () => {
    setMfaSettingsOnLoginModal(true);
    setMandatoryMfaModal(false);
  };
  const handleMandatoryMfaModal = (val: boolean) => {
    setMandatoryMfaModal(val);
  };
  const handleMfaSettingsOnLoginModal = (val: boolean) => {
    setMfaSettingsOnLoginModal(val);
  };

  const handleMfaEnrollmentSuccess = () => {
    setMfaSettingsOnLoginModal(false);
    handleUpdateUseOrgDetails(userAuthResponse);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: username && password ? username : '',
      password: username && password ? password : '',
    },
  });

  useEffect(() => {
    if (id_token != null) {
      loginUser('', '', id_token).then(function (response: any) {
        handleOhsLoginResponse(response);
        setIsSSO(true);
      });
    }
  }, []);

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit(submitLogin)}>
        <TextField
          disabled={id_token != null}
          error={errors.email != null}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Email Address"
          autoComplete="email"
          autoFocus
          {...register('email', {
            required: true,
            validate: { isEmail: (value) => validator.isEmail(value) },
          })}
          helperText={
            errors.email?.type
              ? errors.email?.type === 'isEmail'
                ? 'Please provide a valid email address.'
                : 'Email cannot be empty.'
              : ''
          }
        />
        <TextField
          disabled={id_token != null}
          error={errors.password != null}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          {...register('password', {
            required: true,
          })}
          helperText={
            errors.password?.type === 'required'
              ? 'Password cannot be empty.'
              : ''
          }
        />
        <Button
          disabled={id_token != null}
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          id="loginButtonId"
          className={classes.submit}
        >
          Login
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/reset" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/office365.html" variant="body2">
              {'Login via SSO'}
            </Link>
          </Grid>
        </Grid>
      </form>
      <OhsMultiLoginPlaceDialog
        selectedLocationUserId={selectedLocationUserId}
        open={open}
        onClose={handleDialogClose}
        userOptions={userOptions}
      />
      <OhsMfaModal
        title="Multi-Factor Authentication"
        open={openMfa}
        setModalOpen={setMfaOpen}
        mfaDetails={mfaDetails}
        submitReLogin={submitReLogin}
        login={handleMfaLoginResponse}
        useRecoveryCode={() => handleRecoveryCode()}
      />
      <OhsMfaMandatoryMessageModal
        open={openMandatoryMfaModal}
        setModalOpen={(val: boolean) => handleMandatoryMfaModal(val)}
        activateMfaMethod={handleActivateMfa}
        mfaConfigStatus={mfaUserDetails?.mfaConfigStatus}
        tier={mfaUserDetails?.tier}
      />
      <OhsMfaSettingsOnLoginModal
        open={openMfaSettingsOnLoginModal}
        setModalOpen={(val: boolean) => handleMfaSettingsOnLoginModal(val)}
        userDetails={mfaUserDetails}
        onSuccess={() => handleMfaEnrollmentSuccess()}
      />
      <OhsMfaRecoveryCodeModal
        mfaMethod={mfaDetails?.method ?? ''}
        mfaToken={(mfaDetails && mfaDetails?.mfaToken) ?? ''}
        useremail={(loginCredentials && loginCredentials.email) ?? ''}
        isRecoveryCodeModalOpen={isRecoveryCodeModalOpen}
        setRecoveryCodeModalOpen={setRecoveryCodeModalOpen}
        handleMfaRecoveryCodeRes={handleMfaRecoveryCodeRes}
        handleMfaResetOnRecoveryCode={handleMfaResetOnRecoveryCode}
      />
    </>
  );
};

export default connect(null, { isLoginFn })(OhsLoginForm);
