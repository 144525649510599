import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { OhsUser } from '../../user/OhsUser';

const getMixpanelTraker = (): Mixpanel | null => {
  mixpanel.init(
    (window as any).MIXPANEL_ID
      ? (window as any).MIXPANEL_ID
      : '03776c63a39a3c98908d60ac2e2859db'
  );

  try {
    const user = new OhsUser();
    var morph = 'None';
    if (user.isMorph === true) {
      if (user.tierNum === 2) {
        morph = 'Up';
      } else {
        morph = 'Down';
      }
    }
    mixpanel.register({
      'Application Type': 'Web',
      'Application Version': window.SAFETY_CHAMPION_VERSION,
      'Organisation Name': user.org?.name,
      'Organisation Id': user.org?._id,
      Username: user.name,
      'User Email': user.email.toLowerCase(),
      'User Id': user._id,
      'User Plan Type': user.level,
      'Login Type': user.isSso === true ? 'Azure SSO' : 'Username Password',
      'Tier Name': user.tier.name,
      'Tier Id': user.tier._id,
      'Tier Level': user.tierNum,
      Morph: morph,
    });

    mixpanel.people.set({
      'Organisation Name': user.org?.name,
      'Organisation Id': user.org?._id,
      $name: user.name,
      $email: user.email.toLowerCase(),
      'User Id': user._id,
      'User Plan Type': user.level,
      'Tier Name': user.tier.name,
      'Tier Id': user.tier._id,
      'Tier Level': user.tierNum,
      Morph: morph,
    });

    mixpanel.identify(user.email.toLowerCase());
    mixpanel.track('Reload Application Page');
    return mixpanel;
  } catch (e) {
    return null;
  }
};

export default getMixpanelTraker;
