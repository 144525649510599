import { Grid } from '@mui/material';

import React from 'react';
import OhsRegister from '../../global/register/OhsRegister';
import { ModuleType } from '../../models/OhsObject';
import { getOhsUser } from '../../user/OhsUser';
import { TaskAssignUserStatus } from '../OhsTaskModels';
import { assignTaskToUser } from '../OhsTaskServices';
import { OhsTaskAssignInput } from './OhsTaskAssignInput';
import { Message } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
const user = getOhsUser();

const useStyles = makeStyles()(() => ({
  alert: {
    border: '2px solid #b2dbef',
    background: '#e5f6ff',
    fontSize: '14px',
    padding: '2px 8px',
    borderRadius: '5px',
    marginBottom: '10px',
    color: user?.configs.theme.HEADER_BAR_BG_COLOR ?? 'black',
  },
  assignedUsers: {
    marginBottom: '10px',
  },
}));

export const OhsTaskAssignTable = (props: {
  recordId: string;
  recordType: ModuleType;
  taskId: string;
  statusList: TaskAssignUserStatus[];
}) => {
  const { classes } = useStyles();
  const [userList, setUserList] = React.useState(
    new Array<TaskAssignUserStatus>()
  );
  React.useEffect(() => {
    setUserList(props.statusList);
  }, [props.statusList]);
  const columnconfig = [
    {
      id: 'nameId',
      Header: 'Name',
      accessor: 'name',
      customWidth: '180px',
    },
    {
      Header: 'Workplace',
      accessor: 'tier.name',
    },
    {
      Header: 'Assigned',
      accessor: (d: TaskAssignUserStatus) =>
        d.assigned === true ? 'Yes' : 'No',
    },
    {
      Header: 'Message',
      Cell: function (cellProps: any, instance: any) {
        return (
          cellProps.row.original &&
          cellProps.row.original.assigned !== true && (
            <OhsTaskAssignInput cellProps={cellProps} />
          )
        );
      },
    },
  ];
  const assignedUsersListsColumnconfig = [
    {
      id: 'nameId',
      Header: 'Name',
      accessor: 'name',
      customWidth: '180px',
    },
    {
      Header: 'Workplace',
      accessor: 'tier.name',
    },
  ];
  const columns = React.useMemo(() => columnconfig, [columnconfig]);
  const assignedUsersListsColumns = React.useMemo(
    () => assignedUsersListsColumnconfig,
    [assignedUsersListsColumnconfig]
  );

  return (
    <>
      <Grid container spacing={2} className={classes.assignedUsers}>
        <Grid item xs={12} md={12}>
          <Message info>
            <p>
              This task has been assigned to the following "
              {userList.filter((item) => item.assigned === true).length}" Users:
            </p>
          </Message>

          <OhsRegister
            columns={assignedUsersListsColumns}
            data={userList.filter((item) => item.assigned === true)}
            sortBy={[{ id: 'nameId', desc: false }]}
            noPagination={true}
            hideHeader={true}
            rowOperations={{
              assignButton: {
                func: async (item: TaskAssignUserStatus) => {
                  item.loading = true;
                  setUserList([...userList]);
                  const result = await assignTaskToUser(
                    item.assigned !== true,
                    props.recordId,
                    props.recordType,
                    props.taskId,
                    item._id,
                    item.message
                  );
                  item.loading = false;
                  if (result === true) {
                    item.message = '';
                    item.assigned = !item.assigned;
                  }
                  setUserList([...userList]);
                },
                permission: true,
              },
            }}
          />
        </Grid>
      </Grid>

      <OhsRegister
        columns={columns}
        data={userList}
        sortBy={[{ id: 'nameId', desc: false }]}
        noPagination={true}
        rowOperations={{
          assignButton: {
            func: async (item: TaskAssignUserStatus) => {
              item.loading = true;
              setUserList([...userList]);
              const result = await assignTaskToUser(
                item.assigned !== true,
                props.recordId,
                props.recordType,
                props.taskId,
                item._id,
                item.message
              );
              item.loading = false;
              if (result === true) {
                item.message = '';
                item.assigned = !item.assigned;
              }
              setUserList([...userList]);
            },
            permission: true,
          },
        }}
      />
    </>
  );
};
