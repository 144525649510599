import { getAngularLocalStorage, SsoFlagName } from '../common/OhsDataParse';
import { FreemiumOrgId } from '../global/constants/OhsConstants';

import { ModuleDefaultTitle, ModuleType, OhsModule } from '../models/OhsObject';
import { OhsTier } from '../models/tier/OhsTier';
import { OhsConfigurations } from './configurations/OhsConfigurations';
import { OhsModuleConfig } from './configurations/OhsModuleConfig';

export class OhsUser implements OhsModule {
  readonly type: ModuleType = ModuleType.User;
  _id!: string;
  name!: string;
  email!: string;
  tier!: OhsTier;
  orgAdmin: OhsTier | null;
  org: OhsTier | null;
  workplace: OhsTier | null;
  workerGroup: OhsTier | null;
  isMorph: boolean;
  isSso: boolean;
  tierNum!: number;
  level: string;
  configs: OhsConfigurations;
  mfa?: OhsMFAsettings;
  constructor() {
    const userPayload = getAngularLocalStorage('userPayloadConfig');
    this.orgAdmin = null;
    this.org = null;
    this.workplace = null;
    this.workerGroup = null;
    this.level = 'paid';
    this.configs = new OhsConfigurations();
    if (userPayload && userPayload.user) {
      this._id = userPayload.user._id;
      this.name = userPayload.user.name;
      this.email = userPayload.user.email;
      this.tier = new OhsTier(userPayload.user.tier);
      this.tierNum = parseInt(this.tier.type.substring(11), 10);
      if (userPayload.user.orgAdmin && userPayload.user.orgAdmin._id) {
        this.orgAdmin = userPayload.user.orgAdmin;
      }
      if (userPayload.user.org && userPayload.user.org._id) {
        this.org = userPayload.user.org;
      }
      if (userPayload.user.workplace && userPayload.user.workplace._id) {
        this.workplace = userPayload.user.workplace;
      }
      if (userPayload.user.workerGroup && userPayload.user.workerGroup._id) {
        this.workerGroup = userPayload.user.workerGroup;
      }
      if (
        this.orgAdmin &&
        this.orgAdmin._id &&
        this.orgAdmin._id === FreemiumOrgId
      ) {
        this.level = 'free';
      }
    }

    const isMorph = getAngularLocalStorage('isMorph');
    if (isMorph === true) {
      this.isMorph = true;
    } else {
      this.isMorph = false;
    }
    const isSso = getAngularLocalStorage(SsoFlagName);
    if (isSso === true) {
      this.isSso = true;
    } else {
      this.isSso = false;
    }
  }
  getModuleName(moduleType: ModuleType): string {
    switch (moduleType) {
      case ModuleType.Action: {
        return this.configs.action?.TITLE ?? ModuleDefaultTitle[moduleType];
      }

      case ModuleType.SafetyPlan: {
        return this.configs.safetyplan?.TITLE ?? ModuleDefaultTitle[moduleType];
      }

      case ModuleType.Noticeboard: {
        return (
          this.configs.noticeboard?.TITLE ?? ModuleDefaultTitle[moduleType]
        );
      }

      case ModuleType.CorporateRisk: {
        return (
          this.configs.corporateRisk?.TITLE ?? ModuleDefaultTitle[moduleType]
        );
      }

      case ModuleType.Asset: {
        return this.configs.asset?.TITLE ?? ModuleDefaultTitle[moduleType];
      }

      case ModuleType.Contractor: {
        return this.configs.contractor?.TITLE ?? ModuleDefaultTitle[moduleType];
      }

      case ModuleType.HR: {
        return this.configs.hr?.TITLE ?? ModuleDefaultTitle[moduleType];
      }
      case ModuleType.Export: {
        return this.configs.export?.TITLE ?? ModuleDefaultTitle[moduleType];
      }
      default:
        return moduleType;
    }
  }
  getCurrentURLModuleConfig(): OhsModuleConfig | null {
    const pathname = window.location.pathname;
    const modulePath = pathname.split('/')[1];

    switch (modulePath) {
      case 'safety-plan':
        return this.configs.safetyplan;

      case 'noticeboard': {
        return this.configs.noticeboard;
      }

      case 'crisk': {
        return this.configs.corporateRisk;
      }

      case 'assets': {
        return this.configs.asset;
      }
    }
    return null;
  }

  getCurrentApiRoot(): string {
    const pathname = window.location.pathname;
    const modulePath = pathname.split('/')[1];

    switch (modulePath) {
      case 'safety-plan':
        return 'safetyplans';
      case 'crisk':
        return 'crisks';
      default:
        return modulePath;
    }
  }
}

export const getOhsUser = (): OhsUser | null => {
  const user = new OhsUser();
  if (user.name) {
    return user;
  } else {
    return null;
  }
};

export interface OhsMFAsettings {
  enabled: boolean;
  method: string;
}

export interface OhsMfaLimits {
  app: boolean;
  sms: boolean;
  email: boolean;
}
export { SsoFlagName };
