import _ from 'lodash';

export const getConfigValue = (config: any, valueName: string): any => {
  const valueObj = _.find(config, {
    name: valueName,
  });
  if (valueObj && valueObj.value) {
    return valueObj.value;
  } else {
    return null;
  }
};
