import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const notShowSpinnerUrlList = ['/exportStatus'];

const isShowSpinner = (config: AxiosRequestConfig): boolean => {
  let flag = true;
  for (let index = 0; index < notShowSpinnerUrlList.length; index++) {
    const element = notShowSpinnerUrlList[index];
    if (config.url && config.url.indexOf(element) >= 0) {
      return false;
    }
  }
  return flag;
};

export const onRequestForBackdrop = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  if (isShowSpinner(config) === true) {
    (window as any).toggleBackdrop(true);
  }

  return config;
};

export const onRequestErrorForBackdrop = (
  error: AxiosError
): Promise<AxiosError> => {
  (window as any).toggleBackdrop(false);
  return Promise.reject(error);
};

export const onResponseForBackdrop = (
  response: AxiosResponse
): AxiosResponse => {
  (window as any).toggleBackdrop(false);
  return response;
};

export const onResponseErrorForBackdrop = (
  error: AxiosError
): Promise<AxiosError> => {
  (window as any).toggleBackdrop(false);
  return Promise.reject(error);
};
