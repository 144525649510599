import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
export class OhsBiPermissions {
  view: boolean;

  constructor(flag: boolean) {
    this.view = flag;
  }
}
export class OhsBiConfig {
  TITLE: string;
  PERMISSIONS: OhsBiPermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsBiPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}
