import React, { useEffect } from 'react';
import _ from 'lodash';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

import {
  OhsFormFieldBaseProps as Props,
  OhsFormLayoutType,
  OhsFormSettings,
} from '../OhsFormModels';
import './OhsFormField.css';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

const id = 'time';

export const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
  },
  closeContainer: {
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  close: {
    marginRight: '42px',
    fontSize: '1.25em',
    opacity: 0.5,
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
}));

function InputEle(props: Props) {
  const { classes } = useStyles();
  const ohsFormSettings = new OhsFormSettings(
    { [id]: null },
    OhsFormLayoutType.AngularModalForm
  );
  const propValue = props.ohsFormSettings.useFormMethods.watch(props.id);
  const value = ohsFormSettings.useFormMethods.watch(id);

  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );

  const handleClear = () => {
    props.ohsFormSettings.useFormMethods.setValue(props.id, '');
    ohsFormSettings.useFormMethods.setValue(id, null);
  };

  /*
    empty propValue can't be null, only ''
    empty value cant be '', only null
  */
  useEffect(() => {
    if (propValue === null || propValue === undefined) {
      props.ohsFormSettings.useFormMethods.setValue(props.id, '');
    } else if (propValue !== value && propValue !== '') {
      ohsFormSettings.useFormMethods.setValue(id, propValue);
    }
  }, [propValue]);

  useEffect(() => {
    if (value === '' || value === undefined) {
      ohsFormSettings.useFormMethods.setValue(id, null);
    } else if (value === null) {
      if (propValue !== '') {
        props.ohsFormSettings.useFormMethods.setValue(props.id, '');
      } else return;
    } else if (propValue !== value) {
      props.ohsFormSettings.useFormMethods.setValue(props.id, value);
    }
  }, [value]);

  return (
    <div className={classes.container}>
      <TextField
        type="time"
        error={error != null}
        fullWidth
        size={'small'}
        margin={'dense'}
        InputLabelProps={{
          shrink: true,
        }}
        variant={returnVariant(props.ohsFormSettings.layout)}
        required={props.required}
        disabled={props.disabled}
        defaultValue={null}
        {...ohsFormSettings.useFormMethods.register(id, {
          validate: {
            notEmpty: (val: any) => {
              if (props.required === true) {
                if (val == null || val.trim() === '') {
                  return false;
                }
              }

              return true;
            },
          },
        })}
        style={returnFieldStyle(props.ohsFormSettings.layout)}
      />
      {value && (
        <div className={classes.closeContainer}>
          <CloseIcon className={classes.close} onClick={handleClear} />
        </div>
      )}
    </div>
  );
}

export default function OhsTimeField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
