import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OhsIframeModulePublicHome from '../global/angular-iframe/OhsIframeModulePublicHome';
import { OhsFreemiumUserSetup } from './onboarding/OhsFreemiumUserSetup';
import OhsHrIntegration from './xsi/hr/OhsHrIntegration';
import OhsHrXeroLanding from './xsi/hr/OhsHrXeroLanding';
import OhsHrMYOBLanding from './xsi/hr/OhsHrMYOBLanding';
import OhsHrEHLanding from './xsi/hr/OhsHrEHLanding';
import OhsRequireAuth from '../global/OhsRequireAuth';

const OhsLinkAccessRoutes = () => {
  return (
    <div>
      <Routes>
        <Route
          path={`xsi/connect/:token`}
          element={
            <OhsRequireAuth>
              <OhsHrIntegration />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`xsi/complete/xero`}
          element={
            <OhsRequireAuth>
              <OhsHrXeroLanding />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`xsi/complete/myob`}
          element={
            <OhsRequireAuth>
              <OhsHrMYOBLanding />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`xsi/complete/emphero`}
          element={
            <OhsRequireAuth>
              <OhsHrEHLanding />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`onboarding/first-signin/:token`}
          element={<OhsFreemiumUserSetup />}
        />
        <Route path="*" element={<OhsIframeModulePublicHome />} />
      </Routes>
    </div>
  );
};

export default OhsLinkAccessRoutes;
