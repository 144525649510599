import _ from 'lodash';
import React from 'react';
import { withStyles } from '@mui/styles';
import { Segment } from 'semantic-ui-react';
import { registerButtonStylesFunc } from '../../global/register/OhsModuleHeader';
import { OhsTab } from '../../global/tab/OhsTab';
import { ModuleType } from '../../models/OhsObject';
import { OhsVdocRecord } from '../../vdoc/OhsVdocModels';
import {
  NoticeboardForm,
  OhsNoticeboardBlock,
  OhsNoticeboardBlockType,
  OhsNoticeboardBlockTypeDisplay,
  OhsNoticeboardBlockVdoc,
  OhsNoticeboardContact,
  OhsNoticeBoardRecordConfig,
} from '../OhsNoticeBoardModels';
import { getNoticeBoardRecordConfig } from '../OhsNoticeBoardServices';
import { OhsNoticeBoardBlockList } from './OhsNoticeBoardBlockList';
import { OhsNoticeBoardContactList } from './OhsNoticeBoardContactList';
import { OhsNoticeBoardFormList } from './OhsNoticeBoardFormList';
import { OhsNoticeBoardVdocBlockAddModal } from './OhsNoticeBoardVdocBlockAddModal';
import { OhsWithRouterTypes } from '../../global/types/OhsGlobalTypes';
import OhsWithRouter from '../../global/utils/OhsWithRouter';
type PathParamsType = {
  noticeBoardId: string;
};
type PropsType = {
  classes: any;
  fetchForms: (forms: NoticeboardForm[]) => void;
  router: OhsWithRouterTypes<PathParamsType>;
};
interface StateType {
  noticeBoardBlocks: OhsNoticeboardBlock[];
  noticeBoardContacts: OhsNoticeboardContact[];
  noticeBoardForms: NoticeboardForm[];
  noticeBoardRecordConfig: OhsNoticeBoardRecordConfig | null;
  vdocModalOpen: boolean;
  vdocList: OhsVdocRecord[];
  addOptionValue: string;
}
class OhsNoticeBoardBlockRegister extends React.Component<
  PropsType,
  StateType
> {
  state = {
    noticeBoardBlocks: new Array<OhsNoticeboardBlock>(),
    noticeBoardContacts: new Array<OhsNoticeboardContact>(),
    noticeBoardForms: new Array<NoticeboardForm>(),
    noticeBoardRecordConfig: null,
    vdocModalOpen: false,
    vdocList: new Array<OhsVdocRecord>(),
    addOptionValue: '',
  };
  async componentDidMount() {
    const { fetchForms } = this.props;

    const noticeBoardId = this.props.router.params.noticeBoardId;

    const noticeBoardConfig = await getNoticeBoardRecordConfig(noticeBoardId);
    if (noticeBoardConfig) {
      this.setState(
        {
          noticeBoardRecordConfig: noticeBoardConfig,
          noticeBoardBlocks: noticeBoardConfig.blocks ?? [],
          noticeBoardContacts: noticeBoardConfig.contacts ?? [],

          noticeBoardForms: noticeBoardConfig.forms ?? [],
        },
        () => fetchForms(noticeBoardConfig.forms ?? [])
      );
    }
  }
  render() {
    const setAddVdocModal = (open: boolean) => {
      this.setState({ vdocModalOpen: open, vdocList: [] });
    };
    const handleAddVdocList = (vdocList: OhsVdocRecord[]) => {
      const vdocBlockList = new Array<OhsNoticeboardBlockVdoc>();
      for (let index = 0; index < vdocList.length; index++) {
        const element = vdocList[index];
        vdocBlockList.push({
          type: OhsNoticeboardBlockType.OhsDocument,
          typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsDocument,
          data: {
            _id: element._id,
            title: element.version.name,
            description: element.version.description,
            type: ModuleType.VersionDocument,
            attachment: element.version.attachments[0],
          },
        });
      }
      this.setState({
        noticeBoardBlocks: this.state.noticeBoardBlocks.concat(vdocBlockList),
      });
    };

    const tabItems = [
      {
        tabKey: 'resources',
        tabName: 'Resources',
        content: (
          <Segment>
            <OhsNoticeBoardBlockList
              noticeboardBlocks={this.state.noticeBoardBlocks}
              noticeBoardConfig={this.state.noticeBoardRecordConfig}
            />
          </Segment>
        ),
      },
      {
        tabKey: 'contacts',
        tabName: 'Contacts',
        content: (
          <Segment>
            <OhsNoticeBoardContactList
              noticeboardContacts={this.state.noticeBoardContacts}
              noticeBoardConfig={this.state.noticeBoardRecordConfig}
            />
          </Segment>
        ),
      },
      {
        tabKey: 'forms',
        tabName: 'Forms',
        content: (
          <Segment>
            <OhsNoticeBoardFormList
              noticeboardForms={this.state.noticeBoardForms}
              noticeBoardConfig={this.state.noticeBoardRecordConfig}
            />
          </Segment>
        ),
      },
    ];

    return (
      <>
        <OhsTab tabItems={tabItems} />
      </>
    );
  }
}
export default OhsWithRouter(
  withStyles(registerButtonStylesFunc)(OhsNoticeBoardBlockRegister)
);
