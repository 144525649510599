import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import OhsModuleContent from '../register/OhsModuleContent';
import { OhsTabItem } from './OhsTabModels';
interface Props {
  tabItems: OhsTabItem[];
}
const getIndexByTabkey = (
  tabKey: string | null,
  tabItems: OhsTabItem[]
): number => {
  if (tabKey) {
    for (let index = 0; index < tabItems.length; index++) {
      const element = tabItems[index];
      if (tabKey === element.tabKey) {
        return index;
      }
    }
  }
  return 0;
};
export const OhsTab = (props: Props) => {
  const panes = props.tabItems.map((item) => {
    return {
      menuItem: item.tabName,
      render: () => <Tab.Pane>{item.content}</Tab.Pane>,
    };
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { search } = useLocation();
  let urlParams = new URLSearchParams(window.location.search);
  let tabKey = urlParams.get('tab');

  const index = getIndexByTabkey(tabKey, props.tabItems);
  const [activeIndex, setActiveIndex] = React.useState(index);

  React.useEffect(() => {
    if (index !== activeIndex) {
      setActiveIndex(index);
    }
  }, [tabKey]);
  const handleTabChange = (event: any, data: any) => {
    if (activeIndex !== data.activeIndex) {
      tabKey = props.tabItems[data.activeIndex].tabKey;
      urlParams.set('tab', props.tabItems[data.activeIndex].tabKey);
      window.history.pushState(
        '',
        '',
        window.location.pathname + '?' + urlParams.toString()
      );
      setActiveIndex(data.activeIndex);
    }
  };
  return (
    <>
      {panes.length > 1 && (
        <Tab
          onTabChange={handleTabChange}
          panes={panes}
          activeIndex={activeIndex}
        />
      )}
      {panes.length === 1 && (
        <OhsModuleContent>{props.tabItems[0].content}</OhsModuleContent>
      )}
    </>
  );
};
