import React from 'react';
import { OhsCusval, OhsCusvalType } from './OhsCusvalModel';
import OhsCheckboxField from '../fields/OhsCheckboxField';
import OhsCurrencyField from '../fields/OhsCurrencyField';
import OhsDateField from '../fields/OhsDateField';
import OhsEmailField from '../fields/OhsEmailField';
import OhsPhoneNumberField from '../fields/OhsPhoneNumberField';
import OhsRadioField from '../fields/OhsRadioField';
import OhsTextField from '../fields/OhsTextField';
import OhsUrlField from '../fields/OhsUrlField';
import OhsTimeField from '../fields/OhsTimeField';
import { OhsFormSettings } from '../OhsFormModels';
import OhsCusvalSelectField from './select/OhsCusvalSelectField';
import OhsStaticView from '../fields/OhsStaticView';

function getCusvalField(
  sectionId: string,
  cusval: OhsCusval,
  ohsFormSettings: OhsFormSettings
) {
  const newId = `${sectionId}.${cusval._id}`;
  switch (cusval.type) {
    case OhsCusvalType.Text:
    case OhsCusvalType.Number:
    case OhsCusvalType.Color:
    case OhsCusvalType.Textarea:
      return (
        <OhsTextField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          type={cusval.type}
          ohsFormSettings={ohsFormSettings}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Time:
      return (
        <OhsTimeField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Date:
      return (
        <OhsDateField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Email:
      return (
        <OhsEmailField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Url:
      return (
        <OhsUrlField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Telephone:
      return (
        <OhsPhoneNumberField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Currency:
      return (
        <OhsCurrencyField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          options={cusval.options ?? []}
          ohsFormSettings={ohsFormSettings}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Radio:
      return (
        <OhsRadioField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          options={
            cusval.options?.map((item) => {
              return {
                name: item.name,
                value: item.name,
              };
            }) ?? []
          }
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Checkbox:
      return (
        <OhsCheckboxField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          options={
            cusval.options?.map((item) => {
              return {
                name: item.name,
                value: item.name,
              };
            }) ?? []
          }
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.MutipleSelect:
      return (
        <OhsCusvalSelectField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          options={cusval.options ?? []}
          multi={true}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );

    case OhsCusvalType.Select:
      return (
        <OhsCusvalSelectField
          title={cusval.title}
          id={newId}
          description={cusval.description}
          required={cusval.required}
          ohsFormSettings={ohsFormSettings}
          options={cusval.options ?? []}
          disabled={cusval.disabled}
          renderAction={cusval.renderAction}
        />
      );
    case OhsCusvalType.Static:
      return (
        <OhsStaticView title={cusval.title} description={cusval.description} />
      );
    default:
      return <div></div>;
    // throw new Error('Unknown text');
  }
}
interface Props {
  sectionId: string;
  cusval: OhsCusval;
  ohsFormSettings: OhsFormSettings;
}
export default function OhsCusvalFieldFactory(props: Props) {
  return getCusvalField(props.sectionId, props.cusval, props.ohsFormSettings);
}
