import React from 'react';
import { getHomeUrl } from '../login/OhsLoginServices';
import { morphUpTier2 } from '../user/morph/MorphServices';
export const OhsMorphTier2 = () => {
  morphUpTier2().then((response: any) => {
    if (response && response.success) {
      window.location.href = getHomeUrl();
    }
  });
  return null;
};
