import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OutlinedInput } from '@mui/material';
import { setAngularLocalStorage } from '../../common/OhsDataParse';
import { OhsFilterList, OhsTaskFilterTypes } from './FilterModels';

interface Props {
  ohsFilterList: OhsFilterList;
  setOhsFilterList: React.Dispatch<React.SetStateAction<any>>;
}
export default function OhsTaskDueDateFilter(props: Props) {
  const { ohsFilterList, setOhsFilterList } = props;
  const [currSetDueDate, setCurrSetDueDate] = React.useState('');
  const [optionIndex, setOptionIndex] = React.useState(0);
  let currentFilterList = ohsFilterList.task as unknown as OhsTaskFilterTypes[];

  const dueDateFilterOptions = [
    { name: 'Next 1 Month', value: '1month' },
    { name: 'Next 3 Months', value: '3months' },
    { name: 'Next 6 Months', value: '6months' },
    { name: 'Next 1 Year', value: '12months' },
  ];
  const handleChange = (event: SelectChangeEvent) => {
    setCurrSetDueDate(event.target.value);
    currentFilterList[optionIndex].value = event.target.value;

    const updateFilterList = {
      ...ohsFilterList,
      task: currentFilterList,
    };
    setOhsFilterList(updateFilterList);
    setAngularLocalStorage('filterList', updateFilterList);
  };

  React.useEffect(() => {
    const optionIndex = currentFilterList.findIndex(
      (filter: any) => filter.key === 'dueDateRange'
    );
    const getCurrVal = String(currentFilterList[optionIndex].value);
    setOptionIndex(optionIndex);
    setCurrSetDueDate(getCurrVal);
  }, []);

  return (
    <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
      <Select
        name="dueDateRange"
        labelId="dueDateLabel"
        id="dueDateLabelField"
        value={currSetDueDate}
        onChange={handleChange}
        input={<OutlinedInput notched />}
      >
        <MenuItem value="">
          <span style={{ color: 'gray' }}>-- Please Select --</span>
        </MenuItem>
        {dueDateFilterOptions.map((item: any) => {
          return (
            <MenuItem key={item.name} value={item.value}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
