import React from 'react';
import { OhsUser } from '../user/OhsUser';

import logo from '../modules/build/img/sc-logo.png';
interface IProps {
  className: string;
  style: React.CSSProperties;
}
export const OhsLogoImg = (props: any) => {
  const user = new OhsUser();
  if (
    user &&
    user.configs.theme &&
    user.configs.theme.ORGANIZATION_LOGO !== 'http://example.com/undefined.png'
  ) {
    return (
      <img
        className={props.className}
        style={props.style}
        src={user.configs.theme.ORGANIZATION_LOGO}
        alt=""
      />
    );
  } else {
    return (
      <img className={props.className} style={props.style} src={logo} alt="" />
    );
  }
};
