import React from 'react';
import { connect } from 'react-redux';
import { getOhsUser } from '../../user/OhsUser';
import { OhsConstants } from '../constants/OhsConstants';

function OhsAngularIframe(props: any) {
  let bodyUrl = window.location.href.substring(
    window.location.href.indexOf(window.location.host) +
      window.location.host.length
  );
  let adjustedURL = '/' + OhsConstants.LegacyModuleFolder + '/#' + bodyUrl;
  const user = getOhsUser();
  let heightAdjust = '0px';
  if (props.isShowingBanner === true) {
    heightAdjust =
      user != null && user.level === 'free' ? '177.5px' : '137.5px';
  } else {
    heightAdjust = user != null && user.level === 'free' ? '125px' : '85px';
  }

  return (
    <div>
      <iframe
        style={{
          height: 'calc(100vh - ' + heightAdjust + ')',
          width: 'calc(100vw)',
          boxSizing: 'border-box',
          border: '0px',
        }}
        src={adjustedURL}
        id="OhsAngularIframeId"
        title="OhsAngularIframe"
      ></iframe>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    isShowingBanner: state.dashboardReducer.isShowingBanner,
  };
};
export default connect(mapStateToProps)(OhsAngularIframe);
