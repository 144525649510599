import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';
import { setAttachmentsDownloadUrl } from '../global/attachment/OhsAttachmentServices';
import { OhsHrLookup, OhsHrRecord } from './OhsHrModels';
import { getOhsUser } from 'user/OhsUser';

export const getHrRecord = (hrId: string): Promise<OhsHrRecord | null> => {
  const endpoint = `/hr/${hrId}/fetch`;
  const user = getOhsUser();
  const errMsg = `Unable to get the ${user?.configs.hr?.TITLE} record.`;

  return ohsApiClient
    .get(endpoint)
    .then(async (response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const hrRecord = response.data.result.item as OhsHrRecord;
        hrRecord.attachments = await setAttachmentsDownloadUrl(
          hrRecord.attachments
        );
        hrRecord.profilePhoto = _.find(hrRecord.attachments, {
          group: 'profilePhoto',
        });
        if (hrRecord.attachments && hrRecord.attachments.length > 0)
          hrRecord.attachments = hrRecord.attachments.filter(
            (item) => item.group !== 'profilePhoto'
          );
        return response.data.result.item;
      } else {
        console.log(response);
        toast.error(errMsg);
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};

export const parseHrLookupList = (items: string[][]) => {
  const hrLookupList = new Array<OhsHrLookup>();
  items.forEach((item) => {
    hrLookupList.push(new OhsHrLookup(item));
  });
  return hrLookupList;
};
