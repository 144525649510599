import { ModuleType, OhsModuleCreateBy } from '../models/OhsObject';
import { OhsTier, TierType } from '../models/tier/OhsTier';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import { getOhsUser } from '../user/OhsUser';
import { OhsCusval } from 'global/form/cusvals/OhsCusvalModel';

export interface OhsActionLink {
  type: ModuleType.Action;
  _id: string;
  reference?: string;
}
export class OhsActionsRecord {
  _id: string;
  type: string;
  tier: OhsTier;
  overview: string;
  description: string;
  personReporting: string;
  personResponsible: string;
  personResponsibleEmail: string;
  attachments: OhsAttachment[];
  cusvals: OhsCusval[];
  categoryCusvals: OhsCusval[];
  category: string;
  categoryOther: string | null;
  dateIdentified: string;
  dateDue: string;
  createdBy: OhsModuleCreateBy | any;
  tzDateCreated: string;
  dateCreated: string;
  reference: string;
  closed: boolean;
  severity: string | null;
  categoryConfig: {
    title: string;
    description: '';
    options: any[];
    cusvals: any[];
  } | null;
  date: string;
  newComment: string;
  tz: string;

  constructor() {
    const user = getOhsUser();
    this._id = '';
    this.type = '';
    this.tier = {
      type: TierType.T3,
      _id: '',
      name: '',
    };
    this.overview = '';
    this.description = '';
    this.personReporting = '';
    this.personResponsible = '';
    this.personResponsibleEmail = '';
    this.attachments = [];
    this.cusvals = [];
    this.categoryCusvals = [];
    this.category = '';
    this.categoryOther = null;
    this.dateIdentified = '';
    this.dateDue = '';
    this.createdBy = null;
    this.tzDateCreated = '';
    this.dateCreated = '';
    this.reference = '';
    this.closed = false;
    this.severity = null;
    this.categoryConfig = null;
    this.date = '';
    this.newComment = '';
    this.tz = '';
  }
}
