import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';
import { ModuleType } from '../models/OhsObject';
import { TaskAssignUserStatus } from './OhsTaskModels';

export const getTaskAssignUserStatusList = (
  recordId: string,
  recordType: ModuleType,
  taskId: string
): Promise<TaskAssignUserStatus[]> => {
  const endpoint = `/tasks/assign/status`;
  const errMsg = 'Unable to get the task status list.';
  return ohsApiClient
    .post(endpoint, {
      _id: taskId,
      for: {
        type: recordType,
        _id: recordId,
      },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        toast.error(errMsg);
        console.error(response);
        return [];
      }
    })
    .catch(function (error) {
      toast.error(errMsg);
      console.log(error);
      return [];
    });
};

export const assignTaskToUser = (
  isAssign: boolean,
  recordId: string,
  recordType: ModuleType,
  taskId: string,
  userId: string,
  message?: string
): Promise<boolean> => {
  const endpoint = `/tasks/${isAssign === true ? 'assign' : 'unassign'}`;
  const errMsg = `Unable to ${
    isAssign === true ? 'assign' : 'unassign'
  } the task.`;
  return ohsApiClient
    .post(endpoint, {
      _id: taskId,
      for: {
        type: recordType,
        _id: recordId,
      },
      task: { notes: message },
      to: { _id: userId, type: ModuleType.User },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return true;
      } else {
        toast.error(errMsg);
        console.error(response);
        return false;
      }
    })
    .catch(function (error) {
      toast.error(errMsg);
      console.log(error);
      return false;
    });
};
