import React from 'react';
import { OhsFormFieldBaseProps, OhsFormSettings } from '../OhsFormModels';
import './OhsFormField.css';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import { OhsSwitch } from '../../switch/OhsSwitch';

interface Props extends OhsFormFieldBaseProps {
  id: string;
  title?: string;
  ohsFormSettings: OhsFormSettings;
  disabled?: boolean;
}

function InputEle(props: Props) {
  const [switchVal, setSwitchVal] = React.useState<boolean>(true);

  const getFormVal =
    props.ohsFormSettings.useFormMethods.watch(props.id) ?? false;

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.setValue(props.id, switchVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchVal]);

  React.useEffect(() => {
    setSwitchVal(getFormVal);
  }, [getFormVal]);

  return (
    <OhsSwitch
      checked={switchVal}
      onChange={(e: any) => setSwitchVal(!switchVal)}
      name={props.id}
      disabled={props.disabled}
    />
  );
}

export default function OhsSwitchField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
