import React from 'react';
import { connect } from 'react-redux';

import { setScreenHeight, setScreenWidth } from './OhsScreenSizeSlice';
type PropsType = {
  setScreenHeight: (screenHeight: number) => {};
  setScreenWidth: (screenWidth: number) => {};
};
class OhsScreenSizeRoot extends React.Component<PropsType> {
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.props.setScreenWidth(window.innerWidth);
    this.props.setScreenHeight(window.innerHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }
  render() {
    return <div></div>;
  }
}
export default connect(null, { setScreenHeight, setScreenWidth })(
  OhsScreenSizeRoot
);
