import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OhsAngularIframe from '../global/angular-iframe/OhsAngularIframe';
import OhsRequireAuth from '../global/OhsRequireAuth';

import OhsSafetyPlanRegister from './register/OhsSafetyPlanRegister';

const OhsSafetyPlanRoutes = () => {
  return (
    <div>
      <Routes>
        <Route
          path={`reactHome`}
          element={
            <OhsRequireAuth>
              <OhsSafetyPlanRegister />
            </OhsRequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default OhsSafetyPlanRoutes;
