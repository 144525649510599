import React from 'react';
import { Button } from 'semantic-ui-react';
import OhsModalForm from '../../global/form/OhsModalForm';
import OhsModalFormItem from '../../global/form/OhsModalFormItem';
import OhsModal from '../../global/modal/OhsModal';
import { OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import {
  OhsNoticeboardBlock,
  OhsNoticeboardBlockType,
  OhsNoticeboardBlockTypeDisplay,
  OhsNoticeboardBlockYoutube,
} from '../OhsNoticeBoardModels';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsUrlField from 'global/form/fields/OhsUrlField';

interface Props {
  open: boolean;

  setModalOpenFunc: (addModalOpen: boolean) => void;

  onClose?: () => void;
  handleYouTubeFunc: (youtubeBlock: OhsNoticeboardBlockYoutube) => void;
  operateYoutubeBlock: OhsNoticeboardBlock | null;
}

export const OhsNoticeBoardYoutubeBlockModal = (props: Props) => {
  const [youtubeBlock, setYoutubeBlock] =
    React.useState<OhsNoticeboardBlockYoutube | null>(null);

  const ohsFormSettings = new OhsFormSettings(
    {
      title: youtubeBlock?.data.title,
      description: youtubeBlock?.data.description,
      source: youtubeBlock?.data.source,
    },
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  React.useEffect(() => {
    if (
      props.operateYoutubeBlock &&
      props.operateYoutubeBlock.type === OhsNoticeboardBlockType.OhsYoutube
    ) {
      setYoutubeBlock(props.operateYoutubeBlock);
      ohsFormSettings.useFormMethods.reset({
        title: youtubeBlock?.data.title,
        description: youtubeBlock?.data.description,
        source: youtubeBlock?.data.source,
      });
    }
  }, [
    props.operateYoutubeBlock,
    ohsFormSettings.useFormMethods.reset,
    youtubeBlock,
    ohsFormSettings.useFormMethods,
  ]);

  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleButtonAdd = (data: any) => {
    props.handleYouTubeFunc({
      type: OhsNoticeboardBlockType.OhsYoutube,
      typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsYoutube,
      data: {
        _id: '',
        title: data.title,
        source: data.source,
        description: data.description,
      },
    });
    handleClose();
  };
  return (
    <>
      <OhsModal
        open={props.open}
        title={'YouTube'}
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalForm>
              <OhsModalFormItem sm={6}>
                <OhsTextField
                  type="text"
                  title="Title"
                  id="title"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsUrlField
                  title="URL"
                  id="source"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem>
                <OhsTextField
                  type={OhsCusvalType.Textarea}
                  title="Description"
                  id="description"
                  required={false}
                  ohsFormSettings={ohsFormSettings}
                  rows={4}
                />
              </OhsModalFormItem>
            </OhsModalForm>
          </>
        }
        actionNode={
          <>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleButtonAdd
              )}
              color={'green'}
            >
              Submit
            </Button>
          </>
        }
      />
    </>
  );
};
