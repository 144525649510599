import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { connect } from 'react-redux';
import { toggleTopFilterDrawer } from '../OhsDashboardSlice';
import { OhsLogoImg } from '../OhsLogoImg';
import { OhsWorkplaceFilterCheckList } from './OhsWorkplaceFilter';
import { OhsModuleFilterCheckList } from './OhsModuleFilter';
import { OhsChemicalLocationFilterCheckList } from './OhsChemicalLocationFilter';
import { getAngularIframeInjector } from '../../global/angular-iframe/OhsAngularIframeServices';
import {
  TaskModuleHomeFolder,
  ChemicalModuleHomeFolder,
} from '../../global/constants/OhsConstants';
import { getOhsUser } from '../../user/OhsUser';
import { Button } from 'semantic-ui-react';
import { OhsTaskFilter } from './OhsTaskFilter';
import { OhsFilterList } from './FilterModels';
import {
  getAngularLocalStorage,
  setAngularLocalStorage,
} from '../../common/OhsDataParse';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import _ from 'lodash';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const OhsTopFilterDrawer = (props: any) => {
  let localStorageFilter = getAngularLocalStorage('filterList');
  const [ohsFilterList, setOhsFilterList] = React.useState<any>({});
  const { classes } = useStyles();

  const resetFilter = (open: boolean) => (event: any) => {
    getAngularIframeInjector('filteringService').setFilterDefault();
    getAngularIframeInjector('filteringService').filteringData();
    window.location.reload();
  };

  React.useEffect(() => {
    if (_.isEmpty(ohsFilterList)) {
      setOhsFilterList(localStorageFilter);
    }
  }, [localStorageFilter, ohsFilterList]);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    props.toggleTopFilterDrawer(open);
    if (open === false) {
      if (getAngularIframeInjector('filteringService')) {
        getAngularIframeInjector('filteringService').filteringData();
      } else {
        window.location.reload();
      }
    }
  };
  (window as any).toggleDrawer = toggleDrawer(true);

  const modulePath = window.location.pathname.split('/')[1];
  const fullUrl = window.location.href;
  const user = getOhsUser();

  return (
    <Drawer
      anchor="top"
      className={clsx(classes.list, {
        [classes.fullList]: true,
      })}
      open={props.isOpen}
      onClose={toggleDrawer(false)}
    >
      <div role="presentation">
        <List>
          <Box m={1}>
            <OhsLogoImg style={{ width: '64px' }} />
          </Box>
          <Divider />
          <Box m={1}>
            <div className={classes.root}>
              <Grid container>
                {user && user.tierNum === 3 && (
                  <Grid item md={4} xs={12}>
                    <OhsWorkplaceFilterCheckList
                      ohsFilterList={ohsFilterList}
                      setOhsFilterList={setOhsFilterList}
                    />
                  </Grid>
                )}
                {modulePath === TaskModuleHomeFolder && (
                  <Grid item md={4} xs={12}>
                    <OhsModuleFilterCheckList
                      ohsFilterList={ohsFilterList}
                      setOhsFilterList={setOhsFilterList}
                    />
                  </Grid>
                )}
                {modulePath === ChemicalModuleHomeFolder &&
                  !fullUrl.includes('tab=myChem') && (
                    <Grid item md={4} xs={12}>
                      <OhsChemicalLocationFilterCheckList />
                    </Grid>
                  )}
                {modulePath === TaskModuleHomeFolder &&
                  !fullUrl.includes('tab=historyTasks') &&
                  !fullUrl.includes('tab=myChem') && (
                    <Grid item md={4} xs={12}>
                      <OhsTaskFilter
                        ohsFilterList={ohsFilterList}
                        setOhsFilterList={setOhsFilterList}
                      />
                    </Grid>
                  )}
              </Grid>
            </div>
          </Box>

          <Divider />
          <Box m={1}>
            <div className={classes.buttons}>
              <Button onClick={resetFilter(false)} className="ui gray button">
                Reset
              </Button>
              <Box>
                <Button
                  onClick={toggleDrawer(false)}
                  className="ui orange button"
                  startIcon={<FilterAltIcon />}
                >
                  <i className="filter icon"></i>
                  Filter
                </Button>
              </Box>
            </div>
          </Box>
        </List>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => {
  return { isOpen: state.dashboardReducer.topFilterDrawerToggleOpen };
};

export default connect(mapStateToProps, { toggleTopFilterDrawer })(
  OhsTopFilterDrawer
);
