import React, { useState } from 'react';
import _ from 'lodash';
import OhsRegisterTableSubRow from '../OhsRegisterTableSubRow';
import OhsRegisterCategoryTableRow from './OhsRegisterCategoryTableRow';
import OhsRegisterSubCatTableRow from './OhsRegisterSubCatTableRow';
import { TableBody } from '@mui/material';
import { OhsRegisterTableMoveRow } from '../OhsRegisterTableMoveRow';

function groupBy(seq: any, keys: any): any {
  if (!keys.length) return seq;
  var first = keys[0];
  var rest = keys.slice(1);
  return _.mapValues(_.groupBy(seq, first), function (value) {
    return groupBy(value, rest);
  });
}

interface Props {
  dataLists: any[];
  moveRowFunc?: any;
  prepareRow: any;
  isCompress?: boolean;
  isSortedAscDesc?: boolean;
}

const OhsRegisterTableListWithCatGroupings = (props: Props) => {
  // ----- Group records by categories and sub categories ----
  const [expandedRecords, setExpandRecord] = useState<any[]>([]);
  const showList = (item: string) => {
    const updateExpendedList = [...expandedRecords];
    if (updateExpendedList.includes(item)) {
      const deleteList = updateExpendedList.filter((list) => list !== item);
      setExpandRecord(deleteList);
    } else {
      updateExpendedList.push(item);
      setExpandRecord(updateExpendedList);
    }
  };

  const noticeBoardGrouping = groupBy(props.dataLists, [
    'values.category',
    'values.subcategory',
  ]);

  const groupedList = new Array<any>();
  const allCatList = new Array<any>();
  Object.keys(noticeBoardGrouping).map((i) => {
    const sortedSubCategories = Object.keys(noticeBoardGrouping[i]).sort();
    const parentCategory: any = {
      category: i,
      subcategory: sortedSubCategories,
      children: new Array<any>(),
      collapsed: true,
    };
    sortedSubCategories.map((j) => {
      const child: any = {
        subcategory: j,
        records: noticeBoardGrouping[i][j],
        collapsed: true,
      };
      parentCategory.children.push(child);
      allCatList.push(j.toLowerCase());
    });
    groupedList.push(parentCategory);
    allCatList.push(i.toLowerCase());
  });
  // ----- Group records by categories and sub categories ----

  React.useEffect(() => {
    if (props?.isCompress) {
      setExpandRecord([]);
    } else {
      setExpandRecord(allCatList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isCompress]);

  // Category sorting
  let sortedGroupList = [...groupedList];
  if (props.isSortedAscDesc !== undefined) {
    sortedGroupList = _.orderBy(
      [...groupedList],
      [(list) => list.category.toLowerCase()],
      [props.isSortedAscDesc ? 'desc' : 'asc']
    );
  }

  return (
    <React.Fragment>
      {sortedGroupList.map((list: any, index: number) => {
        // --- Display table row per category and subcategory ---
        return (
          <React.Fragment key={index}>
            <TableBody>
              <OhsRegisterCategoryTableRow
                index={index}
                list={list}
                showList={showList}
                expandedRecords={expandedRecords}
              />
              {expandedRecords.includes(list.category.toLowerCase()) &&
                list.children.length > 0 &&
                list.children.map((subcatdata: any, subindex: number) => {
                  return (
                    <React.Fragment key={subindex}>
                      <OhsRegisterSubCatTableRow
                        index={subindex}
                        subcatdata={subcatdata}
                        showList={showList}
                        expandedRecords={expandedRecords}
                      />
                      {expandedRecords.includes(
                        subcatdata.subcategory.toLowerCase()
                      ) &&
                        subcatdata.records.length > 0 &&
                        subcatdata.records.map((row: any, index: number) => {
                          props.prepareRow(row);
                          const cellNum = row.cells.length;
                          return row.depth === 0 ? (
                            <OhsRegisterTableMoveRow
                              row={row}
                              index={index}
                              key={index}
                            ></OhsRegisterTableMoveRow>
                          ) : (
                            <OhsRegisterTableSubRow
                              {...row.getRowProps()}
                              row={row}
                              cellNum={cellNum}
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default OhsRegisterTableListWithCatGroupings;
