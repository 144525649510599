import React from 'react';
import OhsLeftSideDrawer from './leftSideDrawer/OhsLeftSideDrawer';
import OhsRightSideDrawer from './OhsRightSideDrawer';
import OhsTopBar from './topbar/OhsTopBar';
import OhsTopFilterDrawer from './topFilter/OhsTopFilterDrawer';

export default function OhsDashbaordbars() {
  let isDisplay = true;
  if (
    window.location.pathname == null ||
    window.location.pathname.indexOf('/login') === 0 ||
    window.location.pathname.indexOf('/visitor/signout') === 0 ||
    (window.location.pathname.indexOf('/visitor') === 0 &&
      window.location.pathname.indexOf('/sign-in-out') > 0) ||
    (window.location.pathname.indexOf('/visitor') === 0 &&
      window.location.pathname.indexOf('/signout/') > 0)
  ) {
    isDisplay = false;
  }
  return (
    <div>
      {isDisplay === true && (
        <div>
          <OhsTopBar></OhsTopBar>
          <OhsLeftSideDrawer></OhsLeftSideDrawer>
          <OhsRightSideDrawer></OhsRightSideDrawer>
          <OhsTopFilterDrawer></OhsTopFilterDrawer>
        </div>
      )}
    </div>
  );
}
