import _ from 'lodash';
import React from 'react';
import { getOhsUser } from '../../../user/OhsUser';
import { OhsNoticeBoardRecord } from '../../OhsNoticeBoardModels';
import { getNoticeBoardList } from '../../OhsNoticeBoardServices';
import OhsNoticeBoardList from './OhsNoticeBoardList';
import { setTierName, TierType } from '../../../models/tier/OhsTier';
import OhsModuleBackground from '../../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../../global/register/OhsModuleHeader';
import OhsModuleListBackground from '../../../global/register/OhsModuleListBackground';
import OhsNoticeBoardAddEditModal from './OhsNoticeBoardAddEditModal';
import OhsModuleHeaderButtons from '../../../global/register/OhsModuleHeaderButtons';

interface PropsType {}

const mapBoards = function (
  t3list: Array<OhsNoticeBoardRecord>,
  t4list: Array<OhsNoticeBoardRecord>
) {
  t4list.forEach(function (t4plan: OhsNoticeBoardRecord) {
    let foundFlag = false;
    t3list.forEach(function (t3plan) {
      if (t4plan.allocationOf && t3plan._id === t4plan.allocationOf._id) {
        foundFlag = true;
        t3plan.subRows.push(t4plan);
      }
    });
    if (foundFlag === false) {
      t3list.push(t4plan);
    }
  });
};

export const loadNoticeBoardList = async (): Promise<
  Array<OhsNoticeBoardRecord>
> => {
  const user = getOhsUser();
  return getNoticeBoardList().then((list: Array<OhsNoticeBoardRecord>) => {
    let t3Boardlist = new Array<OhsNoticeBoardRecord>();
    let t4Boardlist = new Array<OhsNoticeBoardRecord>();
    list.forEach(function (d) {
      d.subRows = new Array<OhsNoticeBoardRecord>();

      setTierName(d.tier);

      if (d.allocationOf && user?.tierNum === 3) {
        t4Boardlist.push(d);
      } else {
        t3Boardlist.push(d);
      }
    });
    mapBoards(t3Boardlist, t4Boardlist);
    return t3Boardlist;
  });
};

class OhsNoticeBoardRegister extends React.Component<PropsType> {
  state = {
    noticeBoardList: new Array<OhsNoticeBoardRecord>(),
    operateBoard: {
      title: '',
      description: '',
      control: {},
      category: '',
      categoryOther: null,
      subcategory: '',
      subcategoryOther: null,
    } as OhsNoticeBoardRecord,
    addModalOpen: false,
  };
  async componentDidMount() {
    const noticeBoardList = await loadNoticeBoardList();
    this.setState({ noticeBoardList: noticeBoardList });
  }
  render() {
    const user = getOhsUser();
    const reloadNoticeBoardList = async () => {
      const noticeBoardList = await loadNoticeBoardList();
      this.setState({ noticeBoardList: noticeBoardList });
    };
    const onModalClose = async () => {
      reloadNoticeBoardList();
    };
    const setAddModal = (addModalOpen: boolean) => {
      this.setState({ addModalOpen: addModalOpen });
    };
    const setAddModalOpen = () => {
      this.setState({ addModalOpen: true });
    };

    return (
      <>
        <div>
          <OhsModuleHeader>
            <OhsModuleHeaderButtons
              createButton={{
                func: setAddModalOpen,
                permission:
                  user?.configs.noticeboard?.PERMISSIONS.create === true,
              }}
              archiveButton={{
                permission:
                  user?.configs.noticeboard?.PERMISSIONS.archive === true,
              }}
            />
          </OhsModuleHeader>
          <OhsModuleListBackground>
            <OhsNoticeBoardList
              reloadNoticeBoardList={reloadNoticeBoardList}
              noticeBoardList={this.state.noticeBoardList}
            />
          </OhsModuleListBackground>
        </div>
        <OhsNoticeBoardAddEditModal
          open={this.state.addModalOpen}
          setModalOpenFunc={setAddModal}
          isEdit={false}
          onClose={onModalClose}
          operateBoard={this.state.operateBoard}
        />
      </>
    );
  }
}

export default OhsNoticeBoardRegister;
