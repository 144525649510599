import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';
import {
  toggleLeftSideDrawer,
  toggleRightSideDrawer,
  toggleTopFilterDrawer,
  bannerContentType,
} from '../OhsDashboardSlice';
import { OhsUser } from '../../user/OhsUser';
import { SmallScreenWidthBreakpoint } from '../../global/constants/OhsLayoutConstants';
import {
  getHomeUrl,
  goToHomepage,
  OhsLogout,
} from '../../login/OhsLoginServices';
import { getAngularIframeWindow } from '../../global/angular-iframe/OhsAngularIframeServices';
import OhsBreadcrumbs from '../breadcrumbs/OhsBreadcrumbs';
import { Box, Divider, List, ListItem, Menu } from '@mui/material';
import OhsMorphButtons from './OhsMorphButtons';
import Typography from '@mui/material/Typography';
import { OhsMaterialIconButton } from '../../global/buttons/OhsMaterialButton';
import { OhsFreemiumTopbar } from './OhsFreemiumTopbar';
import { getLocalStorage } from '../../common/OhsDataParse';
import { OhsMultiUserLoginOption } from '../../login/OhsMultiUserLoginOption';
import _, { identity } from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../../common/OhsApi';
import { Icon } from 'semantic-ui-react';
import { OhsPrintButton } from './OhsPrintButton';

import DashboardBannerPreview from '../banner/item/OhsDashboardBannerItem';
import DashboardBannerList from '../banner/item/OhsDashboardBanner';
import OhsWithRouter from '../../global/utils/OhsWithRouter';
import { compose } from 'redux';
import {
  getModuleConfigValue,
  loginMultiUser,
  updateUseOrgDetails,
} from '../../user/UserServices';
import OhsMfaSettingsOnLoginModal, {
  MfaUserDetailsTypes,
} from '../../login/OhsMfaSettingsOnLoginModal';
import OhsMfaMandatoryMessageModal from '../../user/user-settings/mfa/modal/OhsMfaMandatoryMessageModal';
import { ModuleType } from '../../models/OhsObject';
import {
  AdminSwitchOptionMap,
  getMfaStatusStringVal,
} from '../../user/configurations/OhsAdmin2Config';
import OhsQuickInspection from '../../inspection/list/OhsQuickInspection';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  hideOnSmallScreen: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'flex',
    height: '40px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  signOutButton: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  quickAccessButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'block',
    },
  },
  avatarButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },
  avatarRoot: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    color: 'black',
    background: 'white',
  },
  icon: {
    height: theme.spacing(3),
    weight: theme.spacing(3),
  },
  midButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

interface IProps {
  toggleLeftSideDrawer: (isOpen: boolean) => {};
  toggleRightSideDrawer: (isOpen: boolean) => {};
  toggleTopFilterDrawer: (isOpen: boolean) => {};
  showBanner: boolean;
  showBannerPreview: boolean;
  bannerPreviewContent: bannerContentType;
  router: any;
}

interface IState {
  anchorEl: null | HTMLElement;
  mfaUserDetails: undefined | MfaUserDetailsTypes;
  openMandatoryMfaModal: boolean;
  openMfaSettingsOnSwitchModal: boolean;
  openMfaSettingsOnLoginModal: boolean;
  userAuthResponse: undefined | any;
  openInspectionModal: boolean;
}
class OhsTopBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: null,
      mfaUserDetails: undefined,
      openMandatoryMfaModal: false,
      openMfaSettingsOnSwitchModal: false,
      openMfaSettingsOnLoginModal: false,
      userAuthResponse: undefined,
      openInspectionModal: false,
    };
  }
  render() {
    const {
      classes,
      showBanner,
      showBannerPreview,
      bannerPreviewContent,
      router,
    } = this.props as any;
    const toggleLeftSideDrawer = (open: boolean) => (event: any) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      this.props.toggleLeftSideDrawer(open);
    };

    const toggleTopFilterDrawer = (open: boolean) => (event: any) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      this.props.toggleTopFilterDrawer(open);
    };

    const goBack = () => {
      window.history.back();
    };

    const openInspectionStartModal = () => {
      const iframeWindow = getAngularIframeWindow();
      iframeWindow.angular
        .element(iframeWindow.document.body)
        .injector()
        .get('ohsInspectionService')
        .getAvailableList();
    };

    const handleOpenInspectionModal = () => {
      this.setState({ openInspectionModal: !this.state.openInspectionModal });
    };
    (window as any).handleOpenInspectionModal = handleOpenInspectionModal;
    const user = new OhsUser();

    const menuId = 'primary-search-account-menu';
    const isMenuOpen = Boolean(this.state.anchorEl);
    const handleMenuClose = () => {
      this.setState({ anchorEl: null });
    };

    let userOptions = new Array<OhsMultiUserLoginOption>();
    const multiUserPayload = getLocalStorage('multi-users');
    if (
      multiUserPayload &&
      multiUserPayload.item &&
      multiUserPayload.item.options.length > 0
    ) {
      userOptions = multiUserPayload.item
        .options as Array<OhsMultiUserLoginOption>;
    }

    const handleClickLoginOutButton = (
      event: React.MouseEvent<HTMLElement>
    ) => {
      if (userOptions.length > 1) {
        this.setState({ anchorEl: event.currentTarget });
      } else {
        OhsLogout();
      }
    };

    const handleActivateMfa = () => {
      this.setState({
        openMfaSettingsOnLoginModal: true,
        openMandatoryMfaModal: false,
      });
    };
    const handleMandatoryMfaModal = (val: boolean) => {
      this.setState({ openMandatoryMfaModal: val });
    };

    const handleUpdateUseOrgDetails = async (loginRes: any) => {
      const response = await updateUseOrgDetails(loginRes);
      if (_.has(response, 'success') === true && response.success === true) {
        window.location.href = getHomeUrl();
      } else {
        toast.error('Login failed.');
      }
    };
    const handleMfaSettingsOnLoginModal = (val: boolean) => {
      this.setState({ openMfaSettingsOnLoginModal: val });
    };

    const handleMfaEnrollmentSuccess = () => {
      this.setState({ openMfaSettingsOnLoginModal: false });
      handleUpdateUseOrgDetails(this.state.userAuthResponse);
    };

    const handleDialogClose = (id: string) => {
      const userOptions = getLocalStorage('multi-users');
      ohsApiClient.defaults.headers.common['Authorization'] = userOptions.token;
      if (id) {
        loginMultiUser(id).then((response: any) => {
          // Get MFA settings from response configuration
          const getMfaUserConfigValue: string = getModuleConfigValue(
            response.result.item.configuration,
            ModuleType.Admin2,
            'MFA'
          );

          if (
            _.isNil(response.result.item.mfa) &&
            getMfaUserConfigValue === AdminSwitchOptionMap.required &&
            user.isSso !== true
          ) {
            // MFA Register component
            const { email, phone, phoneCountryCode, tier } =
              response.result.item;
            this.setState({
              mfaUserDetails: {
                email,
                phoneCountryCode,
                phone,
                tier,
                mfaConfigStatus:
                  getMfaStatusStringVal(getMfaUserConfigValue) ?? '',
              },
              openMandatoryMfaModal: true,
            });
            this.setState({ userAuthResponse: response });
          } else {
            handleUpdateUseOrgDetails(response);
          }
        });
      }
    };
    const renderMenu = (
      <Box width={400}>
        <Menu
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          id={menuId}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <Box px={6}>
            <Box py={4}>
              <Typography variant="h4" fontWeight={'bold'}>
                Log out or switch
              </Typography>
              <Typography style={{ color: 'black' }}>
                Pick a user to switch to, or select Log Out below
              </Typography>
            </Box>
          </Box>
          <List>
            {userOptions.map((item, index) => (
              <div key={index}>
                <Divider />
                <ListItem
                  button
                  onClick={() => handleDialogClose(item.user._id)}
                  style={
                    item.user._id === user._id
                      ? { backgroundColor: 'rgba(0, 0, 0, 0.05)' }
                      : {}
                  }
                >
                  {item.workerGroupTier && (
                    <Box px={4} py={2}>
                      {user._id === item.user._id && (
                        <Box pb={1}>
                          <Typography
                            className={classes.pos}
                            color="textSecondary"
                          >
                            YOU'RE CURRENTLY LOGGED IN AS:
                          </Typography>
                        </Box>
                      )}
                      <Typography style={{ fontWeight: 'bold' }}>
                        {item.workerGroupTier.name} (Worker Group)
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Profile: {item.user.profile.title}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {item.workplaceTier.name + ' < ' + item.orgTier.name}
                      </Typography>
                    </Box>
                  )}
                  {item.workerGroupTier == null && item.workplaceTier && (
                    <Box px={4} py={2}>
                      {user._id === item.user._id && (
                        <Box pb={1}>
                          <Typography
                            className={classes.pos}
                            color="textSecondary"
                          >
                            YOU'RE CURRENTLY LOGGED IN AS:
                          </Typography>
                        </Box>
                      )}
                      <Typography style={{ fontWeight: 'bold' }}>
                        {item.workplaceTier.name} (Workplace)
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Profile: {item.user.profile.title}
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        {item.orgTier.name}
                      </Typography>
                    </Box>
                  )}
                  {item.workerGroupTier == null &&
                    item.workplaceTier == null &&
                    item.orgTier && (
                      <Box px={4} py={2}>
                        {user._id === item.user._id && (
                          <Box pb={1}>
                            <Typography
                              className={classes.pos}
                              color="textSecondary"
                            >
                              YOU'RE CURRENTLY LOGGED IN AS:
                            </Typography>
                          </Box>
                        )}
                        <Typography style={{ fontWeight: 'bold' }}>
                          {item.orgTier.name} (Organisation)
                        </Typography>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Profile: {item.user.profile.title}
                        </Typography>
                      </Box>
                    )}
                  {item.workerGroupTier == null &&
                    item.workplaceTier == null &&
                    item.orgTier == null &&
                    item.orgAdminTier && (
                      <Box px={4} py={2}>
                        {user._id === item.user._id && (
                          <Box pb={1}>
                            <Typography
                              className={classes.pos}
                              color="textSecondary"
                            >
                              YOU'RE CURRENTLY LOGGED IN AS:
                            </Typography>
                          </Box>
                        )}
                        <Typography style={{ fontWeight: 'bold' }}>
                          {item.orgAdminTier.name} (Org Admin)
                        </Typography>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Profile: {item.user.profile.title}
                        </Typography>
                      </Box>
                    )}
                </ListItem>
              </div>
            ))}
          </List>

          <Divider></Divider>
          <ListItem
            button
            onClick={OhsLogout}
            style={{ textAlign: 'center', justifyContent: 'center' }}
          >
            <Box display="flex" px={4} py={2}>
              <Typography
                variant="h4"
                color="textSecondary"
                style={{
                  textDecoration: 'underline',
                }}
              >
                LOG OUT
              </Typography>
            </Box>
          </ListItem>
        </Menu>
      </Box>
    );
    return (
      <div>
        <AppBar
          position="static"
          style={{
            minHeight: '40px',
          }}
        >
          <Toolbar
            style={{
              minHeight: '40px',
              background: user.configs.theme.HEADER_BAR_BG_COLOR,
            }}
          >
            {user.tierNum !== 5 && (
              <>
                <OhsMaterialIconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleLeftSideDrawer(true)}
                  size="small"
                >
                  <Icon name="content" />
                </OhsMaterialIconButton>
                <Divider orientation="vertical" flexItem />
              </>
            )}

            <OhsMaterialIconButton
              color="inherit"
              size="small"
              className={classes.iconButton}
              onClick={goToHomepage}
            >
              <Icon name="home" />
            </OhsMaterialIconButton>
            <Divider orientation="vertical" flexItem />
            <OhsMaterialIconButton
              color="inherit"
              size="small"
              className={classes.iconButton}
              onClick={goBack}
            >
              <Icon name="arrow left" />
            </OhsMaterialIconButton>
            <Divider orientation="vertical" flexItem />
            {user.tierNum > 2 && user.tierNum < 5 && (
              <>
                {user.configs.incident?.PERMISSIONS.create === true && (
                  <>
                    <OhsMaterialIconButton
                      color="inherit"
                      size="small"
                      className={classes.quickAccessButton}
                      onClick={() => {
                        window.location.href = '/ai/report';
                      }}
                    >
                      <Icon name="plus" />
                      &nbsp;&nbsp;Report {user.configs.incident?.TITLE}
                    </OhsMaterialIconButton>
                    <Divider
                      className={classes.hideOnSmallScreen}
                      orientation="vertical"
                      flexItem
                    />
                  </>
                )}

                {user.configs.action?.PERMISSIONS.create === true && (
                  <>
                    <OhsMaterialIconButton
                      color="inherit"
                      size="small"
                      className={classes.quickAccessButton}
                      onClick={() => {
                        window.location.href = '/action/add';
                      }}
                    >
                      <Icon name="plus" />
                      &nbsp;&nbsp;New {user.configs.action?.TITLE}
                    </OhsMaterialIconButton>
                    <Divider
                      className={classes.hideOnSmallScreen}
                      orientation="vertical"
                      flexItem
                    />
                  </>
                )}
                {user.configs.inspection?.PERMISSIONS.view === true &&
                  user.configs.inspection?.PERMISSIONS.create === true &&
                  user.configs.inspection?.PERMISSIONS.signoff === true && (
                    <>
                      <OhsMaterialIconButton
                        color="inherit"
                        size="small"
                        className={classes.quickAccessButton}
                        onClick={handleOpenInspectionModal}
                      >
                        <Icon name="plus" />
                        &nbsp;&nbsp;{user.configs.inspection?.TITLE}
                      </OhsMaterialIconButton>
                      <Divider
                        className={classes.hideOnSmallScreen}
                        orientation="vertical"
                        flexItem
                      />
                    </>
                  )}
              </>
            )}

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Divider
                className={classes.hideOnSmallScreen}
                orientation="vertical"
                flexItem
              />
              {user.tierNum !== 5 && (
                <Box className={classes.hideOnSmallScreen} mx={1}>
                  <div>
                    <div
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        marginTop: '3px',
                      }}
                    >
                      {user.name.length <= 30
                        ? user.name.toUpperCase()
                        : user.name.substring(0, 30).toUpperCase() + '...'}
                    </div>
                    {user.tierNum === 2 &&
                    user.isMorph === true &&
                    user.configs.admin2?.LOWER_TIER_ACCESS &&
                    user.configs.admin2?.LOWER_TIER_ACCESS[0] ? (
                      <div style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
                        {user.configs.admin2?.LOWER_TIER_ACCESS[0].name
                          .length <= 30
                          ? user.configs.admin2?.LOWER_TIER_ACCESS[0].name
                          : user.configs.admin2?.LOWER_TIER_ACCESS[0].name.substring(
                              0,
                              30
                            ) + '...'}
                      </div>
                    ) : (
                      <div style={{ fontSize: '0.9rem', marginTop: '-5px' }}>
                        {user.tier.name.length <= 30
                          ? user.tier.name
                          : user.tier.name.substring(0, 30) + '...'}
                      </div>
                    )}
                  </div>
                </Box>
              )}
              {user.tierNum === 5 && (
                <OhsMaterialIconButton
                  color="inherit"
                  size="small"
                  className={classes.avatarButton}
                  style={{
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    window.location.href = '/admin2/user-settings';
                  }}
                >
                  <Icon name="user" />

                  <span className={classes.hideOnSmallScreen}>
                    &nbsp;&nbsp;
                    {user.name.length <= 30
                      ? user.name.toUpperCase()
                      : user.name.substring(0, 30).toUpperCase() + '...'}
                  </span>
                </OhsMaterialIconButton>
              )}
              <Divider orientation="vertical" flexItem />
              <OhsMaterialIconButton
                color="inherit"
                onClick={openResourceCentre}
                size="small"
                className={classes.iconButton}
              >
                <Icon name="help" />
              </OhsMaterialIconButton>
              <Divider orientation="vertical" flexItem />
              {((user.tierNum === 2 && user.isMorph === true) ||
                user.tierNum === 3 ||
                (user.tierNum === 4 && user.isMorph === true)) && (
                <>
                  <OhsMorphButtons />
                  <Divider orientation="vertical" flexItem />
                </>
              )}

              {(user.tierNum === 3 ||
                (user.tierNum === 4 &&
                  window.location.pathname.indexOf('/task/active') === 0) ||
                (user.tierNum === 5 &&
                  window.location.pathname.indexOf('/task/active') === 0)) && (
                <>
                  <OhsMaterialIconButton
                    color="inherit"
                    onClick={toggleTopFilterDrawer(true)}
                    size="small"
                    className={classes.iconButton}
                  >
                    <Icon name="filter" />
                  </OhsMaterialIconButton>
                  <Divider orientation="vertical" flexItem />
                </>
              )}

              <OhsPrintButton></OhsPrintButton>
              <Divider
                className={classes.hideOnSmallScreen}
                orientation="vertical"
                flexItem
              />
              <OhsMaterialIconButton
                color="inherit"
                onClick={handleClickLoginOutButton}
                size="small"
                className={classes.signOutButton}
              >
                <Icon name="sign out" />
              </OhsMaterialIconButton>
            </div>
          </Toolbar>
          {user.level === 'free' && <OhsFreemiumTopbar />}

          {/* This is toggled on specific pages (onMount and onUnmount event) */}
          {showBanner && <DashboardBannerList />}
          {showBannerPreview && (
            <DashboardBannerPreview {...bannerPreviewContent} isPreview />
          )}
          <Toolbar
            style={{
              minHeight: '40px',
              background: user.configs.theme.MAIN_BG_COLOR,
            }}
          >
            <OhsBreadcrumbs />
          </Toolbar>
        </AppBar>
        {renderMenu}
        <OhsMfaMandatoryMessageModal
          open={this.state.openMandatoryMfaModal}
          setModalOpen={(val: boolean) => handleMandatoryMfaModal(val)}
          activateMfaMethod={handleActivateMfa}
          mfaConfigStatus={
            this.state.mfaUserDetails?.mfaConfigStatus ?? 'optional'
          }
          tier={this.state.mfaUserDetails?.tier}
        />
        <OhsMfaSettingsOnLoginModal
          open={this.state.openMfaSettingsOnLoginModal}
          setModalOpen={(val: boolean) => handleMfaSettingsOnLoginModal(val)}
          userDetails={this.state.mfaUserDetails}
          onSuccess={() => handleMfaEnrollmentSuccess()}
        />
        <OhsQuickInspection
          isOpen={this.state.openInspectionModal}
          setModalOpen={handleOpenInspectionModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    showBanner: state.dashboardReducer.topBannerToggleOpen,
    showBannerPreview: state.dashboardReducer.topBannerTogglePreviewOpen,
    bannerPreviewContent: state.dashboardReducer.topBannerContentPreview,
  };
};

const AllowClass = (props: any) => {
  const { classes } = useStyles();
  return <OhsTopBar {...props} classes={classes} />;
};

export default compose(
  OhsWithRouter,
  connect(mapStateToProps, {
    toggleLeftSideDrawer,
    toggleRightSideDrawer,
    toggleTopFilterDrawer,
  })
)(AllowClass);

export const openResourceCentre = () => {
  var moduleName = window.location.pathname.split('/')[1];
  if (moduleName !== 'resourceCentre') {
    window.location.href = '/resourcecentre/home?category=' + moduleName;
  }
};
