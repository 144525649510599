import React from 'react';
import { Row } from 'react-table';
import OhsRegister from '../../global/register/OhsRegister';
import { toLocalTime } from '../../global/utils/OhsDateServices';
import { OhsExportRecord } from './OhsExportRecord';
import { getExportDownloadUrl, getExportTypeName } from './OhsExportServices';

interface Props {
  exportList: OhsExportRecord[];
}

export const OhsExportCentreRegisterList = (props: Props) => {
  const columnConfig: any = [
    {
      id: 'exportedOnId',
      Header: 'Export Time',
      accessor: (originalRow: OhsExportRecord) => {
        return toLocalTime(originalRow.exportedOn);
      },
    },
    {
      Header: 'Export Type',
      accessor: (originalRow: OhsExportRecord) => {
        return getExportTypeName(originalRow.exportType);
      },
    },
  ];
  const columns = React.useMemo(() => columnConfig, [columnConfig]);

  const handleExport = async (row: Row) => {
    const exportRecord = row.original as OhsExportRecord;
    if (
      exportRecord &&
      exportRecord.attachments &&
      exportRecord.attachments.length > 0
    ) {
      const downloadUrl = await getExportDownloadUrl(
        exportRecord.attachments[0].storage?.AWS.S3.Key ?? ''
      );
      if (downloadUrl) {
        window.open(downloadUrl, '_blank');
      }
    }
  };
  return (
    <>
      <OhsRegister
        columns={columns}
        data={props.exportList}
        sortBy={[{ id: 'exportedOnId', desc: true }]}
        rowOperations={{ exportButton: { func: handleExport } }}
      />
    </>
  );
};
