import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import React from 'react';
import { Button, FormGroup } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import OhsModalFormSection from '../../../global/form/OhsModalFormSection';
import { editFormLayout } from '../../../global/theme/OhsPresetStyles';
import { getOhsUser, OhsMfaLimits, OhsMFAsettings } from '../../OhsUser';
import {
  fetchOrgLimitDetails,
  getUserSettings,
} from '../OhsUserSettingsServices';
import {
  getMfaMethodPermission,
  getMfaSettingsPermission,
} from '../OhsUserSettingsUtils';
import OhsMfaConfirmModal from './modal/OhsMfaConfirmModal';
import OhsMfaSettingsModal from './modal/OhsMfaSettingsModal';
import CircularProgress from '@mui/material/CircularProgress';
import { mfaChallenge, mfaResetEnrollment } from './OhsUserSettingsMFAservices';
import { OhsUserMFAOTPTypes } from './OhsUserSettingsModels';
import {
  MfaRequiredLimitsErrorMsg,
  OhsLoadingWrap,
} from './modal/OhsMfaModalMessages';

const useStyles = makeStyles()({
  section: {
    display: 'flex',
    flexDirection: 'column',
    '*': {
      fontFamily: `Lato,'Helvetica Neue',Arial`,
      fontSize: '14px',
    },
  },
  checkboxlabel: {
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    padding: '10px 12px',
    background: '#F6F8FA',
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  active: {
    color: '#1976d2 !important',
  },
  mfatitle: {
    color: 'black',
    marginRight: '10px',
    fontSize: '14px',
  },
  contentText: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

interface ConfirmModalInfo {
  confirmStatus: string;
  confirmButtonText: string;
  confirmKey: string;
  confirmModalTitle: string;
}

export interface UserSettingsMFAProps {
  title: string;
  email: string;
  phoneCountryCode: string;
  phoneNum: string;
  modalUse: boolean;
  onSuccess?: () => void;
  mfaConfigStatus?: string;
}
const OhsUserSettingsMFA = (props: UserSettingsMFAProps) => {
  const user = getOhsUser();
  const currentMfaConfig =
    user?.configs.admin2?.MFA ?? props.mfaConfigStatus ?? 'optional';

  const { classes } = useStyles();
  const formLayout = editFormLayout();
  const layout = formLayout.classes;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [mfaModalOpen, setMFAModalOpen] = React.useState(false);
  const [switchToMethod, setSwitchtoMethod] = React.useState('');
  const [mfaInfo, setMFAInfo] = React.useState<OhsUserMFAOTPTypes>(
    new OhsUserMFAOTPTypes()
  );
  const [mfaOptions, setState] = React.useState<OhsMFAsettings>();
  const [mfaLimits, setMfaLimits] = React.useState<OhsMfaLimits>({
    app: false,
    sms: false,
    email: false,
  });
  const [confirmDisableOpen, setConfirmDisableOpen] =
    React.useState<boolean>(false);
  const [confirmInfo, setConfirmInfo] = React.useState<ConfirmModalInfo>();

  const getUserMfaSettings = async () => {
    const getMfaSettings = await getUserSettings();
    setState(getMfaSettings.mfa);
  };

  const handleMFAChallenge = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSwitchtoMethod(event.target.name);
    if (mfaOptions?.method) {
      setConfirmInfo({
        confirmStatus: 'confirmSwitchMethod',
        confirmButtonText: 'Change',
        confirmKey: 'CHANGE',
        confirmModalTitle: 'Multi-Factor Authentication',
      });
      setConfirmDisableOpen(true);
    } else {
      handleMethodSwitching(event.target.name);
    }
  };

  const handleMethodSwitching = async (mfaMethod: string) => {
    const mfaChallengeReq = await mfaChallenge(mfaMethod, {
      email: props.email,
      sms: `+${props.phoneCountryCode} ${props.phoneNum}`,
    });
    if (mfaChallengeReq.success) {
      const prepareData = { ...mfaChallengeReq.data } as OhsUserMFAOTPTypes;
      setMFAInfo({ ...prepareData });
      setMFAModalOpen(true);
    }
  };

  const handleMfaSettingsModalonClose = (status: boolean) => {
    setMFAModalOpen(status);
    if (props.onSuccess) props.onSuccess();
  };

  const handleConfirmResetMethodReq = async () => {
    const resetReq = await mfaResetEnrollment();
    if (resetReq) {
      getUserMfaSettings();
      setSwitchtoMethod('');
    }
  };

  const handleConfirmMethodReq = async () => {
    if (confirmInfo?.confirmStatus === 'confirmDisable') {
      handleConfirmResetMethodReq();
    } else {
      handleMethodSwitching(switchToMethod);
    }
  };

  React.useEffect(() => {
    getUserMfaSettings();
  }, [mfaModalOpen]);

  const getErrorMessage = () => {
    let errMsg = '';
    if (!props.phoneCountryCode) {
      errMsg = 'Please set your country code on your user settings.';
    }
    if (!props.phoneNum) {
      errMsg = 'Please set your phone number on your user settings.';
    }
    return errMsg;
  };

  React.useEffect(() => {
    const getOrgLimits = async () => {
      setIsLoading(true);
      const orgLimits = await fetchOrgLimitDetails({ ...user?.tier });
      setIsLoading(false);
      setMfaLimits(orgLimits.counts.mfa);
    };

    if ((user && user?.tierNum > 2) || !user) {
      getOrgLimits();
    } else {
      setMfaLimits({
        app: true,
        sms: true,
        email: true,
      });
    }
  }, []);

  const getMfaLimitsData = mfaLimits
    ? Object.values(mfaLimits).includes(true)
    : false;

  const hasMfaMethodPermission = (mfaMethod: string) => {
    return getMfaMethodPermission(
      mfaMethod,
      mfaLimits,
      currentMfaConfig,
      mfaOptions
    );
  };

  const hasMfaSettingsPermission = getMfaSettingsPermission(
    currentMfaConfig,
    mfaOptions,
    getMfaLimitsData
  );
  return (
    <>
      {hasMfaSettingsPermission ? (
        <>
          <OhsModalFormSection title={props.title}>
            <Box className={classes.section}>
              {hasMfaSettingsPermission && (
                <FormLabel sx={{ display: 'flex' }}>
                  Please select one of the following options:
                </FormLabel>
              )}
              <Box>
                <FormControl
                  sx={{ m: 1 }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup className={classes.section}>
                    {hasMfaMethodPermission('app') ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              mfaOptions?.method === 'app' ? classes.active : ''
                            }
                            checked={mfaOptions?.method === 'app'}
                            onChange={handleMFAChallenge}
                            name="app"
                            disabled={mfaOptions?.method === 'app'}
                          />
                        }
                        label={
                          <Box className={classes.checkboxlabel}>
                            <Box className={classes.mfatitle}>
                              Authenticator App
                            </Box>
                          </Box>
                        }
                      />
                    ) : (
                      ''
                    )}

                    {hasMfaMethodPermission('email') ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              mfaOptions?.method === 'email'
                                ? classes.active
                                : ''
                            }
                            checked={mfaOptions?.method === 'email'}
                            onChange={handleMFAChallenge}
                            name="email"
                            disabled={mfaOptions?.method === 'email'}
                          />
                        }
                        label={
                          <Box className={classes.checkboxlabel}>
                            <Box className={classes.mfatitle}>Email</Box>
                            <FormHelperText>
                              {props.email
                                ? `(${props.email})`
                                : 'Please set your email on your user settings.'}
                            </FormHelperText>
                          </Box>
                        }
                      />
                    ) : (
                      ''
                    )}

                    {hasMfaMethodPermission('sms') ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={
                              mfaOptions?.method === 'sms' ? classes.active : ''
                            }
                            checked={mfaOptions?.method === 'sms'}
                            onChange={handleMFAChallenge}
                            name="sms"
                            disabled={
                              !props.phoneNum ||
                              !props.phoneCountryCode ||
                              mfaOptions?.method === 'sms'
                            }
                          />
                        }
                        label={
                          <Box className={classes.checkboxlabel}>
                            <Box className={classes.mfatitle}>SMS</Box>
                            <FormHelperText>
                              {props.phoneNum && props.phoneCountryCode
                                ? `(+${props.phoneCountryCode} ${props.phoneNum})`
                                : getErrorMessage()}
                            </FormHelperText>
                          </Box>
                        }
                      />
                    ) : (
                      ''
                    )}
                  </FormGroup>
                </FormControl>
              </Box>
            </Box>
          </OhsModalFormSection>
          {!props.modalUse && (
            <Box className={layout.rightbutton}>
              <Button
                disabled={!mfaOptions}
                onClick={() => {
                  setConfirmInfo({
                    confirmStatus: 'confirmDisable',
                    confirmButtonText: 'Disable',
                    confirmKey: 'DISABLE',
                    confirmModalTitle: 'Disable Two-factor Authentication',
                  });
                  setConfirmDisableOpen(true);
                }}
                color={'red'}
              >
                Disable Multi-Factor Authentication
              </Button>
            </Box>
          )}
        </>
      ) : isLoading ? (
        <OhsLoadingWrap>
          <CircularProgress />
        </OhsLoadingWrap>
      ) : (
        <OhsModalFormSection title={props.title}>
          <MfaRequiredLimitsErrorMsg />
        </OhsModalFormSection>
      )}

      <OhsMfaSettingsModal
        mfaInfo={mfaInfo}
        setMFAInfo={setMFAInfo}
        setMFAModalOpen={handleMfaSettingsModalonClose}
        mfaModalOpen={mfaModalOpen}
        switchToMethod={switchToMethod}
      />

      <OhsMfaConfirmModal
        open={confirmDisableOpen}
        setModalOpenFunc={setConfirmDisableOpen}
        handleConfirmFunc={handleConfirmMethodReq}
        confirmStatus={confirmInfo?.confirmStatus ?? ''}
        keyName={confirmInfo?.confirmKey ?? ''}
        confirmModalTitle={confirmInfo?.confirmModalTitle ?? ''}
        confirmButtonText={confirmInfo?.confirmButtonText ?? ''}
      />
    </>
  );
};

export default OhsUserSettingsMFA;
