import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
export class OhsContractorPermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  signoff: boolean;
  archive: boolean;
  allocate: boolean;
  delete: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.delete = flag;
    this.allocate = flag;
  }
}
export class OhsContractorConfig {
  TITLE: string;
  PERMISSIONS: OhsContractorPermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsContractorPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}
