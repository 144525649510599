import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../../common/OhsApi';
import { TierType } from '../../models/tier/OhsTier';
import {
  combineUserInfoAndUserOrgInfo,
  getUseOrgDetails,
  initUpdateUserConfigObject,
} from '../UserServices';
import { getMultiUserConfig } from './OhsUserSettingsUtils';

export const getUserSettings = (): Promise<any> => {
  const endpoint = '/users/fetch';
  const errorMessage = `Unable to get the  list.`;

  return ohsApiClient
    .get(endpoint)
    .then((response: any) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        let userData = { result: { user: {} } };
        userData.result.user = response.data.result.item;
        initUpdateUserConfigObject(userData);

        return response.data.result.item;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return [];
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const updateUserDetails = async (
  userFormData: any
): Promise<any | null> => {
  let endpoint = `/users/edit`;
  const errMsg = `Error ! unable to update your user settings`;
  const currentPwError = 'The "Current Password" is incorrect.';

  return ohsApiClient
    .post(endpoint, userFormData)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const multiConfig = getMultiUserConfig();

        // Multi User
        if (multiConfig && multiConfig.token) {
          updateMultiUserDetails(userFormData);
        } else {
          let userData = { result: { user: {} } };
          userData.result.user = userFormData;
          getUseOrgDetails().then(function (userOrgInfo) {
            userData = combineUserInfoAndUserOrgInfo(userData, userOrgInfo);
            initUpdateUserConfigObject(userData); // Update Module Config Localstorage
          });
          toast.success('User profile updated.');
          return userData;
        }
      } else {
        console.error(response);
        const errorString = JSON.stringify(response);
        console.log(errorString);
        if (
          errorString &&
          errorString.indexOf('core.user:old_password_rejected') >= 0
        ) {
          toast.error(currentPwError);
        } else toast.error(errMsg);
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errMsg);
      return null;
    });
};

export const updateMultiUserDetails = (
  userFormData: any
): Promise<any | null> => {
  let endpoint = `/users/multiuser/edit`;
  const multiConfig = getMultiUserConfig();

  if (multiConfig && multiConfig.token) {
    ohsApiClient.defaults.headers.common['Authorization'] = multiConfig.token;
  }

  const errMsg = `Error ! unable to update your user settings`;
  const currentPwError = 'The "Current Password" is incorrect.';
  return ohsApiClient
    .post(endpoint, userFormData)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        let userData = { result: { user: {} } };
        userData.result.user = userFormData;
        initUpdateUserConfigObject(userData); // Update Module Config Localstorage
        toast.success('User profile (Multiuser) updated.');

        return response.data;
      } else {
        console.error(response);
        const errorString = JSON.stringify(response);

        if (
          errorString &&
          errorString.indexOf('core.user:old_password_rejected') >= 0
        ) {
          toast.error(currentPwError);
        } else toast.error(errMsg);

        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};

export const fetchOrgLimitDetails = (orgTier: {
  type?: TierType | undefined;
  _id?: string | undefined;
  name?: string | undefined;
}): Promise<any> => {
  const endpoint = '/admin2/counts';
  const errorMessage = `Unable to fetch the org limit details`;

  return ohsApiClient
    .post(endpoint, { orgTier: { ...orgTier } })
    .then((response: any) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
        toast.error(errorMessage);
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return null;
    });
};
