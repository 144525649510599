import React from 'react';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableElementProps,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { makeStyles } from 'tss-react/mui';

interface Props {
  list: any[];
  setList: (value: any[]) => void;
  renderItem: (value: any, index: number) => JSX.Element;
  withHandle?: boolean;
}
interface HandleProps {
  icon: React.ReactNode;
}

const useStyles = makeStyles()(() => ({
  sortableHelper: {
    zIndex: 1400,
  },
  sortItem: {
    borderRadius: '2px',
    display: 'flex',
    margin: '2px',
    minWidth: '0px',
    boxSizing: 'border-box',
  },
  sortList: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 0%',
    flexWrap: 'wrap',
    padding: '2px 8px',
    position: 'relative',
    overflow: 'auto',
    boxSizing: 'border-box',
  },
}));

export const DragHandle = SortableHandle<HandleProps>(
  (props: HandleProps) => props.icon
);

const OhsDragAndDrop = ({ renderItem, list, setList, withHandle }: Props) => {
  const { classes } = useStyles();

  const SortableItem: React.ComponentClass<
    SortableElementProps & { value: any; index: number },
    any
  > = SortableElement(({ value, index }: { value: any; index: number }) => (
    <div className={classes.sortItem}>{renderItem(value, index)}</div>
  ));

  const SortableList: React.ComponentClass<
    SortableContainerProps & { items: any[] },
    any
  > = SortableContainer(({ items }: { items: any[] }) => {
    return (
      <div className={classes.sortList}>
        {items.map((value: any, index: number) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const onSortEnd: SortEndHandler = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const newValue = arrayMove(list, oldIndex, newIndex);
    setList(newValue);
  };

  if (list.length < 1) return <div />;
  return (
    <SortableList
      axis="xy"
      lockAxis="xy"
      items={list}
      onSortEnd={onSortEnd}
      transitionDuration={300}
      lockToContainerEdges={true}
      useDragHandle={withHandle}
      helperClass={classes.sortableHelper}
    />
  );
};

export default OhsDragAndDrop;
