import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from '../leftSideDrawer/OhsLeftSideDrawerModuleList';
import { OhsBreadcrumbsNameAndRef } from './OhsBreadcrumbsServices';

export const OhsBreadCrumbCorrespondence = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  user: OhsUser
) => {
  if (
    (subPath === 'home' && !viewPath) ||
    (subPath === 'home' && viewPath === 'communications')
  ) {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.correspondence?.TITLE ?? '',
    };
  } else if (subPath === 'home' && viewPath === 'template') {
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/home/`,
      name: 'Communication',
    };
    ohsBreadcrumbsNameAndRef.leafName = {
      name: 'Template',
    };
  } else if (subPath === 'template') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: `/${modulePath}/home/`,
      name: 'Communication',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/home/template?archived=false`,
      name: 'Template',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View/Edit' };
  } else if (subPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).correspondence,
      name: user.configs.correspondence?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'New' };
  } else if (viewPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).correspondence,
      name: user.configs.correspondence?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View/Edit' };
  } else if (viewPath === 'exchange') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).correspondence,
      name: user.configs.correspondence?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/edit?rollArchived=false&exchangeArchived=flase`,
      name: 'Recipient Register',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Recipient' };
  } else if (subPath === 'home' && viewPath === 'form') {
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/home/`,
      name: 'Communication',
    };
    ohsBreadcrumbsNameAndRef.leafName = {
      name: 'Form',
    };
  } else if (subPath === 'form' && viewPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: `/${modulePath}/home/`,
      name: 'Communication',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/home/form?archived=false`,
      name: 'Form',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Add' };
  } else if (subPath === 'form') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: `/${modulePath}/home/`,
      name: 'Communication',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/home/form?archived=false`,
      name: 'Form',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  }
  return ohsBreadcrumbsNameAndRef;
};
