import ohsApiClient from '../../common/OhsApi';
import * as _ from 'lodash';
import {
  combineUserInfoAndUserOrgInfo,
  getUseOrgDetails,
  initUserObject,
} from '../UserServices';
import { setAngularLocalStorage } from '../../common/OhsDataParse';
export const morphInto = function (tier4Id: string) {
  var endpoint = '/users/morph';
  var payload = {
    target: {
      type: 'core.tier.T4',
      _id: tier4Id,
    },
  };
  return ohsApiClient
    .post(endpoint, payload)
    .then(function (response) {
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.token
      ) {
        ohsApiClient.defaults.headers.common['Authorization'] =
          response.data.result.token;
        return getUseOrgDetails().then((useOrgDetailsResponse) => {
          if (_.has(useOrgDetailsResponse, 'data.result')) {
            const userInfo = combineUserInfoAndUserOrgInfo(
              response.data,
              useOrgDetailsResponse.data.result
            );
            initUserObject(userInfo);

            if (userInfo && userInfo.success === true) {
              setAngularLocalStorage('isMorph', true);
            }
            return userInfo;
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const morphBack = function () {
  var endpoint = '/users/unmorph';

  return ohsApiClient
    .post(endpoint, {})
    .then(function (response) {
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.token
      ) {
        ohsApiClient.defaults.headers.common['Authorization'] =
          response.data.result.token;
        return getUseOrgDetails().then((useOrgDetailsResponse) => {
          if (_.has(useOrgDetailsResponse, 'data.result')) {
            const userInfo = combineUserInfoAndUserOrgInfo(
              response.data,
              useOrgDetailsResponse.data.result
            );
            initUserObject(userInfo);

            if (userInfo && userInfo.success === true) {
              setAngularLocalStorage('isMorph', false);
            }
            return userInfo;
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const morphUpTier2 = function () {
  var endpoint = '/users/morph';

  return ohsApiClient
    .post(endpoint, {
      target: {
        type: 'core.tier.T2',
      },
    })
    .then(function (response) {
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result.token
      ) {
        ohsApiClient.defaults.headers.common['Authorization'] =
          response.data.result.token;
        return getUseOrgDetails().then((useOrgDetailsResponse) => {
          if (_.has(useOrgDetailsResponse, 'data.result')) {
            const userInfo = combineUserInfoAndUserOrgInfo(
              response.data,
              useOrgDetailsResponse.data.result
            );
            initUserObject(userInfo);

            if (userInfo && userInfo.success === true) {
              setAngularLocalStorage('isMorph', true);
            }
            return userInfo;
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};
