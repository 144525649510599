import { getConfigValue } from './OhsConfigServices';
import { ModuleType } from '../../models/OhsObject';
import { setPermissions } from './OhsConfigurations';
import { OhsModuleCategory } from './OhsModuleCategory';
import { OhsModuleConfig } from './OhsModuleConfig';
import { OhsModulePermissions } from './OhsModulePermissions';
export class OhsNoticeboardConfig implements OhsModuleConfig {
  TITLE: string;
  PERMISSIONS: OhsModulePermissions;
  MORPH_PERMISSIONS: OhsModulePermissions;
  CATEGORY_LIST: OhsModuleCategory[];
  CONTACT_ROLE_LIST: { title: string }[];
  TYPE = ModuleType.Noticeboard;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CATEGORY_LIST = getConfigValue(config, 'CATEGORY_LIST');
    this.CONTACT_ROLE_LIST = getConfigValue(config, 'CONTACT_ROLE_LIST');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}
