import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OhsAngularIframe from '../global/angular-iframe/OhsAngularIframe';
import OhsRequireAuth from '../global/OhsRequireAuth';

const OhsMobileAppAdminRoutes = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <OhsRequireAuth>
            <OhsAngularIframe />
          </OhsRequireAuth>
        }
      />
    </Routes>
  );
};

export default OhsMobileAppAdminRoutes;
