import React from 'react';

import { FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import _ from 'lodash';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

interface Props extends OhsFormFieldBaseProps {
  options: Array<{ name: string; value: string | number }>;
}
function InputEle(props: Props) {
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );

  const result = props.ohsFormSettings.useFormMethods.watch(props.id) ?? [];
  const handleChange = (event: SelectChangeEvent) => {
    const value = (event.target.value as unknown as string[]) ?? [];

    props.ohsFormSettings.useFormMethods.setValue(props.id, value);
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };
  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: () => {
          const value =
            props.ohsFormSettings.useFormMethods.getValues(props.id) ?? [];
          if (props.required === true) {
            if (value && value.length > 0) {
              return true;
            }
          } else {
            return true;
          }

          return false;
        },
      },
    });
  }, [props.id, props.ohsFormSettings.useFormMethods, props.required, result]);
  return (
    <FormControl component="fieldset" fullWidth>
      <Select
        error={error != null}
        fullWidth
        margin={'dense'}
        required={props.required}
        displayEmpty
        multiple
        value={result}
        onChange={handleChange}
        variant={returnVariant(props.ohsFormSettings.layout)}
        style={returnFieldStyle(props.ohsFormSettings.layout, {
          marginTop: '8px',
        })}
      >
        <MenuItem value="" disabled>
          <span style={{ color: 'gray' }}>-- Please Select --</span>
        </MenuItem>
        {props.options.map((item) => {
          return (
            <MenuItem key={item.name} value={item.value}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default function OhsMultipleSelectField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
