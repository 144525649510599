import React from 'react';
import _ from 'lodash';
import { OhsNoticeboardContact } from '../OhsNoticeBoardModels';
import { Card } from 'semantic-ui-react';
import { Dialog, DialogContent } from '@mui/material';
import OhsModalHeader from '../../admin2/copy/OhsModalHeader';
import OhsModalForm from '../../global/form/OhsModalForm';
import OhsModal from '../../global/modal/OhsModal';

class OhsNoticeboardContactsModal extends React.Component<{
  contacts: OhsNoticeboardContact[];
  closeModal: any;
  title: string;
  open: boolean;
}> {
  render() {
    const { contacts, closeModal, title, open } = this.props;

    return (
      <>
        <OhsModal
          open={open}
          title={title}
          onClose={closeModal}
          maxWidth={'lg'}
          ContentNode={
            <>
              <OhsModalForm></OhsModalForm>
              <Card.Group centered style={{ marginBottom: '0px' }}>
                {contacts &&
                  contacts.map(function (contact, index) {
                    return (
                      <Card>
                        <Card.Content>
                          <Card.Header>{contact.name}</Card.Header>
                          <Card.Description>
                            <div>
                              {contact.role &&
                                contact.role.toLowerCase() !== 'other' && (
                                  <span>
                                    <strong>{contact.role}</strong>
                                  </span>
                                )}
                              {contact.role &&
                                contact.role.toLowerCase() === 'other' && (
                                  <span>
                                    <strong>{contact.roleOther}</strong>
                                  </span>
                                )}
                            </div>
                            <div
                              style={{
                                marginTop: '0.5em',
                              }}
                            >
                              <b>Telephone(s):</b>
                              <ul>
                                {contact.phones &&
                                  contact.phones.map(function (phone, index) {
                                    return <li>{phone}</li>;
                                  })}
                              </ul>
                            </div>
                            <div style={{ marginTop: '0.5em' }}>
                              <b>Email(s):</b>
                              <ul>
                                {contact.emails &&
                                  contact.emails.map(function (email, index) {
                                    return (
                                      <li>
                                        <a href='mailto:{email}'>{email}</a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <div style={{ marginTop: '0.5em' }}>
                              <b>Details:</b>
                              <div className='description'>
                                <p style={{ textAlign: 'left' }}>
                                  {contact.notes}
                                </p>
                              </div>
                            </div>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    );
                  })}
              </Card.Group>
            </>
          }
          actionNode={<></>}
        />

        {/* <div
          className="ui dimmer modals page transition visible active"
          ref={(el) => {
            if (el) {
              el.style.setProperty('display', 'flex', 'important');
            }
          }}
        >
          <div className="ui modal resourcePDFViewerModal active">
            <i
              className="close icon"
              onClick={() => closeModal()}
              style={{ top: '0.4em', right: '1em' }}
            />
            <div
              className="header"
              style={{
                textAlign: 'center',
                backgroundColor: '#3b5998',
                color: '#ffffff',
                padding: '0.8rem 1rem',
              }}
            >
              Contacts
            </div>
            <div
              className="content"
              style={{ padding: '10px', height: '100%' }}
            >
              <div className="column sixteen wide" style={{ height: '100%' }}>
                <Card.Group centered>
                  {contacts &&
                    contacts.map(function (contact, index) {
                      return (
                        <Card>
                          <Card.Content>
                            <Card.Header>{contact.name}</Card.Header>
                            <Card.Description>
                              <div>
                                {contact.role !== undefined &&
                                  contact.role.toLowerCase() !== 'other' && (
                                    <span>
                                      <strong>{contact.role}</strong>
                                    </span>
                                  )}
                                {contact.role !== undefined &&
                                  contact.role.toLowerCase() === 'other' && (
                                    <span>
                                      <strong>{contact.roleOther}</strong>
                                    </span>
                                  )}
                              </div>
                              <div
                                style={{
                                  marginTop: '0.5em',
                                }}
                              >
                                <b>Telephone(s):</b>
                                <ul>
                                  {contact.phones &&
                                    contact.phones.map(function (phone, index) {
                                      return <li>{phone}</li>;
                                    })}
                                </ul>
                              </div>
                              <div style={{ marginTop: '0.5em' }}>
                                <b>Email(s):</b>
                                <ul>
                                  {contact.emails &&
                                    contact.emails.map(function (email, index) {
                                      return (
                                        <li>
                                          <a href="mailto:{email}">{email}</a>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                              <div style={{ marginTop: '0.5em' }}>
                                <b>Details:</b>
                                <div className="description">
                                  <p style={{ textAlign: 'left' }}>
                                    {contact.notes}
                                  </p>
                                </div>
                              </div>
                            </Card.Description>
                          </Card.Content>
                        </Card>
                      );
                    })}
                </Card.Group>
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default OhsNoticeboardContactsModal;
