import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { OhsUser } from '../../user/OhsUser';
import { toggleRightSideDrawer } from '../OhsDashboardSlice';
import { morphBack } from '../../user/morph/MorphServices';
import { checkCurrentUrlAfterMorph } from '../../user/morph/UrlCheckServices';
import { OhsMaterialIconButton } from '../../global/buttons/OhsMaterialButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLevelDownAlt,
  faLevelUpAlt,
} from '@fortawesome/free-solid-svg-icons';
import { SmallScreenWidthBreakpoint } from '../../global/constants/OhsLayoutConstants';
interface IProps {
  toggleRightSideDrawer: (isOpen: boolean) => {};
}
const useStyles = (theme: Theme) =>
  createStyles({
    iconButton: {
      [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    },
  });
class OhsMorphButtons extends React.Component<IProps> {
  render() {
    const { classes } = this.props as any;
    const morphUp = () => {
      morphBack().then((response: any) => {
        if (response.success === true) {
          checkCurrentUrlAfterMorph();
        }
      });
    };
    const toggleRightSideDrawer = (open: boolean) => (event: any) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      this.props.toggleRightSideDrawer(open);
    };
    const user = new OhsUser();
    let button;
    if (user.isMorph === true) {
      button = (
        <OhsMaterialIconButton
          color="inherit"
          onClick={morphUp}
          size="small"
          className={classes.iconButton}
        >
          {user.tierNum === 2 ? (
            <FontAwesomeIcon icon={faLevelDownAlt} />
          ) : (
            <FontAwesomeIcon icon={faLevelUpAlt} />
          )}
        </OhsMaterialIconButton>
      );
    } else {
      button = (
        <OhsMaterialIconButton
          color="inherit"
          onClick={toggleRightSideDrawer(true)}
          size="small"
          className={classes.iconButton}
        >
          <FontAwesomeIcon icon={faLevelDownAlt} />
        </OhsMaterialIconButton>
      );
    }
    return button;
  }
}
export default connect(null, {
  toggleRightSideDrawer,
})(withStyles(useStyles)(OhsMorphButtons));
