import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';
import { setAttachmentsDownloadUrl } from '../global/attachment/OhsAttachmentServices';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import {
  OhsContractorLookup,
  OhsContractorRecord,
} from './OhsContractorModels';

export const getContractorRecord = (
  contractorId: string
): Promise<OhsContractorRecord | null> => {
  const endpoint = `/contractors/${contractorId}/fetch`;
  const errMsg = 'Unable to get the contractor record.';

  return ohsApiClient
    .get(endpoint)
    .then(async (response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        const contractorRecord = response.data.result
          .item as OhsContractorRecord;
        contractorRecord.attachments = await setAttachmentsDownloadUrl(
          contractorRecord.attachments
        );
        const getContractorLogo = _.find(contractorRecord.attachments, {
          group: 'logo',
        }) as OhsAttachment;
        contractorRecord.businessLogo = getContractorLogo;

        return response.data.result.item;
      } else {
        console.log(response);
        toast.error(errMsg);
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};
export const parseContraLookupList = (items: any[]) => {
  const contractorList = new Array<OhsContractorLookup>();
  items.forEach((contractor) => {
    if (contractor[0] && contractor[2]) {
      contractorList.push(
        new OhsContractorLookup(contractor[0], contractor[2], null, null)
      );
      if (contractor[7] && contractor[7].length > 0) {
        contractor[7].forEach((contact: any) => {
          if (contact[2] && contact[2].length > 0) {
            contact[2].forEach((email: string) => {
              contractorList.push(
                new OhsContractorLookup(
                  contractor[0],
                  contractor[2],
                  contact[0],
                  email
                )
              );
            });
          } else {
            contractorList.push(
              new OhsContractorLookup(
                contractor[0],
                contractor[2],
                contact[0],
                null
              )
            );
          }
        });
      }
    }
  });
  return contractorList;
};
