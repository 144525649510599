import React from 'react';
import { Routes, Route } from 'react-router-dom';

const OhsVisitorSignHome = React.lazy(
  () => import('./sign/OhsVisitorSignInHome')
);
const OhsVisitorSignOutHome = React.lazy(
  () => import('./sign/OhsVisitorSignOutHome')
);
const OhsVisitorSignOutSearchHome = React.lazy(
  () => import('./sign/OhsVisitorSignOutSearchHome')
);
const OhsVisitorUnloginSignInHome = React.lazy(
  () => import('./unloginSign/OhsVisitorUnloginSignInHome')
);
const OhsVisitorSignInOutCombine = React.lazy(
  () => import('./sign/OhsVisitorSignInOutCombineHome')
);
const OhsIframeModuleHome = React.lazy(
  () => import('../global/angular-iframe/OhsIframeModuleHome')
);

const OhsRequireAuth = React.lazy(() => import('../global/OhsRequireAuth'));

const OhsVisitorRoutes = () => {
  return (
    <React.Suspense fallback={null}>
      <Routes>
        <Route
          path={`:siteId/signin/:tierId?`}
          element={
            <OhsRequireAuth>
              <OhsVisitorSignHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:siteId/sign-in-out/:tierId?`}
          element={
            <OhsRequireAuth>
              <OhsVisitorSignInOutCombine />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`:siteId/signout/:visitId`}
          element={
            <OhsRequireAuth>
              <OhsVisitorSignOutHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path={`org/:orgId/site/:siteId`}
          element={<OhsVisitorUnloginSignInHome />}
        />
        <Route
          path={`signout`}
          element={
            <OhsRequireAuth>
              <OhsVisitorSignOutSearchHome />
            </OhsRequireAuth>
          }
        />
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsIframeModuleHome />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </React.Suspense>
  );
};

export default OhsVisitorRoutes;
