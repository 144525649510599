import { getAngularLocalStorage } from '../../common/OhsDataParse';
import { OhsActionConfig } from './OhsActionConfig';
import { OhsAdmin2Config } from './OhsAdmin2Config';
import { OhsChemicalConfig } from './OhsChemicalConfig';
import { OhsCorrespondenceConfig } from './OhsCorrespondenceConfig';
import { OhsIncidentConfig } from './OhsIncidentConfig';
import { OhsInspectionConfig } from './OhsInspectionConfig';
import { OhsThemeConfig } from './OhsThemeConfig';
import { OhsVisitorConfig } from './OhsVisitorConfig';
import { OhsReviewplanConfig } from './OhsReviewplanConfig';
import { OhsSafetyplanConfig } from './OhsSafetyplanConfig';
import { OhsContractorConfig } from './OhsContractorConfig';
import { OhsHrConfig } from './OhsHrConfig';
import { OhsVdocConfig } from './OhsVdocConfig';
import { OhsImportConfig } from './OhsImportConfig';
import { OhsBiConfig } from './OhsBiConfig';
import { OhsBixConfig } from './OhsBixConfig';
import { OhsNoticeboardConfig } from './OhsNoticeboardConfig';
import { OhsCorporateRiskConfig } from './OhsCorporateRiskConfig';
import { OhsAssetConfig } from './OhsAssetConfig';
import { OhsExportConfig } from './OhsExportConfig';

export class OhsConfigurations {
  admin2: OhsAdmin2Config | null;
  incident: OhsIncidentConfig | null;
  action: OhsActionConfig | null;
  inspection: OhsInspectionConfig | null;
  theme: OhsThemeConfig;
  visitor: OhsVisitorConfig | null;
  chemical: OhsChemicalConfig | null;
  correspondence: OhsCorrespondenceConfig | null;
  reviewplan: OhsReviewplanConfig | null;
  safetyplan: OhsSafetyplanConfig | null;
  contractor: OhsContractorConfig | null;
  hr: OhsHrConfig | null;
  vdoc: OhsVdocConfig | null;
  import: OhsImportConfig | null;
  bi: OhsBiConfig | null;
  bix: OhsBixConfig | null;
  noticeboard: OhsNoticeboardConfig | null;
  corporateRisk: OhsCorporateRiskConfig | null;
  asset: OhsAssetConfig | null;
  export: OhsExportConfig | null;
  constructor() {
    this.admin2 = null;
    const admin2Config = getAngularLocalStorage('admin2Config');
    if (admin2Config) {
      this.admin2 = new OhsAdmin2Config(admin2Config);
    }

    this.action = null;
    const configAction = getAngularLocalStorage('actionConfig');
    if (configAction) {
      this.action = new OhsActionConfig(configAction);
    }

    this.incident = null;
    const configIncident = getAngularLocalStorage('incidentConfig');
    if (configIncident != null) {
      this.incident = new OhsIncidentConfig(configIncident);
    }

    this.inspection = null;
    const configInspection = getAngularLocalStorage('inspectionConfig');
    if (configInspection) {
      this.inspection = new OhsInspectionConfig(configInspection);
    }

    const configTheme = getAngularLocalStorage('themeConfig');
    this.theme = new OhsThemeConfig(configTheme);

    this.visitor = null;
    const configVisitor = getAngularLocalStorage('visitorConfig');
    if (configVisitor) {
      this.visitor = new OhsVisitorConfig(configVisitor);
    }

    this.chemical = null;
    const configChemical = getAngularLocalStorage('chemicalConfig');
    if (configChemical) {
      this.chemical = new OhsChemicalConfig(configChemical);
    }

    this.correspondence = null;
    const configCorrespondence = getAngularLocalStorage('correspondenceConfig');
    if (configCorrespondence) {
      this.correspondence = new OhsCorrespondenceConfig(configCorrespondence);
    }

    this.reviewplan = null;
    const configReviewplan = getAngularLocalStorage('reviewplanConfig');
    if (configReviewplan) {
      this.reviewplan = new OhsReviewplanConfig(configReviewplan);
    }

    this.safetyplan = null;
    const configSafetyplan = getAngularLocalStorage('safetyplanConfig');
    if (configSafetyplan) {
      this.safetyplan = new OhsSafetyplanConfig(configSafetyplan);
    }

    this.contractor = null;
    const configContractor = getAngularLocalStorage('contractorConfig');
    if (configContractor) {
      this.contractor = new OhsContractorConfig(configContractor);
    }

    this.hr = null;
    const configHr = getAngularLocalStorage('hrConfig');
    if (configHr) {
      this.hr = new OhsHrConfig(configHr);
    }

    this.vdoc = null;
    const configVdoc = getAngularLocalStorage('vdocConfig');
    if (configVdoc) {
      this.vdoc = new OhsVdocConfig(configVdoc);
    }

    this.import = null;
    const configImport = getAngularLocalStorage('importConfig');
    if (configImport) {
      this.import = new OhsImportConfig(configImport);
    }

    this.bi = null;
    const configBi = getAngularLocalStorage('biConfig');
    if (configBi) {
      this.bi = new OhsBiConfig(configBi);
    }

    this.bix = null;
    const configBix = getAngularLocalStorage('bixConfig');
    if (configBix) {
      this.bix = new OhsBixConfig(configBix);
    }

    this.noticeboard = null;
    const noticeConfig = getAngularLocalStorage('noticeboardConfig');
    if (noticeConfig) {
      this.noticeboard = new OhsNoticeboardConfig(noticeConfig);
    }

    this.corporateRisk = null;
    const corporateRiskConfig = getAngularLocalStorage('criskConfig');
    if (corporateRiskConfig) {
      const corporateRiskFileBoxConfig = getAngularLocalStorage(
        'filebox.criskConfig'
      );
      this.corporateRisk = new OhsCorporateRiskConfig(
        corporateRiskConfig,
        corporateRiskFileBoxConfig
      );
    }

    this.asset = null;
    const assetConfig = getAngularLocalStorage('assetConfig');
    if (assetConfig) {
      const assetFileBoxConfig = getAngularLocalStorage('filebox.assetConfig');
      this.asset = new OhsAssetConfig(assetConfig, assetFileBoxConfig);
    }

    this.export = null;
    const exportConfig = getAngularLocalStorage('exportConfig');
    if (exportConfig) {
      this.export = new OhsAssetConfig(exportConfig);
    }
  }
}

export const setPermissions = (
  permissionObject: any,
  permissionConfig: Array<string>
) => {
  if (permissionConfig) {
    if (permissionConfig.includes('*')) {
      Object.keys(permissionObject).forEach((key) => {
        permissionObject[key] = true;
      });
    } else {
      permissionConfig.forEach((key) => {
        permissionObject[key] = true;
      });
    }
  }
  return permissionObject;
};
