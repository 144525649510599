import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OhsModuleBackground from '../../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../../global/register/OhsModuleHeader';
import OhsRegister from '../../../global/register/OhsRegister';

import { getBannerList } from '../OhsDashboardBannerServices';
import { OhsDashboardBannerRecord } from '../OhsDashboardBannerModels';
import { dashboardBannerColumns } from '../OhsDashboardBannerConstants';
import { AddButton } from '../OhsDashboardBannerStyledComponents';

export default function OhsDashboardBannerList() {
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState(
    new Array<OhsDashboardBannerRecord>()
  );

  useEffect(() => {
    getBannerList().then(setBannerList);
  }, []);

  return (
    <>
      <OhsModuleBackground>
        <OhsModuleHeader title="Dashboard Banner List">
          <AddButton
            style={{ float: 'right' }}
            onClick={() => navigate(`/dashboardbanner/add`)}
          />
        </OhsModuleHeader>
        <OhsRegister
          columns={dashboardBannerColumns}
          data={bannerList}
          rowOperations={{
            viewFunc: (data) => navigate(`/dashboardbanner/${data._id}/edit`),
          }}
        ></OhsRegister>
      </OhsModuleBackground>
    </>
  );
}
