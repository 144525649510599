import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import React from 'react';
import { getAngularLocalStorage } from '../../common/OhsDataParse';
import {
  OhsFilterList,
  OhsChemicalLocationFilter,
  updateFilterListByChemicalLocationFilter,
} from './FilterModels';

const noChemicalLocation = 'Chemicals with No Locations';

const useStyles = makeStyles()((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));
export const OhsChemicalLocationFilterCheckList = () => {
  const { classes } = useStyles();
  let ohsFilterList = getAngularLocalStorage('filterList') as OhsFilterList;
  let ohsChemicalLocationFilterList = new Array<OhsChemicalLocationFilter>();
  let isSelectAll = true;
  if (ohsFilterList && ohsFilterList.chemicalLocation) {
    for (const key in ohsFilterList.chemicalLocation) {
      if (ohsFilterList.chemicalLocation[key].checkbox !== true) {
        isSelectAll = false;
      }

      ohsChemicalLocationFilterList.push({
        checkbox: ohsFilterList.chemicalLocation[key].checkbox,
        obj: { name: key },
      });
    }
  }
  const findNoLocation = ohsChemicalLocationFilterList.find(
    (x) => x.obj.name === noChemicalLocation
  );
  if (!findNoLocation) {
    ohsChemicalLocationFilterList.push({
      checkbox: true,
      obj: { name: noChemicalLocation },
    });
  }

  const [
    ohsChemicalLocationFilterListState,
    setOhsChemicalLocationFilterListState,
  ] = React.useState(ohsChemicalLocationFilterList);
  const [selectAllState, setSelectAllState] = React.useState(isSelectAll);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    ohsChemicalLocationFilterList = [...ohsChemicalLocationFilterListState];

    let checkedModule = _.find(ohsChemicalLocationFilterList, {
      obj: { name: event.target.name },
    });

    if (checkedModule) checkedModule.checkbox = event.target.checked;

    let isSelectAll = true;
    ohsChemicalLocationFilterList.forEach((item) => {
      if (item.checkbox === false) isSelectAll = false;
    });
    updateFilterListByChemicalLocationFilter(
      ohsFilterList,
      ohsChemicalLocationFilterList
    );
    setSelectAllState(isSelectAll);

    setOhsChemicalLocationFilterListState(ohsChemicalLocationFilterList);
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    ohsChemicalLocationFilterList = [...ohsChemicalLocationFilterListState];
    ohsChemicalLocationFilterList.forEach((item) => {
      item.checkbox = event.target.checked;
    });
    updateFilterListByChemicalLocationFilter(
      ohsFilterList,
      ohsChemicalLocationFilterList
    );
    setSelectAllState(event.target.checked);

    setOhsChemicalLocationFilterListState(ohsChemicalLocationFilterList);
  };
  return (
    <div className={classes.paper}>
      <Box m={2}>
        <p>
          <b>Chemical Locations</b>
        </p>
      </Box>
      <div style={{ height: '320px' }}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllState}
                  onChange={handleSelectAll}
                  name="Select All"
                  size="small"
                  color="primary"
                />
              }
              label={<b>Select All</b>}
            />
            {ohsChemicalLocationFilterListState.map(
              (chemicalLocationFilter, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={chemicalLocationFilter.checkbox}
                        onChange={handleChange}
                        size="small"
                        color="primary"
                        name={chemicalLocationFilter.obj.name}
                      />
                    }
                    label={chemicalLocationFilter.obj.name}
                  />
                );
              }
            )}
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
};
