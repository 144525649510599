import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
import { OhsModulePermissions } from './OhsModulePermissions';
export class OhsInspectionPermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  signoff: boolean;
  archive: boolean;
  allocate: boolean;
  copy: boolean;
  delete: boolean;
  delete_one_off_child: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.allocate = flag;
    this.copy = flag;
    this.delete = flag;
    this.delete_one_off_child = flag;
  }
}
export class OhsInspectionConfig {
  TITLE!: string;
  PERMISSIONS!: OhsInspectionPermissions;
  MORPH_PERMISSIONS: OhsModulePermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');

    this.PERMISSIONS = new OhsInspectionPermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(
      this.MORPH_PERMISSIONS,
      getConfigValue(config, 'MORPH_PERMISSIONS')
    );
  }
}
