import { TierType } from './tier/OhsTier';

export interface OhsObject {
  type: TierType | ModuleType;
  _id: string;
}

export enum ModuleType {
  Admin2 = 'core.module.admin2',
  SafetyPlan = 'core.module.safetyplan',
  Inspection = 'core.module.inspection',
  Xsi = 'core.module.xsi',
  User = 'core.user',
  VisitorSite = 'core.module.visitor.site',
  VisitorForm = 'core.module.visitor.form',
  Noticeboard = 'core.module.noticeboard.board',
  Notification = 'core.module.notification',
  NoticeBoardForm = 'core.module.noticeboard.form',
  getNoticeBoardConfig = 'core.module.noticeboard.blocklist',
  VersionDocument = 'core.module.vdoc',
  CorporateRisk = 'core.module.crisk',
  Asset = 'core.module.asset',
  Export = 'core.module.export',
  DashboardBanner = 'core.module.dashboardbanner',
  HR = 'core.module.hr',
  Contractor = 'core.module.contractor',
  Action = 'core.module.action',
  Task = 'core.module.task',
  Filebox = 'core.module.filebox',
  Chemical = 'core.module.chemical',
  Incident = 'core.module.incident',
  ReviewPlan = 'core.module.reviewplan',
  Bookmark = 'core.module.bookmark',
}

export const ModuleDefaultTitle = {
  'core.module.safetyplan': 'Safety Plan',
  'core.module.inspection': 'Inspection',

  'core.module.vdoc': 'Document',
  'core.module.crisk': 'Corporate Risk',
  'core.module.asset': 'Asset',

  'core.module.export': 'Export Center',
  'core.module.hr': 'Human Resources',
  'core.module.contractor': 'Contractor',
  'core.module.action': 'Action',
  'core.module.task': 'Task',
  'core.module.filebox': 'File Box',
  'core.module.chemical': 'Chemical',
  'core.module.incident': 'Incident',
  'core.module.reviewplan': 'Insurance',
  'core.module.noticeboard.board': 'Notice Board',
};
export interface OhsModule extends OhsObject {
  type: ModuleType;
}

export class OhsModuleCreateBy implements OhsObject {
  type: ModuleType.User;
  _id: string;
  name: string;
  email: string;

  constructor() {
    this.type = ModuleType.User;
    this._id = '';
    this.name = '';
    this.email = '';
  }
}

export enum OhsModuleDetailType {
  Detail = 'detail',
  Evidence = 'evidence',
}
