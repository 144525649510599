import { OhsUser } from '../../user/OhsUser';
import { ModuleHomeUrl } from '../leftSideDrawer/OhsLeftSideDrawerModuleList';
import { OhsBreadcrumbsNameAndRef } from './OhsBreadcrumbsServices';

export const OhsBreadCrumbInspection = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  operationPath: string,
  user: OhsUser
) => {
  let defaultModule = {
    name: user.configs.inspection?.TITLE ?? '',
    href: (ModuleHomeUrl[user.tier.type] as any).inspection,
  };

  let urlParams = new URLSearchParams(window.location.search);
  let paramType = urlParams.get('tempType') || 'tp';
  let inspectionRoot = urlParams.get('inspectionRoot') || '';

  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: defaultModule.name,
    };
  } else if (operationPath.indexOf('/details') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (subPath === 'template') {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Template' };
  } else if (viewPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit Template' };
  } else if (operationPath.indexOf('view') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View Template' };
  } else if (operationPath.indexOf('aggregate') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Aggregate' };
  } else if (operationPath.indexOf('completed') > -1) {
    // for evidence that redirects with different inspectionId
    if (inspectionRoot !== '') {
      ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
      ohsBreadcrumbsNameAndRef.parentOperationRef = {
        href: `/${modulePath}/${inspectionRoot}/details?tempType=${paramType}`,
        name: 'View',
      };
    } else {
      ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
      ohsBreadcrumbsNameAndRef.parentOperationRef = {
        href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
        name: 'View',
      };
    }
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Evidence' };
  } else if (subPath === 'signoff') {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${viewPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Signoff' };
  }
  return ohsBreadcrumbsNameAndRef;
};
