import React from 'react';
import { Row } from 'react-table';
import { Button, Icon } from 'semantic-ui-react';
import { isValidDateString } from '../../../../common/OhsDataParse';
import { OhsAllocationTierRecord } from '../../../../safety-plan/OhsSafetyPlanModels';
import { registerButtonStyles } from '../../OhsModuleHeader';

export interface AllocationButtonsProps {
  row: Row<any>;
  allocationFunc: (allocationItem: OhsAllocationTierRecord) => Promise<any>;
  deAllocationFunc: (deallocationItem: OhsAllocationTierRecord) => Promise<any>;
}
function OhsAllocationButtons({
  row,
  allocationFunc,
  deAllocationFunc,
}: AllocationButtonsProps) {
  const { classes } = registerButtonStyles();
  const [allocationLoading, setAllocationLoading] = React.useState(false);
  const [allocationDisabled, setAllocationDisabled] = React.useState(false);

  const [deallocationLoading, setDeallocationLoading] = React.useState(false);
  const [deallocationDisabled, setDeallocationDisabled] = React.useState(false);
  const hasDueDate = row.allCells.length! > 3;
  let hasValideDate = isValidDateString(row.original.dateDue);

  React.useEffect(() => {
    if (hasValideDate === false) {
      setAllocationDisabled(true);
    } else {
      setAllocationDisabled(false);
    }
  }, [hasValideDate]);

  return (
    <>
      {allocationFunc && row.original && row.original.allocated !== true && (
        <Button
          onClick={async () => {
            setAllocationLoading(true);
            setAllocationDisabled(true);
            setDeallocationDisabled(false);
            setDeallocationLoading(false);
            await allocationFunc(row.original);
            setAllocationLoading(false);
          }}
          className={classes.button}
          size="small"
          color="green"
          disabled={hasDueDate === true && allocationDisabled}
          loading={allocationLoading}
        >
          <Icon className={classes.buttonIcons} name="share" />
          <span className={classes.hideForSmall}>Allocate</span>
        </Button>
      )}

      {deAllocationFunc && row.original && row.original.allocated === true && (
        <Button
          onClick={async () => {
            setDeallocationDisabled(true);
            setDeallocationLoading(true);
            setAllocationLoading(false);
            setAllocationDisabled(false);
            await deAllocationFunc(row.original);
            setDeallocationDisabled(false);
            setDeallocationLoading(false);
            setAllocationLoading(false);
            setAllocationDisabled(true);
          }}
          className={classes.button}
          size="small"
          color="red"
          disabled={hasDueDate === true && deallocationDisabled}
          loading={deallocationLoading}
        >
          <Icon className={classes.buttonIcons} name="reply" />
          <span className={classes.hideForSmall}>Deallocate</span>
        </Button>
      )}
    </>
  );
}

export default OhsAllocationButtons;
