import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Link, styled, Typography } from '@mui/material';
import _ from 'lodash';
import OhsModal2 from '../../../../global/modal/OhsModal2';
import { OhsTier } from '../../../../models/tier/OhsTier';
import { fetchOrgLimitDetails } from '../../OhsUserSettingsServices';
import CircularProgress from '@mui/material/CircularProgress';
import {
  MfaRequiredLimitsErrorMsg,
  MfaRequiredMessage,
  OhsLoadingWrap,
  WhatIsMfa,
} from './OhsMfaModalMessages';

const OhsStyledButton = styled(Button)((props) => {
  return {
    display: 'flex',
    padding: '5px 20px',
    textTransform: 'none',
  };
});

const OhsStyledFooter = styled(Box)((props) => {
  return {
    display: 'flex',
    justifyContent: 'center !important',
    padding: '8px',
    width: '100%',
  };
});
interface Props {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  activateMfaMethod: () => void;
  tierNum?: number;
  tier?: OhsTier;
  mfaConfigStatus?: string;
}
function OhsMfaMandatoryMessageModal(props: Props) {
  const [currentContent, setCurrentContent] = React.useState<number>(1);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasEnabledMfaLimit, setHasEnabledMfaLimit] =
    React.useState<boolean>(false);
  const { tier, mfaConfigStatus } = props;

  React.useEffect(() => {
    const tierNum = tier ? parseInt(tier.type.substring(11), 10) : 0;
    const getOrgLimits = async () => {
      if (tierNum > 2) {
        setIsLoading(true);
        // get account MFA Limit data
        const orgLimits = await fetchOrgLimitDetails({ ...tier });
        const getEnabledMfaLimit = Object.values(orgLimits.counts.mfa).includes(
          true
        );
        setIsLoading(false);
        if (mfaConfigStatus === 'required' && getEnabledMfaLimit) {
          setHasEnabledMfaLimit(true);
        }
      }
    };

    if (tierNum > 2) {
      getOrgLimits();
    }
  }, [tier, mfaConfigStatus]);

  const returnTitle = () => {
    if (currentContent === 1) return `Enable Multi-Factor Authentication`;
    else if (currentContent === 2) return `What is MFA ?`;
    else return '';
  };

  const renderContent = () => {
    if (currentContent === 1)
      return <MfaRequiredMessage setCurrentContent={setCurrentContent} />;
    else if (currentContent === 2) return <WhatIsMfa />;
    else return '';
  };

  return (
    <>
      <OhsModal2
        headerColor="#F6F8FA"
        open={props.open}
        title={returnTitle()}
        alignHeader="center"
        maxWidth={'xs'}
        onClose={() => props.setModalOpen(!props.open)}
        handleBack={
          currentContent === 2 ? () => setCurrentContent(1) : undefined
        }
        ContentNode={
          isLoading ? (
            <OhsLoadingWrap>
              <CircularProgress />
            </OhsLoadingWrap>
          ) : !hasEnabledMfaLimit ? (
            <MfaRequiredLimitsErrorMsg />
          ) : (
            <div>{renderContent()}</div>
          )
        }
        actionNode={
          hasEnabledMfaLimit && (
            <OhsStyledFooter>
              <div>
                <OhsStyledButton
                  onClick={() => props.activateMfaMethod()}
                  fullWidth
                  size="small"
                  variant="contained"
                  color="warning"
                >
                  Activate MFA
                </OhsStyledButton>
              </div>
            </OhsStyledFooter>
          )
        }
      />
    </>
  );
}

export default OhsMfaMandatoryMessageModal;
