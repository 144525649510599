import { ModuleType } from '../../models/OhsObject';
import { getOhsUser } from '../../user/OhsUser';
import { OhsRecordFieldType } from './OhsRecordFieldType';

const user = getOhsUser();

export const OhsDateIssuedRecordField = new OhsRecordFieldType(
  'dateIssued',
  'Issue Date'
);

export const OhsTitleRecordField = new OhsRecordFieldType('title', 'Title');

export const OhsAttachmentsRecordField = new OhsRecordFieldType(
  'attachments',
  'Attachment(s)'
);

export const OhsPreviousAttachmentsRecordField = new OhsRecordFieldType(
  'previousAttachments',
  `Previous ${OhsAttachmentsRecordField.name}`
);

export const OhsActionLinksRecordField = new OhsRecordFieldType(
  'actionLinks',
  `${user?.getModuleName(ModuleType.Action)}`
);

export const OhsDateCompletedRecordField = new OhsRecordFieldType(
  'dateCompleted',
  'Completed Date'
);

export const OhsSignatureRecordField = new OhsRecordFieldType(
  'control.signatures',
  'Signature'
);

export const OhsSignOffSignatureRecordField = new OhsRecordFieldType(
  'signatures',
  'Signatures(s)'
);

export const OhsWorkplaceRecordField = new OhsRecordFieldType(
  'tier.name',
  'Workplace'
);

export const OhsDateDueRecordField = new OhsRecordFieldType(
  'dateDue',
  'Due Date'
);

export const OhsDateSignedOffRecordField = new OhsRecordFieldType(
  'dateSignedoff',
  'Sign-off Date'
);

export const OhsSignedOffByNameRecordField = new OhsRecordFieldType(
  'signedoffBy.name',
  'Sign-off By'
);

export const OhsNameRecordField = new OhsRecordFieldType('name', 'Title');

export const OhsDateCreatedRecordField = new OhsRecordFieldType(
  'dateCreated',
  'Date Created'
);

export const OhsDescriptionRecordField = new OhsRecordFieldType(
  'description',
  'Description'
);

export const OhsCommentRecordField = new OhsRecordFieldType(
  'comment',
  'Comments'
);

export const OhsCusvalsRecordField = new OhsRecordFieldType(
  'cusvals',
  'Custom Details'
);

export const OhsSubCusvalsRecordField = new OhsRecordFieldType(
  'subcategoryCusvals',
  'Sub Custom Details'
);

export const OhsReferenceIdRecordField = new OhsRecordFieldType(
  'referenceId',
  'Reference'
);

export const OhsCategoryRecordField = new OhsRecordFieldType(
  'category',
  'Classification'
);

export const OhsCategoryOtherRecordField = new OhsRecordFieldType(
  'categoryOther',
  'Classification Other'
);

export const OhsSubcategoryRecordField = new OhsRecordFieldType(
  'subcategory',
  'Sub Classification'
);

export const OhsSubcategoryOtherRecordField = new OhsRecordFieldType(
  'subcategoryOther',
  'Sub Classification Other'
);

export const OhsReviewNotesRecordField = new OhsRecordFieldType(
  'reviewNotes',
  'Sign-off Notes'
);

export const OhsConfidentialRecordField = new OhsRecordFieldType(
  'confidential',
  'Confidential'
);
