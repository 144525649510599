import _ from 'lodash';
import React from 'react';
import { Row } from 'react-table';
import { Button, ButtonGroup, Dropdown, Icon } from 'semantic-ui-react';
import OhsDeleteModal from '../../../global/modal/OhsDeleteModal';
import OhsModuleHeader, {
  registerButtonStyles,
} from '../../../global/register/OhsModuleHeader';
import OhsRegister from '../../../global/register/OhsRegister';
import { getOhsUser } from '../../../user/OhsUser';
import { OhsCusval, OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import { OhsNoticeBoardFormCusvalModal } from './OhsNoticeBoardFormCusvalModal';

interface Props {
  cusvals: OhsCusval[];
  setCusvals: React.Dispatch<React.SetStateAction<OhsCusval[]>>;
  setRows: React.Dispatch<React.SetStateAction<Row<any>[]>>;
}
const addOptions = [
  { key: OhsCusvalType.Text, text: 'Text', value: OhsCusvalType.Text },
  { key: OhsCusvalType.Email, text: 'Email', value: OhsCusvalType.Email },
  { key: OhsCusvalType.Date, text: 'Date', value: OhsCusvalType.Date },
  { key: OhsCusvalType.Time, text: 'Time', value: OhsCusvalType.Time },
  {
    key: OhsCusvalType.Telephone,
    text: 'Telephone',
    value: OhsCusvalType.Telephone,
  },
  { key: OhsCusvalType.Number, text: 'Number', value: OhsCusvalType.Number },
  { key: OhsCusvalType.Color, text: 'Color', value: OhsCusvalType.Color },
  { key: OhsCusvalType.Url, text: 'Url', value: OhsCusvalType.Url },
  {
    key: OhsCusvalType.Checkbox,
    text: 'Checkbox',
    value: OhsCusvalType.Checkbox,
  },
  { key: OhsCusvalType.Radio, text: 'Radio', value: OhsCusvalType.Radio },
  { key: OhsCusvalType.Select, text: 'Select', value: OhsCusvalType.Select },
  {
    key: OhsCusvalType.MutipleSelect,
    text: 'Mutiple Select',
    value: OhsCusvalType.MutipleSelect,
  },
  {
    key: OhsCusvalType.Currency,
    text: 'Currency',
    value: OhsCusvalType.Currency,
  },
];

export const OhsNoticeBoardFormCusvalRegister = (props: Props) => {
  const { classes } = registerButtonStyles();
  const [cusvals, setCusvals] = React.useState(new Array<OhsCusval>());
  const [rows, setRows] = React.useState(new Array<Row<any>>());
  const [addOptionValue, setAddOptionValue] = React.useState('');
  const [operateIndex, setOperateIndex] = React.useState(-1);
  const [operateCusval, setOperateCusval] = React.useState<OhsCusval | null>(
    null
  );
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [cusvalModalOpen, setCusvalModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.cusvals) {
      setCusvals(props.cusvals);
    }
    if (rows) {
      props.setRows(rows);
    }
  }, [props, props.cusvals, rows]);
  const columns = React.useMemo(
    () => [
      {
        id: 'titleId',
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Type',
        accessor: 'typeDisplay',
      },
      {
        Header: 'Required Field',
        accessor: 'requiredDisplay',
      },

      {
        Header: 'Description',
        accessor: 'description',
      },
    ],
    []
  );
  const handleAddOptionChange = async (event: any, data: any) => {
    const cusval = new OhsCusval(data.value);
    setOperateCusval(cusval);
    setCusvalModalOpen(true);
  };
  const openDeleteModalFunc = (item: OhsCusval, index?: number) => {
    if (index != null) {
      setOperateIndex(index);
      setOperateCusval(item);
      setDelModalOpen(true);
    }
  };
  const handleDeleteFunc = async () => {
    const toBeDelete = cusvals[operateIndex];
    const list = [...cusvals];
    if (toBeDelete && list) {
      list.splice(operateIndex, 1);
    }

    props.setCusvals(list);
    setDelModalOpen(false);
  };
  const editFunc = (item: OhsCusval, index?: number) => {
    setOperateCusval(item);
    setCusvalModalOpen(true);
  };
  const user = getOhsUser();
  return (
    <>
      <OhsModuleHeader title="Form - Custom Fields Set-up">
        <ButtonGroup floated="right">
          <Dropdown
            floating
            options={addOptions}
            onChange={handleAddOptionChange}
            openOnFocus={false}
            value={addOptionValue}
            trigger={
              <Button color="green" className={classes.button}>
                <Icon name="plus" className={classes.buttonIcons} />
                <span className={classes.hideForSmall}>Custom Fields</span>
              </Button>
            }
          />
        </ButtonGroup>
      </OhsModuleHeader>
      <OhsRegister
        columns={columns}
        data={cusvals}
        setRows={setRows}
        rowOperations={{
          openDeleteModalButton: {
            func: openDeleteModalFunc,
            permission: user?.configs.noticeboard?.PERMISSIONS.edit === true,
          },
          editButton: {
            func: editFunc,
            permission: user?.configs.noticeboard?.PERMISSIONS.edit === true,
          },
        }}
        noPagination={true}
        enableDragDrop={true}
      ></OhsRegister>
      <OhsDeleteModal
        open={delModalOpen}
        setModalOpenFunc={setDelModalOpen}
        moduleTitle={'Resource'}
        handleDeleteFunc={handleDeleteFunc}
        recordName={operateCusval?.title ?? ''}
      />
      <OhsNoticeBoardFormCusvalModal
        open={cusvalModalOpen}
        setModalOpenFunc={setCusvalModalOpen}
        operateCusval={operateCusval}
        cusvals={cusvals}
        setCusvals={props.setCusvals}
      />
    </>
  );
};
