import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../../common/OhsApi';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';

export const fetchAttachmentDownloadUrl = (
  download: string
): Promise<string | null> => {
  const endpoint = '/downloads/url';
  const errMsg = 'Unable to get the attachment.';
  return ohsApiClient
    .post(endpoint, { download: download })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.url;
      } else {
        toast.error(errMsg);
        console.error(response);
        return null;
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return null;
    });
};

export const setAttachmentsDownloadUrl = async (
  attachments: Array<OhsAttachment>
): Promise<Array<OhsAttachment>> => {
  for (let index = 0; index < attachments.length; index++) {
    const element = attachments[index];
    element.downloadUrl =
      (await fetchAttachmentDownloadUrl(element.download)) ?? undefined;
  }
  return attachments;
};

export function fileNameTrim(input: string | undefined) {
  if (!input) return input;
  var trimedLongFileName = '';
  var inputArray = input.split('.');
  var fileType = inputArray[inputArray.length - 1];
  if (input.length > 50) {
    trimedLongFileName = input.substr(0, 30) + '(...).' + fileType;
  } else {
    trimedLongFileName = input;
  }
  return trimedLongFileName;
}

export function fileSizeParse(size: number) {
  if (size === undefined || size === null) return 'unknown';
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    ((size / Math.pow(1024, i)).toFixed(2) ?? 0 * 1) +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}

export function openOfficeFile(url: string | undefined | null) {
  if (url) {
    let openUrl = 'https://view.officeapps.live.com/op/view.aspx?src=';
    openUrl = openUrl + encodeURIComponent(url);
    window.open(openUrl, '_blank');
  }
}

export function openOnGoogleDoc(url: string | undefined | null) {
  if (url) {
    let openUrl = 'https://docs.google.com/gview?url=';
    openUrl = openUrl + encodeURIComponent(url);
    window.open(openUrl, '_blank');
  }
}
