import React from 'react';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { ohsTheme } from './OhsTheme';
import OhsDashbaordbars from '../dashboard/OhsDashboardBars';
import OhsScreenSizeRoot from '../global/screenSize/OhsScreenSizeRoot';
import { OhsUser } from '../user/OhsUser';
import { OhsModuleRouter } from './OhsModuleRouter';
import { connect } from 'react-redux';
import { isLoginFn } from '../login/OhsLoginSlice';
import OhsBackdrop from '../global/backdrop/OhsBackdrop';
import GoogleTranslate from './GoogleTranslate';
import OhsToastContainer from './OhsToastContainer';
import './App.css';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}
function App(props: any) {
  const user = new OhsUser();
  if (user && user.name && props.isLogin !== true) {
    props.isLoginFn(true);
  }
  return (
    <ThemeProvider theme={ohsTheme}>
      <GoogleTranslate />
      <div>
        {props.isLogin === true && <OhsDashbaordbars></OhsDashbaordbars>}
        <OhsScreenSizeRoot></OhsScreenSizeRoot>
        <OhsBackdrop />
        <OhsModuleRouter />
        <OhsToastContainer />
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: any) => {
  return { isLogin: state.loginReducer.isLogin };
};

export default connect(mapStateToProps, { isLoginFn })(App);
