import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { OhsUserMFAOTPTypes } from '../OhsUserSettingsModels';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from 'tss-react/mui';
import { getMFAenrollmentSuccessMessages } from '../../OhsUserSettingsUtils';
import OhsRecoverCodesActions from '../OhsRecoverCodesActions';

const useStyles = makeStyles()({
  contentWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successTitle: {
    color: '#1fba45',
    margin: '5px 0 20px 0',
  },
  content: {
    display: 'flex',
    margin: '2px 0 0 0',
    flexDirection: 'column',
  },
  recoveryCodesWrap: {
    margin: '10px 0 5px 0',
    width: '100%',
  },
  icon: {
    color: '#1fba45',
  },
  divider: {
    margin: '10px 0 10px',
    width: '100%',
  },
  recoverCodes: {
    padding: '2px 0 2px 0',
    width: '100%',
    display: 'block',
    textAlign: 'center',
  },
});

interface Props {
  mfaInfo: OhsUserMFAOTPTypes;
}
const OhsMfaSuccess = (props: Props) => {
  const { classes } = useStyles();
  const getMsgContent = getMFAenrollmentSuccessMessages(props.mfaInfo);
  let recoveryCodesLists: string = '';
  return (
    <Box className={classes.contentWrap}>
      <CheckCircleIcon fontSize="large" className={classes.icon} />
      <Typography variant="body1" gutterBottom className={classes.successTitle}>
        Successfully Enabled
      </Typography>

      <Box className={classes.content}>{getMsgContent.content}</Box>
      {getMsgContent.content2 && (
        <Box className={classes.content}>{getMsgContent.content2}</Box>
      )}
      {props.mfaInfo.recoveryCodes && props.mfaInfo.recoveryCodes.length > 0 && (
        <>
          <Divider className={classes.divider} />
          <Box className={classes.recoverCodes}>
            Save this emergency backup code and store it somewhere safe. If you
            lose your phone, you can use backup codes to sign in.
          </Box>
          <Box className={classes.recoveryCodesWrap}>
            {props.mfaInfo.recoveryCodes &&
              props.mfaInfo.recoveryCodes.map((code: string, index: number) => {
                recoveryCodesLists = code;
                return (
                  <Box className={classes.recoverCodes} key={index}>
                    {code}
                  </Box>
                );
              })}
          </Box>
          <OhsRecoverCodesActions recoveryCode={recoveryCodesLists} />
        </>
      )}
    </Box>
  );
};

export default OhsMfaSuccess;
