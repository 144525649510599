import React, { useEffect, useMemo, useState } from 'react';
import { Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import OhsRegister from '../../global/register/OhsRegister';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import OhsModal from '../../global/modal/OhsModal';
import { OhsUser } from '../../user/OhsUser';
import { getAvailableInspectionList } from '../OhsInspectionServices';
import { OhsInspectionRecord } from '../OhsInspectionModels';
import { OhsActionColorOrange } from '../../global/theme/OhsThemeColors';
import {
  OhsFrequencyTypeRecordField,
  OhsInProgressRecordField,
  OhsCategoryRecordField,
  OhsSubcategoryRecordField,
  OhsTitleRecordField,
} from '../models/OhsInspectionRecordFields';

const useStyles = makeStyles()(() => ({
  statusHolder: {
    textAlign: 'center',
  },
  statusIcon: {
    fill: OhsActionColorOrange,
    marginRight: '0.5em',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  recurring: {
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  setStartOneOffInspectionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setStartInspectionModal: React.Dispatch<React.SetStateAction<boolean>>;

  targetInspection: OhsInspectionRecord;
  setTargetInspection: React.Dispatch<
    React.SetStateAction<OhsInspectionRecord>
  >;
}

const OhsInspectionAvailableModal = (props: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [inspectionList, setInspectionList] = useState<OhsInspectionRecord[]>(
    new Array<OhsInspectionRecord>()
  );
  const user = new OhsUser();

  const fetchInspectionList = async () => {
    const availableInspection = await getAvailableInspectionList();
    const newList = availableInspection.map((item: OhsInspectionRecord) => ({
      ...item,
      frequencyType: item.frequencyKey == null ? 'Non-recurring' : 'Recurring',
      inProgress:
        user?.tier.type === item?.tier.type && item.execute?.task?.inExecution
          ? 'yes'
          : 'no',
    }));
    setInspectionList(newList);
  };

  const checkInProgress = (row: OhsInspectionRecord) => {
    let isInProgress = false;
    if (user?.tier.type === row?.tier.type && row.execute?.task?.inExecution) {
      isInProgress = true;
    }
    return isInProgress;
  };

  const columns = useMemo(
    () => [
      {
        Header: OhsTitleRecordField.name,
        accessor: OhsTitleRecordField.key,
        Cell: ({ row }: any) => row.original.title,
      },
      {
        Header: OhsCategoryRecordField.name,
        accessor: OhsCategoryRecordField.key,
      },
      {
        Header: OhsSubcategoryRecordField.name,
        accessor: OhsSubcategoryRecordField.key,
      },
      {
        Header: OhsFrequencyTypeRecordField.name,
        accessor: OhsFrequencyTypeRecordField.key,
        Cell: ({ row }: any) => (
          <div className={classes.recurring}>{row.original.frequencyType}</div>
        ),
        headerAlign: 'center',
      },
      {
        Header: OhsInProgressRecordField.name,
        accessor: OhsInProgressRecordField.key,
        Cell: ({ row }: any) =>
          checkInProgress(row.original) ? (
            <div className={classes.statusHolder}>
              <FiberManualRecordIcon
                fontSize={'small'}
                className={classes.statusIcon}
              />
            </div>
          ) : (
            ''
          ),
        headerAlign: 'center',
      },
    ],
    []
  );
  const handleGoToInspection = (data: OhsInspectionRecord) => {
    if (
      data.frequencyKey == null &&
      data.frequencyValue == null &&
      user?.configs.inspection?.PERMISSIONS.signoff == true &&
      user?.configs.inspection?.PERMISSIONS.create == true
    ) {
      props.setStartOneOffInspectionModal(true);
      props.setModalOpen(false);
      props.setTargetInspection(data);
    }

    if (
      data.frequencyKey != null &&
      data.frequencyValue != null &&
      user?.configs.inspection?.PERMISSIONS.signoff == true
    ) {
      if (data.execute.task.inExecution == false) {
        props.setStartInspectionModal(true);
        props.setModalOpen(false);
        props.setTargetInspection(data);
      } else {
        props.setModalOpen(false);
        navigate(
          `/inspection/signoff/${data?._id}/signoff/${data?.execute?.task?._id}`
        );
        navigate(0);
      }
    }
  };

  // close all modal and reset
  const handleCloseModal = () => {
    props.setModalOpen(false);
    props.setTargetInspection(new OhsInspectionRecord());
    props.setStartOneOffInspectionModal(false);
    props.setStartInspectionModal(false);
  };

  useEffect(() => {
    fetchInspectionList();
  }, []);

  const inProgressCount = useMemo(() => {
    let counter = 0;
    inspectionList.map((item: OhsInspectionRecord) => {
      if (checkInProgress(item)) counter++;
    });
    return counter;
  }, [inspectionList]);

  return (
    <OhsModal
      open={props.isOpen}
      title={`Available ${user.configs.inspection?.TITLE}`}
      onClose={handleCloseModal}
      maxWidth={'lg'}
      ContentNode={
        <>
          <Message info>
            <b>
              <FiberManualRecordIcon
                fontSize={'small'}
                className={classes.statusIcon}
              />
              ({inProgressCount}) in progress.
            </b>
          </Message>
          <OhsRegister
            columns={columns}
            data={inspectionList}
            sortBy={[{ id: OhsTitleRecordField.key, desc: false }]}
            rowOperations={{
              inspectionButton: {
                func: handleGoToInspection,
              },
            }}
          />
        </>
      }
    />
  );
};

export default OhsInspectionAvailableModal;
