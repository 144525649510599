import _ from 'lodash';
import React from 'react';
import { getOhsUser } from '../../../user/OhsUser';
import OhsModuleBackground from '../../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../../global/register/OhsModuleHeader';
import OhsModuleListBackground from '../../../global/register/OhsModuleListBackground';
import OhsNoticeBoardFormList from './OhsNoticeBoardFormList';
import OhsModuleHeaderButtons from '../../../global/register/OhsModuleHeaderButtons';
import { NoticeboardForm } from '../../OhsNoticeBoardModels';
import { getNoticeboardFormList } from '../../OhsNoticeBoardServices';

interface PropsType {}

export const loadNoticeBoardFormList = async (): Promise<
  Array<NoticeboardForm>
> => {
  // const user = getOhsUser();
  return getNoticeboardFormList().then((list: Array<NoticeboardForm>) => {
    return list;
  });
};
class OhsNoticeBoardFormRegister extends React.Component<PropsType> {
  state = {
    noticeBoardFormList: new Array<NoticeboardForm>(),
    addModalOpen: false,
  };

  async componentDidMount() {
    const noticeBoardFormList = await loadNoticeBoardFormList();
    this.setState({ noticeBoardFormList: noticeBoardFormList });
  }

  render() {
    const reloadNoticeBoardFormList = async () => {
      const noticeBoardFormList = await loadNoticeBoardFormList();
      this.setState({ noticeBoardFormList: noticeBoardFormList });
    };

    const createForm = () => {
      const url = `/noticeboard/form/add`;
      window.open(url, '_self');
    };
    const user = getOhsUser();

    return (
      <>
        <div>
          <OhsModuleHeader title="Form Register">
            <OhsModuleHeaderButtons
              createButton={{
                func: createForm,
                name: 'Form',
                permission:
                  user?.configs.noticeboard?.PERMISSIONS.form_create === true,
              }}
              archiveButton={{
                name: 'Form',
                permission:
                  user?.configs.noticeboard?.PERMISSIONS.form_archive === true,
              }}
            />
          </OhsModuleHeader>
          <OhsModuleListBackground>
            <OhsNoticeBoardFormList
              reloadNoticeBoardFormList={reloadNoticeBoardFormList}
              noticeBoardFormList={this.state.noticeBoardFormList}
            />
          </OhsModuleListBackground>
        </div>
      </>
    );
  }
}

export default OhsNoticeBoardFormRegister;
