import { Box, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { Divider, Header, Segment } from 'semantic-ui-react';
import OhsModuleBackground from '../../global/register/OhsModuleBackground';
import OhsModuleHeader, {
  registerButtonStylesFunc,
} from '../../global/register/OhsModuleHeader';
import { setTierName } from '../../models/tier/OhsTier';
import { getOhsUser } from '../../user/OhsUser';
import OhsNoticeBoardAddEditModal from '../board/register/OhsNoticeBoardAddEditModal';
import { NoticeboardForm, OhsNoticeBoardRecord } from '../OhsNoticeBoardModels';
import {
  getAllNoticeBoardFormSubmission,
  getNoticeBoardRecord,
} from '../OhsNoticeBoardServices';
import OhsNoticeBoardBlockRegister from './OhsNoticeBoardBlockRegister';
import OhsExcelExportModal from '../../global/excel/OhsExcelExportModal';

import { OhsNoticeboardExcelConfigList } from '../../global/excel/OhsExcelExportModels';
import OhsModuleHeaderButtons from '../../global/register/OhsModuleHeaderButtons';
import {
  extractCustomKeyPairs,
  parseExportDataByKeys,
  setConfigFilterOption,
} from '../../global/excel/OhsExcelExportServices';
import { OhsWithRouterTypes } from '../../global/types/OhsGlobalTypes';
import OhsWithRouter from '../../global/utils/OhsWithRouter';

type PathParamsType = {
  noticeBoardId: string;
};
type PropsType = { classes: any; router: OhsWithRouterTypes<PathParamsType> };
class OhsNoticeBoardView extends React.Component<PropsType> {
  state = {
    noticeBoard: {} as OhsNoticeBoardRecord,
    editModalOpen: false,
    excelModalOpen: false,
    excelConfigList: [],
    forms: [] as NoticeboardForm[],
    exportRecordList: [] as NoticeboardForm[],
  };
  async componentDidMount() {
    const noticeBoardId = this.props.router.params.noticeBoardId;
    const noticeBoard = await getNoticeBoardRecord(noticeBoardId);
    if (noticeBoard) {
      setTierName(noticeBoard.tier);
      this.setState({
        noticeBoard: noticeBoard,
      });
    }
  }
  fetchForms = (forms: NoticeboardForm[]) => {
    this.setState({ forms });
  };

  render() {
    const user = getOhsUser();
    const setEditModal = (editModalOpen: boolean) => {
      this.setState({ editModalOpen: editModalOpen });
    };
    const onModalClose = async () => {
      const noticeBoardId = this.props.router.params.noticeBoardId;
      const noticeBoard = await getNoticeBoardRecord(noticeBoardId);
      if (noticeBoard) {
        setTierName(noticeBoard.tier);
        this.setState({
          noticeBoard: noticeBoard,
        });
      }
    };

    const exportFormatingExcelData = (data: any[]) => {
      const dataList = [...data];
      dataList.forEach((item) => {
        if (item.tier.type === 'core.tier.T3') {
          item.exportTierInfo = 'Org: ' + item.tier.name;
        } else {
          item.exportTierInfo = 'Workplace: ' + item.tier.name;
        }
        item.email = item.submittedBy.email;
        item.submittedBy = item.submittedBy.name;
      });
      return dataList;
    };

    const setExcelModal = (excelModalOpen: boolean) => {
      this.setState({ excelModalOpen: excelModalOpen });
    };

    const setExcelModalOpen = async () => {
      const noticeBoardId = this.props.router.params.noticeBoardId;
      let submission = await getAllNoticeBoardFormSubmission(noticeBoardId);
      const cusProps = extractCustomKeyPairs(submission, ['cusvals']);
      cusProps.forEach((prop) => {
        prop.fn = prop.key;
        prop.fd = 'Customised Field: ' + prop.description;
        prop.selected = true;
      });
      let props = [...OhsNoticeboardExcelConfigList, ...cusProps];
      submission = exportFormatingExcelData(submission);
      submission = parseExportDataByKeys(submission, ['cusvals']);
      props = setConfigFilterOption(submission, props);
      this.setState({
        excelModalOpen: true,
        exportRecordList: submission,
        excelConfigList: props,
      });
    };

    const onExcelModalClose = () => {
      // if (configList) {
      //   saveNoticeboardExcelExportConfig(configList);
      // }
    };
    return (
      <>
        <OhsModuleBackground>
          {this.state.noticeBoard?.title && (
            <Segment>
              <OhsModuleHeader
                title={
                  user?.getCurrentURLModuleConfig()?.TITLE +
                  ': ' +
                  this.state.noticeBoard?.title
                }
              >
                <OhsModuleHeaderButtons
                  openExcelModal={
                    user?.configs.noticeboard?.PERMISSIONS.form_export === true
                      ? () => setExcelModalOpen()
                      : undefined
                  }
                  editButton={{
                    func: () => {
                      this.setState({ editModalOpen: true });
                    },
                    permission:
                      user?.configs.noticeboard?.PERMISSIONS.edit === true,
                  }}
                />
              </OhsModuleHeader>

              <Divider />
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Header as="h5">Title</Header>
                    {this.state.noticeBoard.title}
                  </Grid>
                  <Grid item xs={4}>
                    <Header as="h5">Category</Header>
                    {this.state.noticeBoard.category &&
                    this.state.noticeBoard.category.toLocaleLowerCase() ===
                      'other'
                      ? 'Other (' + this.state.noticeBoard.categoryOther + ')'
                      : this.state.noticeBoard.category}
                  </Grid>
                  <Grid item xs={4}>
                    <Header as="h5">Subcategory</Header>
                    {this.state.noticeBoard.subcategory &&
                    this.state.noticeBoard.subcategory.toLocaleLowerCase() ===
                      'other'
                      ? 'Other (' +
                        this.state.noticeBoard.subcategoryOther +
                        ')'
                      : this.state.noticeBoard.subcategory}
                  </Grid>
                  <Grid item xs={4}>
                    <Header as="h5">Workplace</Header>
                    {this.state.noticeBoard.tier.name}
                  </Grid>
                  <Grid item xs={4}>
                    <Header as="h5">Date Created</Header>
                    {this.state.noticeBoard.dateCreated +
                      ' (' +
                      this.state.noticeBoard.tzDateCreated +
                      ')'}
                  </Grid>
                  <Grid item xs={4}>
                    <Header as="h5">Created by</Header>
                    {this.state.noticeBoard.createdBy.name +
                      ' (' +
                      this.state.noticeBoard.createdBy.email +
                      ')'}
                  </Grid>
                  <Grid item xs={12}>
                    <Header as="h5">Description</Header>
                    {this.state.noticeBoard.description}
                  </Grid>
                </Grid>
              </Box>
            </Segment>
          )}
          <OhsNoticeBoardBlockRegister fetchForms={this.fetchForms} />
        </OhsModuleBackground>
        <OhsNoticeBoardAddEditModal
          open={this.state.editModalOpen}
          setModalOpenFunc={setEditModal}
          isEdit={true}
          onClose={onModalClose}
          operateBoard={this.state.noticeBoard}
        />
        <OhsExcelExportModal
          open={this.state.excelModalOpen}
          setOpen={setExcelModal}
          configList={this.state.excelConfigList}
          exportRecordList={this.state.exportRecordList}
          onClose={onExcelModalClose}
        />
      </>
    );
  }
}
export default OhsWithRouter(
  withStyles(registerButtonStylesFunc)(OhsNoticeBoardView)
);
