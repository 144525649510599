import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { getOhsUser } from '../../user/OhsUser';
import OhsFormContainer from './OhsFormContainer';

interface Props {
  title: string;
  children: React.ReactNode;
}
const useStyles = makeStyles()(() => {
  const user = getOhsUser();
  return {
    root: {
      flexGrow: 1,
    },
    header: { color: user?.configs.theme.HEADER_BAR_TEXT_COLOR + '!important' },
    segmentHeader: {
      color: user?.configs.theme.HEADER_BAR_TEXT_COLOR + '!important',
      backgroundColor: user?.configs.theme.HEADER_BAR_BG_COLOR + '!important',
      marginBottom: '0px!important',
      borderBottomLeftRadius: '0px!important',
      borderBottomRightRadius: '0px!important',
    },
    segment: {
      marginTop: '0px!important',
      borderTopLeftRadius: '0px!important',
      borderTopRightRadius: '0px!important',
      border: 'none!important',
      boxShadow: 'none!important',
    },
  };
});

export default function OhsModalFormSection(props: Props) {
  const { classes } = useStyles();

  return (
    <>
      {props.title && (
        <Segment className={classes.segmentHeader}>
          <Header as={'h3'} className={classes.header}>
            {props.title}
          </Header>
        </Segment>
      )}
      <Segment className={classes.segment}>
        <OhsFormContainer>
          <div className={classes.root}>
            <Grid container spacing={0}>
              {props.children}
            </Grid>
          </div>
        </OhsFormContainer>
      </Segment>
    </>
  );
}
