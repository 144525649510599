import * as _ from 'lodash';
import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import OhsModalHeader from '../../admin2/copy/OhsModalHeader';
import { Button, Input, ModalDescription } from 'semantic-ui-react';
import { ModuleType } from '../../models/OhsObject';
import { handleModuleRecordDelete } from '../register/table/rowButtons/OhsRowOperationServices';
import OhsModal from './OhsModal';

interface Props {
  open: boolean;

  setModalOpenFunc: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteFunc: () => void;
  moduleTitle: string;

  recordName: string;
}

export const handleDeleteEndpoint = async (type: ModuleType, id: string) => {
  await handleModuleRecordDelete(type, id);
};

function OhsDeleteModal(props: Props) {
  const handleClose = () => {
    props.setModalOpenFunc(false);
  };
  const [inputName, setInputName] = React.useState('');
  const handleDelete = async () => {
    props.handleDeleteFunc();
  };

  return (
    <OhsModal
      open={props.open}
      title={'Delete'}
      onClose={handleClose}
      headerColor={'red'}
      maxWidth={'md'}
      ContentNode={
        <>
          <ModalDescription>
            <p>
              Are you sure that you want to delete this {props.moduleTitle}?
              Please enter the name of {props.moduleTitle} to delete it.
            </p>
            <p>
              To delete enter: <b>{props.recordName}</b>
            </p>
            <Input
              onChange={(event: any) => {
                setInputName(event.target.value);
              }}
              fluid
            />
          </ModalDescription>
        </>
      }
      actionNode={
        <>
          <Button
            onClick={handleDelete}
            disabled={props.recordName !== inputName}
            color={'red'}
          >
            Delete
          </Button>
        </>
      }
    />
  );
}

export default OhsDeleteModal;
