import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
import { OhsModulePermissions } from './OhsModulePermissions';
export class OhsReviewplanPermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  signoff: boolean;
  archive: boolean;
  allocate: boolean;
  delete: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.delete = flag;
    this.allocate = flag;
  }
}
export class OhsReviewplanConfig {
  TITLE: string;
  PERMISSIONS: OhsReviewplanPermissions;
  MORPH_PERMISSIONS: OhsModulePermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsReviewplanPermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(
      this.MORPH_PERMISSIONS,
      getConfigValue(config, 'MORPH_PERMISSIONS')
    );
  }
}
