import { Card, Image } from 'semantic-ui-react';
import _ from 'lodash';
import React from 'react';
import { OhsModuleCategory } from '../../user/configurations/OhsModuleCategory';
import {
  getFileTypebyFileName,
  OhsFileType,
  OhsNoticeboardBlockTypeDisplay,
  setBlockDisplayType,
} from '../OhsNoticeBoardModels';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  return { screenWidth: state.screenSizeReducer.screenWidth };
};

class OhsNoticeboardItem extends React.Component<{
  item: any;
  onClick: any;
  onOpen: any;
  screenWidth?: any;
}> {
  state = {
    categoryList: new Array<OhsModuleCategory>(),
    selectedCategory: '',
    selectedSubcategory: '',
    previewUrl: '',
  };

  async componentDidMount() {}

  generateThumbnail(item: any) {
    const prefix = 'youtu.be/';
    let embed = '',
      thumbnail = '';

    if (item.type === 'video/x-youtube') {
      const youtubeUrl = new URL(item.data.source);
      let videoId =
        youtubeUrl.searchParams.get('v') ||
        youtubeUrl.pathname.replace('/', '') ||
        '';

      const ap = videoId.indexOf('&');
      if (ap !== -1) {
        videoId = videoId.substring(0, ap);
      }

      thumbnail = 'https://img.youtube.com/vi/' + videoId + '/0.jpg';
      embed = 'https://www.youtube.com/embed/' + videoId;
    }

    return {
      thumbnail,
      embed,
    };
  }

  getIcon(type: string, fileTyleByFileName: string): any {
    switch (type) {
      case OhsFileType.OhsAttachmentPdf:
        return 'pdf';
      case OhsFileType.OhsAttachmentPpt:
        return 'powerpoint';
      case OhsFileType.OhsAttachmentWord:
        return 'word';
      case OhsFileType.OhsAttachmentExcel:
        return 'excel';
      case OhsFileType.OhsAttachmentFile:
      case OhsNoticeboardBlockTypeDisplay.OhsDocument:
        return this.getIcon(fileTyleByFileName, '') || 'document';
      default:
        return false;
    }
  }

  render() {
    const { item, onClick, onOpen, screenWidth } = this.props;
    // const { previewUrl } = this.state;

    const fileType = getFileTypebyFileName(item.data?.attachment?.fileName);
    const blockType = setBlockDisplayType(
      item.type,
      item?.data?.attachment?.fileName
    );
    const icon = this.getIcon(blockType, fileType);

    if (blockType === OhsNoticeboardBlockTypeDisplay.OhsYoutube) {
      const { thumbnail, embed } = this.generateThumbnail(item);
      item.thumbnail = thumbnail;
      item.embed = embed;
    }

    return (
      <>
        <Card
          onClick={() => {
            if (blockType === OhsNoticeboardBlockTypeDisplay.OhsLink) {
              window.open(item.data.links[0].uri, '_blank');
            } else if (
              blockType === OhsNoticeboardBlockTypeDisplay.OhsYoutube ||
              blockType === OhsFileType.OhsAttachmentImg
            ) {
              onClick(item, blockType);
            } else if (fileType === OhsFileType.OhsAttachmentImg) {
              onClick(item, OhsFileType.OhsAttachmentImg);
            } else {
              onOpen(item, fileType);
            }
          }}
          style={{
            width: screenWidth >= 1200 ? '400px' : '',
          }}
        >
          {blockType === OhsNoticeboardBlockTypeDisplay.OhsYoutube && (
            <Image
              src={item.thumbnail}
              height={'225px'}
              style={{
                objectFit: 'contain',
                background: 'rgba(0, 0, 0, 0.05)',
              }}
            />
          )}
          {icon &&
            item.data.attachment &&
            item.data.attachment.downloadUrl == null && (
              <i
                className={'ui huge grey file outline icon ' + icon}
                style={{
                  width: '100%',
                  height: '225px',
                  lineHeight: '225px',
                  background: 'rgba(0, 0, 0, 0.05)',
                }}
              />
            )}
          {fileType === OhsFileType.OhsAttachmentImg && (
            <Image
              src={item.data.attachment.downloadUrl}
              height={'225px'}
              style={{
                objectFit: 'contain',
                background: 'rgba(0, 0, 0, 0.05)',
              }}
            />
          )}

          <Card.Content>
            <Card.Header>{item.data.title}</Card.Header>
            <Card.Description>{item.data.description}</Card.Description>
          </Card.Content>
        </Card>
      </>
    );
  }
}

// export default OhsNoticeboardItem;
export default connect(mapStateToProps, null)(OhsNoticeboardItem);
