import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CreateIcon from '@mui/icons-material/Create';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import ArchiveIcon from '@mui/icons-material/Archive';
import LockOutlined from '@mui/icons-material/LockOutlined';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Cookies from 'js-cookie';

import { getOhsUser } from '../../user/OhsUser';
import { getHomeUrl } from '../../login/OhsLoginServices';
import { fetchAction, getActionTask } from '../../action/OhsActionServices';
import { getChemical } from '../../chemical/OhsChemicalServices';
import {
  getCorporateRiskRecord,
  getCorporateRiskTaskRecord,
} from '../../corporate-risk/OhsCorporateRiskServices';
import { getAccident } from '../../incident/OhsIncidentServices';
import {
  fetchSingleInspection,
  getInspectionTaskRecord,
} from '../../inspection/OhsInspectionServices';
import {
  fetchReviewPlan,
  fetchReviewPlanTask,
} from '../../review-plan/OhsReviewPlanServices';
import {
  fetchSafetyPlan,
  getSafetyPlanTask,
} from '../../safety-plan/OhsSafetyPlanServices';
import {
  fetchSingleVdoc,
  getSingleVdocTask,
} from '../../document/OhsDocumentServices';
import { TierType } from '../../models/tier/OhsTier';
import { ModuleType } from '../../models/OhsObject';
import OhsInspectionStartModal from '../../inspection/add-edit/OhsInspectionStartModal';

const urlToParamObject = (url: string) => {
  const trimmedURL = url.split('?')[1];
  const objectParam = _.fromPairs(
    trimmedURL.split('&').map((s) => s.split('='))
  );
  return objectParam;
};

const useStyles = makeStyles()({
  buttonHolder: {
    marginTop: '40px',
    alignSelf: 'center',
  },
  container: {
    textAlign: 'center',
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '60px',
  },
  description: {
    fontSize: '16px',
  },
  invalidIcon: {
    color: '#db2828',
    fontSize: '160px',
  },
  lockIcon: {
    color: '#2185d0',
    fontSize: '140px',
  },
  signOffIcon: {
    color: '#21ba45',
    fontSize: '160px',
  },
  archivedIcon: {
    color: '#2185d0',
    fontSize: '160px',
  },
  notFoundIcon: {
    color: '#2185d0',
    fontSize: '160px',
  },
});

export const OhsTaskLinkRedirect = () => {
  const [task, setTask] = useState<any>({});
  const [recordData, setRecordData] = useState<any>({});
  const [isWrongUser, setIsWrongUser] = useState<boolean | null>(null);
  const [isArchived, setIsArchived] = useState<boolean | null>(null);
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const [isSignedOff, setIsSignedOff] = useState<boolean | null>(null);
  const [isNotFound, setIsNotFound] = useState<boolean | null>(null);
  const [inspectionIsOpen, setInspectionIsOpen] = useState<boolean>(false);

  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = urlToParamObject(location?.search || '');
  const user = getOhsUser();

  const userId = _.get(urlParams, 'userId') || '';
  const recordType = _.get(urlParams, 'recordType') || '';
  const recordId = _.get(urlParams, 'recordId') || '';
  const recordTierType = _.get(urlParams, 'recordTierType') || '';
  const taskId = _.get(urlParams, 'taskId') || '';
  const invalidUrl =
    taskId === '' || recordType === '' || recordId === '' || userId === '';

  const moduleRecords = {
    [ModuleType.Action]: {
      archiveUrl: `/action/${recordId}/view`,
      evidenceUrl: `/action/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/action/${recordId}/task/${taskId}/sign-off`,
      fetchData: () => fetchAction(recordId),
      fetchRecord: () => getActionTask(recordId),
      withPermission:
        (user?.tier.type === recordTierType &&
          user?.configs.action?.PERMISSIONS.signoff === true) ||
        (user?.tier.type === TierType.T3 &&
          recordTierType === TierType.T4 &&
          user?.configs.action?.MORPH_PERMISSIONS.signoff === true),
    },
    [ModuleType.Chemical]: {
      archiveUrl: `/chemical/${recordId}/view`,
      evidenceUrl: `/chemical/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/chemical/${recordId}/task/${taskId}/signoff`,
      fetchRecord: () => getChemical(recordId),
      withPermission:
        (user?.tier.type === recordTierType &&
          user?.configs.chemical?.PERMISSIONS.signoff === true) ||
        (user?.tier.type !== recordTierType &&
          user?.configs.chemical?.MORPH_PERMISSIONS.signoff === true),
    },
    [ModuleType.CorporateRisk]: {
      archiveUrl: `/crisk/${recordId}/view`,
      evidenceUrl: `/crisk/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/crisk/${recordId}/task/${taskId}/signoff`,
      fetchData: () => getCorporateRiskRecord(recordId),
      fetchRecord: () => getCorporateRiskTaskRecord(recordId, taskId),
      withPermission:
        (user?.tier.type === recordTierType &&
          user?.configs.corporateRisk?.PERMISSIONS.signoff === true) ||
        (user?.tier.type !== recordTierType &&
          user?.configs.corporateRisk?.MORPH_PERMISSIONS.signoff === true),
    },
    [ModuleType.Incident]: {
      archiveUrl: `/ai/${recordId}/view`,
      evidenceUrl: `/ai/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/ai/${recordId}/task/sign-off`,
      fetchRecord: () => getAccident(recordId),
      withPermission:
        (user?.tier.type === recordTierType &&
          user?.configs.incident?.PERMISSIONS.signoff === true) ||
        (user?.tier.type === TierType.T3 &&
          recordTierType === TierType.T4 &&
          user?.configs.incident?.MORPH_PERMISSIONS.signoff === true),
    },
    [ModuleType.Inspection]: {
      archiveUrl: `/inspection/${recordId}/details`,
      evidenceUrl: `/inspection/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/inspection/signoff/${recordId}/signoff/${taskId}`,
      fetchData: () => fetchSingleInspection(recordId),
      fetchRecord: () => getInspectionTaskRecord(recordId, taskId),
      withPermission: () =>
        (user?.tier.type === recordTierType &&
          user?.configs.inspection?.PERMISSIONS.signoff === true) ||
        (user?.tier.type === TierType.T3 &&
          recordTierType === TierType.T4 &&
          user?.configs.inspection?.MORPH_PERMISSIONS.signoff === true),
    },
    [ModuleType.ReviewPlan]: {
      archiveUrl: `/reviewplan/${recordId}/view`,
      evidenceUrl: `/reviewplan/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/reviewplan/${recordId}/task/${taskId}/signoff`,
      fetchData: () => fetchReviewPlan(recordId),
      fetchRecord: () => fetchReviewPlanTask(recordId, taskId),
      withPermission:
        (user?.tier.type === recordTierType &&
          user?.configs.reviewplan?.PERMISSIONS.signoff === true) ||
        (user?.tier.type !== recordTierType &&
          user?.configs.reviewplan?.MORPH_PERMISSIONS.signoff === true),
    },
    [ModuleType.SafetyPlan]: {
      archiveUrl: `/safety-plan/${recordId}/details`,
      evidenceUrl: `/safety-plan/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/safety-plan/${recordId}/task/${taskId}/sign-off`,
      fetchData: () => fetchSafetyPlan(recordId),
      fetchRecord: () => getSafetyPlanTask(recordId, taskId),
      withPermission:
        (user?.tier.type == recordTierType &&
          user?.configs.safetyplan?.PERMISSIONS.signoff === true) ||
        (user?.tier.type !== recordTierType &&
          user?.configs.safetyplan?.MORPH_PERMISSIONS.signoff === true),
    },
    [ModuleType.VersionDocument]: {
      archiveUrl: `/documents/${recordId}/details`,
      evidenceUrl: `/documents/${recordId}/task/${taskId}/completed`,
      signoffUrl: `/documents/${recordId}/task/${taskId}/sign-off`,
      fetchData: () => fetchSingleVdoc(recordId),
      fetchRecord: () => getSingleVdocTask(recordId, taskId),
      withPermission:
        (user?.tier.type === recordTierType &&
          user?.configs.vdoc?.PERMISSIONS.signoff === true) ||
        (user?.tier.type === TierType.T3 &&
          recordTierType === TierType.T4 &&
          user?.configs.vdoc?.MORPH_PERMISSIONS.signoff === true),
    },
  };

  const fetchRecordByType = async () => {
    if (invalidUrl) return;

    const target = _.get(moduleRecords, recordType) || {};

    if (_.isEmpty(target)) {
      toast.error('Invalid record type');
      return;
    }
    const data = await target?.fetchRecord();
    if (_.isEmpty(data)) setIsNotFound(true);
    if (target.fetchData) {
      const record = await target.fetchData();
      const archived = record?.archived || null;
      setRecordData(record);
      if (_.isEmpty(record)) setIsNotFound(true);
      setIsArchived(archived);
    } else {
      const archived = data?.archived || null;
      setIsArchived(archived);
    }
    setTask(data);
    if (typeof target?.withPermission === 'function') {
      const withPermission = target?.withPermission;
      setIsAuthorized(withPermission(data));
    } else {
      const withPermission = target?.withPermission || false;
      await setIsAuthorized(withPermission);
    }

    if (!_.isNil(data.complete)) setIsSignedOff(data.complete);
    if (!_.isNil(data.closed)) setIsSignedOff(data.closed);
  };

  const renderContent = (
    type: string,
    title: string,
    description: string | React.ReactElement,
    icon: React.ReactElement
  ) => {
    const target = _.get(moduleRecords, recordType) || {};
    const evidenceUrl = target?.evidenceUrl;

    return (
      <>
        <div style={{ textAlign: 'center' }}>
          {icon}
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <Box className={classes.buttonHolder}>
          <Link to={getHomeUrl()} className="ui positive button">
            Go to Dashboard
          </Link>
          {type === 'isSignedOff' && (
            <Link
              to={evidenceUrl}
              className="ui primary button"
              style={{ marginLeft: '12px' }}
            >
              Go to Evidence
            </Link>
          )}
        </Box>
      </>
    );
  };

  // For when user sign off the task and redirects back to previous page
  const checkRedirectItems = () => {
    const signedOffItems: string | object =
      Cookies.get('redirect-signoff') || {};
    const parsedItems =
      typeof signedOffItems === 'string' ? JSON.parse(signedOffItems) : {};
    const target = parsedItems[taskId];
    if (taskId !== '' && !_.isNil(target) && isSignedOff) {
      getHomeUrl();
    }
  };

  const setRedirectItems = () => {
    const signedOffItems = Cookies.get('redirect-signoff') || {};
    const parsedItems =
      typeof signedOffItems === 'string' && JSON.parse(signedOffItems);
    const newSignedOffItems = {
      ...parsedItems,
      [taskId]: true,
    };
    Cookies.set('redirect-signoff', newSignedOffItems, {
      expires: 1,
    });
  };

  const renderInspectionContent = () => {
    if (_.isEmpty(task)) return '';
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <h1>{task.title}</h1>
          <p>
            Inspection is currently not in progress, click "Go to Inspection"
            button to start the inspection.
          </p>
        </div>
        <Box className={classes.buttonHolder}>
          <Link to={getHomeUrl()} className="ui positive button">
            Go to Dashboard
          </Link>
          <Button
            variant="contained"
            color="blue"
            startIcon={<CreateIcon />}
            onClick={() => setInspectionIsOpen(true)}
            style={{ marginLeft: '12px' }}
          >
            Go to Inspection
          </Button>
        </Box>
        <OhsInspectionStartModal
          isOpen={inspectionIsOpen}
          setModalOpen={setInspectionIsOpen}
          recordData={recordData}
          task={task}
          onRedirect={setRedirectItems}
        />
      </>
    );
  };

  const renderArchivedContent = () => {
    const target = _.get(moduleRecords, recordType) || {};
    const archiveUrl = target?.archiveUrl;

    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <ArchiveIcon className={classes.archivedIcon} />
          <h2>Archived</h2>
          <p className="description">
            This task cannot be signed off
            <br />
            Check details instead or go to dashboard
          </p>
        </div>
        <Box className={classes.buttonHolder}>
          <Link to={getHomeUrl()} className="ui positive button">
            Go to Dashboard
          </Link>
          <Link
            to={archiveUrl}
            className="ui primary button"
            style={{ marginLeft: '12px' }}
          >
            View Details
          </Link>
        </Box>
      </>
    );
  };

  const returnContent = () => {
    if (invalidUrl) {
      return renderContent(
        'invalidUrl',
        'Invalid Link',
        'Sorry! There seems to be a problem with the url.',
        <ErrorOutlineOutlinedIcon className={classes.invalidIcon} />
      );
    }
    if (isAuthorized === false || isWrongUser) {
      return renderContent(
        'isAuthorized',
        'Restricted Access',
        <span className="description">
          Confirm you are logged into the correct account or contact
          <br />
          System Administrator if access is required
        </span>,
        <LockOutlined className={classes.lockIcon} />
      );
    }
    if (isSignedOff) {
      const displayTitle = () => {
        if (recordType === ModuleType.Incident) return task.reference;
        return task.title;
      };
      return renderContent(
        'isSignedOff',
        displayTitle(),
        'The task has been signed-off.',
        <AssignmentTurnedInOutlinedIcon className={classes.signOffIcon} />
      );
    }
    if (isArchived) {
      return renderArchivedContent();
    }

    if (recordType === ModuleType.Inspection) {
      return renderInspectionContent();
    }
    if (isNotFound) {
      return renderContent(
        'isNotFound',
        'Unable to fetch task',
        <span className="description">
          Sorry! There seems to be a problem with fetching the task.
          <br />
          This might be caused by a deleted task.
        </span>,
        <HelpOutline className={classes.notFoundIcon} />
      );
    }

    return '';
  };

  const redirectToSignOff = () => {
    if (
      isWrongUser === true ||
      isArchived === true ||
      isAuthorized !== true ||
      isSignedOff === true ||
      isNotFound === true
    )
      return;
    const target = _.get(moduleRecords, recordType) || {};

    // If record is inspection but not yet in progress
    if (recordType === ModuleType.Inspection && !task.inExecution) {
      setInspectionIsOpen(true);
    } else {
      setRedirectItems();
      navigate(target?.signoffUrl);
    }
  };

  useEffect(() => {
    fetchRecordByType();
    checkRedirectItems();
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      redirectToSignOff();
    }
  }, [isWrongUser, isArchived, isAuthorized, isSignedOff, isNotFound]);

  useEffect(() => {
    if (userId !== '' && user?._id !== '') {
      const isAssignedToUser = userId !== user?._id;
      setIsWrongUser(isAssignedToUser);
    }
  }, [userId, user]);

  return <div className={classes.container}>{returnContent()}</div>;
};
