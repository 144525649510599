import _ from 'lodash';
import React from 'react';
import OhsExcelExportModal from '../../global/excel/OhsExcelExportModal';
import {
  OhsExcelConfig,
  OhsSafetyPlanExcelConfigList,
} from '../../global/excel/OhsExcelExportModels';
import {
  exportFormating,
  setConfigFilterOption,
} from '../../global/excel/OhsExcelExportServices';
import OhsModuleBackground from '../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../global/register/OhsModuleHeader';
import OhsModuleListBackground from '../../global/register/OhsModuleListBackground';
import { setTierName, TierType } from '../../models/tier/OhsTier';
import { getOhsUser } from '../../user/OhsUser';
import { OhsSafetyPlanRecord } from '../OhsSafetyPlanModels';
import {
  getSafetyPlanExcelExportConfig,
  getSafetyPlanExportList,
  getSafetyPlanList,
  saveSafetyPlanExcelExportConfig,
  getSafetyPlanCopyList,
} from '../OhsSafetyPlanServices';
import OhsSafetyPlanAddEditModal from './OhsSafetyPlanAddEditModal';
import OhsSafetyPlanList from './OhsSafetyPlanList';
import OhsSafetyPlanCopyModal from './OhsSafetyPlanCopyModal';
import OhsModuleHeaderButtons from '../../global/register/OhsModuleHeaderButtons';

interface PropsType {}

const frequencyDisplaySetup = function (obj: OhsSafetyPlanRecord) {
  obj.frequencyKey =
    obj.frequencyKey.substring(0, 1).toUpperCase() +
    obj.frequencyKey.substring(1);
  if (obj.frequencyKey === 'Weeks') {
    obj.numberOfDays = obj.frequencyValue * 7;
  } else if (obj.frequencyKey === 'Months') {
    obj.numberOfDays = obj.frequencyValue * 30;
  } else if (obj.frequencyKey === 'Years') {
    obj.numberOfDays = obj.frequencyValue * 365;
  }
  if (obj.frequencyValue === 1) {
    obj.frequencyKey = obj.frequencyKey.substring(
      0,
      obj.frequencyKey.length - 1
    );
  }
  obj.frequencyDisplay = obj.frequencyValue + ' ' + obj.frequencyKey;
};
const mapPlans = function (
  t3list: Array<OhsSafetyPlanRecord>,
  t4list: Array<OhsSafetyPlanRecord>
) {
  t4list.forEach(function (t4plan: OhsSafetyPlanRecord) {
    //without org_records_access the parent safe plan
    // many not in the list but the children plan still need display (Bill)
    let foundFlag = false;
    t3list.forEach(function (t3plan) {
      if (t4plan.allocationOf && t3plan._id === t4plan.allocationOf._id) {
        foundFlag = true;
        t3plan.subRows.push(t4plan);
      }
    });
    if (foundFlag === false) {
      t3list.push(t4plan);
    }
  });
};

const loadSafetyPlanList = async (): Promise<Array<OhsSafetyPlanRecord>> => {
  const user = getOhsUser();
  return getSafetyPlanList().then((list: Array<OhsSafetyPlanRecord>) => {
    let t3Planlist = new Array<OhsSafetyPlanRecord>();
    let t4Planlist = new Array<OhsSafetyPlanRecord>();
    list.forEach(function (d) {
      frequencyDisplaySetup(d);
      d.subRows = new Array<OhsSafetyPlanRecord>();

      setTierName(d.tier);

      if (d.allocationOf && user?.tierNum === 3) {
        t4Planlist.push(d);
      } else {
        t3Planlist.push(d);
      }
    });
    mapPlans(t3Planlist, t4Planlist);

    return t3Planlist;
  });
};
class OhsSafetyPlanRegister extends React.Component<PropsType> {
  state = {
    safetyPlanList: new Array<OhsSafetyPlanRecord>(),
    safetyPlanExportList: new Array<any>(),
    safetyPlanExcelConfigList: OhsSafetyPlanExcelConfigList,
    safetyPlanCopyList: new Array<any>(),
    addModalOpen: false,
    excelModalOpen: false,
    copyModalOpen: false,
  };
  async componentDidMount() {
    const safetyPlanList = await loadSafetyPlanList();
    this.setState({ safetyPlanList: safetyPlanList });
  }
  render() {
    const user = getOhsUser();

    const reloadSafetyPlanList = async () => {
      const safetyPlanList = await loadSafetyPlanList();
      this.setState({ safetyPlanList: safetyPlanList });
    };
    const onModalClose = async () => {
      reloadSafetyPlanList();
    };
    const setAddModal = (addModalOpen: boolean) => {
      this.setState({ addModalOpen: addModalOpen });
    };
    const setAddModalOpen = () => {
      this.setState({ addModalOpen: true });
    };
    const setExcelModalOpen = async () => {
      const spList = exportFormating(await getSafetyPlanExportList());
      let configList = getSafetyPlanExcelExportConfig();

      configList = setConfigFilterOption(spList, configList);

      this.setState({
        excelModalOpen: true,
        safetyPlanExportList: spList,
        safetyPlanExcelConfigList: [...configList],
      });
    };
    const onExcelModalClose = (configList?: Array<OhsExcelConfig>) => {
      if (configList) {
        saveSafetyPlanExcelExportConfig(configList);
      }
    };
    const setExcelModal = (excelModalOpen: boolean) => {
      this.setState({ excelModalOpen: excelModalOpen });
    };
    const setCopyModal = (copyModalOpen: any) => {
      this.setState({ copyModalOpen: copyModalOpen });
    };
    const setCopyModalOpen = async () => {
      const safetyPlanCopyList = await getSafetyPlanCopyList();

      this.setState({
        copyModalOpen: true,
        safetyPlanCopyList: safetyPlanCopyList,
      });
    };
    return (
      <>
        <OhsModuleBackground>
          <OhsModuleHeader>
            <OhsModuleHeaderButtons
              createButton={{
                func: setAddModalOpen,
                permission:
                  user?.configs.safetyplan?.PERMISSIONS.form_create === true,
              }}
              openExcelModal={setExcelModalOpen}
              openCopyModal={setCopyModalOpen}
            />
          </OhsModuleHeader>
          <OhsModuleListBackground>
            <OhsSafetyPlanList
              reloadSafetyPlanList={reloadSafetyPlanList}
              safetyPlanList={this.state.safetyPlanList}
            />
          </OhsModuleListBackground>
        </OhsModuleBackground>
        <OhsSafetyPlanAddEditModal
          open={this.state.addModalOpen}
          setModalOpenFunc={setAddModal}
          isEdit={false}
          onClose={onModalClose}
        />
        <OhsSafetyPlanCopyModal
          title={'Copy ' + user?.configs.safetyplan?.TITLE!}
          open={this.state.copyModalOpen}
          onClose={onModalClose}
          setModalOpen={setCopyModal}
          copyList={this.state.safetyPlanCopyList}
          loadSafetyPlanList={reloadSafetyPlanList}
        />
        <OhsExcelExportModal
          open={this.state.excelModalOpen}
          setOpen={setExcelModal}
          configList={this.state.safetyPlanExcelConfigList}
          exportRecordList={this.state.safetyPlanExportList}
          onClose={onExcelModalClose}
        />
      </>
    );
  }
}

export default OhsSafetyPlanRegister;
