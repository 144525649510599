import React from 'react';
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from 'tss-react/mui';
import { getOhsUser } from '../../OhsUser';

const useStyles = makeStyles()({
  wrap: {
    display: 'flex',
  },
  action: {
    padding: '6px',
  },
  icon: {
    cursor: 'pointer',
    color: '#1fba45 !important',
  },
});

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

interface Props {
  recoveryCode: string;
}
const OhsRecoverCodesActions = (props: Props) => {
  const { classes } = useStyles();
  const user = getOhsUser();
  const downloadRecoveryFile = () => {
    const fileContent = `${
      user?.name ? user?.name + ' - ' : ''
    }Recovery Code(s): ${props.recoveryCode}`;
    const element = document.createElement('a');
    const file = new Blob([fileContent], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = `${
      user?.name ? user?.name.replace(/\s+/g, '-').toLowerCase() + ' - ' : ''
    }safety-champion-recovery-codes.txt`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Box className={classes.wrap}>
      <Box className={classes.action}>
        <LightTooltip title="Download recovery code.">
          <DownloadIcon
            color="success"
            sx={{ fontSize: '22px' }}
            onClick={() => downloadRecoveryFile()}
            className={classes.icon}
          />
        </LightTooltip>
      </Box>
      <Box className={classes.action}>
        <LightTooltip title="Click to copy recovery code.">
          <ContentCopyIcon
            fontSize="small"
            color="success"
            onClick={() => navigator.clipboard.writeText(props.recoveryCode)}
            className={classes.icon}
          />
        </LightTooltip>
      </Box>
    </Box>
  );
};

export default OhsRecoverCodesActions;
