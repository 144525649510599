import React from 'react';
import OhsRegisterTableSubRow from '../OhsRegisterTableSubRow';
import { OhsRegisterTableMoveRow } from '../OhsRegisterTableMoveRow';

interface Props {
  dataLists: any[];
  moveRowFunc?: any;
  prepareRow: any;
}

const OhsRegisterTableRowList = (props: Props) => {
  return (
    <React.Fragment>
      {props.dataLists.map((row: any, index: number) => {
        props.prepareRow(row);

        const cellNum = row.cells.length;
        return (
          <React.Fragment key={index}>
            {row.depth === 0 ? (
              <OhsRegisterTableMoveRow row={row} index={index} key={index} />
            ) : (
              <OhsRegisterTableSubRow
                {...row.getRowProps()}
                row={row}
                cellNum={cellNum}
              />
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default OhsRegisterTableRowList;
