import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface OhsScreenSizeState {
  screenWidth: number;
  screenHeight: number;
}
const initialState = {
  screenWidth: 0,
  screenHeight: 0,
} as OhsScreenSizeState;

const screenSizeSlice = createSlice({
  name: 'screenSize',
  initialState,
  reducers: {
    setScreenWidth: (state, action: PayloadAction<number>) => {
      state.screenWidth = action.payload;
    },
    setScreenHeight: (state, action: PayloadAction<number>) => {
      state.screenHeight = action.payload;
    },
  },
});

export const { setScreenWidth, setScreenHeight } = screenSizeSlice.actions;
export const screenSizeReducer = screenSizeSlice.reducer;
