import { decode } from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { getAngularLocalStorage } from '../common/OhsDataParse';

export const isAuthenticated = (): boolean => {
  const token = getToken();
  let isAuthenticated = false;
  if (token) {
    const jwt_payload = decode(token);
    if (
      jwt_payload &&
      typeof jwt_payload !== 'string' &&
      jwt_payload.exp &&
      jwt_payload.exp * 1000 > new Date().getTime()
    ) {
      isAuthenticated = true;
    }
  }
  return isAuthenticated;
};
export const getToken = (): string | undefined => {
  return Cookies.get('token');
};

export const isMorph = (): boolean | null => {
  const isMprphString = getAngularLocalStorage('isMorph');
  if (isMprphString === 'true') {
    return true;
  } else if (isMprphString === 'false') {
    return false;
  } else {
    return null;
  }
};
