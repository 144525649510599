import { ModuleType, OhsModule, OhsObject } from '../../models/OhsObject';
import { OhsTime } from '../../models/OhsTimeModels';
import { OhsUser } from '../../user/OhsUser';
import { OhsTier } from '../../models/tier/OhsTier';
import { OhsCusval } from 'global/form/cusvals/OhsCusvalModel';

export class VisitorRole {
  _id: string;
  type: string;
  title: string;
  constructor() {
    this._id = '';
    this.type = '';
    this.title = '';
  }
}
export class VisitorContact {
  name: string;
  email: string;
  phone: string;
  phoneCountryCode: string;
  constructor() {
    this.name = '';
    this.email = '';
    this.phone = '';
    this.phoneCountryCode = Australian_Phone_Country_Code;
  }
}
const Australian_Phone_Country_Code = '61';

export class VisitorSite implements OhsModule {
  _id!: string;
  readonly type: ModuleType = ModuleType.VisitorSite;

  title!: string;
  description!: string | null;
  category!: string;
  categoryOther!: string | null;
  subcategory!: string;
  subcategoryOther!: string | null;
  tier!: OhsObject;
  forms!: Array<VisitorSiteForm>;
  qrCode!: {
    urlPath: string;
  };
}
export class VisitorSiteForm {
  role!: VisitorRole;
  arrive!: {
    form: OhsObject;
    messages: {
      pre: VisitorMessage | null;
      in: VisitorMessage | null;
      post: VisitorMessage | null;
    };
  } | null;
  leave!: {
    form: OhsObject;
    messages: {
      pre: VisitorMessage | null;
      in: VisitorMessage | null;
      post: VisitorMessage | null;
    };
  } | null;
}
export class VisitorMessage {
  type!: string;
  value!: any;
}
export class VisitorForm implements OhsModule {
  _id!: string;
  readonly type: ModuleType = ModuleType.VisitorForm;
  cusvals!: Array<OhsCusval>;
  title!: string;
  description!: string | null;
  messages!: {
    pre: VisitorMessage | null;
    in: VisitorMessage | null;
    post: VisitorMessage | null;
  };

  tier!: OhsObject;
}
export const Visitor_Local_Arrive_List_Name = 'Visitor_Local_Arrive_List';

export class VisitorSignInRecord {
  _id!: string;
  readonly type: string = 'core.module.visitor.visit';
  site!: OhsObject;
  tier!: OhsTier | null;
  visitor!: { role: VisitorRole; pii: VisitorContact };
  createdBy!: OhsUser;
  arrive!: {
    type: string;
    _id: string;
    cusvals: Array<OhsCusval>;
    tz: string;
    date: string;
    time: OhsTime;
  };
}
