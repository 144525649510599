import * as _ from 'lodash';
import React from 'react';

import { Button } from 'semantic-ui-react';
import { getOhsUser } from '../../../user/OhsUser';
import { OhsAllocationTierRecord } from '../../../safety-plan/OhsSafetyPlanModels';
import { OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import {
  addEditNoticeBoard,
  allocateNoticeBoard,
  deallocateNoticeBoard,
  getNoticeBoardAllocationList,
} from '../../OhsNoticeBoardServices';
import { getCurrentTimezone } from '../../../common/OhsDataParse';
import OhsModalForm from '../../../global/form/OhsModalForm';
import OhsModalFormItem from '../../../global/form/OhsModalFormItem';
import OhsAllocationModal from '../../../global/modal/OhsAllocationModal';
import { OhsNoticeBoardRecord } from '../../OhsNoticeBoardModels';
import OhsModal from '../../../global/modal/OhsModal';
import OhsTextField from 'global/form/fields/OhsTextField';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import OhsSelectFieldPair from 'global/form/fields/OhsSelectFieldPair';
import { OhsCategoryRecordField } from 'global/record-filed/OhsGlobalRecordFiled';

interface Props {
  open: boolean;
  isEdit: boolean;
  operateBoard: OhsNoticeBoardRecord;

  setModalOpenFunc: (addModalOpen: boolean) => void;

  onClose?: () => void;
}

function OhsNoticeBoardAddEditModal(props: Props) {
  const user = getOhsUser();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [allocationList, setAllocationList] = React.useState(
    new Array<OhsAllocationTierRecord>()
  );
  const [noticeBoardIdOperated, setNoticeBoardIdOperated] = React.useState('');
  const [noticeBoardOperated, setNoticeBoardOperated] =
    React.useState<OhsNoticeBoardRecord | null>(null);

  const moduleConf = user?.configs.noticeboard?.CATEGORY_LIST || [];

  const ohsFormSettings = new OhsFormSettings(
    noticeBoardOperated,
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  const loadAlocationList = async (id: string) => {
    return getNoticeBoardAllocationList(id).then(
      (allocationRecordList: OhsAllocationTierRecord[]) => {
        setAllocationList(allocationRecordList);
        return allocationRecordList;
      }
    );
  };

  const handleClose = () => {
    props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleButtonSubmit = async (data: any) => {
    data['tzDateCreated'] = getCurrentTimezone();
    data['control'] = {};
    props.setModalOpenFunc(false);
    if (!data['categoryOther']) {
      data['categoryOther'] = null;
    }
    if (!data['category']) {
      data['category'] = null;
    }
    if (!data['subcategoryOther']) {
      data['subcategoryOther'] = null;
    }
    if (!data['subcategory']) {
      data['subcategory'] = null;
    }
    if (props.operateBoard && props.operateBoard._id) {
      data['_id'] = props.operateBoard._id;
    }
    const sp = await addEditNoticeBoard(data);
    if (sp) window.location.reload();

    if (props.onClose) {
      props.onClose();
    }
  };

  const handleButtonSubmitAllocate = async (data: any) => {
    data['tzDateCreated'] = getCurrentTimezone();
    props.setModalOpenFunc(false);
    const sp = await addEditNoticeBoard(data);

    if (sp) {
      if (props.onClose) {
        props.onClose();
      }
      setNoticeBoardIdOperated(sp._id);
      await loadAlocationList(sp._id);
      setModalTitle('Allocate Task: ' + sp.title);
      setModalOpen(true);
    }
  };

  const onModalClose = async () => {};

  React.useEffect(() => {
    if (props.operateBoard && props.operateBoard.title) {
      setNoticeBoardOperated(props.operateBoard);
      ohsFormSettings.useFormMethods.reset(props.operateBoard);
    }
  }, [props.operateBoard, ohsFormSettings.useFormMethods]);

  const [category]: [string] = ohsFormSettings.useFormMethods.watch([
    OhsCategoryRecordField.key,
  ]);

  const subCategoryOptions = () => {
    if (category) {
      const options = _.find(moduleConf, {
        category,
      })?.subcategory;
      if (options) {
        return options.map((item: any) => {
          return { name: item, value: item };
        });
      }
    }
    return [];
  };

  return (
    <>
      <OhsModal
        open={props.open}
        title={
          props.isEdit === true
            ? 'Edit '
            : 'Created ' + user?.configs.noticeboard?.TITLE
        }
        onClose={handleClose}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalForm>
              <OhsModalFormItem>
                <OhsTextField
                  type="text"
                  title="Title"
                  id="title"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsSelectFieldPair
                  selectProps={{
                    title: 'Category',
                    id: 'category',
                    required: false,
                    options:
                      moduleConf.map((item) => {
                        return { name: item.category, value: item.category };
                      }) ?? [],
                  }}
                  textProps={{
                    type: 'text',
                    title: 'Other',
                    id: 'categoryOther',
                    required: true,
                  }}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsSelectFieldPair
                  selectProps={{
                    title: 'Subcategory',
                    id: 'subcategory',
                    required: false,
                    options: subCategoryOptions(),
                  }}
                  textProps={{
                    type: 'text',
                    title: 'Other',
                    id: 'subcategoryOther',
                    required: true,
                  }}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem>
                <OhsTextField
                  type={OhsCusvalType.Textarea}
                  title="Details"
                  id="description"
                  required
                  ohsFormSettings={ohsFormSettings}
                  rows={4}
                />
              </OhsModalFormItem>
            </OhsModalForm>
          </>
        }
        actionNode={
          <>
            {' '}
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleButtonSubmit
              )}
              color={'green'}
            >
              Submit
            </Button>
            {props.isEdit !== true &&
              user?.configs.noticeboard?.PERMISSIONS.allocate === true &&
              user.tierNum === 3 && (
                <Button
                  onClick={ohsFormSettings.useFormMethods.handleSubmit(
                    handleButtonSubmitAllocate
                  )}
                  color={'green'}
                >
                  Submit & Allocate
                </Button>
              )}
          </>
        }
      />

      <OhsAllocationModal
        title={modalTitle}
        id={noticeBoardIdOperated}
        open={modalOpen}
        allocationList={allocationList}
        loadAlocationList={loadAlocationList}
        setAllocationList={setAllocationList}
        allocationFunc={allocateNoticeBoard}
        deAllocationFunc={deallocateNoticeBoard}
        setModalOpen={setModalOpen}
        onClose={onModalClose}
        hasDueDate={false}
      />
    </>
  );
}

export default OhsNoticeBoardAddEditModal;
