import { Box, Theme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    padding: '16px',
    border: '1px solid #d4d4d5',
  },
}));

type Props = {
  children: React.ReactNode;
};
export default function OhsModuleContent(props: Props) {
  const { classes } = useStyles();
  return <Box className={classes.root}>{props.children}</Box>;
}
