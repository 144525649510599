import { getAngularLocalStorageConfig } from '../../common/OhsDataParse';

export class OhsUserSettings {
  _id: string;
  name: string;
  email: string;
  phone: string;
  phoneCountryCode: string;
  oldPassword: string;
  password: string;
  timezone: string;
  language: string;
  notifyTime: string;
  notifySubscribeEmail: boolean;
  notifySubscribeSMS: boolean;
  notifySubscribePush: boolean;
  bookmarkEnable: boolean;

  constructor() {
    this._id = '';
    this.name = '';
    this.email = '';
    this.phone = '';
    this.phoneCountryCode = '';
    this.oldPassword = '';
    this.password = '';
    this.timezone = '';
    this.language = '';
    this.notifyTime = '';
    this.notifySubscribeEmail = true;
    this.notifySubscribeSMS = getAngularLocalStorageConfig(
      'notificationConfig',
      'NOTIFY_SUBSCRIBE_SMS'
    );
    this.notifySubscribePush = true;
    this.bookmarkEnable = true;
  }
}
