import React from 'react';
import { Routes, Route } from 'react-router-dom';

const OhsAngularIframe = React.lazy(
  () => import('../global/angular-iframe/OhsAngularIframe')
);
const OhsRequireAuth = React.lazy(() => import('../global/OhsRequireAuth'));

const OhsVisitorRoutes = () => {
  return (
    <React.Suspense fallback={null}>
      <Routes>
        <Route
          path="*"
          element={
            <OhsRequireAuth>
              <OhsAngularIframe />
            </OhsRequireAuth>
          }
        />
      </Routes>
    </React.Suspense>
  );
};

export default OhsVisitorRoutes;
