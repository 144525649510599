import React from 'react';

import _ from 'lodash';

import { Row } from 'react-table';

import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { getOhsUser } from '../../../user/OhsUser';
import { IconButton, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import {
  expanderColumnId,
  searchColumnId,
  statuColumnId,
} from '../OhsRegister';
import { TierType } from '../../../models/tier/OhsTier';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const getAllocationStatusString = (
  registerRecord: any
): 'Allocated' | 'Workplace Owned' | 'Not Allocated' | '' => {
  if (registerRecord.tier && registerRecord.tier.type === TierType.T4) {
    return 'Workplace Owned';
  }
  if (
    registerRecord.tier &&
    registerRecord.tier.type === TierType.T3 &&
    registerRecord.subRows
  ) {
    if (registerRecord.subRows.length === 0) return 'Not Allocated';
    if (registerRecord.subRows.length > 0) return 'Allocated';
  }
  return '';
};

const useStyles = makeStyles()((theme) => ({
  root: {},
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

interface Props {
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  rowFilterNode?: React.ReactNode;
  getTableProps: any;
  headerGroups: any;
  page: any;
  prepareRow: any;
  selectedFlatRows: any;
  setGlobalFilter: any;
  globalFilter: any;
}

const OhsRegisterCardList = (props: Props) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState(props.globalFilter);

  const cleanKey = () => {
    setSearchKey('');
    props.setGlobalFilter(undefined);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value;
    props.setGlobalFilter(newValue || undefined);
    setSearchKey(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (props.setSelectedRows) {
      props.setSelectedRows(props.selectedFlatRows);
    }
  });

  const user = getOhsUser();
  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          style={{
            backgroundColor: user?.configs.theme.HEADER_BAR_BG_COLOR,
            color: user?.configs.theme.MAIN_BG_COLOR,
            fontSize: '1.28571429rem',
            fontWeight: 'bold',
          }}
          action={
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon style={{ fill: 'white' }} />
            </IconButton>
          }
          disableTypography
          title={user?.getCurrentURLModuleConfig()?.TITLE}
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <OutlinedInput
              sx={{ width: '100%' }}
              value={searchKey}
              onChange={handleChange}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  {(_.isNil(searchKey) || searchKey === '') 
                    ? (
                      <IconButton type="submit">
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={cleanKey}>
                        <ClearIcon />
                      </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </CardContent>
        </Collapse>
      </Card>
      {props.rowFilterNode && (
        <React.Fragment>
          <Divider />
          <Card className={classes.root}>
            <CardContent style={{ textAlign: 'center' }}>
              <table style={{ width: '100%' }}>
                <tbody> {props.rowFilterNode}</tbody>
              </table>
            </CardContent>
          </Card>
        </React.Fragment>
      )}
      {props.page.map((row: any, index: number) => {
        props.prepareRow(row);
        const titleCell = _.find(row.cells, (cell) => {
          if (cell.value != null) return cell;
        });
        const contentCells = row.cells.filter(
          (cell: any) =>
            cell.value != null && cell.column.id !== titleCell.column.id
        );
        const statusCell = _.find(row.cells, {
          column: { id: statuColumnId },
        });
        const expanderCell = _.find(row.cells, {
          column: { id: expanderColumnId },
        });
        const operationCell = _.find(row.cells, {
          column: { id: searchColumnId },
        });

        return (
          <React.Fragment key={index}>
            <Divider />
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  expanderCell ? (
                    <>{expanderCell.render('Cell')}</>
                  ) : (
                    <IconButton aria-label="settings"></IconButton>
                  )
                }
                action={<>{operationCell && operationCell.render('Cell')}</>}
                title={
                  row.depth == null || row.depth === 0
                    ? titleCell.value
                    : `Allocated to ${
                        contentCells[contentCells.length - 1].value ?? ''
                      }`
                }
                style={{
                  color:
                    row.depth == null || row.depth === 0 ? 'inherit' : 'green',
                }}
              />
              {(row.depth == null || row.depth === 0) && (
                <CardContent>
                  {contentCells.map((cell: any, index: number) => {
                    return (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        key={index}
                      >
                        <b>{cell.column.Header + ': '}</b>
                        {cell.value}
                      </Typography>
                    );
                  })}
                  {statusCell && statusCell.row && statusCell.row.original && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      key={index}
                    >
                      <b>{'Status: '}</b>
                      {getAllocationStatusString(statusCell.row.original)}
                    </Typography>
                  )}
                </CardContent>
              )}
            </Card>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default OhsRegisterCardList;
