import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import React from 'react';
import { getAngularLocalStorage } from '../../common/OhsDataParse';
import {
  OhsFilterList,
  OhsModuleFilter,
  OhsPotentialFilterModuleList,
  updateFilterListByModuleFilter,
} from './FilterModels';
const useStyles = makeStyles()((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));
interface Props {
  ohsFilterList: OhsFilterList;
  setOhsFilterList: React.Dispatch<React.SetStateAction<OhsFilterList>>;
}
export const OhsModuleFilterCheckList = (props: Props) => {
  const { classes } = useStyles();
  let ohsFilterList = { ...props.ohsFilterList };
  let ohsModuleFilterList = new Array<OhsModuleFilter>();
  let isSelectAll = true;
  if (ohsFilterList && ohsFilterList.module) {
    for (const key in ohsFilterList.module) {
      if (
        ohsFilterList.module[key] &&
        OhsPotentialFilterModuleList.includes(key)
      ) {
        if (ohsFilterList.module[key].checkbox !== true) {
          isSelectAll = false;
        }
        ohsModuleFilterList.push(ohsFilterList.module[key]);
      }
    }
  }
  const [ohsModuleFilterListState, setOhsModuleFilterListState] =
    React.useState(ohsModuleFilterList);
  const [selectAllState, setSelectAllState] = React.useState(isSelectAll);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    ohsModuleFilterList = [...ohsModuleFilterListState];
    let checkedModule = _.find(ohsModuleFilterList, {
      obj: { type: event.target.name },
    });

    if (checkedModule) checkedModule.checkbox = event.target.checked;

    let isSelectAll = true;
    ohsModuleFilterList.forEach((item) => {
      if (item.checkbox === false) isSelectAll = false;
    });
    const updatedFilter = updateFilterListByModuleFilter(
      ohsFilterList,
      ohsModuleFilterList
    );
    props.setOhsFilterList(updatedFilter);
    setSelectAllState(isSelectAll);

    setOhsModuleFilterListState(ohsModuleFilterList);
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    ohsModuleFilterList = [...ohsModuleFilterListState];
    ohsModuleFilterList.forEach((item) => {
      item.checkbox = event.target.checked;
    });
    const updatedFilter = updateFilterListByModuleFilter(
      ohsFilterList,
      ohsModuleFilterList
    );
    props.setOhsFilterList(updatedFilter);
    setSelectAllState(event.target.checked);

    setOhsModuleFilterListState(ohsModuleFilterList);
  };
  return (
    <div className={classes.paper}>
      <Box m={2}>
        <p>
          <b>Modules</b>
        </p>
      </Box>
      <div>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllState}
                  onChange={handleSelectAll}
                  name="Select All"
                  size="small"
                  color="primary"
                />
              }
              label={<b>Select All</b>}
            />
            {ohsModuleFilterListState.map(
              (moduleFilter: any, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={moduleFilter.checkbox}
                        onChange={handleChange}
                        size="small"
                        color="primary"
                        name={moduleFilter.obj.type}
                      />
                    }
                    label={moduleFilter.obj.title}
                  />
                );
              }
            )}
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
};
