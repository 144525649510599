import getMixpanelTraker from '../common/mixpanel/OhsMixpanel';
import { OhsUser } from '../user/OhsUser';

export const goToHomepage = () => {
  const user = new OhsUser();
  let from: string;
  if (user.tierNum > 2) {
    from = '/task/active';
  } else {
    from = '/admin2/home';
  }
  window.location.href = from;
};

export const getHomeUrl = (): string => {
  const user = new OhsUser();
  if (user && user.name) {
    if (user.tierNum > 2) {
      return '/task/active';
    } else {
      return '/admin2/home';
    }
  } else {
    return '/login';
  }
};

export const OhsLogout = () => {
  const user = new OhsUser();
  const mixpanel = getMixpanelTraker();
  mixpanel?.track('Sign out');
  mixpanel?.reset();
  if (user.isSso === true) {
    window.open('/unlogin.html#logout', '_self');
  } else {
    window.open('/login', '_self');
  }
};
