import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { OhsFormSettings } from 'global/form/OhsFormModels';
import OhsSelectField from 'global/form/fields/OhsSelectField';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsModalForm from '../../../../global/form/OhsModalForm';
import OhsModalFormItem from '../../../../global/form/OhsModalFormItem';

import {
  ButtonFormWrapper,
  AddButton,
  RemoveButtonIcon,
} from '../../OhsDashboardBannerStyledComponents';

interface Props {
  ohsFormSettings: OhsFormSettings;
}

const LinkForm = ({
  buttonIndex,
  buttonItem,
  ohsFormSettings,
}: {
  buttonIndex: number;
  buttonItem: any;
  ohsFormSettings: OhsFormSettings;
}) => {
  return (
    <OhsModalFormItem sm={4}>
      <OhsSelectField
        id={`content.buttons[${buttonIndex}].click.external`}
        title="Is External"
        required
        options={[
          { name: 'Yes', value: 'true' },
          { name: 'No', value: 'false' },
        ]}
        ohsFormSettings={ohsFormSettings}
        defaultValue={String(buttonItem.click.external)}
      />
    </OhsModalFormItem>
  );
};

const PopupForm = ({
  buttonIndex,
  buttonItem,
  ohsFormSettings,
}: {
  buttonIndex: number;
  buttonItem: any;
  ohsFormSettings: OhsFormSettings;
}) => {
  return (
    <>
      <OhsModalFormItem sm={6}>
        <OhsSelectField
          id={`content.buttons[${buttonIndex}].click.overlay`}
          title="Has Overlay"
          required
          options={[
            { name: 'Yes', value: 'true' },
            { name: 'No', value: 'false' },
          ]}
          ohsFormSettings={ohsFormSettings}
          defaultValue={buttonItem.click.overlay ?? 'true'}
        />
      </OhsModalFormItem>
      <OhsModalFormItem sm={6}>
        <OhsTextField
          type="number"
          title="Overlay Opacity"
          id={`content.buttons[${buttonIndex}].click.overlayOpacity`}
          required
          ohsFormSettings={ohsFormSettings}
          defaultValue={buttonItem.click.overlayOpacity ?? 1}
        />
      </OhsModalFormItem>
    </>
  );
};

const SlideForm = ({
  buttonIndex,
  buttonItem,
  ohsFormSettings,
}: {
  buttonIndex: number;
  buttonItem: any;
  ohsFormSettings: OhsFormSettings;
}) => {
  return (
    <>
      <OhsModalFormItem sm={4}>
        <OhsSelectField
          id={`content.buttons[${buttonIndex}].click.position`}
          title="Has Overlay"
          required
          options={[
            { name: 'Top', value: 'top' },
            { name: 'Bottom', value: 'bottom' },
            { name: 'Left', value: 'left' },
            { name: 'Right', value: 'right' },
            { name: 'Top Left', value: 'topLeft' },
            { name: 'Top Right', value: 'topRight' },
            { name: 'Bottom Left', value: 'bottomLeft' },
            { name: 'Bottom Right', value: 'bottomRight' },
          ]}
          ohsFormSettings={ohsFormSettings}
          defaultValue={buttonItem.click.position ?? 'bottomRight'}
        />
      </OhsModalFormItem>
      <OhsModalFormItem sm={4}>
        <OhsSelectField
          id={`content.buttons[${buttonIndex}].click.transition`}
          title="Has Overlay"
          required
          options={[
            { name: 'Up', value: 'up' },
            { name: 'Down', value: 'down' },
            { name: 'Left', value: 'left' },
            { name: 'Right', value: 'right' },
          ]}
          ohsFormSettings={ohsFormSettings}
          defaultValue={buttonItem.click.transition ?? 'up'}
        />
      </OhsModalFormItem>

      <OhsModalFormItem sm={4}>
        <OhsTextField
          type="number"
          title="Overlay Opacity"
          id={`content.buttons[${buttonIndex}].click.speed`}
          required
          ohsFormSettings={ohsFormSettings}
          defaultValue={buttonItem.click.speed ?? 500}
        />
      </OhsModalFormItem>
    </>
  );
};

// for popup and slide banner action component
// MUI's <Slide/> and <Fade/> component might help implementing it.
// For reference: https://v4.mui.com/components/transitions/
const renderActionTypeScpeific = ({
  buttonIndex,
  buttonItem,
  actionType,
  ohsFormSettings,
}: {
  buttonIndex: number;
  buttonItem: any;
  actionType: string;
  ohsFormSettings: OhsFormSettings;
}) => {
  switch (actionType) {
    case 'link':
      return (
        <LinkForm
          buttonIndex={buttonIndex}
          buttonItem={buttonItem}
          ohsFormSettings={ohsFormSettings}
        />
      );
    case 'popup':
      return (
        <PopupForm
          buttonIndex={buttonIndex}
          buttonItem={buttonItem}
          ohsFormSettings={ohsFormSettings}
        />
      );
    case 'slide':
      return (
        <SlideForm
          buttonIndex={buttonIndex}
          buttonItem={buttonItem}
          ohsFormSettings={ohsFormSettings}
        />
      );
  }

  return '';
};

const ActionTypeForm = ({
  buttonIndex,
  buttonItem,
  ohsFormSettings,
}: {
  buttonIndex: number;
  buttonItem: any;
  ohsFormSettings: OhsFormSettings;
}) => {
  const actionTypeValue = ohsFormSettings.useFormMethods.watch(
    `content.buttons[${buttonIndex}].click.actionType`
  );
  return (
    <>
      <OhsModalFormItem sm={4}>
        <OhsSelectField
          id={`content.buttons[${buttonIndex}].click.actionType`}
          title="Click Action Type"
          required
          options={[
            { name: 'Link', value: 'link' },
            { name: 'Pop-up', value: 'popup' },
            { name: 'Slide', value: 'slide' },
          ]}
          ohsFormSettings={ohsFormSettings}
          defaultValue={buttonItem.click.actionType ?? 'link'}
        />
      </OhsModalFormItem>
      <OhsModalFormItem sm={4}>
        <OhsTextField
          type="text"
          title="Target URL"
          id={`content.buttons[${buttonIndex}].click.target`}
          required
          ohsFormSettings={ohsFormSettings}
          defaultValue={buttonItem.click.target ?? ''}
        />
      </OhsModalFormItem>
      <OhsModalFormItem sm={4} />

      {renderActionTypeScpeific({
        buttonIndex,
        actionType: actionTypeValue,
        buttonItem,
        ohsFormSettings,
      })}
    </>
  );
};

const defaultButtonObject = {
  type: 'action',
  label: 'Action Btn',
  fgColor: '#FFFFFF',
  bgColor: '#43A047',
  click: {
    actionType: 'link',
    target: '',
    external: true,
  },
};

export default function ButtonForm(props: Props) {
  const control = props.ohsFormSettings.useFormMethods.control;

  const {
    fields: buttonFieldList,
    append: appendButton,
    remove: removeButton,
  } = useFieldArray({ control, name: 'content.buttons' });

  return (
    <>
      <h5>Buttons</h5>
      {buttonFieldList.length === 0 && (
        <ButtonFormWrapper>
          <div style={{ color: 'grey', textAlign: 'center', flex: '1' }}>
            No action button(s) applied.
          </div>
        </ButtonFormWrapper>
      )}
      {buttonFieldList.map((buttonItem: any, buttonIndex) => (
        <ButtonFormWrapper key={buttonItem.id}>
          <div style={{ flex: '1' }}>
            <OhsModalForm>
              <OhsModalFormItem sm={4}>
                <OhsSelectField
                  id={`content.buttons[${buttonIndex}].type`}
                  title={`Button ${buttonIndex + 1}`}
                  required
                  options={[
                    { name: 'Action', value: 'action' },
                    { name: 'Menu', value: 'menu' },
                  ]}
                  ohsFormSettings={props.ohsFormSettings}
                  defaultValue={buttonItem.type}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={4}>
                <OhsTextField
                  type="text"
                  title="Label"
                  id={`content.buttons[${buttonIndex}].label`}
                  required
                  ohsFormSettings={props.ohsFormSettings}
                  defaultValue={buttonItem.label}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={2}>
                <OhsTextField
                  type="color"
                  title="Text Color"
                  id={`content.buttons[${buttonIndex}].fgColor`}
                  required
                  ohsFormSettings={props.ohsFormSettings}
                  defaultValue={buttonItem.fgColor}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={2}>
                <OhsTextField
                  type="color"
                  title="Button Color"
                  id={`content.buttons[${buttonIndex}].bgColor`}
                  required
                  ohsFormSettings={props.ohsFormSettings}
                  defaultValue={buttonItem.bgColor}
                />
              </OhsModalFormItem>
            </OhsModalForm>
            <OhsModalForm>
              <ActionTypeForm
                buttonIndex={buttonIndex}
                buttonItem={buttonItem}
                ohsFormSettings={props.ohsFormSettings}
              />
            </OhsModalForm>
          </div>
          <div>
            <RemoveButtonIcon onClick={() => removeButton(buttonIndex)} />
          </div>
        </ButtonFormWrapper>
      ))}
      <div style={{ display: 'flex' }}>
        <AddButton
          style={{ margin: '0 10px 0 auto' }}
          onClick={() => appendButton(defaultButtonObject)}
        />
      </div>
    </>
  );
}
