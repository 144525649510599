import React from 'react';

import OhsModuleBackground from '../../global/register/OhsModuleBackground';
import { OhsTab } from '../../global/tab/OhsTab';
import OhsNoticeBoardFormRegister from '../form/register/OhsNoticeBoardFormRegister';
import OhsNoticeBoardRegister from './register/OhsNoticeBoardRegister';

export default function OhsNoticeBoardPage() {
  const tabItems = [
    {
      tabKey: 'board',
      tabName: 'Noticeboard',
      content: <OhsNoticeBoardRegister />,
    },
    {
      tabKey: 'form',
      tabName: 'Form',
      content: <OhsNoticeBoardFormRegister />,
    },
  ];
  return (
    <OhsModuleBackground>
      <OhsTab tabItems={tabItems} />
    </OhsModuleBackground>
  );
}
