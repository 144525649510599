import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
import { OhsModulePermissions } from './OhsModulePermissions';
export class OhsIncidentPermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  signoff: boolean;
  archive: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
  }
}
export class OhsIncidentConfig {
  TITLE: string;
  PERMISSIONS: OhsIncidentPermissions;
  MORPH_PERMISSIONS: OhsModulePermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');

    this.PERMISSIONS = new OhsIncidentPermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(
      this.MORPH_PERMISSIONS,
      getConfigValue(config, 'MORPH_PERMISSIONS')
    );
  }
}
