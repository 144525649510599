import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';
import { Button, Divider, Segment } from 'semantic-ui-react';
import { getCurrentTimezone } from '../../../common/OhsDataParse';
import OhsModalForm from '../../../global/form/OhsModalForm';
import OhsModalFormItem from '../../../global/form/OhsModalFormItem';
import OhsModuleBackground from '../../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../../global/register/OhsModuleHeader';
import { editFormLayout } from '../../../global/theme/OhsPresetStyles';
import { getOhsUser } from '../../../user/OhsUser';
import { OhsCusval, OhsCusvalType } from 'global/form/cusvals/OhsCusvalModel';
import OhsTextField from 'global/form/fields/OhsTextField';
import OhsUrlField from 'global/form/fields/OhsUrlField';
import OhsSelectField from 'global/form/fields/OhsSelectField';
import OhsRichTextField from 'global/form/fields/OhsRichTextField';
import {
  NoticeboardForm,
  NoticeboardFormMessageType,
} from '../../OhsNoticeBoardModels';
import {
  addEditNoticeBoardForm,
  fetchNoticeboardForm,
} from '../../OhsNoticeBoardServices';
import { OhsNoticeBoardFormCusvalRegister } from './OhsNoticeBoardFormCusvalRegister';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';

export const OhsNoticeBoardFormAddEditPage = () => {
  const user = getOhsUser();
  const formLayout = editFormLayout();
  const layout = formLayout.classes;

  let { formId } = useParams<{ formId: string }>();
  const [noticeboardForm, setNoticeboardForm] = React.useState(
    new NoticeboardForm()
  );
  const [cusvals, setCusvals] = React.useState(new Array<OhsCusval>());
  const [rows, setRows] = React.useState(new Array<Row<any>>());
  const ohsFormSettings = new OhsFormSettings(
    noticeboardForm,
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  React.useEffect(() => {
    async function fetchForm() {
      if (formId) {
        const form = await fetchNoticeboardForm(formId);
        if (form) {
          if (form.cusvals && form.cusvals.length > 0 && cusvals.length === 0) {
            setCusvals([...form.cusvals]);
          }
          setNoticeboardForm(form);
          const formObj = { ...form } as any;
          if (
            form.messages.pre &&
            form.messages.pre.type === NoticeboardFormMessageType.Youtube
          ) {
            formObj.preYoutubeValue = form.messages.pre.value;
          } else if (
            form.messages.pre &&
            form.messages.pre.type === NoticeboardFormMessageType.RichText
          ) {
            formObj.messages.pre.richTextValue = form.messages.pre.value;
          }

          if (
            form.messages.in &&
            form.messages.in.type === NoticeboardFormMessageType.Youtube
          ) {
            formObj.inYoutubeValue = form.messages.in.value;
          } else if (
            form.messages.in &&
            form.messages.in.type === NoticeboardFormMessageType.RichText
          ) {
            formObj.messages.in.richTextValue = form.messages.in.value;
          }

          if (
            form.messages.post &&
            form.messages.post.type === NoticeboardFormMessageType.Youtube
          ) {
            formObj.postYoutubeValue = form.messages.post.value;
          } else if (
            form.messages.post &&
            form.messages.post.type === NoticeboardFormMessageType.RichText
          ) {
            formObj.messages.post.richTextValue = form.messages.post.value;
          }

          ohsFormSettings.useFormMethods.reset({ ...formObj });
        }
      }
    }
    fetchForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  const [preType, inType, postType]: [string, string, string] =
    ohsFormSettings.useFormMethods.watch([
      'messages.pre.type',

      'messages.in.type',

      'messages.post.type',
    ]);

  const messageTypeOptions = [
    { name: 'YouTube', value: NoticeboardFormMessageType.Youtube },
    { name: 'Rich Text', value: NoticeboardFormMessageType.RichText },
  ];
  const formSubmit = async (data: any) => {
    const form = new NoticeboardForm();
    if (noticeboardForm._id) {
      form._id = noticeboardForm._id;
    }
    form.title = data.title;
    form.description = data.description;

    form.cusvals = [...rows].map((item: any) => {
      delete item.original.requiredDisplay;
      delete item.original.typeDisplay;
      return item.original;
    });
    form.tzDateCreated = getCurrentTimezone();
    if (data.messages.pre.type === NoticeboardFormMessageType.Youtube) {
      form.messages.pre = {
        type: data.messages.pre.type,
        value: data.preYoutubeValue,
      };
    } else if (data.messages.pre.type === NoticeboardFormMessageType.RichText) {
      form.messages.pre = {
        type: data.messages.pre.type,
        value: data.messages.pre.richTextValue,
      };
    }

    if (data.messages.in.type === NoticeboardFormMessageType.Youtube) {
      form.messages.in = {
        type: data.messages.in.type,
        value: data.inYoutubeValue,
      };
    } else if (data.messages.in.type === NoticeboardFormMessageType.RichText) {
      form.messages.in = {
        type: data.messages.in.type,
        value: data.messages.in.richTextValue,
      };
    }

    if (data.messages.post.type === NoticeboardFormMessageType.Youtube) {
      form.messages.post = {
        type: data.messages.post.type,
        value: data.postYoutubeValue,
      };
    } else if (
      data.messages.post.type === NoticeboardFormMessageType.RichText
    ) {
      form.messages.post = {
        type: data.messages.post.type,
        value: data.messages.post.richTextValue,
      };
    }

    const addEditNoticeBoardFormReq = await addEditNoticeBoardForm(form);
    let url = `/noticeboard/admin?tab=form&archived=false`;
    if (addEditNoticeBoardFormReq) window.open(url, '_self');
  };

  return (
    <>
      <OhsModuleBackground>
        <main className={layout.layout}>
          <OhsModuleHeader
            title={
              (formId ? 'Edit ' : 'Add ') +
              user?.getCurrentURLModuleConfig()?.TITLE +
              ' Form'
            }
          ></OhsModuleHeader>
          <Segment>
            <OhsModalForm>
              <OhsModalFormItem>
                <OhsTextField
                  type="text"
                  title="Title"
                  id="title"
                  required
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>

              <OhsModalFormItem>
                <OhsTextField
                  type={OhsCusvalType.Textarea}
                  title="Details"
                  id="description"
                  required
                  ohsFormSettings={ohsFormSettings}
                  rows={4}
                />
              </OhsModalFormItem>
            </OhsModalForm>
          </Segment>
          <Segment>
            <OhsNoticeBoardFormCusvalRegister
              cusvals={cusvals}
              setCusvals={setCusvals}
              setRows={setRows}
            ></OhsNoticeBoardFormCusvalRegister>
          </Segment>
          <Segment>
            <OhsModuleHeader title="Form - Message Set-up"></OhsModuleHeader>
            <OhsModalForm>
              <OhsModalFormItem>
                <OhsModuleHeader as="h4" title="Pre Message"></OhsModuleHeader>
                <p>This information is the intro message.</p>
              </OhsModalFormItem>

              <OhsModalFormItem sm={6}>
                <OhsSelectField
                  id="messages.pre.type"
                  title="Message Type"
                  required={false}
                  options={messageTypeOptions}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                {preType === NoticeboardFormMessageType.Youtube && (
                  <OhsUrlField
                    title="YouTube"
                    id="preYoutubeValue"
                    required
                    ohsFormSettings={ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>
              <OhsModalFormItem sm={12}>
                {preType === NoticeboardFormMessageType.RichText && (
                  <OhsRichTextField
                    title="Rich Text"
                    id="messages.pre.richTextValue"
                    required={true}
                    ohsFormSettings={ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>
              <OhsModalFormItem>
                <Divider />
              </OhsModalFormItem>
              <OhsModalFormItem>
                <OhsModuleHeader as="h4" title="In Message"></OhsModuleHeader>
                <p>
                  This is the section where the custom questions are presented.
                </p>
              </OhsModalFormItem>

              <OhsModalFormItem sm={6}>
                <OhsSelectField
                  id="messages.in.type"
                  title="Message Type"
                  required={false}
                  options={messageTypeOptions}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                {inType === NoticeboardFormMessageType.Youtube && (
                  <OhsUrlField
                    title="YouTube"
                    id="inYoutubeValue"
                    required
                    ohsFormSettings={ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>
              <OhsModalFormItem>
                {inType === NoticeboardFormMessageType.RichText && (
                  <OhsRichTextField
                    title="Rich Text"
                    id="messages.in.richTextValue"
                    required={true}
                    ohsFormSettings={ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>
              <OhsModalFormItem>
                <Divider />
              </OhsModalFormItem>
              <OhsModalFormItem>
                <OhsModuleHeader as="h4" title="Post Message"></OhsModuleHeader>
                <p>This information is presented on submission of the form.</p>
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                <OhsSelectField
                  id="messages.post.type"
                  title="Message Type"
                  required={false}
                  options={messageTypeOptions}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                {postType === NoticeboardFormMessageType.Youtube && (
                  <OhsUrlField
                    title="YouTube"
                    id="postYoutubeValue"
                    required
                    ohsFormSettings={ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>
              <OhsModalFormItem>
                {postType === NoticeboardFormMessageType.RichText && (
                  <OhsRichTextField
                    title="Rich Text"
                    id="messages.post.richTextValue"
                    required={true}
                    ohsFormSettings={ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>
            </OhsModalForm>
          </Segment>

          <div className={layout.rightbutton}>
            <Button
              onClick={ohsFormSettings.useFormMethods.handleSubmit(formSubmit)}
              color="green"
            >
              Submit
            </Button>
          </div>
        </main>
      </OhsModuleBackground>
    </>
  );
};
