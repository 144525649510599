import { MenuItem } from '@mui/material';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';

const useCascadingMenuStyles = makeStyles()(() => ({
  menuitem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'initial',
  },
}));

interface SingleMenuItemProps {
  onClick?: any;
  children: React.ReactNode;
  item: any;
  ohsFormSettings: any;
  id: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OhsOptionWithNoSubMenus = (props: SingleMenuItemProps) => {
  const { classes } = useCascadingMenuStyles();

  const result =
    props.ohsFormSettings.useFormMethods.watch(props.id) ?? ([] as string[]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      const newItem = [props.item.name];
      const exists = result.some((arr: any) => {
        return (
          arr.length === newItem.length &&
          arr.every((element: any, index: number) => element === newItem[index])
        );
      });
      if (!exists) {
        props.ohsFormSettings.useFormMethods.setValue(props.id, [
          ...result,
          newItem,
        ]);
        props.ohsFormSettings.useFormMethods.trigger(props.id);
      }
      props.setOpen(false);
      if (props.onClick) props.onClick(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onClick]
  );

  return (
    <MenuItem {...props} onClick={handleClick} className={classes.menuitem}>
      {props.item.icon ? <Icon name={props.item.icon} /> : ''}
      <span
        style={{
          whiteSpace: 'pre-wrap',
        }}
      >
        {props.item.name}
      </span>
    </MenuItem>
  );
};
