import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import _ from 'lodash';
import OhsModal2 from '../global/modal/OhsModal2';
import OhsUserSettingsMFA from '../user/user-settings/mfa/OhsUserSettingsMFA';
import { OhsTier } from '../models/tier/OhsTier';

export interface MfaUserDetailsTypes {
  email: string;
  phoneCountryCode: string;
  phone: string;
  mfaConfigStatus?: string;
  tier?: OhsTier;
}

interface Props {
  open: boolean;
  setModalOpen: (open: boolean) => void;
  userDetails?: MfaUserDetailsTypes;
  onSuccess?: () => void;
}

const useStyles = makeStyles()({
  content: {
    minHeight: '250px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-10px',
    '* > label': {
      fontWeight: 'bold',
    },
  },
  description: {
    fontSize: '0.875',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center !important',
    padding: '8px',
    width: '100%',
  },
});

function OhsMfaSettingsOnLoginModal(props: Props) {
  const { classes } = useStyles();

  return (
    <>
      <OhsModal2
        headerColor="#F6F8FA"
        open={props.open}
        title={'Enable Multi-Factor Authentication'}
        alignHeader="center"
        maxWidth={'xs'}
        setModalOpenFunc={() => props.setModalOpen(!props.open)}
        showClose={true}
        ContentNode={
          <Box className={classes.content}>
            <OhsUserSettingsMFA
              title={''}
              email={props.userDetails?.email ?? ''}
              phoneCountryCode={props.userDetails?.phoneCountryCode ?? ''}
              phoneNum={props.userDetails?.phone ?? ''}
              modalUse={true}
              onSuccess={() => props.onSuccess && props.onSuccess()}
              mfaConfigStatus={props.userDetails?.mfaConfigStatus}
            />
          </Box>
        }
        actionNode={<div className={classes.footer}></div>}
      />
    </>
  );
}

export default OhsMfaSettingsOnLoginModal;
