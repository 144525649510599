import ohsApiClient from '../../common/OhsApi';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import {
  VisitorContact,
  VisitorForm,
  VisitorMessage,
  VisitorRole,
  VisitorSignInRecord,
  VisitorSite,
  Visitor_Local_Arrive_List_Name,
} from './VisitorModels';
import { OhsUser } from '../../user/OhsUser';
import {
  OhsCheckboxCusval,
  OhsCurrencyCusval,
  OhsCusval,
} from 'global/form/cusvals/OhsCusvalModel';

export const fetchSite = (siteId: string): Promise<VisitorSite> => {
  let endpoint = `/visitors/site/${siteId}/fetch`;
  return ohsApiClient
    .get(endpoint)
    .then(function (response) {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};
export const fetchForm = (formId: string): Promise<VisitorForm> => {
  let endpoint = `/visitors/form/${formId}/fetch`;
  return ohsApiClient
    .get(endpoint)
    .then(function (response) {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.item;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const submitSignForm = (
  visitorContact: VisitorContact,
  visitorSite: VisitorSite,
  visitorRole: VisitorRole,
  visitorCusvalListMap: Map<
    String,
    {
      arrive: Array<OhsCusval> | undefined;
      leave: Array<OhsCusval> | undefined;
    }
  >,
  tierId: string | undefined,
  visitId: string | undefined
) => {
  if (visitId) {
    return submitLeaveForm(
      visitorContact,
      visitorSite,
      visitorRole,
      visitorCusvalListMap,
      visitId
    );
  } else {
    return submitArriveForm(
      visitorContact,
      visitorSite,
      visitorRole,
      visitorCusvalListMap,
      tierId
    );
  }
};

export const submitArriveForm = (
  visitorContact: VisitorContact,
  visitorSite: VisitorSite,
  visitorRole: VisitorRole,
  visitorCusvalListMap: Map<
    String,
    {
      arrive: Array<OhsCusval> | undefined;
      leave: Array<OhsCusval> | undefined;
    }
  >,
  tierId: string | undefined
) => {
  let endpoint = '/visitors/visit/arrive';
  const siteForm = _.find(visitorSite.forms, {
    role: { _id: visitorRole._id },
  });
  let formId = siteForm?.arrive?.form._id;
  let payload = {
    site: {
      _id: visitorSite._id,
    },

    arrive: {
      type: 'core.module.visitor.form',
      _id: formId,

      cusvals: parseCusvalList(
        visitorCusvalListMap.get(visitorRole._id)?.arrive
      ),
    },

    visitor: {
      role: siteForm?.role,
      pii: {
        name: visitorContact.name,
        email: visitorContact.email,

        phone: visitorContact.phone,
        phoneCountryCode: visitorContact.phoneCountryCode,
      },
    },
    tier: tierId == null ? null : { type: 'core.tier.T4', _id: tierId },
    tz: moment.tz.guess(),
  };

  return ohsApiClient
    .post(endpoint, payload)
    .then(function (response) {
      if (response && response.data && response.data.result) {
        if (response.data.success === true) {
          storeVisitorSignInLocalRecord(response.data.result.item);
        }
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

export const submitLeaveForm = (
  visitorContact: VisitorContact,
  visitorSite: VisitorSite,
  visitorRole: VisitorRole,
  visitorCusvalListMap: Map<
    String,
    {
      arrive: Array<OhsCusval> | undefined;
      leave: Array<OhsCusval> | undefined;
    }
  >,
  visitId: string
) => {
  let endpoint = '/visitors/visit/leave';
  let formId = _.find(visitorSite.forms, { role: visitorRole })?.leave?.form
    ._id;
  let payload = {
    _id: visitId,

    leave: {
      type: 'core.module.visitor.form',
      _id: formId ?? null,

      cusvals: parseCusvalList(
        visitorCusvalListMap.get(visitorRole._id)?.leave
      ),
    },

    tz: moment.tz.guess(),
  };

  return ohsApiClient
    .post(endpoint, payload)
    .then(function (response) {
      if (response && response.data && response.data.result) {
        if (response.data.success === true) {
          removeVisitorSignInLocalRecord(response.data.result.item._id);
        }
        return response.data;
      } else {
        return null;
      }
    })
    .catch(function (error) {
      console.error(error);
      return error;
    });
};

const parseCusvalList = (list: Array<OhsCusval> | undefined): Array<any> => {
  const cusList = new Array<any>();
  if (list) {
    list.map((cusval) => {
      let cusValue: any = cusval.value ? cusval.value : null;
      if (cusval.type === 'time' && cusval.value) {
        let timeParts = cusval.value.split(':');
        cusValue = {
          hours: parseInt(timeParts[0], 10),
          minutes: parseInt(timeParts[1], 0),
        };
      }
      if (cusval.type === 'tel' && cusval.value) {
        cusValue = parsePhoneNumberJSON(cusval.value);
      }
      if (cusval.type === 'radio' && cusval.value) {
        cusValue = [cusval.value];
      }
      if (cusval.type === 'select_single' && cusval.value) {
        cusValue = [cusval.value];
      }
      if (cusval.type === 'checkbox' && cusval.value) {
        if (JSON.parse(cusval.value)) {
          cusValue = JSON.parse(cusval.value);
        } else {
          cusValue = null;
        }
      }
      if (cusval.type === 'currency' && cusval.value) {
        if (JSON.parse(cusval.value).amount) {
          cusValue = JSON.parse(cusval.value);
        } else {
          cusValue = null;
        }
      }
      cusList.push({
        _id: cusval._id,
        title: cusval.title,
        type: cusval.type,
        value: cusValue,
      });
    });
  }

  return cusList;
};

export const PhoneNumberDivider = ' - ';
const parsePhoneNumberJSON = (value: string | null | undefined) => {
  if (value && JSON.parse(value).number) {
    let phonenumber =
      JSON.parse(value).code + PhoneNumberDivider + JSON.parse(value).number;
    return phonenumber;
  } else {
    return null;
  }
};
const parseCurrencyJSON = (value: string | null | undefined) => {
  if (value && JSON.parse(value).amount) {
    let currency = JSON.parse(value).amount + ' ' + JSON.parse(value).code;
    return currency;
  } else {
    return null;
  }
};
const parseCheckboxJSON = (value: string | null | undefined) => {
  if (value && JSON.parse(value)) {
    return JSON.parse(value).join(', ');
  } else {
    return null;
  }
};
export const getDisplayValue = (product: OhsCusval) => {
  if (product.type === 'tel') {
    return parsePhoneNumberJSON(product.value);
  } else if (product.type === 'currency') {
    return parseCurrencyJSON(product.value);
  } else if (product.type === 'checkbox') {
    return parseCheckboxJSON(product.value);
  } else {
    return product.value;
  }
};

export type propsType = {
  step: number;
  visitorSite: VisitorSite;
  visitorRole: VisitorRole;
  handleNext: () => void;
  handleBack: () => void;
  visitorFormList: Array<VisitorForm>;
  visitorCusvalListMap: Map<
    String,
    {
      arrive: Array<OhsCusval> | undefined;
      leave: Array<OhsCusval> | undefined;
    }
  >;
};
export const getAndInitialCusvalListByRole = (
  visitorSite: VisitorSite,
  visitorRole: VisitorRole,
  visitorFormList: Array<VisitorForm>,
  visitorCusvalListMap: Map<
    String,
    {
      arrive: Array<OhsCusval> | undefined;
      leave: Array<OhsCusval> | undefined;
    }
  >,
  isArrive: boolean
) => {
  let siteForm = _.find(visitorSite.forms, { role: { _id: visitorRole._id } });
  if (siteForm == null || visitorFormList == null || visitorFormList.length < 1)
    return new Array<OhsCusval>();
  const cusvalListKey = visitorRole._id;
  let cusvalArriveLeaveList = visitorCusvalListMap.get(cusvalListKey);
  let cusvalList;
  if (cusvalArriveLeaveList == null) {
    cusvalArriveLeaveList = { arrive: undefined, leave: undefined };
  }
  if (isArrive === true) {
    if (cusvalArriveLeaveList.arrive) {
      cusvalList = cusvalArriveLeaveList.arrive;
    } else {
      cusvalList = _.find(visitorFormList, {
        _id: siteForm?.arrive?.form._id,
      })?.cusvals;
    }
  } else {
    if (cusvalArriveLeaveList.leave) {
      cusvalList = cusvalArriveLeaveList.leave;
    } else {
      cusvalList = _.find(visitorFormList, {
        _id: siteForm?.leave?.form._id,
      })?.cusvals;
    }
  }
  if (cusvalList == null || cusvalList.length === 0) {
    if (cusvalList == null || cusvalList.length === 0) {
      cusvalList = new Array<OhsCusval>();
    }
  }
  cusvalList.map((item) => {
    if (item.value == null) {
      if (item.type === 'tel') {
        item.value = JSON.stringify({ code: '61', number: '' });
      } else if (item.type === 'currency') {
        item = item as OhsCurrencyCusval;
        item.value = JSON.stringify({
          amount: '',
          code: item.options ? item.options[0] : '',
        });
      } else if (item.type === 'checkbox') {
        item.value = JSON.stringify([]);
      } else {
        item.value = '';
      }
    }
  });
  if (isArrive === true) {
    cusvalArriveLeaveList.arrive = cusvalList;
  } else {
    cusvalArriveLeaveList.leave = cusvalList;
  }
  visitorCusvalListMap.set(cusvalListKey, cusvalArriveLeaveList);
  return cusvalList;
};
export const assignValuesToCusvalList = (
  cusvalList: Array<OhsCusval>,
  getValues: any
) => {
  cusvalList.forEach((item) => {
    if (item.type === 'tel' && item.value) {
      let phoneObj = JSON.parse(item.value);
      phoneObj.number = getValues(item._id);
      item.value = JSON.stringify(phoneObj);
    } else if (item.type === 'currency' && item.value) {
      let currencyObj = JSON.parse(item.value);
      currencyObj.amount = getValues(item._id);
      item.value = JSON.stringify(currencyObj);
    } else if (item.type === 'checkbox') {
      item = item as OhsCheckboxCusval;
      item.value = getValues(item._id);
    } else {
      item.value = getValues(item._id);
    }
  });
  return cusvalList;
};
export const getArriveIntroMessage = (
  role: VisitorRole,
  site: VisitorSite,
  formList: Array<VisitorForm>
): VisitorMessage | null | undefined => {
  const siteForm = _.find(site.forms, {
    role: { _id: role._id },
  });
  let message;
  message = siteForm?.arrive?.messages.pre;
  if (message == null) {
    const form = _.find(formList, {
      _id: siteForm?.arrive?.form._id,
    });
    message = form?.messages.pre;
  }

  return message;
};
export const getFormMessage = (
  role: VisitorRole,
  site: VisitorSite,
  formList: Array<VisitorForm>,
  isMiddle: boolean,
  isSignIn: boolean
): VisitorMessage | null | undefined => {
  const siteForm = _.find(site.forms, {
    role: { _id: role._id },
  });
  let message;
  if (isSignIn === true) {
    message =
      isMiddle === true
        ? siteForm?.arrive?.messages.in
        : siteForm?.arrive?.messages.post;
    if (message == null) {
      const form = _.find(formList, {
        _id: siteForm?.arrive?.form._id,
      });
      message = isMiddle === true ? form?.messages.in : form?.messages.post;
    }
  } else {
    message =
      isMiddle === true
        ? siteForm?.leave?.messages.in
        : siteForm?.leave?.messages.post;
    if (message == null) {
      const form = _.find(formList, {
        _id: siteForm?.leave?.form._id,
      });
      message = isMiddle === true ? form?.messages.in : form?.messages.post;
    }
  }

  return message;
};

export const storeVisitorSignInLocalRecord = (record: VisitorSignInRecord) => {
  let visitorSignInRecordList: Array<VisitorSignInRecord>;
  const user = new OhsUser();
  record.createdBy = user;
  if (localStorage.getItem(Visitor_Local_Arrive_List_Name)) {
    visitorSignInRecordList = JSON.parse(
      localStorage.getItem(Visitor_Local_Arrive_List_Name)!
    );
  } else {
    visitorSignInRecordList = new Array<VisitorSignInRecord>();
  }
  visitorSignInRecordList.push(record);
  localStorage.setItem(
    Visitor_Local_Arrive_List_Name,
    JSON.stringify(visitorSignInRecordList)
  );
};
export const removeVisitorSignInLocalRecord = (id: string) => {
  let visitorSignInRecordList: Array<VisitorSignInRecord>;
  if (localStorage.getItem(Visitor_Local_Arrive_List_Name)) {
    visitorSignInRecordList = JSON.parse(
      localStorage.getItem(Visitor_Local_Arrive_List_Name)!
    );
    _.remove(visitorSignInRecordList, (item) => {
      if (item._id === id) {
        return item;
      }
    });
    localStorage.setItem(
      Visitor_Local_Arrive_List_Name,
      JSON.stringify(visitorSignInRecordList)
    );
  }
};
export const getVisitorSignInLocalRecordList = (
  searchKey: string
): Array<VisitorSignInRecord> => {
  let visitorSignInRecordList = new Array<VisitorSignInRecord>();
  const user = new OhsUser();
  if (searchKey && localStorage.getItem(Visitor_Local_Arrive_List_Name)) {
    const localSignInRecordList: Array<VisitorSignInRecord> = JSON.parse(
      localStorage.getItem(Visitor_Local_Arrive_List_Name)!
    );
    for (let index = 0; index < localSignInRecordList.length; index++) {
      const element = localSignInRecordList[index];
      if (
        element.createdBy._id === user._id &&
        (element.visitor.pii.name === searchKey ||
          element.visitor.pii.email === searchKey ||
          element.visitor.pii.phone === searchKey)
      ) {
        visitorSignInRecordList.push(element);
      }
    }
  }
  return visitorSignInRecordList;
};
