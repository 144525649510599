import React, { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import _ from 'lodash';

import MButton from '../../../../global/buttons/OhsMaterialButton';
import {
  OhsCountry,
  getStateOfCountry,
} from '../../../../global/utils/OhsCountry';
import OhsModalForm from '../../../../global/form/OhsModalForm';
import OhsModalFormItem from '../../../../global/form/OhsModalFormItem';
import { OhsFormSettings } from 'global/form/OhsFormModels';
import OhsSelectField from 'global/form/fields/OhsSelectField';

import {
  FormContainer,
  AddButton,
  RemoveButtonIcon,
} from '../../OhsDashboardBannerStyledComponents';

const defaultLocationObject = { country: '', state: '' };

interface Props {
  ohsFormSettings: OhsFormSettings;
}

const StateInput = ({
  inputIndex,
  countryValue,
  defaultValue,
  ohsFormSettings,
}: {
  inputIndex: number;
  countryValue: string;
  defaultValue: string;
  ohsFormSettings: OhsFormSettings;
}) => {
  const stateOptions = useMemo(() => {
    if (countryValue) {
      const filteredState = getStateOfCountry(countryValue);
      return [
        { name: 'All', value: '*' },
        ..._.map(filteredState, ({ name }) => ({ name, value: name })),
      ];
    }

    return [];
  }, [countryValue]);

  return (
    <OhsSelectField
      id={`countryAndState[${inputIndex}].state`}
      title="State"
      required
      options={stateOptions}
      ohsFormSettings={ohsFormSettings}
      defaultValue={defaultValue}
    />
  );
};

export default function CountryAndStateForm(props: Props) {
  const control = props.ohsFormSettings.useFormMethods.control;
  const countryOptions = useMemo(() => {
    return _.map(OhsCountry, ({ id, name }) => ({
      name,
      value: String(id),
    }));
  }, []);

  const {
    fields: locationFieldList,
    append: appendLocation,
    remove: removeLocation,
  } = useFieldArray({ control, name: 'countryAndState' });

  const locationLength = locationFieldList.length;

  return (
    <>
      <h4>Country And State</h4>
      <FormContainer>
        {locationFieldList.map((item: any, index) => {
          const countryValue = props.ohsFormSettings.useFormMethods.watch(
            `countryAndState[${index}].country`
          );

          return (
            <OhsModalForm key={item.id}>
              <OhsModalFormItem sm={5}>
                <OhsSelectField
                  id={`countryAndState[${index}].country`}
                  title="Country"
                  required
                  options={countryOptions}
                  ohsFormSettings={props.ohsFormSettings}
                  defaultValue={item.country}
                />
              </OhsModalFormItem>
              <OhsModalFormItem sm={6}>
                {countryValue && (
                  <StateInput
                    inputIndex={index}
                    countryValue={countryValue}
                    defaultValue={item.state || '*'}
                    ohsFormSettings={props.ohsFormSettings}
                  />
                )}
              </OhsModalFormItem>
              {locationLength > 1 && (
                <OhsModalFormItem sm={1}>
                  <RemoveButtonIcon onClick={() => removeLocation(index)} />
                </OhsModalFormItem>
              )}
            </OhsModalForm>
          );
        })}
        <div style={{ display: 'flex' }}>
          {locationLength > 0 && (
            <MButton
              style={{ marginLeft: 'auto' }}
              variant="outlined"
              color="primary"
              onClick={() =>
                props.ohsFormSettings.useFormMethods.setValue(
                  'countryAndState',
                  []
                )
              }
            >
              Set To All Country
            </MButton>
          )}
          <AddButton
            style={{
              margin: '0 10px 0',
              marginLeft: locationLength > 0 ? '10px' : 'auto',
            }}
            onClick={() => appendLocation(defaultLocationObject)}
            label="Add Specific Country And State"
          />
        </div>
      </FormContainer>
    </>
  );
}
