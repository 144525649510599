import React from 'react';
import OhsRegister from '../../../global/register/OhsRegister';
import { getOhsUser } from '../../../user/OhsUser';
import { NoticeboardForm } from '../../OhsNoticeBoardModels';
import { archiveNoticeBoardForm } from '../../OhsNoticeBoardServices';

type Props = {
  noticeBoardFormList: Array<NoticeboardForm>;
  reloadNoticeBoardFormList: any;
};

export default function OhsNoticeBoardFormList(props: Props) {
  const [noticeBoardFormList, setNoticeBoardFormList] = React.useState(
    props.noticeBoardFormList
  );

  React.useEffect(() => {
    setNoticeBoardFormList(props.noticeBoardFormList);
  }, [props.noticeBoardFormList]);

  const archiveFunc = async (item: NoticeboardForm, isArchive: boolean) => {
    const archiveNBFormReq = await archiveNoticeBoardForm(item._id, isArchive);
    if (archiveNBFormReq) window.location.reload();
  };

  const editFunc = (item: NoticeboardForm) => {
    const url = `/noticeboard/form/${item._id}/edit`;
    window.open(url, '_self');
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'titleId',
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
    ],
    []
  );
  const user = getOhsUser();

  return (
    <>
      <OhsRegister
        columns={columns}
        data={noticeBoardFormList}
        sortBy={[{ id: 'titleId', desc: false }]}
        hasExpanding={false}
        rowOperations={{
          editButton: {
            func: editFunc,
            permission:
              user?.configs.noticeboard?.PERMISSIONS.form_edit === true,
          },
          archiveButton: {
            func: archiveFunc,
            permission:
              user?.configs.noticeboard?.PERMISSIONS.form_archive === true,
          },
        }}
      />
    </>
  );
}
