import React from 'react';
import OhsModal from '../../../global/modal/OhsModal';
import OhsNoticeboardForm from './OhsNoticeboardForm';

export default function RenderOhsCusvalField(props: any) {
  const {
    modalOpen,
    setModalOpen,
    onClose,
    contacts,
    selectedRecord,
    selectedForm,
  } = props;
  const { title } = selectedForm;
  return (
    <OhsModal
      open={modalOpen}
      setModalOpenFunc={setModalOpen}
      onClose={onClose}
      title={title}
      ContentNode={
        <OhsNoticeboardForm
          selectedRecord={selectedRecord}
          selectedForm={selectedForm}
          contacts={contacts}
          onClose={onClose}
        />
      }
    />
  );
}
