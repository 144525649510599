import React from 'react';
import { getOhsUser } from '../../user/OhsUser';
export const OhsModalContentHeader = (props: { title: string }) => {
  const user = getOhsUser();
  return (
    <h3
      style={{
        color: user?.configs.theme.HEADER_BAR_BG_COLOR,
        fontWeight: 700,
        fontFamily: 'Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
      }}
    >
      <span>{props.title}</span>
    </h3>
  );
};
