import React, { useEffect, useState } from 'react';
import { Divider, Form, FormField, Icon, Segment } from 'semantic-ui-react';
import { OhsModuleCategory } from '../../user/configurations/OhsModuleCategory';
import { connect } from 'react-redux';
import { OhsNoticeBoardRecord } from '../OhsNoticeBoardModels';
import { makeStyles } from 'tss-react/mui';

interface Props {
  categoryList: OhsModuleCategory[];
  selectedCategory: number;
  selectedSubcategory: number;
  selectedRecord?: OhsNoticeBoardRecord;
  uncategoryList: OhsNoticeBoardRecord[];
  setCategory: (category: string, subcategory: string) => void;
  screenWidth?: any;
  selectBoard: (record: OhsNoticeBoardRecord) => void;
}

const mapStateToProps = (state: any) => {
  return { screenWidth: state.screenSizeReducer.screenWidth };
};

function OhsNoticeboardRightMenu(props: Props) {
  const useStyles = makeStyles()({
    selected: {
      color: '#03a9f4',
    },
    default: {
      color: '#000',
    },
  });
  const { classes } = useStyles();

  const [state, setState] = useState(props);

  useEffect(() => {
    setState((state) => ({
      ...state,
      ...{
        selectedCategory: props.selectedCategory,
        selectedSubcategory: props.selectedSubcategory,
        selectedRecord: props.selectedRecord,
      },
    }));
  }, [
    props.selectedCategory,
    props.selectedSubcategory,
    props.selectedRecord,
    props.categoryList,
  ]);

  const setCollapse = (item: any, value: Boolean) => {
    item.collapsed = value;
    setState((state) => ({
      ...state,
      ...item,
    }));
  };

  const selectNoticeboardRecord = (
    categoryIndex: number,
    subCategoryIndex: number,
    record: OhsNoticeBoardRecord
  ) => {
    props.selectBoard(record);
    setState((state) => ({
      ...state,
      ...{
        selectedCategory: categoryIndex,
        selectedSubcategory: subCategoryIndex,
        selectedRecord: record,
      },
    }));
  };

  const { screenWidth } = props;

  let items: any = [];
  props.categoryList.forEach((item, index) => {
    items.push({ key: index, value: item.category, text: item.category });
    item.children.forEach((sub, index2) => {
      items.push({
        key: `${index}-${index2}`,
        value: sub.category,
        text: `-- ${sub.category}`,
      });
      sub.records.forEach((record, index3) => {
        items.push({
          key: `${index}-${index2}-${index3}`,
          value: record,
          text: `---- ${record.title}`,
        });
      });
    });
  });

  return (
    <div
      id="rcLeftNavBarId"
      style={{
        position: 'sticky',
        top: 10,
        // width: '24%',
        maxHeight: 'calc(100vh - 175px)',
        overflowY: screenWidth >= 1200 ? 'auto' : 'unset',
        paddingLeft: '5px',
      }}
    >
      {screenWidth >= 1200 && (
        <div className="ui fluid accordion" style={{ paddingLeft: '10px' }}>
          {props.categoryList.map((item, index) => {
            return (
              <div
                key={index}
                className="title"
                style={{
                  fontSize: '18px',
                  fontFamily:
                    'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
                  paddingRight: '8px',
                  marginTop: '0',
                }}
                id="{{category.type}}"
              >
                <div onClick={() => setCollapse(item, !item.collapsed)}>
                  <span
                    className={
                      state.selectedCategory === index
                        ? classes.selected
                        : classes.default
                    }
                  >
                    {item.category}
                  </span>
                  <Icon
                    style={{
                      float: 'right',
                    }}
                    color="grey"
                    size="large"
                    name={item.collapsed ? 'angle right' : 'angle down'}
                  />
                </div>

                <Divider />
                <div>
                  <div>
                    <ul
                      style={{
                        listStyleType: 'none',
                        paddingLeft: '10px',
                        fontSize: '15px',
                        marginTop: '0',
                        display: item.collapsed === false ? 'block' : 'none',
                      }}
                    >
                      {item.children
                        .filter((sub, index) => sub.category === 'null')
                        .map((sub, subIndex) => (
                          <li key={subIndex} style={{ marginTop: '20px' }}>
                            <div
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'visible',
                                textOverflow: 'ellipsis',
                                width: '95%',
                              }}
                            >
                              <div>
                                <ul
                                  style={{
                                    listStyleType: 'none',
                                    paddingLeft: '10px',
                                    fontSize: '15px',
                                    display: 'block',
                                  }}
                                >
                                  {sub.records.map((record, indexBoard) => {
                                    return (
                                      <li
                                        key={indexBoard}
                                        onClick={() =>
                                          selectNoticeboardRecord(
                                            -1,
                                            subIndex,
                                            record
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            width: '95%',
                                          }}
                                        >
                                          <a
                                            className={
                                              state.selectedRecord?._id ===
                                              record._id
                                                ? classes.selected
                                                : classes.default
                                            }
                                          >
                                            {record.title}
                                          </a>
                                          <Divider />
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </li>
                        ))}
                      {item.children
                        .filter((sub, index) => sub.category !== 'null')
                        .map((sub, subIndex) => {
                          return (
                            <li
                              key={subIndex}
                              style={{ paddingBottom: '20px' }}
                            >
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow:
                                    !!sub.category && sub.category != 'null'
                                      ? 'hidden'
                                      : 'visible',
                                  textOverflow: 'ellipsis',
                                  width: '95%',
                                }}
                              >
                                {!!sub.category && sub.category != 'null' && (
                                  <>
                                    <div
                                      onClick={() =>
                                        setCollapse(sub, !sub.collapsed)
                                      }
                                    >
                                      <a
                                        className={
                                          props.selectedSubcategory ===
                                            subIndex &&
                                          state.selectedCategory === index
                                            ? classes.selected
                                            : classes.default
                                        }
                                      >
                                        {sub.category}
                                      </a>
                                      <Icon
                                        style={{
                                          float: 'right',
                                        }}
                                        color="grey"
                                        size="large"
                                        name={
                                          sub.collapsed
                                            ? 'angle right'
                                            : 'angle down'
                                        }
                                      />
                                    </div>
                                    <Divider />
                                  </>
                                )}

                                <div>
                                  <ul
                                    style={{
                                      listStyleType: 'none',
                                      paddingLeft: '10px',
                                      fontSize: '15px',
                                      marginTop: '0',
                                      display:
                                        sub.collapsed == false
                                          ? 'block'
                                          : 'none',
                                    }}
                                  >
                                    {sub.records.map((record, index) => {
                                      return (
                                        <li
                                          key={index}
                                          onClick={() =>
                                            selectNoticeboardRecord(
                                              index,
                                              subIndex,
                                              record
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              width: '95%',
                                            }}
                                          >
                                            <a
                                              className={
                                                state.selectedRecord?._id ===
                                                record._id
                                                  ? classes.selected
                                                  : classes.default
                                              }
                                            >
                                              {record.title}
                                            </a>
                                            <Divider />
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
          {props.uncategoryList.map((record, uncategoryListIndex) => {
            return (
              <div
                key={uncategoryListIndex}
                className="title"
                style={{
                  fontSize: '18px',
                  fontFamily:
                    'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
                  paddingRight: '8px',
                  marginTop: '-10px',
                }}
              >
                <div onClick={() => selectNoticeboardRecord(-1, -1, record)}>
                  <span
                    className={
                      state.selectedRecord?._id === record._id
                        ? classes.selected
                        : classes.default
                    }
                  >
                    {record.title}
                  </span>
                </div>
                <Divider />
              </div>
            );
          })}
        </div>
      )}
      {screenWidth < 1200 && (
        <Segment>
          <Form>
            <FormField>
              <label>Select:</label>
              <select
                onChange={(event) =>
                  selectNoticeboardRecord(
                    -1,
                    -1,
                    items[event.target.value].value
                  )
                }
              >
                {items.map((item: any, index: number) => {
                  return (
                    <option key={index} value={index}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
            </FormField>
          </Form>
        </Segment>
      )}
    </div>
  );
}

export default connect(mapStateToProps, null)(OhsNoticeboardRightMenu);
