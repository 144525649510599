import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getEmbeddedReports } from './PowerBIServices';
import { EmbedConfig } from './models/EmbedConfig';
import './EmbedStyle.css';
import { useLocation, useParams } from 'react-router-dom';
export default function OhsDashboardReports() {
  const { tokenType } = useParams<{ tokenType: string }>();
  console.log(tokenType);

  const location = useLocation();

  const reportType = location.pathname.split('/')[2];

  const [embedConfig, setEmbedConfig] = React.useState<EmbedConfig>();

  React.useEffect(() => {
    getEmbeddedReports(reportType, tokenType ?? '').then((response) => {
      if (response) {
        setEmbedConfig(response);
      }
    });
  }, [reportType, tokenType]);
  return (
    <div>
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          id: embedConfig?.embedUrl[0].reportId,
          embedUrl: embedConfig?.embedUrl[0].embedUrl,
          accessToken: embedConfig?.accessToken,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.All,
          viewMode:
            tokenType === 'edit' ? models.ViewMode.Edit : models.ViewMode.View,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
            },
            background: models.BackgroundType.Default,
          },
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              function () {
                console.log('Report loaded');
              },
            ],
            [
              'rendered',
              function () {
                console.log('Report rendered');
              },
            ],
            [
              'error',
              function (event: any) {
                console.log(event.detail);
              },
            ],
          ])
        }
        cssClassName={'report-style-class'}
      />
    </div>
  );
}
function UserState(arg0: string): [any, any] {
  throw new Error('Function not implemented.');
}
