import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
export class OhsBixPermissions {
  edit_report: boolean;

  constructor(flag: boolean) {
    this.edit_report = flag;
  }
}
export class OhsBixConfig {
  TITLE: string;
  PERMISSIONS: OhsBixPermissions;
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsBixPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}
