import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import _ from 'lodash';
import OhsModal2 from '../../global/modal/OhsModal2';
import { CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { verifyMfaRecoveryCode } from './OhsMfaServices';
import OhsRecoverCodesActions from '../../user/user-settings/mfa/OhsRecoverCodesActions';

interface RecoveryCodeFormProps {
  verifyinCode: boolean;
  codeVerifiedSuccess: boolean;
  useremail: string;
  setRecoveryCode: React.Dispatch<React.SetStateAction<string>>;
  codeVerifiedResponse: any;
}

const OhsMfaRecoveryCodeForm = (props: RecoveryCodeFormProps) => {
  const { classes } = useStyles();
  const [codeVerifyError, setCodeVerifyError] = React.useState<string>('');
  const [newRecoveryCode, setNewRecoveryCode] = React.useState<string>('');

  useEffect(() => {
    const response = props.codeVerifiedResponse;
    const hasErr = _.get(response, 'data');
    if (hasErr) {
      setCodeVerifyError('Incorrect backup code.');
    }

    if (response) {
      // single user
      if (_.has(response.result, 'user'))
        setNewRecoveryCode(response.result.user.recoveryCodes[0] ?? '');

      // multi user
      if (_.has(response.result, 'item'))
        setNewRecoveryCode(response.result.item.recoveryCodes[0] ?? '');
    }
  }, [props.codeVerifiedResponse]);

  return (
    <Box className={classes.mainContainer}>
      {!props.codeVerifiedSuccess ? (
        <>
          <Typography variant="body1" className={classes.modalTexts}>
            Type your one-time backup code below provided during
            <br />
            MFA setup.
          </Typography>
          <Box className={classes.inputBox}>
            <TextField
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCodeVerifyError('');
                props.setRecoveryCode(event.target.value);
              }}
              variant="outlined"
              size="small"
              className={classes.input}
            />
            {props.verifyinCode && (
              <Typography
                variant="body1"
                gutterBottom
                className={`${classes.validating}`}
              >
                <CircularProgress className={classes.validatingIcon} />
                {`Verifiying recovery code ...`}
              </Typography>
            )}

            {codeVerifyError !== '' && (
              <Typography
                variant="body1"
                gutterBottom
                className={`${classes.validating} ${classes.error}`}
              >
                <ErrorIcon
                  className={`${classes.validatingIcon} ${classes.errorIcon}`}
                />
                {codeVerifyError}
              </Typography>
            )}
          </Box>
          <Typography variant="body1" className={classes.modalTexts}>
            After using a backup code, it will no longer be active.<br></br>
            Click 'Next' to login and generate new backup code.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" className={classes.modalTexts}>
            Save this new backup code and store it somewhere safe. If you lose
            your phone, you can use backup codes to sign in. <br />
            <br />
            This code will only be provided once.
          </Typography>

          <Box className={classes.recoveryCodes}>{newRecoveryCode}</Box>
          <Box className={classes.actionCodesWrap}>
            <OhsRecoverCodesActions recoveryCode={newRecoveryCode} />
          </Box>
        </>
      )}
    </Box>
  );
};

interface Props {
  onClose?: () => void;
  isRecoveryCodeModalOpen: boolean;
  setRecoveryCodeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  useremail: string;
  mfaToken: string;
  handleMfaRecoveryCodeRes: (response: any) => void;
  handleMfaResetOnRecoveryCode: (response: any) => void;
  mfaMethod: string;
}

const OhsMfaRecoveryCodeModal = (props: Props) => {
  const [recoveryCode, setRecoveryCode] = React.useState('');
  const [verifyinCode, setVerifyingCode] = React.useState(false);
  const [codeVerifiedSuccess, setCodeVerifiedSuccess] = React.useState(false);
  const [codeVerifiedResponse, setCodeVerifiedResponse] = React.useState<any>();
  const { classes } = useStyles();

  const handleVerifyRecoveryCode = async () => {
    setVerifyingCode(true);
    let formData = {
      email: props.useremail,
      recoveryCode,
      mfaToken: props.mfaToken,
    };

    const response = await verifyMfaRecoveryCode(formData);
    setVerifyingCode(false);

    if (_.get(response, 'success') === true) {
      setCodeVerifiedSuccess(true);
    }
    setCodeVerifiedResponse(response);
  };

  const handleVerifySuccess = () => {
    props.handleMfaRecoveryCodeRes(codeVerifiedResponse);
    props.setRecoveryCodeModalOpen(false);
  };

  const handleMfaResetOnRecoveryCode = () => {
    props.handleMfaResetOnRecoveryCode(codeVerifiedResponse);
    props.setRecoveryCodeModalOpen(false);
  };

  return (
    <>
      <OhsModal2
        open={props.isRecoveryCodeModalOpen}
        title={
          codeVerifiedSuccess || props.mfaMethod === 'app'
            ? 'Multi-factor Authentication'
            : 'Lost access to phone or email ?'
        }
        maxWidth={'xs'}
        ContentNode={
          <OhsMfaRecoveryCodeForm
            verifyinCode={verifyinCode}
            codeVerifiedSuccess={codeVerifiedSuccess}
            useremail={props.useremail}
            setRecoveryCode={setRecoveryCode}
            codeVerifiedResponse={codeVerifiedResponse}
          />
        }
        actionNode={
          <Box className={classes.footer}>
            {codeVerifiedSuccess ? (
              <>
                <Button
                  onClick={(e: any) => handleMfaResetOnRecoveryCode()}
                  size="small"
                  variant="contained"
                  color="warning"
                  sx={{ color: 'white' }}
                >
                  Go to reset MFA
                </Button>
                <Button
                  onClick={(e: any) => handleVerifySuccess()}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Done
                </Button>
              </>
            ) : (
              <>
                <Link
                  href="#"
                  className={classes.actionLink}
                  onClick={() => props.setRecoveryCodeModalOpen(false)}
                >
                  Cancel
                </Link>
                <Button
                  disabled={recoveryCode === ''}
                  onClick={() => handleVerifyRecoveryCode()}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Next
                </Button>
              </>
            )}
          </Box>
        }
      />
    </>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  mainContainer: {
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    justifyContent: 'center',
  },
  modalTexts: {
    fontSize: '14px',
    textAlign: 'center',
  },
  inputBox: {
    margin: '20px',
  },
  input: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  actionLink: {
    textDecoration: 'none',
    margin: '5px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  recoveryCodes: {
    padding: '2px 0 2px 0',
    width: '100%',
    display: 'block',
    textAlign: 'center',
  },
  actionCodesWrap: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 5px 0',
    width: '100%',
  },
  validating: {
    color: '#606060',
    margin: '10px 0 15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  validatingIcon: {
    verticalAlign: '-4px',
    fontSize: '16px',
    marginRight: '4px',
    width: '18px !important',
    height: '18px !important',
    color: '#606060',
    marginTop: '2px',
  },
  error: {
    color: '#DB2828',
  },
  errorIcon: {
    color: '#DB2828',
  },
}));

export default OhsMfaRecoveryCodeModal;
