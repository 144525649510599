import React, { useEffect } from 'react';
import { delCookie, setCookie } from '../common/OhsDataParse';

const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    // @ts-ignore
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,de,fr,es,zh-CN,nl,it,sv,ar,hi,tr,pt,pl,tl,vi,fa',
        // @ts-ignore
        autoDisplay: false,
        // @ts-ignore
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      'google_translate_element'
    );
  };
  useEffect(() => {
    // @ts-ignore
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    // @ts-ignore
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <>
      <div id="google_translate_element"></div>
    </>
  );
};

export default GoogleTranslate;
