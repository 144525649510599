import React from 'react';
import { TierType } from '../../models/tier/OhsTier';

export interface OhsTabItem {
  tabName: string;
  tabKey: string;
  restrictaccess?: TierType[] | boolean;
  content: React.ReactNode;
}

export enum OhsRegisterTabItemTypes {
  WorkplaceRegister = 'workplace',
  OrgRegister = 'organisation',
  MainRegister = 'mainlist',
}
