import _ from 'lodash';
import { toast } from 'react-toastify';
import ohsApiClient from '../common/OhsApi';

export const getChemical = (chemicalId: string) => {
  const endpoint = `/chemicals/${chemicalId}/fetch`;
  const errMsg = 'Unable to get the chemical';

  return ohsApiClient
    .get(endpoint)
    .then((response) => {
      if (_.get(response, 'data.success')) {
        var resData = _.get(response, 'data.result.item') || {};
        var isSDS_uploaded = false;
        (resData?.attachments || []).map((attachment: any) => {
          if (attachment.group === 'sds') {
            isSDS_uploaded = true;
          }
        });
        resData = {
          ...resData,
          isSDS_uploaded,
        };
        return resData;
      } else {
        toast.error('Unable to fetch the Chemical');
        return {};
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error(errMsg);
      return [];
    });
};
