import { getConfigValue } from './OhsConfigServices';
import { setPermissions } from './OhsConfigurations';
import { OhsModulePermissions } from './OhsModulePermissions';
export class OhsActionPermissions {
  view: boolean;
  create: boolean;
  edit: boolean;
  signoff: boolean;
  archive: boolean;
  assign_task: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.assign_task = flag;
  }
}
export class OhsActionConfig {
  TITLE: string;
  PERMISSIONS!: OhsActionPermissions;
  MORPH_PERMISSIONS: OhsModulePermissions;
  CREATE_ACTION_CATEGORY_LIST: any[];
  CREATE_ACTION_CUSVALS: any[];
  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsActionPermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.CREATE_ACTION_CATEGORY_LIST = getConfigValue(
      config,
      'CREATE_ACTION_CATEGORY_LIST'
    );
    this.CREATE_ACTION_CUSVALS = getConfigValue(
      config,
      'CREATE_ACTION_CUSVALS'
    );
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(
      this.MORPH_PERMISSIONS,
      getConfigValue(config, 'MORPH_PERMISSIONS')
    );
  }
}
