import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { registerButtonStyles } from '../register/OhsModuleHeaderButtons';
export interface OhsButtonProps {
  onClick: () => void;
  loading?: boolean;
}
export const OhsExportButton = (props: OhsButtonProps) => {
  const { classes } = registerButtonStyles();
  return (
    <Button
      className={classes.button}
      color="green"
      style={{ backgroundColor: '#1e6e42' }}
      onClick={props.onClick}
      loading={props.loading}
    >
      <Icon className={classes.buttonIcons} name="download" />
      <span className={classes.hideForSmall}>Export</span>
    </Button>
  );
};
