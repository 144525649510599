import * as _ from 'lodash';
import ohsApiClient from '../../common/OhsApi';
import { getCurrentTimezone } from '../../common/OhsDataParse';
import { OhsXsiRecord } from './OhsXsiModels';

export const getXsiList = (orgId: string): Promise<OhsXsiRecord> => {
  let endpoint = '/xsi/list';

  return ohsApiClient
    .post(endpoint, { orgTier: { _id: orgId, type: 'core.tier.T3' } })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result.items;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const setupXsiIntegration = (
  email: string,
  xsiId: string,
  orgId: string
) => {
  const endpoint = '/xsi/' + xsiId + '/setup-link';
  return ohsApiClient
    .post(endpoint, {
      email: email,
      orgTier: { _id: orgId, type: 'core.tier.T3' },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const createXsiIntegration = (
  title: string,
  description: string | null,
  errorNotifyTo: string,
  orgId: string,
  platform: string,
  version: string | null,
  emailMapping: string,
  phoneMapping: string
) => {
  const endpoint = '/xsi/new';
  const payload = {
    title: title,
    description: description,
    platform: {
      name: platform,
    },

    errorNotifyTo: {
      email: errorNotifyTo,
    },
    platformSettings: {
      version: version ? version : undefined,
      mapping: {
        email: emailMapping,
        phone: phoneMapping,
      },
    },

    tzDateCreated: getCurrentTimezone(),
    orgTier: { _id: orgId, type: 'core.tier.T3' },
  } as any;
  return ohsApiClient
    .post(endpoint, payload)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
export const editXsiIntegration = (
  xsiId: string,
  title: string,
  description: string | null,
  errorNotifyTo: string,
  orgId: string,
  version: string | null,
  emailMapping: string,
  phoneMapping: string
) => {
  const endpoint = '/xsi/' + xsiId + '/edit';
  return ohsApiClient
    .post(endpoint, {
      title: title,
      description: description,
      errorNotifyTo: {
        email: errorNotifyTo,
      },
      platformSettings: {
        mapping: {
          email: emailMapping,
          phone: phoneMapping,
        },
        version: version ? version : undefined,
      },
      orgTier: { _id: orgId, type: 'core.tier.T3' },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const disableEnableXsiIntegration = (
  xsiId: string,

  orgId: string,
  isDisable: boolean
) => {
  const endpoint =
    '/xsi/' + xsiId + (isDisable === true ? '/disable' : '/enable');
  return ohsApiClient
    .post(endpoint, {
      orgTier: { _id: orgId, type: 'core.tier.T3' },
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return response.data.result;
      } else {
        console.error(response);
      }
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
