import React from 'react';

import { TextField } from '@mui/material';
import validator from 'validator';

import { OhsFormFieldBaseProps } from '../OhsFormModels';
import _ from 'lodash';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

interface Props extends OhsFormFieldBaseProps {}
function InputEle(props: Props) {
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );
  return (
    <TextField
      type="email"
      error={error != null}
      fullWidth
      margin={'dense'}
      InputLabelProps={{
        shrink: true,
      }}
      size={'small'}
      variant={returnVariant(props.ohsFormSettings.layout)}
      required={props.required}
      disabled={props.disabled}
      {...props.ohsFormSettings.useFormMethods.register(props.id, {
        required: props.required,
        validate: {
          isEmail: (emailValue: any) => {
            if (props.required !== true && _.isEmpty(emailValue)) {
              return true;
            } else return validator.isEmail(emailValue);
          },
        },
      })}
      style={returnFieldStyle(props.ohsFormSettings.layout)}
    />
  );
}

export default function OhsEmailField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
