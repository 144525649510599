import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { OhsLegacyModules } from './OhsLegacyModules';
import OhsLogin from '../login/OhsLogin';
import OhsIframeModuleHome from '../global/angular-iframe/OhsIframeModuleHome';
import OhsIframeModulePublicHome from '../global/angular-iframe/OhsIframeModulePublicHome';
import { OhsMorphTier2 } from '../admin2/OhsMorphTier2';
import OhsReportingHome from '../data-analytics/ReportingHome';
import OhsAssetsRoutes from '../assets/OhsAssetsRoutes';
import OhsCorporateRiskRoutes from '../corporate-risk/OhsCorporateRiskRoutes';
import OhsVisitorRoutes from '../visitor/OhsVisitorRoutes';
import OhsActionRoutes from '../action/OhsActionRoutes';
import OhsTaskRoutes from '../task/OhsTaskRoutes';
import OhsAdmin2Routes from '../admin2/OhsAdmin2Routes';
import OhsIncidentRoutes from '../incident/OhsIncidentRoutes';
import OhsResourceCentreRoutes from '../resource-centre/OhsResourceCentreRoutes';
import OhsInspectionRoutes from '../inspection/OhsInspectionRoutes';
import OhsMobileAppAdminRoutes from '../mobile-app-admin/OhsMobileAppAdminRoutes';
import OhsSafetyPlanRoutes from '../safety-plan/OhsSafetyPlanRoutes';
import OhsNoticeBoardRoutes from '../notice-board/OhsNoticeBoardRoutes';
import OhsDashboardBannerRoutes from '../dashboard/banner/OhsDashboardBannerRoutes';
import OhsLinkAccessRoutes from '../linkaccess/OhsLinkAccessRoutes';
import OhsReviewPlanRoutes from '../review-plan/OhsIncidentRoutes';

export const OhsModuleRouter = () => {
  return (
    <Routes>
      <Route path={`/visitor/*`} element={<OhsVisitorRoutes />} />
      <Route path={`/task/*`} element={<OhsTaskRoutes />} />
      <Route path={`/admin2/*`} element={<OhsAdmin2Routes />} />
      <Route path={`/ai/*`} element={<OhsIncidentRoutes />} />
      <Route path={`/action/*`} element={<OhsActionRoutes />} />
      <Route path={`/inspection/*`} element={<OhsInspectionRoutes />} />
      <Route path={`/resourcecentre/*`} element={<OhsResourceCentreRoutes />} />
      <Route path={`/mobileappadmin/*`} element={<OhsMobileAppAdminRoutes />} />
      <Route path={`/safety-plan/*`} element={<OhsSafetyPlanRoutes />} />
      <Route path={`/noticeboard/*`} element={<OhsNoticeBoardRoutes />} />
      <Route path={`/crisk/*`} element={<OhsCorporateRiskRoutes />} />
      <Route path={`/assets/*`} element={<OhsAssetsRoutes />} />
      <Route path={`/documents/*`} element={<OhsIframeModuleHome />} />
      <Route path={`/contractor/*`} element={<OhsIframeModuleHome />} />
      <Route path={`/chemical/*`} element={<OhsIframeModuleHome />} />
      <Route path={`/correspondence/*`} element={<OhsIframeModuleHome />} />
      <Route path={`/people/*`} element={<OhsIframeModuleHome />} />
      <Route path={`/reviewplan/*`} element={<OhsReviewPlanRoutes />} />
      <Route
        path={`/dashboardbanner/*`}
        element={<OhsDashboardBannerRoutes />}
      />
      <Route path={`/signup/*`} element={<OhsIframeModulePublicHome />} />
      <Route path={`/reporting/*`} element={<OhsReportingHome />} />
      <Route path={`/logging/*`} element={<OhsIframeModuleHome />} />
      <Route path={`/feedback/*`} element={<OhsIframeModuleHome />} />
      <Route path={`/recovery/*`} element={<OhsIframeModulePublicHome />} />
      <Route path={`/reset/*`} element={<OhsIframeModulePublicHome />} />
      <Route path={`/morphTier2/*`} element={<OhsMorphTier2 />} />
      <Route path={`/linkaccess/*`} element={<OhsLinkAccessRoutes />} />
      <Route path={`/onboardings/*`} element={<OhsLinkAccessRoutes />} />
      <Route path={`/terms-of-use/*`} element={<OhsIframeModulePublicHome />} />
      <Route path={`/login`} element={<OhsLogin />} />
      <Route path={`*`} element={<OhsLegacyModules />} />
    </Routes>
  );
};
