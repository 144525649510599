import { Box, FormControl, FormGroup, FormLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import React from 'react';
import { OhsFilterList } from './FilterModels';
import OhsTaskDueDateFilter from './OhsTaskDueDateFilter';
import { OhsTaskCheckboxFilters } from './OhsTaskCheckboxFilters';

const useStyles = makeStyles()((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
  },
  formLabel: {
    color: '#000000',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  formGroup: {
    marginBottom: '20px',
  },
}));

interface Props {
  ohsFilterList: OhsFilterList;
  setOhsFilterList: React.Dispatch<React.SetStateAction<OhsFilterList>>;
}
export const OhsTaskFilter = (props: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.paper}>
      <Box m={2}>
        <b>Tasks</b>
      </Box>
      <Box sx={{ height: '320px', overflow: 'hidden' }}>
        <Box sx={{ height: '310px', paddingRight: '15px', overflow: 'auto' }}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" className={classes.formLabel}>
              Select from the following
            </FormLabel>
            {props.ohsFilterList && props.ohsFilterList.task && (
              <OhsTaskCheckboxFilters
                ohsFilterList={props.ohsFilterList}
                setOhsFilterList={props.setOhsFilterList}
              />
            )}
            <FormLabel component="legend" className={classes.formLabel}>
              Due Date
            </FormLabel>
            <FormGroup>
              {props.ohsFilterList && props.ohsFilterList.task && (
                <OhsTaskDueDateFilter
                  ohsFilterList={props.ohsFilterList}
                  setOhsFilterList={props.setOhsFilterList}
                />
              )}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </div>
  );
};
