export const ohsPhoneCountryCode: Array<{
  countryCode: string;
  code: string;
  title: string;
}> = [
  { countryCode: 'AU', code: '61', title: 'Australia (+61)' },
  { countryCode: 'NZ', code: '64', title: 'New Zealand (+64)' },
  { countryCode: 'GB', code: '44', title: 'UK (+44)' },
  { countryCode: 'US', code: '1', title: 'USA (+1)' },
  { countryCode: 'DZ', code: '213', title: 'Algeria (+213)' },
  { countryCode: 'AD', code: '376', title: 'Andorra (+376)' },
  { countryCode: 'AO', code: '244', title: 'Angola (+244)' },
  { countryCode: 'AI', code: '1264', title: 'Anguilla (+1264)' },
  { countryCode: 'AG', code: '1268', title: 'Antigua &amp; Barbuda (+1268)' },
  { countryCode: 'AR', code: '54', title: 'Argentina (+54)' },
  { countryCode: 'AM', code: '374', title: 'Armenia (+374)' },
  { countryCode: 'AW', code: '297', title: 'Aruba (+297)' },
  { countryCode: 'AT', code: '43', title: 'Austria (+43)' },
  { countryCode: 'AZ', code: '994', title: 'Azerbaijan (+994)' },
  { countryCode: 'BS', code: '1242', title: 'Bahamas (+1242)' },
  { countryCode: 'BH', code: '973', title: 'Bahrain (+973)' },
  { countryCode: 'BD', code: '880', title: 'Bangladesh (+880)' },
  { countryCode: 'BB', code: '1246', title: 'Barbados (+1246)' },
  { countryCode: 'BY', code: '375', title: 'Belarus (+375)' },
  { countryCode: 'BE', code: '32', title: 'Belgium (+32)' },
  { countryCode: 'BZ', code: '501', title: 'Belize (+501)' },
  { countryCode: 'BJ', code: '229', title: 'Benin (+229)' },
  { countryCode: 'BM', code: '1441', title: 'Bermuda (+1441)' },
  { countryCode: 'BT', code: '975', title: 'Bhutan (+975)' },
  { countryCode: 'BO', code: '591', title: 'Bolivia (+591)' },
  { countryCode: 'BA', code: '387', title: 'Bosnia Herzegovina (+387)' },
  { countryCode: 'BW', code: '267', title: 'Botswana (+267)' },
  { countryCode: 'BR', code: '55', title: 'Brazil (+55)' },
  { countryCode: 'BN', code: '673', title: 'Brunei (+673)' },
  { countryCode: 'BG', code: '359', title: 'Bulgaria (+359)' },
  { countryCode: 'BF', code: '226', title: 'Burkina Faso (+226)' },
  { countryCode: 'BI', code: '257', title: 'Burundi (+257)' },
  { countryCode: 'KH', code: '855', title: 'Cambodia (+855)' },
  { countryCode: 'CM', code: '237', title: 'Cameroon (+237)' },
  { countryCode: 'CA', code: '1', title: 'Canada (+1)' },
  { countryCode: 'CV', code: '238', title: 'Cape Verde Islands (+238)' },
  { countryCode: 'KY', code: '1345', title: 'Cayman Islands (+1345)' },
  { countryCode: 'CF', code: '236', title: 'Central African Republic (+236)' },
  { countryCode: 'CL', code: '56', title: 'Chile (+56)' },
  { countryCode: 'CN', code: '86', title: 'China (+86)' },
  { countryCode: 'CO', code: '57', title: 'Colombia (+57)' },
  { countryCode: 'KM', code: '269', title: 'Comoros (+269)' },
  { countryCode: 'CG', code: '242', title: 'Congo (+242)' },
  { countryCode: 'CK', code: '682', title: 'Cook Islands (+682)' },
  { countryCode: 'CR', code: '506', title: 'Costa Rica (+506)' },
  { countryCode: 'HR', code: '385', title: 'Croatia (+385)' },
  { countryCode: 'CU', code: '53', title: 'Cuba (+53)' },
  { countryCode: 'CY', code: '90392', title: 'Cyprus North (+90392)' },
  { countryCode: 'CY', code: '357', title: 'Cyprus South (+357)' },
  { countryCode: 'CZ', code: '42', title: 'Czech Republic (+42)' },
  { countryCode: 'DK', code: '45', title: 'Denmark (+45)' },
  { countryCode: 'DJ', code: '253', title: 'Djibouti (+253)' },
  { countryCode: 'DM', code: '1809', title: 'Dominica (+1809)' },
  { countryCode: 'DO', code: '1809', title: 'Dominican Republic (+1809)' },
  { countryCode: 'EC', code: '593', title: 'Ecuador (+593)' },
  { countryCode: 'EG', code: '20', title: 'Egypt (+20)' },
  { countryCode: 'SV', code: '503', title: 'El Salvador (+503)' },
  { countryCode: 'GQ', code: '240', title: 'Equatorial Guinea (+240)' },
  { countryCode: 'ER', code: '291', title: 'Eritrea (+291)' },
  { countryCode: 'EE', code: '372', title: 'Estonia (+372)' },
  { countryCode: 'ET', code: '251', title: 'Ethiopia (+251)' },
  { countryCode: 'FK', code: '500', title: 'Falkland Islands (+500)' },
  { countryCode: 'FO', code: '298', title: 'Faroe Islands (+298)' },
  { countryCode: 'FJ', code: '679', title: 'Fiji (+679)' },
  { countryCode: 'FI', code: '358', title: 'Finland (+358)' },
  { countryCode: 'FR', code: '33', title: 'France (+33)' },
  { countryCode: 'GF', code: '594', title: 'French Guiana (+594)' },
  { countryCode: 'PF', code: '689', title: 'French Polynesia (+689)' },
  { countryCode: 'GA', code: '241', title: 'Gabon (+241)' },
  { countryCode: 'GM', code: '220', title: 'Gambia (+220)' },
  { countryCode: 'GE', code: '7880', title: 'Georgia (+7880)' },
  { countryCode: 'DE', code: '49', title: 'Germany (+49)' },
  { countryCode: 'GH', code: '233', title: 'Ghana (+233)' },
  { countryCode: 'GI', code: '350', title: 'Gibraltar (+350)' },
  { countryCode: 'GR', code: '30', title: 'Greece (+30)' },
  { countryCode: 'GL', code: '299', title: 'Greenland (+299)' },
  { countryCode: 'GD', code: '1473', title: 'Grenada (+1473)' },
  { countryCode: 'GP', code: '590', title: 'Guadeloupe (+590)' },
  { countryCode: 'GU', code: '671', title: 'Guam (+671)' },
  { countryCode: 'GT', code: '502', title: 'Guatemala (+502)' },
  { countryCode: 'GN', code: '224', title: 'Guinea (+224)' },
  { countryCode: 'GW', code: '245', title: 'Guinea - Bissau (+245)' },
  { countryCode: 'GY', code: '592', title: 'Guyana (+592)' },
  { countryCode: 'HT', code: '509', title: 'Haiti (+509)' },
  { countryCode: 'HN', code: '504', title: 'Honduras (+504)' },
  { countryCode: 'HK', code: '852', title: 'Hong Kong (+852)' },
  { countryCode: 'HU', code: '36', title: 'Hungary (+36)' },
  { countryCode: 'IS', code: '354', title: 'Iceland (+354)' },
  { countryCode: 'IN', code: '91', title: 'India (+91)' },
  { countryCode: 'ID', code: '62', title: 'Indonesia (+62)' },
  { countryCode: 'IR', code: '98', title: 'Iran (+98)' },
  { countryCode: 'IQ', code: '964', title: 'Iraq (+964)' },
  { countryCode: 'IE', code: '353', title: 'Ireland (+353)' },
  { countryCode: 'IL', code: '972', title: 'Israel (+972)' },
  { countryCode: 'IT', code: '39', title: 'Italy (+39)' },
  { countryCode: 'JM', code: '1876', title: 'Jamaica (+1876)' },
  { countryCode: 'JP', code: '81', title: 'Japan (+81)' },
  { countryCode: 'JO', code: '962', title: 'Jordan (+962)' },
  { countryCode: 'KZ', code: '7', title: 'Kazakhstan (+7)' },
  { countryCode: 'KE', code: '254', title: 'Kenya (+254)' },
  { countryCode: 'KI', code: '686', title: 'Kiribati (+686)' },
  { countryCode: 'KP', code: '850', title: 'Korea North (+850)' },
  { countryCode: 'KR', code: '82', title: 'Korea South (+82)' },
  { countryCode: 'KW', code: '965', title: 'Kuwait (+965)' },
  { countryCode: 'KG', code: '996', title: 'Kyrgyzstan (+996)' },
  { countryCode: 'LA', code: '856', title: 'Laos (+856)' },
  { countryCode: 'LV', code: '371', title: 'Latvia (+371)' },
  { countryCode: 'LB', code: '961', title: 'Lebanon (+961)' },
  { countryCode: 'LS', code: '266', title: 'Lesotho (+266)' },
  { countryCode: 'LR', code: '231', title: 'Liberia (+231)' },
  { countryCode: 'LY', code: '218', title: 'Libya (+218)' },
  { countryCode: 'LI', code: '417', title: 'Liechtenstein (+417)' },
  { countryCode: 'LT', code: '370', title: 'Lithuania (+370)' },
  { countryCode: 'LU', code: '352', title: 'Luxembourg (+352)' },
  { countryCode: 'MO', code: '853', title: 'Macao (+853)' },
  { countryCode: 'MK', code: '389', title: 'Macedonia (+389)' },
  { countryCode: 'MG', code: '261', title: 'Madagascar (+261)' },
  { countryCode: 'MW', code: '265', title: 'Malawi (+265)' },
  { countryCode: 'MY', code: '60', title: 'Malaysia (+60)' },
  { countryCode: 'MV', code: '960', title: 'Maldives (+960)' },
  { countryCode: 'ML', code: '223', title: 'Mali (+223)' },
  { countryCode: 'MT', code: '356', title: 'Malta (+356)' },
  { countryCode: 'MH', code: '692', title: 'Marshall Islands (+692)' },
  { countryCode: 'MQ', code: '596', title: 'Martinique (+596)' },
  { countryCode: 'MR', code: '222', title: 'Mauritania (+222)' },
  { countryCode: 'YT', code: '269', title: 'Mayotte (+269)' },
  { countryCode: 'MX', code: '52', title: 'Mexico (+52)' },
  { countryCode: 'FM', code: '691', title: 'Micronesia (+691)' },
  { countryCode: 'MD', code: '373', title: 'Moldova (+373)' },
  { countryCode: 'MC', code: '377', title: 'Monaco (+377)' },
  { countryCode: 'MN', code: '976', title: 'Mongolia (+976)' },
  { countryCode: 'MS', code: '1664', title: 'Montserrat (+1664)' },
  { countryCode: 'MA', code: '212', title: 'Morocco (+212)' },
  { countryCode: 'MZ', code: '258', title: 'Mozambique (+258)' },
  { countryCode: 'MN', code: '95', title: 'Myanmar (+95)' },
  { countryCode: 'NA', code: '264', title: 'Namibia (+264)' },
  { countryCode: 'NR', code: '674', title: 'Nauru (+674)' },
  { countryCode: 'NP', code: '977', title: 'Nepal (+977)' },
  { countryCode: 'NL', code: '31', title: 'Netherlands (+31)' },
  { countryCode: 'NC', code: '687', title: 'New Caledonia (+687)' },
  { countryCode: 'NI', code: '505', title: 'Nicaragua (+505)' },
  { countryCode: 'NE', code: '227', title: 'Niger (+227)' },
  { countryCode: 'NG', code: '234', title: 'Nigeria (+234)' },
  { countryCode: 'NU', code: '683', title: 'Niue (+683)' },
  { countryCode: 'NF', code: '672', title: 'Norfolk Islands (+672)' },
  { countryCode: 'NP', code: '670', title: 'Northern Marianas (+670)' },
  { countryCode: 'NO', code: '47', title: 'Norway (+47)' },
  { countryCode: 'OM', code: '968', title: 'Oman (+968)' },
  { countryCode: 'PW', code: '680', title: 'Palau (+680)' },
  { countryCode: 'PA', code: '507', title: 'Panama (+507)' },
  { countryCode: 'PG', code: '675', title: 'Papua New Guinea (+675)' },
  { countryCode: 'PY', code: '595', title: 'Paraguay (+595)' },
  { countryCode: 'PE', code: '51', title: 'Peru (+51)' },
  { countryCode: 'PH', code: '63', title: 'Philippines (+63)' },
  { countryCode: 'PL', code: '48', title: 'Poland (+48)' },
  { countryCode: 'PT', code: '351', title: 'Portugal (+351)' },
  { countryCode: 'PR', code: '1787', title: 'Puerto Rico (+1787)' },
  { countryCode: 'QA', code: '974', title: 'Qatar (+974)' },
  { countryCode: 'RE', code: '262', title: 'Reunion (+262)' },
  { countryCode: 'RO', code: '40', title: 'Romania (+40)' },
  { countryCode: 'RU', code: '7', title: 'Russia (+7)' },
  { countryCode: 'RW', code: '250', title: 'Rwanda (+250)' },
  { countryCode: 'SM', code: '378', title: 'San Marino (+378)' },
  { countryCode: 'ST', code: '239', title: 'Sao Tome &amp; Principe (+239)' },
  { countryCode: 'SA', code: '966', title: 'Saudi Arabia (+966)' },
  { countryCode: 'SN', code: '221', title: 'Senegal (+221)' },
  { countryCode: 'CS', code: '381', title: 'Serbia (+381)' },
  { countryCode: 'SC', code: '248', title: 'Seychelles (+248)' },
  { countryCode: 'SL', code: '232', title: 'Sierra Leone (+232)' },
  { countryCode: 'SG', code: '65', title: 'Singapore (+65)' },
  { countryCode: 'SK', code: '421', title: 'Slovak Republic (+421)' },
  { countryCode: 'SI', code: '386', title: 'Slovenia (+386)' },
  { countryCode: 'SB', code: '677', title: 'Solomon Islands (+677)' },
  { countryCode: 'SO', code: '252', title: 'Somalia (+252)' },
  { countryCode: 'ZA', code: '27', title: 'South Africa (+27)' },
  { countryCode: 'ES', code: '34', title: 'Spain (+34)' },
  { countryCode: 'LK', code: '94', title: 'Sri Lanka (+94)' },
  { countryCode: 'SH', code: '290', title: 'St. Helena (+290)' },
  { countryCode: 'KN', code: '1869', title: 'St. Kitts (+1869)' },
  { countryCode: 'SC', code: '1758', title: 'St. Lucia (+1758)' },
  { countryCode: 'SD', code: '249', title: 'Sudan (+249)' },
  { countryCode: 'SR', code: '597', title: 'Suriname (+597)' },
  { countryCode: 'SZ', code: '268', title: 'Swaziland (+268)' },
  { countryCode: 'SE', code: '46', title: 'Sweden (+46)' },
  { countryCode: 'CH', code: '41', title: 'Switzerland (+41)' },
  { countryCode: 'SI', code: '963', title: 'Syria (+963)' },
  { countryCode: 'TW', code: '886', title: 'Taiwan (+886)' },
  { countryCode: 'TJ', code: '7', title: 'Tajikstan (+7)' },
  { countryCode: 'TH', code: '66', title: 'Thailand (+66)' },
  { countryCode: 'TG', code: '228', title: 'Togo (+228)' },
  { countryCode: 'TO', code: '676', title: 'Tonga (+676)' },
  { countryCode: 'TT', code: '1868', title: 'Trinidad &amp; Tobago (+1868)' },
  { countryCode: 'TN', code: '216', title: 'Tunisia (+216)' },
  { countryCode: 'TR', code: '90', title: 'Turkey (+90)' },
  { countryCode: 'TM', code: '7', title: 'Turkmenistan (+7)' },
  { countryCode: 'TM', code: '993', title: 'Turkmenistan (+993)' },
  {
    countryCode: 'TC',
    code: '1649',
    title: 'Turks &amp; Caicos Islands (+1649)',
  },
  { countryCode: 'TV', code: '688', title: 'Tuvalu (+688)' },
  { countryCode: 'UG', code: '256', title: 'Uganda (+256)' },
  { countryCode: 'UA', code: '380', title: 'Ukraine (+380)' },
  { countryCode: 'AE', code: '971', title: 'United Arab Emirates (+971)' },
  { countryCode: 'UY', code: '598', title: 'Uruguay (+598)' },
  { countryCode: 'UZ', code: '7', title: 'Uzbekistan (+7)' },
  { countryCode: 'VU', code: '678', title: 'Vanuatu (+678)' },
  { countryCode: 'VA', code: '379', title: 'Vatican City (+379)' },
  { countryCode: 'VE', code: '58', title: 'Venezuela (+58)' },
  { countryCode: 'VN', code: '84', title: 'Vietnam (+84)' },
  { countryCode: 'VG', code: '1284', title: 'Virgin Islands - British (+1284)' },
  { countryCode: 'VI', code: '1340', title: 'Virgin Islands - US (+1340)' },
  { countryCode: 'WF', code: '681', title: 'Wallis &amp; Futuna (+681)' },
  { countryCode: 'YE', code: '969', title: 'Yemen (North)(+969)' },
  { countryCode: 'YE', code: '967', title: 'Yemen (South)(+967)' },
  { countryCode: 'ZM', code: '260', title: 'Zambia (+260)' },
  { countryCode: 'ZW', code: '263', title: 'Zimbabwe (+263)' },
];
