import React from 'react';
import { makeStyles } from 'tss-react/mui';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import { OhsMultiUserLoginOption } from './OhsMultiUserLoginOption';
import scLogo from './../modules/build/img/sc-logo.png';
import { Box, Divider, Typography } from '@mui/material';

const useStyles = makeStyles()({
  root: {
    minWidth: 275,
    border: 'none',
    boxShadow: 'none',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export interface OhsMultiLoginPlaceDialogProps {
  open: boolean;
  selectedLocationUserId: string;
  onClose: (value: string) => void;
  userOptions: OhsMultiUserLoginOption[];
}

export default function OhsMultiLoginPlaceDialog(
  props: OhsMultiLoginPlaceDialogProps
) {
  const { classes } = useStyles();
  const { onClose, selectedLocationUserId, open, userOptions } = props;

  const handleClose = () => {
    onClose(selectedLocationUserId);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };
  const pathName = window.location.pathname;

  return (
    <Box width={400}>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Box px={6} pt={6}>
          <img src={scLogo} width="120px" alt="Safety Champion" />
          <Box py={4}>
            <Typography variant="h4" fontWeight={'bold'}>
              Login As
            </Typography>
            <Typography style={{ color: 'black' }}>
              Pick a user to log into Safety Champion Software
            </Typography>
          </Box>
        </Box>
        <List>
          {userOptions?.map((item, index) => (
            <div key={index}>
              <Divider />
              <ListItem
                button
                onClick={() => handleListItemClick(item.user._id)}
                key={item.user._id}
              >
                {item.workerGroupTier && (
                  <Box px={4} py={2}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {item.workerGroupTier.name} (Worker Group)
                    </Typography>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Profile: {item.user.profile.title}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {item.workplaceTier.name + ' < ' + item.orgTier.name}
                    </Typography>
                  </Box>
                )}
                {item.workerGroupTier == null && item.workplaceTier && (
                  <Box px={4} py={2}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {item.workplaceTier.name} (Workplace)
                    </Typography>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Profile: {item.user.profile.title}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {item.orgTier.name}
                    </Typography>
                  </Box>
                )}
                {item.workerGroupTier == null &&
                  item.workplaceTier == null &&
                  item.orgTier && (
                    <Box px={4} py={2}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {item.orgTier.name} (Organisation)
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Profile: {item.user.profile.title}
                      </Typography>
                    </Box>
                  )}
                {item.workerGroupTier == null &&
                  item.workplaceTier == null &&
                  item.orgTier == null &&
                  item.orgAdminTier && (
                    <Box px={4} py={2}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {item.orgAdminTier.name} (Org Admin)
                      </Typography>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Profile: {item.user.profile.title}
                      </Typography>
                    </Box>
                  )}
              </ListItem>
            </div>
          ))}
        </List>
      </Dialog>
    </Box>
  );
}
