import { ModuleType, OhsModule, OhsModuleCreateBy } from '../models/OhsObject';
import { OhsTier } from '../models/tier/OhsTier';
import { OhsAttachment } from 'global/form/fields/OhsFormFieldModels';
import { getOhsUser } from '../user/OhsUser';

export interface OhsContractorRecord extends OhsModule {
  _id: string;
  type: ModuleType.Contractor;
  tier: OhsTier;
  allocationActive: true;
  links: OhsContractorLinks[];
  businessName: string;
  category: string;
  subcategory: string;
  contacts: OhsContacts[];
  attachments: OhsAttachment[];
  businessLogo?: OhsAttachment;
  createdBy: OhsModuleCreateBy;
  tzDateCreated: string;
  dateCreated: string;
  businessNotes: string | null;
  status: string | null;
  statusOther: string | null;
  categoryOther: string | null;
  subcategoryOther: string | null;
  referenceIdType: string | null;
  referenceId: string | null;
  address: {
    street: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    postcode: string | null;
  };
  locations: any[];
}

export interface OhsContacts {
  phones: string[];
  emails: string[];
  role: string | null;
  roleOther: string | null;
  notes: string | null;
  name: string;
}

interface OhsContractorLinks {
  _id: string;
  type: ModuleType.Contractor;
  tier: OhsTier;
  name: string;
  description: string;
  frequencyKey: string;
  frequencyValue: number;
  createdBy: OhsModuleCreateBy;
  tzDateCreated: string;
  dateCreated: string;
}

export class OhsContractorLookup {
  _id: string;
  type: ModuleType.Contractor;
  moduleName: string;

  businessName: string;
  contactName: string | null;
  contactEmail: string | null;
  constructor(
    _id: string,
    businessName: string,
    contactName: string | null,
    contactEmail: string | null
  ) {
    const user = getOhsUser();
    this._id = _id;
    this.type = ModuleType.Contractor;
    this.moduleName = user?.configs.contractor?.TITLE ?? '';
    this.businessName = businessName;
    this.contactEmail = contactEmail;
    this.contactName = contactName;
  }
}
