import {
  OhsActionColorOrange,
  OhsActionColorGreen,
  OhsActionColorRed,
} from '../../../theme/OhsThemeColors';

export interface ColumnTypes {
  Header: string;
  filterValue: any[];
  setFilter: any;
  preFilteredRows: any;
  id: any;
}
export interface RowTypes {
  column: ColumnTypes;
}

export enum OhsStatusFilterOption {
  Allocated = 'allocated',
  NotAllocated = 'notAllocated',
  WorkplaceOwned = 'workplaceOwned',
}

export enum OhsAllocStatusFilter {
  Allocated = 'wpAllocated',
  NotAllocated = 'wpNotAllocated',
}

export const wpStatusLists = [
  {
    label: 'Allocated',
    value: OhsStatusFilterOption.Allocated,
    iconColor: OhsActionColorOrange,
    permission: [4, 3],
  },
  {
    label: 'Workplace Owned',
    value: OhsStatusFilterOption.WorkplaceOwned,
    iconColor: OhsActionColorGreen,
    permission: [4, 3],
  },
];

export const mainStatusLists = [
  {
    label: 'Allocated',
    value: OhsStatusFilterOption.Allocated,
    iconColor: OhsActionColorOrange,
    permission: [4, 3],
  },
  {
    label: 'Not Allocated',
    value: OhsStatusFilterOption.NotAllocated,
    iconColor: OhsActionColorRed,
    permission: [3],
  },
  {
    label: 'Workplace Owned',
    value: OhsStatusFilterOption.WorkplaceOwned,
    iconColor: OhsActionColorGreen,
    permission: [4],
  },
];
