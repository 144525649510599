import React from 'react';
import OhsAngularIframe from '../../global/angular-iframe/OhsAngularIframe';
import { OhsTaskAssignModal } from '../assign/OhsTaskAssignModal';
import { OhsTask, TaskAssignUserStatus } from '../OhsTaskModels';

export const OhsTaskRegisterHome = () => {
  const [assignModalOpen, setAssignModalOpen] = React.useState(false);
  const [assignStatusList, setAssignStatusList] = React.useState(
    new Array<TaskAssignUserStatus>()
  );
  const [assignTask, setAssignTask] = React.useState<OhsTask | null>(null);
  const openAssignModal = (
    assignStatusList: TaskAssignUserStatus[],
    assignTask: OhsTask
  ) => {
    setAssignStatusList(assignStatusList);
    setAssignTask(assignTask);
    setAssignModalOpen(true);
  };
  (window as any).openAssignModal = openAssignModal;
  return (
    <>
      {assignTask && (
        <OhsTaskAssignModal
          open={assignModalOpen}
          setModalOpen={setAssignModalOpen}
          recordId={assignTask?.for._id}
          taskId={assignTask._id}
          recordType={assignTask?.for.type}
          statusList={assignStatusList}
          onModalClose={() => {}}
        />
      )}
      <OhsAngularIframe></OhsAngularIframe>
    </>
  );
};
