import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import { getOhsUser } from '../../../user/OhsUser';

interface Props {
  title?: string;
  description?: string;
}

const OhsStaticView = (props: Props) => {
  const useStyles = makeStyles()(() => {
    const user = getOhsUser();

    return {
      header: {
        color: user?.configs.theme.HEADER_BAR_TEXT_COLOR + '!important',
      },
      segmentHeader: {
        color: user?.configs.theme.HEADER_BAR_TEXT_COLOR + '!important',
        backgroundColor: user?.configs.theme.HEADER_BAR_BG_COLOR + '!important',
        opacity: '0.75',
        marginBottom: '0px!important',
        borderRadius: '0px !important',
      },
      description: {
        marginTop: '0px!important',
        borderRadius: '0px !important',
        width: '100% !important',
      },
    };
  });
  const { classes } = useStyles();
  return (
    <>
      {props.title && (
        <Segment className={classes.segmentHeader}>
          <Header as={'h4'} className={classes.header}>
            {props.title}
          </Header>
        </Segment>
      )}
      {props.description && (
        <Segment className={`angularLayoutLabel ${classes.description}`}>
          {props.description}
        </Segment>
      )}
    </>
  );
};

export default OhsStaticView;
