import React from 'react';
import { Row } from 'react-table';
import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import OhsDeleteModal from '../../global/modal/OhsDeleteModal';
import OhsModuleHeader, {
  registerButtonStyles,
} from '../../global/register/OhsModuleHeader';
import OhsRegister from '../../global/register/OhsRegister';
import { getOhsUser } from '../../user/OhsUser';
import {
  NoticeboardForm,
  OhsNoticeBoardRecordConfig,
} from '../OhsNoticeBoardModels';
import {
  editNoticeBoardRecordConfig,
  getNoticeboardFormList,
} from '../OhsNoticeBoardServices';
import { OhsNoticeBoardFormSelectModal } from './OhsNoticeBoardFormSelectModal';

interface Props {
  noticeboardForms: NoticeboardForm[];
  noticeBoardConfig: OhsNoticeBoardRecordConfig | null;
}

export const OhsNoticeBoardFormList = (props: Props) => {
  const [noticeboardForms, setNoticeboardForms] = React.useState(
    new Array<NoticeboardForm>()
  );
  const [fullNoticeboardForms, setFullNoticeboardForms] = React.useState(
    new Array<NoticeboardForm>()
  );

  const [operateIndex, setOperateIndex] = React.useState(-1);
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [selectFormModalOpen, setSelectFormModalOpen] = React.useState(false);
  const [rows, setRows] = React.useState(new Array<Row<any>>());

  React.useEffect(() => {
    if (props.noticeboardForms.length !== noticeboardForms.length)
      setNoticeboardForms(props.noticeboardForms);
  }, [noticeboardForms.length, props.noticeboardForms, rows, setRows]);
  const columns = React.useMemo(
    () => [
      {
        id: 'titleId',
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
    ],
    []
  );

  const openDeleteModalFunc = (item: any, index?: number) => {
    if (index != null) {
      setOperateIndex(index);

      setDelModalOpen(true);
    }
  };
  const { classes } = registerButtonStyles();

  const saveFormList = async () => {
    const newFormList = new Array<NoticeboardForm>();
    rows.forEach((item: any) => {
      newFormList.push(item.original);
    });

    if (props.noticeBoardConfig) {
      const config = { ...props.noticeBoardConfig };
      config.forms = newFormList;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) window.location.reload();
    }
  };

  const handleDeleteFunc = async () => {
    const list = [...noticeboardForms];
    list.splice(operateIndex, 1);
    setNoticeboardForms(list);
    setDelModalOpen(false);
    if (props.noticeBoardConfig) {
      const config = { ...props.noticeBoardConfig };
      config.forms = list;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) window.location.reload();
    }
  };
  const user = getOhsUser();
  return (
    <>
      <OhsModuleHeader title="Form Register">
        <ButtonGroup floated="right">
          <Button
            color="green"
            className={classes.button}
            onClick={async () => {
              const fullList = await getNoticeboardFormList();
              setFullNoticeboardForms(fullList);
              setSelectFormModalOpen(true);
            }}
          >
            <Icon name="plus" className={classes.buttonIcons} />
            <span className={classes.hideForSmall}>Form</span>
          </Button>
          <Button
            className={classes.button}
            style={{ height: '36px' }}
            color="blue"
            onClick={saveFormList}
          >
            <Icon className={classes.buttonIcons} name="save" />
            <span className={classes.hideForSmall}>Save</span>
          </Button>
        </ButtonGroup>
      </OhsModuleHeader>
      <OhsRegister
        columns={columns}
        data={noticeboardForms}
        setRows={setRows}
        rowOperations={{
          openDeleteModalButton: {
            func: openDeleteModalFunc,
            permission: user?.configs.noticeboard?.PERMISSIONS.edit === true,
          },
        }}
        noPagination={true}
        enableDragDrop={true}
      ></OhsRegister>
      <OhsNoticeBoardFormSelectModal
        open={selectFormModalOpen}
        setModalOpen={setSelectFormModalOpen}
        preFormList={noticeboardForms}
        fullFormList={fullNoticeboardForms}
        noticeboardConfig={props.noticeBoardConfig}
      />
      <OhsDeleteModal
        open={delModalOpen}
        setModalOpenFunc={setDelModalOpen}
        moduleTitle={'Resource'}
        handleDeleteFunc={handleDeleteFunc}
        recordName={props.noticeboardForms[operateIndex]?.title ?? ''}
      />
    </>
  );
};
