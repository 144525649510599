import { OhsRecordFieldType } from '../../global/record-filed/OhsRecordFieldType';

export const OhsFrequencyTypeRecordField = new OhsRecordFieldType(
  'frequencyType',
  'Type'
);

export const OhsInProgressRecordField = new OhsRecordFieldType(
  'inProgress',
  'In-Progress'
);

export const OhsTitleRecordField = new OhsRecordFieldType('title', 'Name');

export const OhsCategoryRecordField = new OhsRecordFieldType(
  'category',
  'Category'
);

export const OhsSubcategoryRecordField = new OhsRecordFieldType(
  'subcategory',
  'Subcategory'
);
