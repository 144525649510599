import { OhsCusval } from 'global/form/cusvals/OhsCusvalModel';
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { OhsFormMessage } from '../../../visitor/common/OhsFormMessage';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { OhsEmailList } from './OhsEmailList';
import { OhsNoticeboardContact } from '../../OhsNoticeBoardModels';
import { Button, Label } from 'semantic-ui-react';
import { formSubmission } from '../../OhsNoticeBoardServices';
import {
  assignValuesToCusvalList,
  getDisplayValue,
  parseCusvalList,
} from '../../../common/OhsCusvalsUtils';
import { getCurrentTimezone } from '../../../common/OhsDataParse';
import { getOhsUser } from '../../../user/OhsUser';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import OhsModalFormItem from 'global/form/OhsModalFormItem';
import OhsCusvalFieldFactory from 'global/form/cusvals/OhsCusvalFieldFactory';

const getFormInitialObject = (cusvalList: Array<OhsCusval>) => {
  let initObject: any = {};
  cusvalList.forEach((item) => {
    if (item.type === 'tel' && item.value) {
      initObject[item._id] = JSON.parse(item.value!).number;
    } else if (item.type === 'currency' && item.value) {
      initObject[item._id] = JSON.parse(item.value!).amount;
    } else if (item.type === 'checkbox') {
      initObject[item._id] = [];
    } else if (item.type === 'color') {
      initObject[item._id] = item.value || '#000000';
    } else if (item.type === 'select_multiple') {
      initObject[item._id] = item.value || [];
    } else if (item.type === 'select_single') {
      initObject[item._id] = item.value || '';
    } else {
      initObject[item._id] = item.value;
    }
  });
  return initObject;
};
export default function OhsNoticeboardForm(props: any) {
  const { contacts } = props;
  const { messages } = props.selectedForm;
  const user = getOhsUser();
  const [defaultValue, setDefaultValue] = React.useState<Array<OhsCusval>>(
    props.selectedForm.cusvals
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [emailList, setEmailList] = React.useState(new Array<string>());
  const [commonErr, setCommonErr] = React.useState('');
  const [steps, setSeps] = React.useState(['Intro', 'Form', 'Send']);
  const [isSendCopy, setIsSendCopy] = React.useState(true);
  const [isLoadContactEmails, setIsLoadContactEmails] = React.useState(false);
  const ohsFormSettings = new OhsFormSettings(
    getFormInitialObject(props.selectedForm.cusvals),
    OhsFormLayoutType.AngularModalUnderlinedForm
  );
  let hasContactsEmails = false;
  if (contacts) {
    contacts.forEach((contact: OhsNoticeboardContact) =>
      contact.emails.forEach((email) => {
        if (email) {
          hasContactsEmails = true;
        }
      })
    );
  }
  React.useEffect(() => {
    if (messages && messages.pre) {
      setActiveStep(0);
    }
    if (messages == null || messages.pre == null) {
      setSeps(['Form', 'Send']);
    }
  }, [messages]);

  const handleUpdateEmailList = (emailList: string[]) => {
    setEmailList(emailList);
    if (emailList.length > 0) {
      setCommonErr('');
    }
  };
  const { classes } = useStyles();
  const handleSubmitBtn = async (data: any) => {
    if (emailList.length < 1) {
      setCommonErr('Email is required!');
      return;
    }
    const newEmailList = [...emailList];
    if (
      isSendCopy === true &&
      user?.email &&
      newEmailList.includes(user.email) === false
    ) {
      newEmailList.push(user.email);
    }
    const { selectedRecord, selectedForm } = props;

    const payload = {
      tz: getCurrentTimezone(),
      board: {
        type: selectedRecord.type,
        _id: selectedRecord._id,
      },
      form: {
        type: selectedForm.type,
        _id: selectedForm._id,
      },
      cusvals: parseCusvalList(defaultValue),
      submitTo: newEmailList,
    };
    const result = await formSubmission(payload);
    if (result === true) {
      toast.success('Form Submitted.');
      props.onClose();
    }
  };
  const handleBackBtn = () => {
    setCommonErr('');
    if (activeStep === steps.length - 1) {
      const formValue = ohsFormSettings.useFormMethods.getValues();
      const newFormValues = Object.keys(formValue).reduce((acc: any, key) => {
        acc[key] = { [key]: formValue[key] };
        return acc;
      }, {});
      ohsFormSettings.useFormMethods.reset(newFormValues);

      setDefaultValue(
        assignValuesToCusvalList(
          defaultValue,
          ohsFormSettings.useFormMethods.getValues
        )
      );
    }
    ohsFormSettings.useFormMethods.reset(getFormInitialObject(defaultValue));
    setActiveStep(activeStep - 1);
  };
  const handleNextBtn = () => {
    if (activeStep === steps.length - 2) {
      const formValue = ohsFormSettings.useFormMethods.getValues();

      // cusval field returns nested value which is complicated, ex:  { id: { id: 1 } }
      // this converts { id: { id: 1 } } to { id: 1 }
      const newFormValues = Object.keys(formValue).reduce((acc: any, key) => {
        acc[key] = formValue[key][key];
        return acc;
      }, {});
      ohsFormSettings.useFormMethods.reset(newFormValues);

      const values = assignValuesToCusvalList(
        defaultValue,
        ohsFormSettings.useFormMethods.getValues
      );

      setDefaultValue(values);
    }
    setActiveStep(activeStep + 1);
  };

  const handleFillContactEmail = () => {
    if (contacts) {
      const newEmailList = [...emailList];
      contacts.forEach((contact: OhsNoticeboardContact) =>
        contact.emails.forEach((email) => {
          if (email) {
            if (
              isLoadContactEmails === false &&
              newEmailList.includes(email.toLocaleLowerCase()) === false
            ) {
              newEmailList.push(email.toLocaleLowerCase());
            } else if (
              isLoadContactEmails === true &&
              newEmailList.includes(email.toLocaleLowerCase()) === true
            ) {
              const index = newEmailList.indexOf(email.toLocaleLowerCase());
              newEmailList.splice(index, 1);
            }
          }
        })
      );
      if (newEmailList.length !== emailList.length) {
        setEmailList(newEmailList);
      }
    }
    setIsLoadContactEmails(!isLoadContactEmails);
  };

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <div className={classes.header}>
          <h3 className={classes.text}>{props.title}</h3>
        </div>
        <Stepper className={classes.stepper} activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && steps.length === 3 && (
          <div className={classes.mess}>
            {messages.pre && <OhsFormMessage message={messages.pre} />}
          </div>
        )}
        {activeStep === steps.length - 2 && (
          <Grid container spacing={3}>
            {messages.in && (
              <Grid item xs={12}>
                <OhsFormMessage message={messages.in} />
              </Grid>
            )}
            {defaultValue.map((item: OhsCusval) => (
              <OhsModalFormItem key={item._id} sm={12}>
                <OhsCusvalFieldFactory
                  sectionId={item._id}
                  cusval={item}
                  ohsFormSettings={ohsFormSettings}
                />
              </OhsModalFormItem>
            ))}
          </Grid>
        )}
        {activeStep === steps.length - 1 && (
          <>
            {messages.post && (
              <div className={classes.mess}>
                <OhsFormMessage message={messages.post} />
              </div>
            )}
            <List disablePadding>
              {defaultValue != null &&
                defaultValue.map((product: OhsCusval) => (
                  <ListItem className={classes.listItem} key={product._id}>
                    <ListItemText
                      primary={
                        <>
                          <div>{product.title}</div>
                          <div>
                            <Typography component={'span'} variant="body2">
                              {product.description}
                            </Typography>
                          </div>
                        </>
                      }
                      secondary={getDisplayValue(product)}
                    />
                  </ListItem>
                ))}
            </List>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSendCopy}
                    onChange={() => {
                      setIsSendCopy(!isSendCopy);
                    }}
                    color={'default'}
                  />
                }
                label={
                  <>
                    Send me a copy to{' '}
                    <a href={'mailto: ' + user?.email}>{user?.email}</a>
                  </>
                }
              />
            </FormGroup>
            {hasContactsEmails && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isLoadContactEmails}
                      onChange={handleFillContactEmail}
                      color={'default'}
                    />
                  }
                  label={'Fill contact emails.'}
                />
              </FormGroup>
            )}
            <OhsEmailList
              emailList={emailList}
              updateEmailList={handleUpdateEmailList}
            />
          </>
        )}
        {commonErr && <Label color={'red'}>{commonErr}</Label>}
        <div className={classes.buttons}>
          {activeStep !== 0 && (
            <Button className={classes.button} onClick={handleBackBtn}>
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              variant="contained"
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleSubmitBtn
              )}
              className={classes.button}
              color="blue"
            >
              Submit
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              onClick={ohsFormSettings.useFormMethods.handleSubmit(
                handleNextBtn
              )}
              className={classes.button}
              disabled={!!commonErr}
              color="blue"
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e9ebee',
    overflowY: 'auto',
    minHeight: 600,
    padding: 20,
    marginInline: '-24px',
    marginBlock: '-7px',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '40px',
    width: '100%',
  },
  body: {
    minWidth: 500,
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 24,
    borderRadius: 4,
    marginBottom: theme.spacing(5),
  },
  stepper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  text: {
    color: '#3b5998',
    fontSize: 20,
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  mess: {
    marginBottom: 20,
  },
}));
