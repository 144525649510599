import _ from 'lodash';
import React from 'react';
import YouTube from 'react-youtube';
import { Options } from 'react-youtube';
import { youtube_parser } from '../../common/OhsDataParse';
import { VisitorMessage } from './VisitorModels';
import parse from 'html-react-parser';

import Quill from 'quill';
import './OhsFormMessage.css';
function quillGetHTML(inputDelta: any) {
  var tempQuill = new Quill(document.createElement('div'));
  tempQuill.setContents(inputDelta);
  return tempQuill.root.innerHTML;
}

export const OhsFormMessage = (props: {
  message: VisitorMessage | null | undefined;
}) => {
  let roleDisc = null;
  if (props.message && props.message.type) {
    const videoId = youtube_parser(props.message.value);
    if (props.message.type === 'video/x-youtube' && videoId) {
      roleDisc = (
        <iframe
          width="100%"
          height="500px"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }
    if (props.message.type === 'text/html' && props.message.value) {
      if (props.message.value.ops) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: quillGetHTML(props.message.value.ops),
            }}
          />
        );
      }
      roleDisc = parse(props.message.value);
    }
  }
  return <div>{roleDisc}</div>;
};
OhsFormMessage.propTypes = {};
