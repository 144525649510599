import { Box, Theme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { getOhsUser } from '../../user/OhsUser';
const user = getOhsUser();

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: user?.configs.theme.MAIN_BG_COLOR,
    minHeight: 'calc(100vh - 80px)',
  },
}));

type Props = {
  children: React.ReactNode;
};
export default function OhsModuleBackground(props: Props) {
  const { classes } = useStyles();
  return (
    <Box px={3} pt={2} pb={2} className={classes.root}>
      {props.children}
    </Box>
  );
}
