import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()(() => ({
  pointMessageBefore: {
    marginTop: '-1px',
    borderWidth: '1px 0 0 1px',
    transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
    borderColor: 'inherit',
    top: 0,
    left: '50%',
    position: 'absolute',
    zIndex: 2,
    width: '0.6666em',
    height: '0.6666em',
    backgroundColor: 'inherit',
    borderStyle: 'solid',
  },
  pointMessage: {
    whiteSpace: 'normal',
    border: '1px solid #e0b4b4 !important;',
    color: '#9f3a38 !important',
    display: 'inline-block !important;',
    position: 'relative',
    fontSize: '0.85714286rem;',
    lineHeight: 1,
    verticalAlign: 'baselne',
    margin: '10px 0 0 0',
    fontWeight: 'bold',
    padding: '0.5833em 0.833em',
    borderRadius: '0.28571429rem',
    backgroundColor: 'white',
  },
}));
export const OhsFormFieldWarningMessage = (props: {
  children: React.ReactNode;
}) => {
  const { classes } = useStyles();
  return (
    <>
      <div>
        <div className={classes.pointMessage}>
          <div className={classes.pointMessageBefore}></div>
          {props.children}
        </div>
      </div>
    </>
  );
};
