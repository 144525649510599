import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { toggleBackdrop } from './OhsBackdropSlice';

const useStyles = makeStyles()((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
}));

function OhsBackdrop(props: any) {
  (window as any).toggleBackdrop = props.toggleBackdrop;
  const { classes } = useStyles();
  const handleClose = () => {
    props.toggleBackdrop(false);
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={props.isOpen}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return { isOpen: state.backdropReducer.backdropOpen };
};

export default connect(mapStateToProps, { toggleBackdrop })(OhsBackdrop);
