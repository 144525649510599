import { getConfigValue } from './OhsConfigServices';

import { setPermissions } from './OhsConfigurations';
export class OhsAdmin2Permissions {
  view: boolean;
  morph: boolean;
  morph_up: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.morph = flag;
    this.morph_up = flag;
  }
}
export interface OhsLowerTier {
  _id: string;
  access: boolean;
  name: string;
  disabled: boolean | undefined;
}
export enum AdminSwitchOptionMap {
  disabled = '0',
  optional = '1',
  required = '2',
}

export const getMfaStatusStringVal = (getMfaConfigValue: string) => {
  let MfaConfigValue: AdminSwitchOption;

  switch (getMfaConfigValue) {
    case AdminSwitchOptionMap.disabled:
      MfaConfigValue = 'disabled';
      break;
    case AdminSwitchOptionMap.optional:
      MfaConfigValue = 'optional';
      break;
    case AdminSwitchOptionMap.required:
      MfaConfigValue = 'required';
      break;
    default:
      MfaConfigValue = 'disabled';
  }
  return MfaConfigValue;
};
export class OhsAdmin2Config {
  TITLE: string;
  PERMISSIONS: OhsAdmin2Permissions;
  LOWER_TIER_ACCESS: Array<OhsLowerTier> | null;
  MFA: AdminSwitchOption;
  constructor(config: any) {
    this.PERMISSIONS = new OhsAdmin2Permissions(false);
    this.LOWER_TIER_ACCESS = null;
    this.TITLE = getConfigValue(config, 'TITLE');
    this.LOWER_TIER_ACCESS = getConfigValue(config, 'LOWER_TIER_ACCESS');
    if (this.LOWER_TIER_ACCESS && this.LOWER_TIER_ACCESS.length > 0) {
      this.LOWER_TIER_ACCESS.sort((a: OhsLowerTier, b: OhsLowerTier) => {
        return a.name.localeCompare(b.name);
      });
    }

    const getMfaConfigValue: string = getConfigValue(config, 'MFA') as string;
    let MfaConfigValue: AdminSwitchOption =
      getMfaStatusStringVal(getMfaConfigValue);

    this.MFA = MfaConfigValue;

    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export type AdminSwitchOption = 'optional' | 'disabled' | 'required';
