import _ from 'lodash';
import React from 'react';
import { Row } from 'react-table';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import OhsModal from '../../global/modal/OhsModal';
import { OhsModalContentHeader } from '../../global/modal/OhsModalContentHeader';
import OhsRegister from '../../global/register/OhsRegister';
import { editNoticeBoardRecordConfig } from '../OhsNoticeBoardServices';
import {
  NoticeboardForm,
  OhsNoticeBoardRecordConfig,
} from '../OhsNoticeBoardModels';

interface Props {
  open: boolean;
  preFormList: NoticeboardForm[];
  fullFormList: NoticeboardForm[];
  noticeboardConfig: OhsNoticeBoardRecordConfig | null;

  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function OhsNoticeBoardFormSelectModal(props: Props) {
  const columns = React.useMemo(
    () => [
      {
        id: 'titleId',
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
    ],
    []
  );

  const [selectedRows, setSelectedRows] = React.useState(new Array<Row<any>>());
  React.useEffect(() => {});

  const saveConfig = async () => {
    if (selectedRows == null || selectedRows.length < 1) {
      toast.error('Please at least select one record.');
    } else {
      const newFormList = [...props.preFormList];
      selectedRows.forEach((row) => {
        const selectedItem = row.original as NoticeboardForm;

        const index = _.findIndex(newFormList, { _id: selectedItem._id });
        if (index < 0) {
          newFormList.push(selectedItem);
        }
      });
      if (
        props.preFormList.length !== newFormList.length &&
        props.noticeboardConfig
      ) {
        const config = { ...props.noticeboardConfig };
        config.forms = newFormList;
        const editNoticeBoardRecordConfigReq =
          await editNoticeBoardRecordConfig(config);
        if (editNoticeBoardRecordConfigReq) {
          props.setModalOpen(false);
          window.location.reload();
        }
      } else {
        props.setModalOpen(false);
      }
    }
  };

  return (
    <>
      <OhsModal
        open={props.open}
        title="Add Forms"
        setModalOpenFunc={props.setModalOpen}
        maxWidth={'md'}
        ContentNode={
          <>
            <OhsModalContentHeader title={'Form Register'} />
            <OhsRegister
              columns={columns}
              data={props.fullFormList}
              sortBy={[{ id: 'titleId', desc: false }]}
              rowOperations={{}}
              setSelectedRows={setSelectedRows}
              noPagination={true}
            />
          </>
        }
        actionNode={
          <Button onClick={saveConfig} color={'green'}>
            Submit
          </Button>
        }
      ></OhsModal>
    </>
  );
}
