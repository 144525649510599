import React, { useEffect } from 'react';
import { Button, ModalDescription } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import * as _ from 'lodash';
import { OhsExcelConfig } from './OhsExcelExportModels';
import { genExelFile } from './OhsExcelExportServices';
import { getOhsUser } from '../../user/OhsUser';
import OhsModal from '../modal/OhsModal';
import { OhsFormLayoutType, OhsFormSettings } from '../form/OhsFormModels';
import OhsCheckboxField from '../form/fields/OhsCheckboxField';
import OhsExcelExportModalFilter from './OhsExcelExportModalFilter';

interface Props {
  exportRecordList: Array<any>;
  configList: Array<OhsExcelConfig>;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: (configList?: Array<OhsExcelConfig>) => void;
}

const useStyles = makeStyles()((theme) => ({
  modalFooter: {
    width: '100%',
    display: 'flex',
  },
  marginLeft: {
    marginLeft: 'auto !important',
  },
}));

export default function OhsExcelExportModal(props: Props) {
  const { classes } = useStyles();
  const user = getOhsUser();
  const CheckboxValeKey = 'checkboxValues';
  const [configList, setConfigList] = React.useState<Array<OhsExcelConfig>>(
    props.configList
  );

  const [stepNum, SetStepNum] = React.useState<0 | 1>(0);

  const clearDefaultValues = (confList: OhsExcelConfig[]) => {
    const defaultValues = {
      [CheckboxValeKey]: confList
        .filter((item) => item.selected === true)
        .map((item) => item.fn),
    } as any;
    confList.forEach((item) => {
      if (item.filter && item.filter.type === 'multiple') {
        defaultValues[item.fn + '-' + item.filter.type] = [];
      } else if (
        item.filter &&
        item.filter.type === 'dateRange' &&
        item.filter.value &&
        item.filter.value.length === 2
      ) {
        if (item.filter.value[0])
          defaultValues[item.fn + '-' + item.filter.type + '-from'] = '';
        if (item.filter.value[1])
          defaultValues[item.fn + '-' + item.filter.type + '-to'] = '';
      } else if (item.filter && item.filter.type === 'amount') {
        defaultValues[item.fn + '-' + item.filter.type + '-currency'] = '';
        defaultValues[item.fn + '-' + item.filter.type + '-min'] = '';
        defaultValues[item.fn + '-' + item.filter.type + '-max'] = '';
      }
    });
    return defaultValues;
  };

  const getDefaultValues = (confList: OhsExcelConfig[]) => {
    const CheckboxValeKeyValue = confList.map((item) => item.fn);
    const defaultValues = {
      [CheckboxValeKey]: CheckboxValeKeyValue,
    } as any;
    confList.forEach((item) => {
      if (item.filter && item.filter.type === 'multiple') {
        defaultValues[item.fn + '-' + item.filter.type] =
          item.filter.value ?? [];
      } else if (
        item.filter &&
        item.filter.type === 'dateRange' &&
        item.filter.value &&
        item.filter.value.length === 2
      ) {
        if (item.filter.value[0])
          defaultValues[item.fn + '-' + item.filter.type + '-from'] =
            item.filter.value[0];
        if (item.filter.value[1])
          defaultValues[item.fn + '-' + item.filter.type + '-to'] =
            item.filter.value[1];
      }
    });
    return defaultValues;
  };

  const ohsFormSettings = new OhsFormSettings(
    getDefaultValues(props.configList),
    OhsFormLayoutType.AngularModalForm
  );

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleReset = () => {
    ohsFormSettings.useFormMethods.reset({
      ...clearDefaultValues(props.configList),
    });
  };
  const handleExportAll = (data: any) => {
    SetStepNum(0);
    if (data == null || data[CheckboxValeKey] == null) return;

    configList.forEach((item) => {
      item.selected = data[CheckboxValeKey].includes(item.fn);
    });
    setConfigList([...configList]);
    genExelFile(
      true,
      user?.getCurrentURLModuleConfig()?.TITLE ?? '',
      configList,
      props.exportRecordList
    );
    props.setOpen(false);
    if (props.onClose) props.onClose(configList);
  };
  const handleExport = (data: any) => {
    configList.forEach((config) => {
      if (config.filter) {
        const configType = config.filter.type;
        const configFn = config.fn;
        if (configType === 'multiple') {
          config.filter.value = data[configFn + '-' + configType] ?? [];
        } else if (configType === 'dateRange') {
          config.filter.value = [
            data[configFn + '-' + configType + '-from'],
            data[configFn + '-' + configType + '-to'],
          ];
        } else if (configType === 'amount') {
          config.filter.value = [
            data[configFn + '-' + configType + '-currency'],
            data[configFn + '-' + configType + '-min'],
            data[configFn + '-' + configType + '-max'],
          ];
        }
      }
    });

    setConfigList([...configList]);
    genExelFile(
      false,
      user?.getCurrentURLModuleConfig()?.TITLE ?? '',
      configList,
      props.exportRecordList
    );
    props.setOpen(false);
    if (props.onClose) props.onClose(configList);
  };

  useEffect(() => {
    setConfigList(props.configList);
    ohsFormSettings.useFormMethods.reset({
      ...getDefaultValues(props.configList),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configList, stepNum]);

  return (
    <>
      <OhsModal
        open={props.open}
        title={stepNum === 0 ? 'Export Wizard' : 'Filter Wizard'}
        onClose={handleClose}
        maxWidth={'sm'}
        setModalOpenFunc={() => {}}
        ContentNode={
          <>
            <ModalDescription>
              {stepNum === 0 ? (
                <>
                  <div>Please select data fields you want to export:</div>
                  <OhsCheckboxField
                    id={CheckboxValeKey}
                    description=""
                    required={false}
                    ohsFormSettings={ohsFormSettings}
                    options={configList.map((item: any, index: number) => {
                      return {
                        name: item.fd,
                        value: item.fn,
                      };
                    })}
                  />
                </>
              ) : (
                <OhsExcelExportModalFilter
                  recordList={props.exportRecordList}
                  configList={props.configList}
                  ohsFormSettings={ohsFormSettings}
                />
              )}
            </ModalDescription>
          </>
        }
        actionNode={
          <>
            {stepNum === 0 ? (
              <>
                <Button onClick={() => SetStepNum(1)} color={'orange'}>
                  Filter
                </Button>
                <Button
                  onClick={ohsFormSettings.useFormMethods.handleSubmit(
                    handleExportAll
                  )}
                  color={'green'}
                >
                  Export All
                </Button>
              </>
            ) : (
              <div className={classes.modalFooter}>
                <Button onClick={handleReset}>Reset</Button>
                <Button
                  onClick={() => SetStepNum(0)}
                  className={classes.marginLeft}
                  color={'orange'}
                >
                  Back
                </Button>
                <Button
                  onClick={ohsFormSettings.useFormMethods.handleSubmit(
                    handleExport
                  )}
                  color={'green'}
                >
                  Export
                </Button>
              </div>
            )}
          </>
        }
      />
    </>
  );
}
