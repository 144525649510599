import React, { useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import OhsSelectField from 'global/form/fields/OhsSelectField';
import OhsCheckboxField from 'global/form/fields/OhsCheckboxField';
import OhsDateField from 'global/form/fields/OhsDateField';
import OhsModalForm from '../../../../global/form/OhsModalForm';
import OhsModalFormItem from '../../../../global/form/OhsModalFormItem';
import { FormContainer } from '../../OhsDashboardBannerStyledComponents';
import { tierTypeOptions } from '../../OhsDashboardBannerConstants';
import { OhsFormSettings } from 'global/form/OhsFormModels';

interface Props {
  ohsFormSettings: OhsFormSettings;
}

const TierTypeDisplayText = memo<{ tierTypeValue: string }>(
  ({ tierTypeValue }) => {
    const mappedTierTypeOption = _.find(tierTypeOptions, {
      value: tierTypeValue,
    });

    return (
      <div>
        <span style={{ color: 'grey' }}>Banner for</span>
        <h4 style={{ margin: 0 }}>{_.get(mappedTierTypeOption, 'name', '')}</h4>
      </div>
    );
  }
);

export default function FilterForm(props: Props) {
  const { bannerId } = useParams<{ bannerId: string }>();
  const isAddForm = useMemo(() => _.isEmpty(bannerId), [bannerId]);

  const [tierTypeValue] = props.ohsFormSettings.useFormMethods.watch([
    'tierType',
    'platform',
  ]);

  return (
    <FormContainer>
      <OhsModalForm>
        <OhsModalFormItem sm={6}>
          {isAddForm ? (
            <OhsSelectField
              id="tierType"
              title="Tier Type"
              required
              options={tierTypeOptions}
              ohsFormSettings={props.ohsFormSettings}
            />
          ) : (
            <TierTypeDisplayText tierTypeValue={tierTypeValue} />
          )}
        </OhsModalFormItem>
        <OhsModalFormItem sm={6}>
          <OhsCheckboxField
            required
            id="platform"
            options={[
              { name: 'Web', value: 'enableOnWeb' },
              { name: 'Android', value: 'enableOnAndroid' },
              { name: 'IOS', value: 'enableOnIOS' },
            ]}
            ohsFormSettings={props.ohsFormSettings}
            rowDirection={true}
          />
        </OhsModalFormItem>
      </OhsModalForm>
      <OhsModalForm>
        <OhsModalFormItem sm={6}>
          <OhsDateField
            title="Start Date"
            id="startDate"
            required={true}
            ohsFormSettings={props.ohsFormSettings}
          />
        </OhsModalFormItem>
        <OhsModalFormItem sm={6}>
          <OhsDateField
            title="End Date"
            id="endDate"
            required={true}
            ohsFormSettings={props.ohsFormSettings}
          />
        </OhsModalFormItem>
      </OhsModalForm>
    </FormContainer>
  );
}
