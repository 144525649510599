import React from 'react';
import { createStyles, withStyles } from '@mui/styles';
import {
  Avatar,
  CircularProgress,
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { removeNonNumeric } from '../../../global/utils/OhsUtility';
import scLogo from '../../../modules/build/img/sc-logo.png';
import ehLogo from '../../../images/eh-logo.png';
import { completeXSISetup } from '../common/OhsXSIServices';
import OhsCopyright from '../../../global/OhsCopyright';
import { OhsUser } from '../../../user/OhsUser';
import { OhsWithRouterTypes } from '../../../global/types/OhsGlobalTypes';
import OhsWithRouter from '../../../global/utils/OhsWithRouter';
// import { OhsVisitorConfig } from '../../user/configurations/OhsVisitorConfig';
type PathParamsType = {
  token: string;
};
type PropsType = { classes: any; router: OhsWithRouterTypes<PathParamsType> };
const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(10),

      [theme.breakpoints.up(600 + removeNonNumeric(theme.spacing(2)) * 2)]: {
        width: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    Media: {
      height: '100%',
      width: '100%',
    },
    logo: {
      objectFit: 'contain',
      height: 300,
      width: '100%',
      padding: theme.spacing(2),
    },
    grid: {
      marginTop: theme.spacing(2),
    },
    footer: {
      marginTop: theme.spacing(10),
    },
  });

class OhsHrIntegration extends React.Component<PropsType> {
  state = {
    ehData: undefined,
    ehError: false,
    scData: undefined,
    scError: false,
    ehErrorMessages: [],
    step: 1,
  };
  componentDidMount() {
    const params = new URLSearchParams(this.props.router.location.search);
    const code = params.get('code');
    const token = params.get('state');

    const init = () => {
      this.setState({
        ehData: {},
        step: 2,
      });

      completeXSISetup(token || '', code || '').then((data: any) => {
        if (data && data.success === true) {
          this.setState({
            scData: {},
            step: 3,
          });
        } else {
          this.setState({
            scError: true,
            ehErrorMessages: data.error.message,
          });
          toast.error('Unable to complete setup.');
        }
      });
    };

    let n: ReturnType<typeof setTimeout>;
    n = setTimeout(init, 3000);

    window.addEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e: Event) {}

  render() {
    const { classes } = this.props as any;
    // const tierId = this.props.match.params.tierId;

    const user = new OhsUser();

    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <img
                  className={classes.logo}
                  src={scLogo}
                  alt="Safety Champion"
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <img
                  className={classes.logo}
                  src={ehLogo}
                  alt="Safety Champion"
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
              <Paper className={classes.paper}>
                <List>
                  {this.state.ehError === true ? (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CloseIcon color="disabled" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="1. Employment Hero"
                        secondary="Unable to exchange tokens."
                      />
                    </ListItem>
                  ) : (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {this.state.ehData ? (
                            <DoneIcon color="primary" />
                          ) : this.state.step === 1 ? (
                            <CircularProgress />
                          ) : (
                            ' '
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="1. Employment Hero"
                        secondary="Exchanging code tokens."
                      />
                    </ListItem>
                  )}
                </List>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <List>
                  {this.state.scError === true ? (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CloseIcon color="disabled" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="2. Safety Champion"
                        secondary="Session authentication failed."
                      />
                    </ListItem>
                  ) : (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {this.state.scData ? (
                            <DoneIcon color="primary" />
                          ) : this.state.step === 2 ? (
                            <CircularProgress />
                          ) : (
                            ' '
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="2. Safety Champion"
                        secondary="Authenticating state session."
                      />
                    </ListItem>
                  )}
                </List>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        {this.state.step === 3 ? (
                          <DoneIcon color="primary" />
                        ) : (
                          ' '
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="3. Done"
                      secondary="Setup Complete!"
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>
          {this.state.step === 3 ? (
            <Grid item xs={12} className={classes.grid}>
              <Typography align="center">
                {'Safety Champion | Employment Hero Integration'}
              </Typography>
              <Typography
                variant="body2"
                align="center"
                style={{ marginTop: 10 }}
              >
                {`You have successfully completed the Employment Hero
									integration setup, you may now close this window.`}
              </Typography>
            </Grid>
          ) : null}
          {this.state.step === 2 && this.state.scError === true ? (
            <Grid item xs={12} className={classes.grid}>
              <Typography align="center">{'Setup Unsuccessful!'}</Typography>
              <Typography
                variant="body2"
                align="center"
                style={{ marginTop: 10 }}
              >
                {this.state.ehErrorMessages[1]}
              </Typography>
            </Grid>
          ) : null}
          <div className={classes.footer}></div>
          <OhsCopyright />
        </main>
      </React.Fragment>
    );
  }
}

export default OhsWithRouter(withStyles(styles)(OhsHrIntegration));
