import { Button, IconButton, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
const OhsMaterialButton = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: [
      'Lato',
      'Helvetica Neue',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(','),
    fontSize: '1rem',
  },
}))(Button);
export default OhsMaterialButton;
export const OhsMaterialIconButton = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 400,
    textTransform: 'none',
    fontFamily: [
      'Lato',
      'Helvetica Neue',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(','),
    fontSize: '1rem',
  },
}))(IconButton);
