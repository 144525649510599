import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import _ from 'lodash';
import ErrorIcon from '@mui/icons-material/Error';
import OhsModal2 from '../../global/modal/OhsModal2';
import OhsOTPInput from '../../global/form/OhsOTPInput';
import { mfaDetails } from './OhsMfaModels';
import { verifyMfa } from './OhsMfaServices';
import OhsMfaHelpModal from './OhsMfaHelpModal';

interface Props {
  title: string;
  open: boolean;
  onClose?: () => void;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mfaDetails?: mfaDetails | null;
  submitReLogin: () => void;
  login: (response: any) => void;
  useRecoveryCode: () => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  mainContainer: {
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    justifyContent: 'center',
  },
  singleContainer: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  helpLink: {
    fontSize: '.875rem',
    display: 'flex',
    justifyContent: 'center',
    color: 'black',
    whiteSpace: 'nowrap',
    marginBottom: '-10px',

    '& > a': {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '12px',
      textDecoration: 'none',
    },
  },
  singleResend: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    '& > a': {
      textDecoration: 'none',
    },
  },

  multiContainer: {
    display: 'flex',
    padding: '6px 0px',
  },
  multiResend: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    padding: '0px 12px',
  },
  marginBottom: {
    marginBottom: '40px',
  },
  email: {
    overflowWrap: 'break-word',
    fontWeight: 'bold',
    color: 'black',
  },
  footer: {
    padding: '8px',
  },
  error: {
    color: '#DB2828',
    textAlign: 'center',
  },
  errorIcon: {
    verticalAlign: '-4px',
    fontSize: '16px',
    marginRight: '2px',
  },
  actionLink: {
    textDecoration: 'none',
  },
  validating: {
    color: '#606060',
    margin: '-5px 0 15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  validatingIcon: {
    verticalAlign: '-4px',
    fontSize: '16px',
    marginRight: '8px',
    width: '18px !important',
    height: '18px !important',
    color: '#606060',
  },
  link: {
    textDecoration: 'none',
    margin: '5px',
    fontSize: '14px',
    cursor: 'pointer',
  },
}));

export const OTP_LENGTH = 6;

function OhsMfaModal(props: Props) {
  const { classes } = useStyles();
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [phoneOtp, setPhoneOtp] = useState<string>('');
  const [phoneOtpLoading, setPhoneOtpLoading] = useState<boolean>(false);
  const [emailOtp, setEmailOtp] = useState<string>('');
  const [emailOtpLoading, setEmailOtpLoading] = useState<boolean>(false);
  const [appOtp, setAppOtp] = useState<string>('');
  const [appOtpLoading, setAppOtpLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const email = props.mfaDetails?.destination;
  const phone = props.mfaDetails?.destination;
  const withEmailOtp = props.mfaDetails?.method === 'email';
  const withPhoneOtp = props.mfaDetails?.method === 'sms';
  const withAppOtp = props.mfaDetails?.method === 'app';

  // For when dual otp is allowed, add this condition
  const isMultiple = false;

  const handleReset = () => {
    setEmailOtp('');
    setPhoneOtp('');
    setAppOtp('');
  };
  const handleResend = (
    e: React.MouseEvent<HTMLAnchorElement> | undefined
  ): void => {
    e?.preventDefault();
    handleReset();
    props.submitReLogin();
  };

  const handleCancel = (
    e: React.MouseEvent<HTMLAnchorElement> | undefined
  ): void => {
    e?.preventDefault();
    handleReset();
    props.setModalOpen(false);
  };

  const handleVerifyOtp = async (method: 'email' | 'sms' | 'app') => {
    let otp = '';
    let formData = {};

    if (method === 'sms') {
      otp = phoneOtp;
      setPhoneOtpLoading(true);
    }
    if (method === 'email') {
      otp = emailOtp;
      setEmailOtpLoading(true);
    }
    if (method === 'app') {
      otp = appOtp;
      setAppOtpLoading(true);
    }

    if (method === 'sms' || method === 'email') {
      formData = {
        otp,
        mfaToken: props?.mfaDetails?.mfaToken,
        oob: props?.mfaDetails?.oob,
      };
    }
    if (method === 'app') {
      formData = {
        otp,
        mfaToken: props?.mfaDetails?.mfaToken,
      };
    }

    const response = await verifyMfa(formData);
    const withError = _.get(response, 'data.success') === false;
    if (withError) {
      setShowError(true);
    }

    if (method === 'sms') {
      setPhoneOtpLoading(false);
    }
    if (method === 'email') {
      setEmailOtpLoading(false);
    }
    if (method === 'app') {
      setAppOtpLoading(false);
    }

    if (_.get(response, 'success') === true) {
      props.login(response);
    }
  };

  const returnContent = () => {
    if (!isMultiple) {
      return (
        <>
          <Box className={classes.mainContainer}>
            {withEmailOtp && (
              <>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  Enter the authentication code we sent to:
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  <span className={classes.email}>{email}</span>
                </Typography>
                <div className={classes.singleContainer}>
                  <OhsOTPInput
                    value={emailOtp}
                    setValue={setEmailOtp}
                    shouldAutoFocus
                    length={OTP_LENGTH}
                    disabled={emailOtpLoading}
                  />
                </div>
              </>
            )}
            {withPhoneOtp && (
              <>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  Enter the authentication code we sent to:
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  {phone}
                </Typography>
                <div className={classes.singleContainer}>
                  <OhsOTPInput
                    value={phoneOtp}
                    setValue={setPhoneOtp}
                    shouldAutoFocus
                    length={OTP_LENGTH}
                    disabled={phoneOtpLoading}
                  />
                </div>
              </>
            )}
            {withAppOtp && (
              <>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  Enter the 6-digit authentication code generated by your app:
                </Typography>
                <div className={classes.singleContainer}>
                  <OhsOTPInput
                    value={appOtp}
                    setValue={setAppOtp}
                    shouldAutoFocus
                    length={OTP_LENGTH}
                    disabled={appOtpLoading}
                  />
                </div>
              </>
            )}
            {(phoneOtpLoading || emailOtpLoading || appOtpLoading) && (
              <Typography
                variant="body1"
                gutterBottom
                className={`${classes.link} ${classes.validating}`}
              >
                <CircularProgress className={classes.validatingIcon} />
                {`Verifiying authentication code ...`}
              </Typography>
            )}
            {showError && (
              <p className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                Incorrect verification code.
              </p>
            )}
            {(withEmailOtp || withPhoneOtp) && (
              <div>
                <div className={classes.singleResend}>
                  <Link href="#" onClick={(e: any) => handleResend(e)}>
                    Resend Code
                  </Link>
                </div>
              </div>
            )}
          </Box>
          {(withEmailOtp || withPhoneOtp) && (
            <div className={classes.helpLink}>
              <Link href="#" onClick={() => setOpenHelp(true)}>
                Not Receiving code?
              </Link>
            </div>
          )}
          {withAppOtp && (
            <div className={classes.helpLink}>
              <Link href="#" onClick={() => props.useRecoveryCode()}>
                Try Another Way
              </Link>
            </div>
          )}
        </>
      );
    }
    // Multiple OTP layout available when enabled
    if (isMultiple) {
      return (
        <Box className={classes.mainContainer}>
          <div className={classes.marginBottom}>
            <Typography variant="body1" color="textSecondary">
              Email Verification Code:
            </Typography>
            <div className={classes.multiContainer}>
              <OhsOTPInput
                value={emailOtp}
                setValue={setEmailOtp}
                shouldAutoFocus
                length={OTP_LENGTH}
                disabled={emailOtpLoading}
              />
              <div className={classes.multiResend}>
                <Link href="#" onClick={(e: any) => handleResend(e)}>
                  Resend Code
                </Link>
              </div>
            </div>
            <Typography variant="body2" color="textSecondary">
              Enter the 6-digit code sent to{' '}
              <span className={classes.email}>{email}</span>
            </Typography>
          </div>
          <div>
            <Typography variant="body1" color="textSecondary">
              Phone Number Verification Code:
            </Typography>
            <div className={classes.multiContainer}>
              <OhsOTPInput
                value={phoneOtp}
                setValue={setPhoneOtp}
                length={OTP_LENGTH}
                disabled={phoneOtpLoading}
              />
              <div className={classes.multiResend}>
                <Link href="#" onClick={(e: any) => handleResend(e)}>
                  Resend Code
                </Link>
              </div>
            </div>
            <Typography variant="body2" color="textSecondary">
              Enter the 6-digit code sent to {phone}
            </Typography>
          </div>
        </Box>
      );
    }
  };

  useEffect(() => {
    setShowError(false);
    if (phoneOtp.length === OTP_LENGTH) {
      handleVerifyOtp('sms');
    }
    if (emailOtp.length === OTP_LENGTH) {
      handleVerifyOtp('email');
    }
    if (appOtp.length === OTP_LENGTH) {
      handleVerifyOtp('app');
    }
  }, [phoneOtp, emailOtp, appOtp]);

  return (
    <>
      <OhsModal2
        open={props.open}
        title={props.title}
        setModalOpenFunc={props.setModalOpen}
        onClose={props.onClose}
        maxWidth={'xs'}
        ContentNode={returnContent()}
        actionNode={
          <div className={classes.footer}>
            <Link
              href="#"
              className={classes.actionLink}
              onClick={(e: any) => handleCancel(e)}
            >
              Cancel
            </Link>
          </div>
        }
      />
      <OhsMfaHelpModal
        open={openHelp}
        setHelpModalOpen={setOpenHelp}
        method={props.mfaDetails?.method || ''}
        useRecoveryCode={() => props.useRecoveryCode()}
      />
    </>
  );
}

export default OhsMfaModal;
