import { ModuleType } from '../models/OhsObject';

export const getApiPrexByModuleType = (
  moduleType: ModuleType
): string | null => {
  switch (moduleType) {
    case ModuleType.SafetyPlan:
      return 'safetyplans';

    default:
      return null;
  }
};
