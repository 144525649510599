import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  item: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  label: {
    fontWeight: 'bold',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function OhsModalForm({
  children,
  style,
}: {
  children?: React.ReactNode;
  style?: any;
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.root} style={style}>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </div>
  );
}
