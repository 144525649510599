import _ from 'lodash';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings, OhsFormLayoutType } from './OhsFormModels';

import { FormControl } from '@mui/material';
import OhsTooltip from './OhsTooltip';
import { OhsFormFieldWarningMessage } from './OhsFormFieldWarningMessage';
interface MessageFramePrps {
  title?: string;
  description?: string;
  labelEle?: React.ReactNode;
  noLabel?: boolean;
  required: boolean;
  id: string;
  ohsFormSettings: OhsFormSettings;
  inputEle: React.ReactNode;
  style?: React.CSSProperties;
}
export enum OhsFormFieldWarningTypes {
  Required = 'required',
  Email = 'isEmail',
  NoEmpty = 'notEmpty',
  MaxDate = 'maxDate',
  MinDate = 'minDate',
  MaxAmount = 'maxAmount',
  MinAmount = 'minAmount',
  MaxLength = 'maxLength',
  MinLength = 'minLength',
  OverSize = 'overSize',
  isNumber = 'isNumber',
  phoneCodeNumComplete = 'phoneCodeNumComplete',
  comparePasswords = 'comparePasswords',
  passwordValidation = 'passwordValidation',
  IsURL = 'isURL',
  ValidMaxFiles = 'validMaxFiles',
}

export const useStyles = makeStyles()(() => ({
  errorContainer: {
    background: '#fff6f6',
    borderColor: '#e0b4b4',
    color: '#e0b4b4',
  },
}));

function formHelperMessage(props: MessageFramePrps): string {
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );
  if (error != null) {
    switch (error.type) {
      case OhsFormFieldWarningTypes.NoEmpty:
      case OhsFormFieldWarningTypes.Required:
        if (_.isString(error.message) && error.message) {
          return error.message;
        }
        if (props.title) {
          return props.title + ' is required.';
        } else return 'The field is required.';

      case OhsFormFieldWarningTypes.Email:
        if (props.title) {
          return props.title + ' must be an email.';
        } else return 'The field must be an email.';
      case OhsFormFieldWarningTypes.MaxDate:
      case OhsFormFieldWarningTypes.MinDate:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MaxLength:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MinLength:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MaxAmount:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MinAmount:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.OverSize:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.isNumber:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.phoneCodeNumComplete:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.comparePasswords:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.passwordValidation:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.IsURL:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.ValidMaxFiles:
        return error.message?.toString() ?? '';
      default:
        break;
    }
  }

  return '';
}
export default function OhsFormFieldMessageFrame(props: MessageFramePrps) {
  const { classes } = useStyles();
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );

  return (
    <FormControl style={props.style} component="fieldset" fullWidth>
      {!props.noLabel &&
        props.ohsFormSettings.layout !== OhsFormLayoutType.AngularPageForm && (
          <>
            <span
              style={
                props.ohsFormSettings.layout ===
                OhsFormLayoutType.AngularModalUnderlinedForm
                  ? {}
                  : { fontWeight: 'bolder' }
              }
            >
              {props.title ? props.title : ''}
              {props.title && props.required === true ? ' * ' : '\u00A0'}
              {props.description && (
                <OhsTooltip message={props.description} color={'black'} />
              )}
            </span>
            {props.labelEle}
          </>
        )}

      <div className={error != null ? classes.errorContainer : ''}>
        {props.inputEle}
      </div>

      {error && (
        <OhsFormFieldWarningMessage>
          {formHelperMessage(props)}
        </OhsFormFieldWarningMessage>
      )}
    </FormControl>
  );
}
