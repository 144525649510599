import React from 'react';
import { Box, styled, TableCell, TableRow } from '@mui/material';
import { OhsUser } from '../../../../user/OhsUser';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const user = new OhsUser();
interface Props {
  index: number;
  subcatdata: any;
  showList: (item: string) => void;
  expandedRecords: any[];
}
const OhsRegisterSubCatTableRow = (props: Props) => {
  return (
    <OhsStyledSubCatTableRow
      key={props.index}
      onClick={() => props.showList(props.subcatdata.subcategory.toLowerCase())}
    >
      <TableCell colSpan={100} size="small" sx={{ color: '#ffffff' }}>
        <OhsExpandedIconWrap>
          <ChevronRightIcon
            sx={{
              transform: props.expandedRecords.includes(
                props.subcatdata.subcategory.toLowerCase()
              )
                ? 'rotate(90deg)'
                : 'none',
              fontSize: '20px',
              marginRight: '10px',
            }}
          />
          {props.subcatdata.subcategory}
        </OhsExpandedIconWrap>
      </TableCell>
    </OhsStyledSubCatTableRow>
  );
};

const OhsStyledSubCatTableRow = styled(TableRow)(() => {
  return {
    backgroundColor: user.configs.theme.HEADER_BAR_BG_COLOR,
    opacity: '0.75',
  };
});

const OhsExpandedIconWrap = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '10px',
    fontSize: '14px',
  };
});

export default OhsRegisterSubCatTableRow;
