import { Box, Button, styled, TextField } from '@mui/material';
import React from 'react';
import OhsModal2 from '../../../../global/modal/OhsModal2';
import { getMfaConfirmModalHeaders } from '../../OhsUserSettingsUtils';

const ConfirmInput = styled(TextField)({
  width: '80%',
  margin: 'auto',
  background: '#f0f0f0',
  borderRadius: '5px',
  '& .MuiOutlinedInput-root': {
    '& > fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px',
  },
});

const ContentWrap = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0 30px 0',
  width: '100%',
});

const ActionBtn = styled(Button)({
  textTransform: 'none',
  fontSize: '0.875rem',
  lineHeight: '0.875rem',
  padding: '8px 0 8px 0 !important',
});

const ActionsWrap = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

interface Props {
  open: boolean;
  setModalOpenFunc: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmFunc: () => void;
  confirmStatus: string;
  keyName: string;
  confirmModalTitle: string;
  confirmButtonText: string;
}
const OhsMfaConfirmModal = (props: Props) => {
  const [inputName, setInputName] = React.useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputName(event.target.value);
  };
  const handleDelete = async () => {
    setInputName('');
    props.handleConfirmFunc();
    props.setModalOpenFunc(false);
  };

  return (
    <OhsModal2
      headerColor="#F6F8FA"
      open={props.open}
      title={props.confirmModalTitle}
      setModalOpenFunc={() => props.setModalOpenFunc(true)}
      onClose={() => props.setModalOpenFunc(false)}
      maxWidth={'xs'}
      ContentNode={
        <ContentWrap>
          {getMfaConfirmModalHeaders(props.confirmStatus ?? 'confirmDisable')}
          <ConfirmInput
            id="outlined"
            value={inputName}
            onChange={handleChange}
            variant="outlined"
          />
        </ContentWrap>
      }
      actionNode={
        <ActionsWrap>
          <ActionBtn
            variant="contained"
            onClick={handleDelete}
            disabled={props.keyName !== inputName}
            color={
              props.confirmStatus === 'confirmDisable' ? 'error' : 'primary'
            }
          >
            {props.confirmButtonText ?? ''}
          </ActionBtn>
          <ActionBtn
            variant="text"
            onClick={() => props.setModalOpenFunc(false)}
          >
            Close
          </ActionBtn>
        </ActionsWrap>
      }
    ></OhsModal2>
  );
};

export default OhsMfaConfirmModal;
