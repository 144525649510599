import React from 'react'
import { Theme, Paper, IconButton, ButtonGroup } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';

import GreenButton from '../../global/buttons/OhsGreenButton';
import { OhsMaterialIconButton } from '../../global/buttons/OhsMaterialButton';
import { SmallScreenWidthBreakpoint } from '../../global/constants/OhsLayoutConstants';

const registerComponentstyles = makeStyles()((theme: Theme) => ({
  paperContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  buttonIcons: {
    // height: '100%!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      opacity: '.9',
      margin: '0!important',
      verticalAlign: 'top',
    },
  },
  hideForSmall: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'inline',
    },
  },
  buttonFormWrapper: {
    display: 'flex',
    borderRadius: '5px',
    marginBottom: '10px',
    padding: '20px 10px',
    alignItems: 'center'
  },
  bannerItemContainerStyle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(1),
    minHeight: '36px',
    borderRadius: 0,
  },
  carouselStyle: {
    backgroundColor: '#3b5998',
    '& button[aria-label="Next"]': {
      marginRight: '1em'
    }
  },
  carouselNavStyle: {
    top: '50% !important',
    transform: 'translateY(-50%) !important',
    padding: '5px',
    '& $next': {
      right: '1.5em'
    }
  },
  bannerMessageStyle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
  bannerCloseButtonStyle: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: '#f50057',
    padding: '2px',
    zIndex: 99
  },
  smallIconStyle: {
    fontSize: '0.90rem',
  },
  bannerButtonGroupStyle: {
    marginLeft: '10px',

    '& button': {
      borderRadius: 0,
      borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    },

    '& button:first-child': {
      borderRadius: '4px 0 0 4px',
    },

    '& button:last-child': {
      borderRadius: '0 4px 4px 0',
      borderRight: 0,
    }
  }
}));

interface AddButtonProps {
  onClick?: () => void;
  style?: object;
  label?: string;
}

export const AddButton = (props: AddButtonProps) => {
  const { classes } = registerComponentstyles();

  return (
    <div style={props.style}>
      <GreenButton onClick={props.onClick}>
        <AddIcon fontSize="small" className={classes.buttonIcons} />
        <span className={classes.hideForSmall}>{ props.label ? props.label : 'Add' }</span>
      </GreenButton>
    </div>
  )
}

interface FormContainerProps {
  children?: React.ReactNode;
}

export const FormContainer = ({ children } : FormContainerProps) => {
  const { classes } = registerComponentstyles();

  return (
    <Paper className={classes.paperContainer}>
      { children }
    </Paper>
  )
}

interface RemoveButtonProps {
  onClick?: () => void;
}

export const RemoveButtonIcon = (props: RemoveButtonProps) => (
  <OhsMaterialIconButton onClick={props.onClick}>
    <DeleteIcon color="error" />
  </OhsMaterialIconButton>
)

interface ButtonFormWrapperProps {
  children?: React.ReactNode;
}
export const ButtonFormWrapper = (props: ButtonFormWrapperProps) => {
  const { classes } = registerComponentstyles();

  return (
    <Paper elevation={3} className={classes.buttonFormWrapper}>
      { props.children }
    </Paper>
  )
}

interface BannerItemContainerProps {
  children?: React.ReactNode;
  style?: any;
}

export const BannerItemContainer = (props: BannerItemContainerProps) => {
  const { classes } = registerComponentstyles();

  return (
    <Paper elevation={0} style={props.style} className={classes.bannerItemContainerStyle} >
      { props.children }
    </Paper>
  )
}

interface BannerCarouselProps {
  children?: React.ReactNode;
  itemCount: number;
}
export const BannerCarousel = (props: BannerCarouselProps) => {
  const { classes } = registerComponentstyles();

  return (
    <Carousel
      className={classes.carouselStyle}
      indicators={false}
      stopAutoPlayOnHover
      autoPlay={false}
      navButtonsAlwaysVisible={props.itemCount > 1}
      navButtonsAlwaysInvisible={props.itemCount < 2}
      navButtonsProps={{
        className: classes.carouselNavStyle
      }}
    >
      { props.children }
    </Carousel>
  )
}

interface BannerMessageWrapperProps {
  children?: React.ReactNode;
}
export const BannerMessageWrapper = (props: BannerMessageWrapperProps) => {
  const { classes } = registerComponentstyles();

  return (
    <span className={classes.bannerMessageStyle}>
      {props.children}
    </span>
  )
}

export const BannerCloseButton = (props: { onClick: () => void }) => {
  const { classes } = registerComponentstyles();

  return (
    <IconButton onClick={props.onClick} className={classes.bannerCloseButtonStyle} >
      <CloseIcon classes={{ fontSizeSmall: classes.smallIconStyle }} fontSize="small" style={{ color: 'white' }} />
    </IconButton>
  )
}

export const BannerButtonGroup = (props: { children: React.ReactNode; }) => {
  const { classes } = registerComponentstyles()

  return (
    <ButtonGroup
      className={classes.bannerButtonGroupStyle}
      variant="contained"
      size="small"
    >
        {props.children}
    </ButtonGroup>
  )
}
