import React, { useState } from 'react';
import _ from 'lodash';
import OhsInspectionAvailableModal from './OhsInspectionAvailableModal';
import OhsOneOffInspectionModal from '../add-edit/OhsOneOffInspectionModal';
import OhsInspectionStartModal from '../add-edit/OhsInspectionStartModal';
import { OhsInspectionRecord } from '../OhsInspectionModels';

interface Props {
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OhsQuickInspection = (props: Props) => {
  const [startOneOffInspectionModal, setStartOneOffInspectionModal] =
    useState(false);
  const [startInspectionModal, setStartInspectionModal] = useState(false);
  const [targetInspection, setTargetInspection] = useState(
    new OhsInspectionRecord()
  );

  const handleStartOneOffInspectionModal = () => {
    if (startOneOffInspectionModal) {
      props.setModalOpen(true);
      setStartOneOffInspectionModal(false);
      setTargetInspection(new OhsInspectionRecord());
    } else {
      props.setModalOpen(false);
      setStartOneOffInspectionModal(true);
    }
  };

  const handleStartInspection = () => {
    if (startInspectionModal) {
      props.setModalOpen(true);
      setStartInspectionModal(false);
      setTargetInspection(new OhsInspectionRecord());
    } else {
      props.setModalOpen(false);
      setStartInspectionModal(true);
    }
  };

  return (
    <>
      {props.isOpen && (
        <OhsInspectionAvailableModal
          isOpen={props.isOpen}
          setModalOpen={props.setModalOpen}
          setStartOneOffInspectionModal={setStartOneOffInspectionModal}
          setStartInspectionModal={setStartInspectionModal}
          targetInspection={targetInspection}
          setTargetInspection={setTargetInspection}
        />
      )}
      {startOneOffInspectionModal && !_.isNil(targetInspection?._id) && (
        <OhsOneOffInspectionModal
          isOpen={startOneOffInspectionModal && !_.isNil(targetInspection?._id)}
          setModalOpen={handleStartOneOffInspectionModal}
          closeAllModal={() => setStartOneOffInspectionModal(false)}
          recordData={targetInspection}
        />
      )}
      {startInspectionModal && !_.isNil(targetInspection?._id) && (
        <OhsInspectionStartModal
          isOpen={startInspectionModal && !_.isNil(targetInspection?._id)}
          setModalOpen={handleStartInspection}
          closeAllModal={() => setStartInspectionModal(false)}
          recordData={targetInspection}
          task={targetInspection?.execute?.task}
        />
      )}
    </>
  );
};

export default OhsQuickInspection;
