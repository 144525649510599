import { getAngularLocalStorage } from '../common/OhsDataParse';

export class OhsRegisterFilter {
  archived?: boolean;
  modules?: Array<string>;
  workplaces?: Array<string>;
  locations_EMPTY?: boolean;
  locations?: Array<string>;
  constructor() {
    const urlParams = new URLSearchParams(window.location.search);
    const archivedFlag = urlParams.get('archived');
    if (archivedFlag === 'true') {
      this.archived = true;
    }
    const localFilter = getAngularLocalStorage('filterList');
    if (localFilter && localFilter.chemicalLocation) {
      const keys = Object.keys(localFilter.chemicalLocation);
      const locations = new Array<string>();
      keys.forEach(function (key) {
        if (localFilter.chemicalLocation[key].checkbox === true) {
          locations.push(key);
        }
      });
      if (locations.length > 0) {
        this.locations = locations;
      }
    }

    if (localFilter && localFilter.module) {
      const keys = Object.keys(localFilter.module);
      const modules = new Array<string>();
      keys.forEach(function (key) {
        if (localFilter.module[key].checkbox === true) {
          modules.push(key);
        }
      });
      if (modules.length > 0) {
        this.modules = modules;
      }
    }

    if (localFilter && localFilter.workplace) {
      const keys = Object.keys(localFilter.workplace);
      const workplaces = new Array<string>();
      keys.forEach(function (key) {
        if (
          localFilter.workplace[key].checkbox === true &&
          localFilter.workplace[key].obj &&
          localFilter.workplace[key].obj._id
        ) {
          workplaces.push(localFilter.workplace[key].obj._id);
        }
      });
      this.workplaces = workplaces;
    }
  }
}
