// Common Function

export const getValueByStringProperties = (object: any, key: string) => {
  let stringProperties = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  stringProperties = stringProperties.replace(/^\./, ''); // strip a leading dot
  let a = stringProperties.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  return object;
};

export const explodeTreeHierarychy = (collection: any[], separator: string) => {
  if (!Array.isArray(collection)) {
    return collection;
  }
  let value = '';

  collection.map((val, index) => {
    value = value + val;

    if (index < collection.length - 1) {
      let g = '';

      for (let m = index + 1; m > 0; m--) {
        g = g + separator;
      }

      value = value + ' ' + g + ' ';
    }
  });

  return value;
};

export const limitStrLength = (text: string, max_length: number) => {
  if (text.length > max_length - 3) {
    return text.substring(0, max_length).trimEnd() + ' ...';
  } else {
    return text;
  }
};

export const removeNonNumeric = (str: string | number) => {
  if (typeof str == 'number') return str;
  const newValue = str.replace(/[^\d.-]/g, '');
  return +newValue;
};

export const removeInvalidExcelCharacters = (title: string) => {
  // Characters not allowed as Excel titles
  const invalidChars = /[\[\]:\*?\,/\\]/g;

  // Replace invalid characters with an empty string
  return title.replace(invalidChars, '');
};
