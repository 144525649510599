import { TableCellProps } from '@mui/material';
import React from 'react';

import OhsRegisterTableBodyCell from './OhsRegisterTableBodyCell';
import OhsRegisterTableRow from './OhsRegisterTableRow';

interface Props {
  row: any;
  index: number;
}
export const OhsRegisterTableMoveRow = (props: Props) => {
  return (
    <OhsRegisterTableRow key={props.index} {...props.row.getRowProps()}>
      {props.row.cells.map(
        (
          cell: {
            column: any;
            getCellProps: () => JSX.IntrinsicAttributes & TableCellProps;
            render: (arg0: string) => React.ReactNode;
          },
          index: number
        ) => {
          return (
            <OhsRegisterTableBodyCell
              {...cell.getCellProps()}
              key={props.index.toString() + index}
              style={{
                width: cell.column.customWidth
                  ? cell.column.customWidth
                  : 'auto',
              }}
            >
              {cell.render('Cell')}
            </OhsRegisterTableBodyCell>
          );
        }
      )}
    </OhsRegisterTableRow>
  );
};
