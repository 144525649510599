import React, { useEffect } from 'react';
import * as _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';

import OhsModalHeader2 from '../../admin2/copy/OhsModalHeader2';
import { Theme } from '@mui/material';

interface Props {
  open: boolean;
  title?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  headerColor?: string;
  setModalOpenFunc?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  handleBack?: () => void;
  ContentNode: React.ReactNode;
  actionNode?: React.ReactNode;
  showClose?: boolean;
  alignHeader?: 'left' | 'center' | 'right';
}

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    padding: '18px 28px',
    position: 'relative',
    width: '100%',
  },
  footer: {
    padding: '10px 12px',
    background: '#F6F8FA',
    display: 'flex',
    justifyContent: 'end',
  },
  modal: {
    zIndex: theme.zIndex.modal + 1,
  },
}));

function OhsModal2(props: Props) {
  const { classes } = useStyles();

  const handleClose = () => {
    if (props.setModalOpenFunc) props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Dialog
      className={classes.modal}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={props.maxWidth != null ? props.maxWidth : 'md'}
      fullWidth={true}
    >
      <OhsModalHeader2
        title={props.title ?? ' '}
        align={props.alignHeader}
        onCloseFn={
          props.setModalOpenFunc || props.onClose ? handleClose : undefined
        }
        onBackFn={props.handleBack}
        headerColor={props.headerColor}
      />
      <div className={classes.content}>{props.ContentNode}</div>
      {props.actionNode && (
        <div className={classes.footer}>{props.actionNode}</div>
      )}
    </Dialog>
  );
}

export default OhsModal2;
