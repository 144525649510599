import ohsApiClient from 'axios';
import {
  onRequestErrorForBackdrop,
  onRequestForBackdrop,
  onResponseErrorForBackdrop,
  onResponseForBackdrop,
} from '../global/backdrop/OhsBackdropInterceptors';

import { getToken, isAuthenticated } from '../global/OhsAuthenticate';
import {
  responseMixpanelInterceptor,
  responseRejectedMixpanelInterceptor,
} from './mixpanel/OhsMixpanelInterceptors';
const getApiRoot = () => {
  const API_ROOT = (window as any).API_ROOT;

  if (API_ROOT) {
    return API_ROOT;
  } else {
    // return 'https://api.stage.safetychampion.tech';
    return 'https://api.dev.safetychampion.tech';
    // return 'http://http://13.237.219.167:3001';
    // return 'http://192.168.133.129:3000';
    // return 'https://api.safetychampion.online';
    // return 'http://localhost:3003';
  }
};
ohsApiClient.defaults.baseURL = getApiRoot();
if (isAuthenticated()) {
  ohsApiClient.defaults.headers.common['Authorization'] = getToken();
}
ohsApiClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
ohsApiClient.interceptors.response.use(
  responseMixpanelInterceptor,
  responseRejectedMixpanelInterceptor
);
//backdrop
ohsApiClient.interceptors.request.use(
  onRequestForBackdrop,
  onRequestErrorForBackdrop
);
ohsApiClient.interceptors.response.use(
  onResponseForBackdrop,
  onResponseErrorForBackdrop
);
export default ohsApiClient;
