import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { IconButton, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';
import { getAngularLocalStorage } from '../../common/OhsDataParse';
import {
  OhsFilterList,
  OhsWorkplaceFilter,
  updateFilterListByWorkplaceFilter,
} from './FilterModels';
const useStyles = makeStyles()((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
  },
}));
const getOhsWorkplaceFilterList = () => {
  let ohsFilterList = getAngularLocalStorage('filterList') as OhsFilterList;
  let ohsWorkplaceFilterList = new Array<OhsWorkplaceFilter>();
  if (ohsFilterList && ohsFilterList.workplace) {
    for (const key in ohsFilterList.workplace) {
      if (ohsFilterList.workplace[key]) {
        ohsWorkplaceFilterList.push(ohsFilterList.workplace[key]);
      }
    }
  }
  return ohsWorkplaceFilterList;
};

interface Props {
  ohsFilterList: OhsFilterList;
  setOhsFilterList: React.Dispatch<React.SetStateAction<OhsFilterList>>;
}
export const OhsWorkplaceFilterCheckList = (props: Props) => {
  const { classes } = useStyles();
  let ohsFilterList = { ...props.ohsFilterList };
  let ohsWorkplaceFilterList = new Array<OhsWorkplaceFilter>();
  let isSelectAll = true;
  if (ohsFilterList && ohsFilterList.workplace) {
    for (const key in ohsFilterList.workplace) {
      if (ohsFilterList.workplace[key]) {
        if (ohsFilterList.workplace[key].checkbox !== true) {
          isSelectAll = false;
        }
        ohsWorkplaceFilterList.push(ohsFilterList.workplace[key]);
      }
    }
  }
  const [ohsWorkplaceFilterListState, setOhsWorkplaceFilterListState] =
    React.useState(ohsWorkplaceFilterList);
  const [selectAllState, setSelectAllState] = React.useState(isSelectAll);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    ohsWorkplaceFilterList = [...ohsWorkplaceFilterListState];
    let checkedWorkplace = _.find(ohsWorkplaceFilterList, {
      obj: { name: event.target.name },
    });

    if (checkedWorkplace) checkedWorkplace.checkbox = event.target.checked;
    setSelectAllBasedOnList(ohsWorkplaceFilterList);
    const updatedFilter = updateFilterListByWorkplaceFilter(
      ohsFilterList,
      ohsWorkplaceFilterList
    );
    props.setOhsFilterList(updatedFilter);
    setOhsWorkplaceFilterListState(ohsWorkplaceFilterList);
  };
  const setSelectAllBasedOnList = (
    ohsWorkplaceFilterList: OhsWorkplaceFilter[]
  ) => {
    let isSelectAll = true;
    ohsWorkplaceFilterList.forEach((item) => {
      if (item.checkbox === false) isSelectAll = false;
    });
    setSelectAllState(isSelectAll);
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    ohsWorkplaceFilterList = [...ohsWorkplaceFilterListState];
    ohsWorkplaceFilterList.forEach((item) => {
      item.checkbox = event.target.checked;
    });
    const updatedFilter = updateFilterListByWorkplaceFilter(
      ohsFilterList,
      ohsWorkplaceFilterList
    );
    props.setOhsFilterList(updatedFilter);
    setSelectAllState(event.target.checked);

    setOhsWorkplaceFilterListState(ohsWorkplaceFilterList);
  };
  const [searchKey, setSearchKey] = React.useState('');
  const cleanKey = () => {
    setSearchKey('');

    ohsWorkplaceFilterList = getOhsWorkplaceFilterList();
    setSelectAllBasedOnList(ohsWorkplaceFilterList);
    setOhsWorkplaceFilterListState(ohsWorkplaceFilterList);
  };
  const onSearchKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value;
    setSearchKey(newValue);
    ohsWorkplaceFilterList = getOhsWorkplaceFilterList();
    if (newValue) {
      ohsWorkplaceFilterList = ohsWorkplaceFilterList.filter(
        (item) =>
          item.obj.name.toLowerCase().indexOf(newValue.toLocaleLowerCase()) > -1
      );
    }
    setSelectAllBasedOnList(ohsWorkplaceFilterList);
    setOhsWorkplaceFilterListState(ohsWorkplaceFilterList);
  };
  return (
    <div className={classes.paper}>
      <Box m={2}>
        <p>
          <b>Workplaces</b>
        </p>
      </Box>
      <Box m={2}>
        <OutlinedInput
          sx={{ width: '100%', maxWidth: '15rem', paddingRight: '0px' }}
          value={searchKey}
          onChange={onSearchKeyChange}
          placeholder="Search"
          endAdornment={
            <InputAdornment position="end">
              {_.isNil(searchKey) || searchKey === '' ? (
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton onClick={cleanKey}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </Box>
      <div style={{ height: '320px', overflow: 'hidden' }}>
        <div
          style={{
            height: '310px',
            paddingRight: '15px',
            overflow: 'auto',
          }}
        >
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllState}
                    onChange={handleSelectAll}
                    name="Select All"
                    size="small"
                    color="primary"
                  />
                }
                label={<b>Select All</b>}
              />
              {ohsWorkplaceFilterListState.map(
                (moduleFilter: any, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={moduleFilter.checkbox}
                          onChange={handleChange}
                          size="small"
                          color="primary"
                          name={moduleFilter.obj.name}
                        />
                      }
                      label={moduleFilter.obj.name}
                    />
                  );
                }
              )}
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
};
