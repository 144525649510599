import React from 'react';

import { FormControl, IconButton, InputBase, Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DragIndicatorSharpIcon from '@mui/icons-material/DragIndicatorSharp';

import { Icon, Label } from 'semantic-ui-react';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import OhsDragAndDrop, { DragHandle } from '../../../global/dnd/OhsDragAndDrop';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import OhsFormFieldLayout from '../OhsFormFieldLayout';

const useStyles = makeStyles()((theme) => ({
  chipRoot: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export interface Props extends OhsFormFieldBaseProps {}

function InputEle(props: Props) {
  const { classes } = useStyles();

  const [textArray, setTextArray] = React.useState(new Array<string>());

  const [optionsInput, setOptionInput] = React.useState('');

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      required: props.required,
      validate: {
        moreThanOne: (content: any) => {
          if (props.required === true && content && content.length === 0) {
            return false;
          }

          return true;
        },
      },
    });
  }, [props, props.ohsFormSettings.useFormMethods.register]);

  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );
  const resultList = props.ohsFormSettings.useFormMethods.watch(props.id);
  if (
    resultList &&
    resultList.length > 0 &&
    isArraySame(resultList, textArray) === false
  ) {
    setTextArray([...resultList]);
  }

  const handleAdd = () => {
    if (optionsInput) {
      if (textArray && textArray.length > 0) {
        textArray.push(optionsInput);
        props.ohsFormSettings.useFormMethods.setValue(props.id, textArray);
      } else {
        props.ohsFormSettings.useFormMethods.setValue(props.id, [optionsInput]);
      }
    }
    setOptionInput('');
    if (error) {
      props.ohsFormSettings.useFormMethods.trigger(props.id);
    }
  };
  const handleRemove = (value: string) => {
    const newList = textArray.filter((item: string) => item !== value);
    setTextArray(newList);
    props.ohsFormSettings.useFormMethods.setValue(props.id, newList);
  };
  const handleArrange = (newList: string[]) => {
    setTextArray(newList);
    props.ohsFormSettings.useFormMethods.setValue(props.id, newList);
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleAdd();
  };

  return (
    <FormControl component="fieldset" fullWidth onSubmit={handleSubmit}>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          onChange={(event) => setOptionInput(event.target.value)}
          value={optionsInput}
        />
        <IconButton onClick={handleAdd} className={classes.iconButton}>
          <AddIcon />
        </IconButton>
      </Paper>
      <div className={classes.chipRoot}>
        <OhsDragAndDrop
          list={textArray || []}
          setList={handleArrange}
          withHandle
          renderItem={(item: string) => (
            <Label
              as="a"
              tag
              style={{
                whiteSpace: 'nowrap',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '30px',
              }}
            >
              <>
                <span style={{ position: 'absolute', left: '8px', top: '2px' }}>
                  <DragHandle icon={<DragIndicatorSharpIcon />} />
                </span>
                <span style={{ wordBreak: 'break-all' }}>{item}</span>
                <Icon
                  name="delete"
                  onClick={() => {
                    handleRemove(item);
                  }}
                />
              </>
            </Label>
          )}
        />
      </div>
    </FormControl>
  );
}
function isArraySame(resultList: any, textArray: string[]) {
  if (
    resultList &&
    resultList.length > 0 &&
    resultList.length === textArray.length
  ) {
    for (let index = 0; index < resultList.length; index++) {
      const element = resultList[index];
      if (element !== textArray[index]) return false;
    }
  } else return false;
  return true;
}

export default function OhsTextArrayFields(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
