import * as _ from 'lodash';
import { toast } from 'react-toastify';

import { OhsDashboardBannerRecord } from './OhsDashboardBannerModels';
import ohsApiClient from '../../common/OhsApi';
import { getOhsUser } from '../../user/OhsUser';
import { TierType } from '../../models/tier/OhsTier';

export const getBannerList = (): Promise<Array<OhsDashboardBannerRecord>> => {
  const errorMessage = 'Unable to get the dashboard banner list.';
  const user = getOhsUser();
  const payload = user?.tier.type !== TierType.T1
    ? { filter: { enableOnWeb: true } }
    : {}

  return ohsApiClient
    .post('/dashboardbanner/list', payload)
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return _.get(response, ['data', 'result', 'items'], []);
      }

      const responseErrorMessage = _.get(
        response,
        ['data', 'error', 'message'],
        errorMessage
      );
      throw new Error(responseErrorMessage);
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const getBannerSingle = (
  id: string
): Promise<OhsDashboardBannerRecord> => {
  const errorMessage = 'Unable to get the dashboard banner item.';

  return ohsApiClient
    .get(`/dashboardbanner/${id}/fetch`, {})
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return _.get(response, ['data', 'result', 'item'], {});
      }

      const responseErrorMessage = _.get(
        response,
        ['data', 'error', 'message'],
        errorMessage
      );
      throw new Error(responseErrorMessage);
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const addBanner = (payload: any): Promise<OhsDashboardBannerRecord> => {
  const errorMessage = 'Unable to add dashboard banner record.';

  return ohsApiClient
    .post('/dashboardbanner/new', {
      ...payload,
      type: 'core.module.dashboardbanner',
      countryAndState: payload.countryAndState ?? [],
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return _.get(response, ['data', 'result'], {});
      }

      const responseErrorMessage = _.get(
        response,
        ['data', 'error', 'message'],
        errorMessage
      );
      throw new Error(responseErrorMessage);
    })
    .then((data) => {
      toast.success('Successfully created a Banner!');
      window.open('/dashboardbanner/home', '_self');
      return data;
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};

export const editBanner = (
  id: string,
  payload: any
): Promise<OhsDashboardBannerRecord> => {
  const errorMessage = 'Unable to edit dashboard banner record.';

  return ohsApiClient
    .post(`/dashboardbanner/${id}/edit`, {
      ...payload,
      type: 'core.module.dashboardbanner',
      countryAndState: payload.countryAndState ?? [],
    })
    .then((response) => {
      if (_.has(response, 'data.success') && response.data.success === true) {
        return _.get(response, ['data', 'result'], {});
      }

      const responseErrorMessage = _.get(
        response,
        ['data', 'error', 'message'],
        errorMessage
      );
      throw new Error(responseErrorMessage);
    })
    .then((data) => {
      toast.success('Successfully created a Banner!');
      window.open('/dashboardbanner/home', '_self');
      return data;
    })
    .catch(function (error) {
      console.error(error);
      toast.error(errorMessage);
      return [];
    });
};
