import {
  Box,
  Divider,
  ListItem,
  ListItemProps,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { OhsUser } from '../../user/OhsUser';
import { OhsLeftSideDrawerFreeUserModuleList } from './OhsLeftSideDrawerFreeUserModuleList';
export function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export const OhsLeftSideDrawerModuleList = () => {
  const user = new OhsUser();
  const moduleList = getModuleList();
  return (
    <div>
      <Box m={2}>
        <p>
          <b>Modules</b>
        </p>
      </Box>
      {user.tierNum === 1 && (
        <>
          <ListItemLink
            button
            key={'mobileappadmin'}
            href="/mobileappadmin/home"
            dense={true}
          >
            <ListItemText primary={'Mobile App Admin'} />
          </ListItemLink>
          <ListItemLink button href="/reporting/orgAdminReports" dense={true}>
            <ListItemText primary="Business Intelligence" />
          </ListItemLink>
          <ListItemLink button href="/dashboardbanner/home" dense={true}>
            <ListItemText primary="Dashboard Banner" />
          </ListItemLink>
        </>
      )}
      {(user.tierNum === 1 || user.tierNum === 2) && (
        <ListItemLink
          button
          key={'resourcecentre'}
          href="/resourcecentre/home"
          dense={true}
        >
          <ListItemText primary={'Resource Centre'} />
        </ListItemLink>
      )}
      {user.level === 'paid' &&
        moduleList.map((module) => (
          <ListItemLink button key={module.key} href={module.href} dense={true}>
            <ListItemText primary={module.title} />
          </ListItemLink>
        ))}
      {user.level === 'free' && <OhsLeftSideDrawerFreeUserModuleList />}

      <Divider />
    </div>
  );
};

class LeftDrawerModule {
  key: string;
  title: string;
  href: string;
  constructor(key: string, title: string, href: string) {
    this.key = key;
    this.title = title;
    this.href = href;
  }
}

export const ModuleHomeUrl = {
  'core.tier.T1': {},
  'core.tier.T2': {
    vdoc: '/documents/home',
    import: '/admin2/import',
    inspection: '/inspection/home/template?archived=false',
    safetyplan: '/safety-plan/home',
  },
  'core.tier.T3': {
    correspondence: '/correspondence/home/',
    action: '/action/home',
    inspection: '/inspection/home/scheduled?archived=false',
    chemical: '/chemical/home?tab=orgChem&archived=false',
    contractor: '/contractor/home?tab=orgContractor&archived=false',
    vdoc: '/documents/home',
    hr: '/people/home',
    incident: '/ai/home',
    reviewplan: '/reviewplan/home?tab=myReviewPlan&archived=false',
    safetyplan: '/safety-plan/home',
    visitor: '/visitor/home/',
    noticeboard: '/noticeboard/home',
    corporateRisk: '/crisk/home?tab=my',
    asset: '/assets/home?tab=my',
  },
  'core.tier.T4': {
    correspondence: '/correspondence/home/',
    action: '/action/home',
    inspection: '/inspection/home/scheduled',
    chemical: '/chemical/home?tab=myChem&archived=false',
    contractor: '/contractor/home?tab=myContractor&archived=false',
    vdoc: '/documents/home',
    hr: '/people/home',
    incident: '/ai/home',
    reviewplan: '/reviewplan/home?tab=myReviewPlan&archived=false',
    safetyplan: '/safety-plan/home',
    visitor: '/visitor/home/',
    noticeboard: '/noticeboard/home',
    corporateRisk: '/crisk/home?tab=my',
    asset: '/assets/home?tab=my',
  },
  'core.tier.T5': {},
};

const getModuleList = (): Array<LeftDrawerModule> => {
  let moduleList = new Array<LeftDrawerModule>();
  const user = new OhsUser();
  const moduleUrl = ModuleHomeUrl[user.tier.type];
  Object.keys(moduleUrl).forEach((moduleKey: string) => {
    let config = (user.configs as any)[moduleKey];
    if (config) {
      moduleList.push(
        new LeftDrawerModule(
          moduleKey,
          config.TITLE,
          (moduleUrl as any)[moduleKey]
        )
      );
    }
  });
  moduleList.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });
  return moduleList;
};
