import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  OhsActionColorRed,
  OhsActionColorGreen,
  OhsActionColorOrange,
  OhsActionColorGrey,
} from '../../theme/OhsThemeColors';
import { getOhsUser } from '../../../user/OhsUser';
import { TierType } from '../../../models/tier/OhsTier';
import {
  OhsAllocStatusFilter,
  OhsStatusFilterOption,
} from './filters/OhsFilterTypes';

const getIconColor = (row: any, option: string) => {
  if (option === OhsStatusFilterOption.Allocated) {
    if (row.tier.type === TierType.T3)
      return row.allocationStatus === OhsStatusFilterOption.Allocated
        ? OhsActionColorOrange
        : OhsActionColorGrey;

    if (row.tier.type === TierType.T4)
      return row.allocationStatus === OhsStatusFilterOption.Allocated
        ? OhsActionColorOrange
        : OhsActionColorGrey;
  }

  if (option === OhsStatusFilterOption.WorkplaceOwned) {
    if (row.tier.type === TierType.T4)
      return row.allocationStatus === OhsStatusFilterOption.WorkplaceOwned
        ? OhsActionColorGreen
        : OhsActionColorGrey;
  }

  if (option === OhsStatusFilterOption.NotAllocated) {
    if (row.tier.type === TierType.T3)
      return row.allocationStatus === OhsStatusFilterOption.NotAllocated
        ? OhsActionColorRed
        : OhsActionColorGrey;

    if (row.tier.type === TierType.T4)
      return row.allocationStatus === OhsStatusFilterOption.NotAllocated
        ? OhsActionColorRed
        : OhsActionColorGrey;
  }

  if (option === OhsAllocStatusFilter.Allocated) {
    return row.allocated ? OhsActionColorOrange : OhsActionColorGrey;
  }

  if (option === OhsAllocStatusFilter.NotAllocated) {
    return !row.allocated ? OhsActionColorRed : OhsActionColorGrey;
  }
};
interface Props {
  row: any;
  statusLists: any[];
}
const OhsRegisterTableStatusIcons = (props: Props) => {
  const user = getOhsUser();
  return (
    <>
      <div
        style={{ textAlign: 'center', height: '20px', whiteSpace: 'nowrap' }}
      >
        {props.statusLists &&
          props.statusLists.length > 0 &&
          props.statusLists.map((option: any, index: number) => {
            return option.permission.includes(user?.tierNum) ? (
              <span style={{ margin: '0 1px 0 1px' }} key={index}>
                <FiberManualRecordIcon
                  fontSize={'small'}
                  style={{
                    fill: getIconColor(props.row, option.value),
                  }}
                ></FiberManualRecordIcon>
              </span>
            ) : (
              ''
            );
          })}
      </div>
    </>
  );
};

export default OhsRegisterTableStatusIcons;
