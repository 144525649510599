import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { connect } from 'react-redux';

import OhsModuleBackground from '../../../global/register/OhsModuleBackground';
import OhsModuleHeader from '../../../global/register/OhsModuleHeader';
import { editFormLayout } from '../../../global/theme/OhsPresetStyles';
import MButton from '../../../global/buttons/OhsMaterialButton';
import {
  toggleTopBannerPreview,
  setBannerPreviewContent,
  bannerContentType,
} from '../../OhsDashboardSlice';

import {
  allowedTierForLocationForm,
  platformList,
} from '../OhsDashboardBannerConstants';
import {
  addBanner,
  editBanner,
  getBannerSingle,
} from '../OhsDashboardBannerServices';

import FilterForm from './form/FilterForm';
import CountryAndStateForm from './form/CountryAndStateForm';
import BannerContentForm from './form/BannerContentForm';
import { OhsFormLayoutType, OhsFormSettings } from 'global/form/OhsFormModels';
import { OhsDashboardBannerRecord } from '../OhsDashboardBannerModels';

const OhsDashboardBannerSave = (props: {
  toggleTopBannerPreview: (show: boolean) => void;
  setBannerPreviewContent: (payload: bannerContentType) => void;
}) => {
  const [ohsBannerRecord, setOhsBannerRecord] = useState(
    new OhsDashboardBannerRecord()
  );
  const ohsFormSettings = new OhsFormSettings(
    ohsBannerRecord,
    OhsFormLayoutType.AngularModalUnderlinedForm
  );

  const { bannerId } = useParams<{ bannerId: string }>();

  const formLayout = editFormLayout();
  const layout = formLayout.classes;
  const isAddForm = useMemo(() => _.isEmpty(bannerId), [bannerId]);

  useEffect(() => {
    // show banner preview through reducer
    props.toggleTopBannerPreview(true);

    // hide banner preview upon unomount of page
    return () => props.toggleTopBannerPreview(false);
  }, []);

  useEffect(() => {
    // Other than defaultValues for empty form
    // We need to reset the formValues with the values from
    // API response or saved in DB.
    if (bannerId && !isAddForm) {
      getBannerSingle(bannerId).then((bannerItem: OhsDashboardBannerRecord) => {
        let platform = [] as string[];

        for (const platformName of platformList) {
          if (_.get(bannerItem, platformName)) {
            platform.push(platformName);
          }
        }

        ohsFormSettings.useFormMethods.reset({
          ...bannerItem,
          platform,
        });

        setOhsBannerRecord(bannerItem);
      });
    }
  }, []);

  const [tierTypeValue, content]: [string, bannerContentType] =
    ohsFormSettings.useFormMethods.watch(['tierType', 'content']);

  const contentJson = JSON.stringify(content);
  useEffect(() => {
    if (content) {
      try {
        props.setBannerPreviewContent(JSON.parse(contentJson));
      } catch (error) {
        console.error(error);
      }
    }
  }, [contentJson]);

  const formSubmit = (data: any) => {
    const { platform, ...rest } = data;

    const transformedData = {
      ...rest,
      enableOnWeb: _.some(platform, (item) => item === 'enableOnWeb'),
      enableOnAndroid: _.some(platform, (item) => item === 'enableOnAndroid'),
      enableOnIOS: _.some(platform, (item) => item === 'enableOnIOS'),
    };

    if (isAddForm) {
      addBanner(transformedData);
    } else {
      if (bannerId) editBanner(bannerId, transformedData);
    }
  };

  return (
    <OhsModuleBackground>
      <main className={layout.layout} style={{ paddingBottom: '1em' }}>
        <OhsModuleHeader
          title={`${bannerId ? 'Edit' : 'Add'} Dashboard Banner Record`}
        />

        <FilterForm ohsFormSettings={ohsFormSettings} />
        {_.includes(allowedTierForLocationForm, tierTypeValue) && (
          <CountryAndStateForm ohsFormSettings={ohsFormSettings} />
        )}
        <BannerContentForm ohsFormSettings={ohsFormSettings} />

        <MButton
          variant="contained"
          color="primary"
          onClick={ohsFormSettings.useFormMethods.handleSubmit(formSubmit)}
        >
          Submit
        </MButton>
      </main>
    </OhsModuleBackground>
  );
};

export default connect(null, {
  toggleTopBannerPreview,
  setBannerPreviewContent,
})(OhsDashboardBannerSave);
