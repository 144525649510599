import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { setAngularLocalStorage } from '../../common/OhsDataParse';
import { OhsFilterList, OhsTaskFilterTypes } from './FilterModels';

const useStyles = makeStyles()((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
  },
  formLabel: {
    color: '#000000',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  formGroup: {
    marginBottom: '20px',
  },
}));

interface Props {
  ohsFilterList: OhsFilterList;
  setOhsFilterList: React.Dispatch<React.SetStateAction<any>>;
}
export const OhsTaskCheckboxFilters = (props: Props) => {
  const { classes } = useStyles();

  let ohsFilterList = { ...props.ohsFilterList };
  let ohsTaskFilterList = new Array<OhsTaskFilterTypes>();
  let checkboxSelectAll = true;
  if (ohsFilterList && ohsFilterList.task) {
    for (const key in ohsFilterList.task) {
      if (ohsFilterList.task[key]) {
        if (ohsFilterList.task[key].value !== true) {
          checkboxSelectAll = false;
        }
        ohsTaskFilterList.push(ohsFilterList.task[key]);
      }
    }
  }

  const [ohsTaskFilterListState, setOhsTaskFilterListState] =
    React.useState(ohsTaskFilterList);
  const [selectAllState, setSelectAllState] = React.useState(checkboxSelectAll);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    ohsTaskFilterList = [...ohsTaskFilterListState];
    ohsTaskFilterList[index].value = event.target.checked;

    const updateFilterList = {
      ...ohsFilterList,
      task: ohsTaskFilterList,
    };
    props.setOhsFilterList(updateFilterList);
    setSelectAllBasedOnList(ohsTaskFilterList);
    setOhsTaskFilterListState(ohsTaskFilterList);
    setAngularLocalStorage('filterList', updateFilterList);
  };

  const setSelectAllBasedOnList = (
    ohsWorkplaceFilterList: OhsTaskFilterTypes[]
  ) => {
    let isSelectAll = true;
    ohsWorkplaceFilterList.forEach((item) => {
      if (item.value === false) isSelectAll = false;
    });
    setSelectAllState(isSelectAll);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    ohsTaskFilterList = [...ohsTaskFilterListState];
    ohsTaskFilterList.forEach((item) => {
      item.value = event.target.checked;
    });
    const updateFilterList = {
      ...ohsFilterList,
      task: ohsTaskFilterList,
    };
    props.setOhsFilterList(updateFilterList);
    setSelectAllState(event.target.checked);
    setOhsTaskFilterListState(ohsTaskFilterList);
    setAngularLocalStorage('filterList', updateFilterList);
  };

  return (
    <div className={classes.paper}>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAllState}
              onChange={handleSelectAll}
              name="Select All"
              size="small"
              color="primary"
            />
          }
          label={<b>Select All</b>}
        />
        {ohsTaskFilterListState.map(
          (moduleFilter: OhsTaskFilterTypes, index: number) => {
            const isChecked = moduleFilter.value as boolean;
            return (
              moduleFilter.type === 'checkbox' && (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(e) => handleChange(e, index)}
                      size="small"
                      color="primary"
                      name={moduleFilter.name}
                    />
                  }
                  label={moduleFilter.name}
                />
              )
            );
          }
        )}
      </FormGroup>
    </div>
  );
};
