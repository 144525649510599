export class OhsUserMFAOTPTypes {
  method: string;
  otp: string;
  mfaToken: string;
  oob: string;
  recoveryCodes: string[];
  destination: string;
  barcodeUri?: string;
  firstTimeEnroll?: boolean;
  constructor() {
    this.method = '';
    this.otp = '';
    this.mfaToken = '';
    this.oob = '';
    this.recoveryCodes = [];
    this.destination = '';
  }
}

export class OhsResendCodeEnroll extends OhsUserMFAOTPTypes {
  resendOtp: boolean;
  constructor() {
    super()
    this.resendOtp = false;
  }
}
export interface OhsMfaListType {
  name: string;
  value: string;
}

export interface UserServicesMFAProps {
  email: string;
  sms: string;
}

export type MfaSettingsStatus = 'otpValidating' | 'invalid' | 'valid' | 'resendingcode' | 'idle';
