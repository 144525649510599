import {
  OhsRiskAssessmentsRequiredRecordField,
  OhsDateExpiryRecordField,
  OhsDatePurchasedRecordField,
  OhsIssuedToRecordField,
  OhsLocationRecordField,
  OhsNotesRecordField,
  OhsPurchaseAmountRecordField,
  OhsRiskAssessmentsCompleteRecordField,
  OhsSerialNumberRecordField,
  OhsStatusRecordField,
  OhsRiskRatingRecordField,
} from '../../assets/models/OhsAssetRecordFields';
import { ModuleType } from '../../models/OhsObject';
import {
  OhsCategoryRecordField,
  OhsDateIssuedRecordField,
  OhsReferenceIdRecordField,
  OhsSubcategoryRecordField,
  OhsTitleRecordField,
  OhsWorkplaceRecordField,
} from '../record-filed/OhsGlobalRecordFiled';

export type OhsExcelFilterType = 'multiple' | 'dateRange' | 'amount';
export interface OhsExcelFilter {
  type: OhsExcelFilterType;
  options?: Array<string>;
  value: Array<string>;
  maxAmount?: number;
  minAmount?: number;
}
export interface OhsExcelConfig {
  fn: string;
  fd: string;
  filter?: OhsExcelFilter;
  selected: boolean;
}
export const ModuleTypeExcelConfigNameMap = new Map([
  [ModuleType.SafetyPlan, 'OhsSafetyPlanExcelConfigList'],
]);

export const OhsSafetyPlanExcelConfigList: Array<OhsExcelConfig> = [
  {
    fn: 'exportTierInfo',
    fd: 'Workplace Name',
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
  { fn: 'name', fd: 'Name', selected: true },
  {
    fn: 'frequency',
    fd: 'Frequency',
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
  { fn: 'description', fd: 'Details', selected: true },
  {
    fn: 'exportCreateInfo',
    fd: 'User Creating The Safety Plan',
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
  { fn: 'tzDateCreated', fd: 'Timezone Reported', selected: true },
  {
    fn: 'dateCreated',
    fd: 'Date Created',
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },
  {
    fn: 'exportAllocateInfo',
    fd: 'Allocated To',
    selected: true,
  },
];

export const OhsNoticeboardExcelConfigList: Array<OhsExcelConfig> = [
  {
    fn: 'exportTierInfo',
    fd: 'Workplace Name',
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
  {
    fn: 'email',
    fd: 'Submitted By Email',
    selected: true,
    filter: { type: 'multiple', value: [] },
  },
  {
    fn: 'submittedBy',
    fd: 'Submitted By Name',
    selected: true,
    filter: { type: 'multiple', value: [] },
  },
  // {
  //   fn: 'formTitle',
  //   fd: 'Submitted By Form',
  //   filter: { type: 'multiple', value: [] },
  //   selected: true,
  // },
  {
    fn: 'date',
    fd: 'Date Created',
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },
];

export const OhsCorporateRiskdExcelConfigList: Array<OhsExcelConfig> = [
  { fn: 'title', fd: 'Title', selected: true },
  { fn: 'currentMitigation', fd: 'Mitigation and Control', selected: true },
  { fn: 'inherentRiskRating', fd: 'Initial Risk', selected: true },
  { fn: 'residualRisk', fd: 'Current Risk', selected: true },
  { fn: 'futureRiskRating', fd: 'Future Risk Rating', selected: true },
  {
    fn: 'exportTierInfo',
    fd: 'Workplace Name',
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
  { fn: 'dateIssued', fd: 'Identified Date', selected: true },
  {
    fn: 'dateExpiry',
    fd: 'Review Date',
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },
  { fn: 'exportCreateInfo', fd: 'Created By', selected: true },
  {
    fn: 'exportAllocateInfo',
    fd: 'Allocated To',
    selected: true,
  },
  { fn: 'notes', fd: 'Risk and Impact Description', selected: true },
  {
    fn: 'futureMitigation',
    fd: 'Future Additional Mitigation & Controls',
    selected: true,
  },
  { fn: 'riskOwnerLinks', fd: 'Risk Owner', selected: true },
];

export const OhsAssetsExcelConfigList: Array<OhsExcelConfig> = [
  {
    fn: 'exportTierInfo',
    fd: OhsWorkplaceRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },

  {
    fn: OhsCategoryRecordField.key,
    fd: OhsCategoryRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },

  {
    fn: OhsSubcategoryRecordField.key,
    fd: OhsSubcategoryRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },

  { fn: OhsTitleRecordField.key, fd: OhsTitleRecordField.name, selected: true },

  {
    fn: OhsReferenceIdRecordField.key,
    fd: OhsReferenceIdRecordField.name,
    selected: true,
  },

  {
    fn: OhsSerialNumberRecordField.key,
    fd: OhsSerialNumberRecordField.name,
    selected: true,
  },

  {
    fn: OhsDatePurchasedRecordField.key,
    fd: OhsDatePurchasedRecordField.name,
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },

  {
    fn: OhsPurchaseAmountRecordField.key,
    fd: OhsPurchaseAmountRecordField.name,
    filter: { type: 'amount', value: [] },
    selected: true,
  },

  {
    fn: OhsStatusRecordField.key,
    fd: OhsStatusRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },

  {
    fn: OhsIssuedToRecordField.key,
    fd: OhsIssuedToRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },

  {
    fn: OhsDateIssuedRecordField.key,
    fd: OhsDateIssuedRecordField.name,
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },

  {
    fn: OhsLocationRecordField.key,
    fd: OhsLocationRecordField.name,
    selected: true,
  },

  {
    fn: OhsDateExpiryRecordField.key,
    fd: OhsDateExpiryRecordField.name,
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },

  { fn: OhsNotesRecordField.key, fd: OhsNotesRecordField.name, selected: true },

  { fn: 'exportCreateInfo', fd: 'Created By', selected: true },
  {
    fn: 'exportAllocateInfo',
    fd: 'Allocated To',
    selected: true,
  },

  {
    fn: OhsRiskAssessmentsRequiredRecordField.key,
    fd: OhsRiskAssessmentsRequiredRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
  {
    fn: OhsRiskAssessmentsCompleteRecordField.key,
    fd: OhsRiskAssessmentsCompleteRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
  {
    fn: OhsRiskRatingRecordField.key,
    fd: OhsRiskRatingRecordField.name,
    filter: { type: 'multiple', value: [] },
    selected: true,
  },
];
