import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OhsFormFieldBaseProps } from '../OhsFormModels';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import OhsFormFieldLayout from '../OhsFormFieldLayout';
import { returnFieldStyle, returnVariant } from './OhsFieldLayout';

interface Props extends OhsFormFieldBaseProps {
  options: string[];
}

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
  },
  clearContainer: {
    top: '0',
    left: '0',
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    paddingRight: '26px',
    justifyContent: 'end',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  clearButton: {
    pointerEvents: 'auto',
  },
  closeIcon: {
    fontSize: '1rem !important',
  },
}));

function InputEle(props: Props) {
  const { classes } = useStyles();
  const disabled = props.disabled || false;
  const inputRef = props.ohsFormSettings.useFormMethods.register(props.id).ref;
  const error = _.get(
    props.ohsFormSettings.useFormMethods.formState.errors,
    props.id
  );
  const currency = props.ohsFormSettings.useFormMethods.watch(props.id) ?? null;

  const checkIfEmpty = (
    fieldValue: { code: string | null; amount: string | number | null } | null
  ) => {
    if (!_.isNil(fieldValue) && !props.required) {
      if (!fieldValue.code && !fieldValue.amount) {
        props.ohsFormSettings.useFormMethods.setValue(props.id, null);
        props.ohsFormSettings.useFormMethods.trigger(props.id);
      }
    }
  };
  const handleCodeChange = (event: SelectChangeEvent) => {
    const code = event.target.value as string;
    const newCurr = { ...currency };
    newCurr.code = code;

    props.ohsFormSettings.useFormMethods.setValue(props.id, newCurr);
    props.ohsFormSettings.useFormMethods.clearErrors(props.id);
  };
  const handleAmountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const amount = event.target.value as string;
    const newCurr = { ...currency };
    newCurr.amount = amount;
    props.ohsFormSettings.useFormMethods.setValue(props.id, newCurr);
    props.ohsFormSettings.useFormMethods.clearErrors(props.id);
  };

  const handleClear = () => {
    const newCurr = { ...currency };
    newCurr.code = '';

    props.ohsFormSettings.useFormMethods.setValue(props.id, newCurr);
    props.ohsFormSettings.useFormMethods.clearErrors(props.id);
  };

  useEffect(() => {
    checkIfEmpty(currency);
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: () => {
          const currencyValue =
            props.ohsFormSettings.useFormMethods.getValues(props.id) ?? null;

          const currAmount = currencyValue?.amount ?? null;
          const currCode = currencyValue?.code ?? null;

          const amountEmpty = currAmount === null || currAmount === '';
          const codeEmpty = currCode === null || currCode === '';

          if (props.required === true) {
            if (amountEmpty || codeEmpty) {
              return false;
            }
          }
          if (!amountEmpty && codeEmpty) {
            return 'Code is required if amount is not empty';
          }
          if (amountEmpty && !codeEmpty) {
            return 'Amount is required if code is not empty';
          }

          return true;
        },
        isNumber: (inputval: any) => {
          const currencyValue = props.ohsFormSettings.useFormMethods.getValues(
            props.id
          ) ?? {
            amount: '',
            code: props.options[0] ?? '',
          };
          if (currencyValue?.amount === null || currencyValue?.amount === '')
            return true;
          const isFloat = (n: any) =>
            parseFloat(n.match(/^-?\d*(\.\d+)?$/)) > 0;

          const numval = inputval;
          if (numval !== undefined) {
            if (!_.isNumber(Number(numval.amount)) || !isFloat(numval.amount)) {
              return 'Please enter number(s) only';
            }
          }
          return true;
        },
      },
    });
  }, [
    currency,
    props.id,
    props.ohsFormSettings.useFormMethods,
    props.ohsFormSettings.useFormMethods.register,
    props.options,
    props.required,
  ]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <FormControl component="fieldset" fullWidth>
          <div className={classes.container}>
            <Select
              fullWidth
              margin="dense"
              value={currency?.code ?? ''}
              error={error != null}
              onChange={handleCodeChange}
              required={props.required}
              variant={returnVariant(props.ohsFormSettings.layout)}
              size="small"
              disabled={disabled}
              style={returnFieldStyle(props.ohsFormSettings.layout, {
                marginTop: '8px',
              })}
            >
              {props.options.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            {currency?.code && !disabled && (
              <div className={classes.clearContainer}>
                <IconButton
                  size="small"
                  className={classes.clearButton}
                  onClick={handleClear}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </div>
            )}
          </div>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={9}>
        <FormControl component="fieldset" fullWidth>
          <TextField
            ref={inputRef}
            type="number"
            error={error != null}
            fullWidth
            margin="dense"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={currency?.amount ?? ''}
            onChange={handleAmountChange}
            required={props.required}
            disabled={disabled}
            variant={returnVariant(props.ohsFormSettings.layout)}
            style={returnFieldStyle(props.ohsFormSettings.layout)}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default function OhsCurrencyField(props: Props) {
  return (
    <>
      <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />
    </>
  );
}
