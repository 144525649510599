import React from 'react';
import { Row } from 'react-table';
import { Button, ButtonGroup, Dropdown, Icon } from 'semantic-ui-react';
import OhsDeleteModal from '../../global/modal/OhsDeleteModal';
import OhsModuleHeader, {
  registerButtonStyles,
} from '../../global/register/OhsModuleHeader';
import OhsRegister from '../../global/register/OhsRegister';
import { ModuleType } from '../../models/OhsObject';
import { getOhsUser } from '../../user/OhsUser';
import { OhsVdocRecord } from '../../vdoc/OhsVdocModels';
import { getVdocLinkableList } from '../../vdoc/OhsVdocServices';
import {
  OhsNoticeboardBlock,
  OhsNoticeboardBlockType,
  OhsNoticeboardBlockTypeDisplay,
  OhsNoticeboardBlockVdoc,
  OhsNoticeboardBlockYoutube,
  OhsNoticeBoardRecordConfig,
} from '../OhsNoticeBoardModels';
import { editNoticeBoardRecordConfig } from '../OhsNoticeBoardServices';
import { OhsNoticeBoardFileBlockModal } from './OhsNoticeBoardFileBlockModal';
import { OhsNoticeBoardLinkBlockModal } from './OhsNoticeBoardLinkBlockModal';
import { OhsNoticeBoardVdocBlockAddModal } from './OhsNoticeBoardVdocBlockAddModal';
import { OhsNoticeBoardYoutubeBlockModal } from './OhsNoticeBoardYoutubeBlockModal';

interface Props {
  noticeboardBlocks: OhsNoticeboardBlock[];
  noticeBoardConfig: OhsNoticeBoardRecordConfig | null;
}
const addOptions = [
  { key: 'vdoc', icon: 'book', text: 'Document', value: 'vdoc' },
  { key: 'youtube', icon: 'youtube play', text: 'Youtube', value: 'youtube' },
  { key: 'file', icon: 'file', text: 'File', value: 'file' },
  { key: 'link', icon: 'linkify', text: 'Link', value: 'link' },
];

export const OhsNoticeBoardBlockList = (props: Props) => {
  const [noticeboardBlocks, setNoticeboardBlocks] = React.useState(
    new Array<OhsNoticeboardBlock>()
  );
  const [vdocList, setVdocList] = React.useState(new Array<OhsVdocRecord>());
  const [vdocModalOpen, setVdocModalOpen] = React.useState(false);
  const [youtubeModalOpen, setYoutubeModalOpen] = React.useState(false);
  const [fileModalOpen, setFileModalOpen] = React.useState(false);
  const [linkModalOpen, setLinkModalOpen] = React.useState(false);
  const [addOptionValue, setAddOptionValue] = React.useState('');
  const [operateIndex, setOperateIndex] = React.useState(-1);
  const [operateBlock, setOperateBlock] =
    React.useState<OhsNoticeboardBlock | null>(null);
  React.useEffect(() => {
    setNoticeboardBlocks(props.noticeboardBlocks);
  }, [props.noticeboardBlocks]);
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [rows, setRows] = React.useState(new Array<Row<any>>());
  const columns = React.useMemo(
    () => [
      {
        id: 'titleId',
        Header: 'Title',
        accessor: 'data.title',
      },
      {
        Header: 'Description',
        accessor: 'data.description',
      },
      {
        Header: 'Type',
        accessor: 'typeDisplay',
      },
    ],
    []
  );
  const editFunc = (item: OhsNoticeboardBlock, index?: number) => {
    if (index != null) {
      setOperateIndex(index);
      switch (item.type) {
        case OhsNoticeboardBlockType.OhsYoutube:
          setOperateBlock(item);
          setYoutubeModalOpen(true);

          break;
        case OhsNoticeboardBlockType.OhsAttachment:
          setOperateBlock(item);
          setFileModalOpen(true);

          break;
        case OhsNoticeboardBlockType.OhsLink:
          setOperateBlock(item);
          setLinkModalOpen(true);

          break;

        default:
          break;
      }
    }
  };
  const openDeleteModalFunc = (item: OhsNoticeboardBlock, index?: number) => {
    if (index != null) {
      setOperateIndex(index);
      setOperateBlock(item);
      setDelModalOpen(true);
    }
  };
  const { classes } = registerButtonStyles();
  const handleAddOptionChange = async (event: any, data: any) => {
    switch (data.value) {
      case 'vdoc':
        {
          const vdocList = await getVdocLinkableList();
          setVdocList(vdocList);
          setVdocModalOpen(true);
          setAddOptionValue('');
        }
        break;
      case 'youtube':
        setOperateBlock({
          type: OhsNoticeboardBlockType.OhsYoutube,
          typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsYoutube,
          data: { _id: '', title: '', source: '', description: '' },
        });
        setYoutubeModalOpen(true);

        break;
      case 'file':
        setOperateBlock({
          type: OhsNoticeboardBlockType.OhsAttachment,
          typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsAttachmentFile,
          data: {
            _id: '',
            title: '',
            description: '',
            attachment: {} as any,
          },
        });
        setFileModalOpen(true);
        break;
      case 'link':
        setOperateBlock({
          type: OhsNoticeboardBlockType.OhsLink,
          typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsLink,
          data: {
            _id: '',
            title: '',
            description: '',
            links: [{ title: null, uri: '' }],
            attachment: {} as any,
          },
        });
        setLinkModalOpen(true);

        break;
      default:
        break;
    }
  };
  const handleAddVdocList = (vdocList: OhsVdocRecord[]) => {
    const vdocBlockList = new Array<OhsNoticeboardBlockVdoc>();
    for (let index = 0; index < vdocList.length; index++) {
      const element = vdocList[index];
      vdocBlockList.push({
        type: OhsNoticeboardBlockType.OhsDocument,
        typeDisplay: OhsNoticeboardBlockTypeDisplay.OhsDocument,
        data: {
          _id: element._id,
          title: element.version.name,
          description: element.version.description,
          type: ModuleType.VersionDocument,
          attachment: element.version.attachments[0],
        },
      });
    }
    setNoticeboardBlocks(noticeboardBlocks.concat(vdocBlockList));
    saveBlockList(noticeboardBlocks.concat(vdocBlockList));
  };
  const handleYouTubeBlock = (youtubeBlock: OhsNoticeboardBlockYoutube) => {
    const list = [...noticeboardBlocks];
    if (operateIndex > -1) {
      list[operateIndex] = youtubeBlock;
    } else {
      list.push(youtubeBlock);
    }
    setNoticeboardBlocks(list);
    setOperateIndex(-1);
    saveBlockList(list);
  };
  const handleFileBlock = (fileBlock: OhsNoticeboardBlock) => {
    const list = [...noticeboardBlocks];
    if (operateIndex > -1) {
      list[operateIndex] = fileBlock;
    } else {
      list.push(fileBlock);
    }
    setNoticeboardBlocks(list);
    setOperateIndex(-1);
    saveBlockList(list);
  };
  const handleLinkBlock = (linkBlock: OhsNoticeboardBlock) => {
    const list = [...noticeboardBlocks];
    if (operateIndex > -1) {
      list[operateIndex] = linkBlock;
    } else {
      list.push(linkBlock);
    }
    setNoticeboardBlocks(list);
    setOperateIndex(-1);
    saveBlockList(list);
  };
  const setAddVdocModal = (open: boolean) => {
    setVdocModalOpen(open);
    setVdocList([]);
  };
  const saveBlockList = async (blockList: OhsNoticeboardBlock[]) => {
    if (props.noticeBoardConfig && blockList) {
      const config = { ...props.noticeBoardConfig };
      config.blocks = blockList;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) window.location.reload();
    }
  };
  const saveBlockListFunc = async () => {
    const blockList = new Array<OhsNoticeboardBlock>();

    rows.forEach((item: any) => {
      blockList.push(item.original);
    });

    if (props.noticeBoardConfig) {
      const config = { ...props.noticeBoardConfig };
      config.blocks = blockList;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) window.location.reload();
    }
  };
  const editBlockShow = (item: OhsNoticeboardBlock): boolean => {
    if (item && item.type !== OhsNoticeboardBlockType.OhsDocument) {
      return true;
    }
    return false;
  };
  const handleDeleteFunc = async () => {
    const toBeDelete = noticeboardBlocks[operateIndex];
    const list = [...noticeboardBlocks];
    if (toBeDelete && list) {
      switch (toBeDelete.type) {
        case OhsNoticeboardBlockType.OhsDocument:
        case OhsNoticeboardBlockType.OhsYoutube:
          list.splice(operateIndex, 1);
          break;
        case OhsNoticeboardBlockType.OhsAttachment:
        case OhsNoticeboardBlockType.OhsLink:
          list[operateIndex] = {
            type: toBeDelete.type,
            data: { _id: toBeDelete.data._id, attachment: { delete: true } },
          } as OhsNoticeboardBlock;
          break;

        default:
          break;
      }
    }

    // setNoticeboardBlocks(list);
    setDelModalOpen(false);
    if (props.noticeBoardConfig) {
      const config = { ...props.noticeBoardConfig };
      config.blocks = list;
      const editNoticeBoardRecordConfigReq = await editNoticeBoardRecordConfig(
        config
      );
      if (editNoticeBoardRecordConfigReq) window.location.reload();
    }
  };
  const user = getOhsUser();
  return (
    <>
      <OhsModuleHeader title="Resource Register">
        <ButtonGroup floated="right">
          <Dropdown
            floating
            options={addOptions}
            onChange={handleAddOptionChange}
            openOnFocus={false}
            value={addOptionValue}
            trigger={
              <Button color="green" className={classes.button}>
                <Icon name="plus" className={classes.buttonIcons} />
                <span className={classes.hideForSmall}>Resource</span>
              </Button>
            }
          />
          <Button
            className={classes.button}
            style={{ height: '36px' }}
            color="blue"
            onClick={() => {
              saveBlockListFunc();
            }}
          >
            <Icon className={classes.buttonIcons} name="save" />
            <span className={classes.hideForSmall}>Save</span>
          </Button>
        </ButtonGroup>
      </OhsModuleHeader>
      <OhsRegister
        columns={columns}
        data={noticeboardBlocks}
        rowOperations={{
          editButton: {
            func: editFunc,
            showFunc: editBlockShow,
            permission: user?.configs.noticeboard?.PERMISSIONS.edit === true,
          },

          openDeleteModalButton: {
            func: openDeleteModalFunc,
            permission: user?.configs.noticeboard?.PERMISSIONS.edit === true,
          },
        }}
        setRows={setRows}
        noPagination={true}
        enableDragDrop={true}
      ></OhsRegister>
      <OhsNoticeBoardVdocBlockAddModal
        setModalOpenFunc={setAddVdocModal}
        open={vdocModalOpen}
        vdocList={vdocList}
        handleAddVdocListFunc={handleAddVdocList}
      />
      <OhsNoticeBoardYoutubeBlockModal
        setModalOpenFunc={setYoutubeModalOpen}
        open={youtubeModalOpen}
        handleYouTubeFunc={handleYouTubeBlock}
        operateYoutubeBlock={operateBlock}
      />
      <OhsDeleteModal
        open={delModalOpen}
        setModalOpenFunc={setDelModalOpen}
        moduleTitle={'Resource'}
        handleDeleteFunc={handleDeleteFunc}
        recordName={operateBlock?.data.title ?? ''}
      />
      <OhsNoticeBoardFileBlockModal
        setModalOpenFunc={setFileModalOpen}
        open={fileModalOpen}
        handleFileFunc={handleFileBlock}
        operateFileBlock={operateBlock}
      />
      <OhsNoticeBoardLinkBlockModal
        setModalOpenFunc={setLinkModalOpen}
        open={linkModalOpen}
        handleLinkFunc={handleLinkBlock}
        operateLinkBlock={operateBlock}
      />
    </>
  );
};
