import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import _ from 'lodash';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import logo from '../modules/build/img/sc-logo.png';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { toggleRightSideDrawer } from './OhsDashboardSlice';
import { OhsLowerTier } from '../user/configurations/OhsAdmin2Config';
import { morphInto } from '../user/morph/MorphServices';
import { OhsUser } from '../user/OhsUser';
import { IconButton, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { checkCurrentUrlAfterMorph } from '../user/morph/UrlCheckServices';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

const OhsRightSideDrawer = (props: any) => {
  const { classes } = useStyles();

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    props.toggleRightSideDrawer(open);
    cleanKey();
  };
  const morphDown = (id: string, event: any) => {
    morphInto(id).then((response: any) => {
      if (response.success === true) {
        checkCurrentUrlAfterMorph();
      }
    });
  };
  const user = new OhsUser();
  const [searchKey, setSearchKey] = React.useState('');
  const [lowerTierList, setLowerTierList] = React.useState(
    user.configs.admin2?.LOWER_TIER_ACCESS
  );
  const cleanKey = () => {
    setSearchKey('');
    setLowerTierList(user.configs.admin2?.LOWER_TIER_ACCESS);
  };
  const onSearchKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value;
    setSearchKey(newValue);

    if (newValue === '') {
      setLowerTierList(user.configs.admin2?.LOWER_TIER_ACCESS)
    } else {
      let newList = new Array<OhsLowerTier>();
      if (newValue && user.configs.admin2 && user.configs.admin2.LOWER_TIER_ACCESS) {
        user.configs.admin2.LOWER_TIER_ACCESS.forEach((item) => {
          if (
            item.access === true &&
            item.name &&
            item.name.toLowerCase().indexOf(newValue.toLowerCase()) > -1
          ) {
            newList.push(item);
          }
        });
      }
      setLowerTierList(newList);
    }
  };
  return (
    <Drawer anchor="right" open={props.isOpen} onClose={toggleDrawer(false)}>
      <div className={clsx(classes.list)} role="presentation">
        <List>
          <Box m={1}>
            <img style={{ width: '64px' }} src={logo} alt="" />
          </Box>
          <Divider />
          <Box m={2}>
            <p>
              <b>Login to: </b>
            </p>
          </Box>
          <Divider />

          <Box m={2}>
            <p>
              <b>Workplaces</b>
            </p>
          </Box>
          <Box m={2}>
            <OutlinedInput
              sx={{ width: '100%', paddingRight: '0px' }}
              value={searchKey}
              onChange={onSearchKeyChange}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  {(_.isNil(searchKey) || searchKey === '') 
                    ? (
                      <IconButton type="submit">
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={cleanKey}>
                        <ClearIcon />
                      </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </Box>

          <div onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {lowerTierList?.map((lowerTier: OhsLowerTier) =>
              lowerTier.disabled !== true && lowerTier.access === true ? (
                <ListItem
                  button
                  key={lowerTier._id}
                  onClick={(event: any) => {
                    morphDown(lowerTier._id, event);
                  }}
                >
                  <ListItemText primary={lowerTier.name} />
                </ListItem>
              ) : null
            )}
          </div>
        </List>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => {
  return { isOpen: state.dashboardReducer.rightSideDrawerToggleOpen };
};

export default connect(mapStateToProps, { toggleRightSideDrawer })(
  OhsRightSideDrawer
);
